import React from 'react';
import { Navbar as MantineNavbar, type NavbarProps as MantineNavbarProps, Group, Skeleton } from '@mantine/core';
import useNavbarStyles from './useNavbarStyles';

const NavbarLoading: React.FC<Omit<MantineNavbarProps, 'children'>> = ({ className, ...props }) => {
  const { classes, cx } = useNavbarStyles();

  return (
    <MantineNavbar className={cx(classes.navbar, className)} {...props}>
      <div className={classes.navbarMain}>
        <Group align="left" className={classes.header}>
          <div className={classes.logo}>
            <Skeleton className={classes.navbarSkelton} height={52} circle mb="xl" />
          </div>
          <Skeleton className={classes.navbarSkelton} height={22} width="70%" mt="sm" />{' '}
          {/* Mimics the welcome back text */}
        </Group>

        {/* Mimic sections */}
        <div className={classes.navbarSection}>
          <Skeleton className={classes.navbarSkelton} height={18} width="20%" />
          <Skeleton className={classes.navbarSkelton} height={20} mt="md" width="90%" />
          <Skeleton className={classes.navbarSkelton} height={20} mt="xs" width="90%" />
          <Skeleton className={classes.navbarSkelton} height={20} mt="xs" width="90%" />
        </div>
      </div>

      <div className={classes.navbarSection}>
        <Skeleton className={classes.navbarSkelton} height={18} width="30%" />
        <Skeleton className={classes.navbarSkelton} height={20} mt="md" width="80%" />
        <Skeleton className={classes.navbarSkelton} height={20} mt="xs" width="80%" />
      </div>
    </MantineNavbar>
  );
};

export default NavbarLoading;
