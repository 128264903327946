import { Code, Flex, Table, Box, createStyles, Paper, Title, Tabs, Container } from '@mantine/core';
import { TabsPanel } from '@mantine/core/lib/Tabs/TabsPanel/TabsPanel';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Forbidden from 'pages/Forbidden';
import devApi from 'services/dev';
import userApi from 'services/user';

export default function Diagnostic() {
  const { classes } = useStyles();

  const { data: userData, isLoading: isUserLoading, error: userError } = userApi.endpoints.getUser.useQuery();

  const {
    data: providerData,
    isLoading: isproviderLoading,
    error: providerError,
  } = userApi.endpoints.getProviders.useQuery();

  const { data: inviteData, isLoading: isInviteLoading, error: inviteError } = userApi.endpoints.getInvites.useQuery();

  return (
    <Container w='100%'>
      <Flex>
      <Tabs color="dark" defaultValue="User">
        <Tabs.List>
          <Tabs.Tab value="User">User</Tabs.Tab>
          <Tabs.Tab value="Providers">Providers</Tabs.Tab>
          <Tabs.Tab value="Samples">Samples</Tabs.Tab>
          <Tabs.Tab value="Intakes">Intakes</Tabs.Tab>
          <Tabs.Tab value="Invites">Invites</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="User">
          <Paper shadow="xs" p="md">
            <Title order={4}>User</Title>
            <pre>{userData ? JSON.stringify(userData, null, 3) : 'Loading...'}</pre>
          </Paper>
        </Tabs.Panel>
        <Tabs.Panel value="Providers">
          <Paper shadow="xs" p="md">
            <Title order={4}>Providers</Title>
            <pre>{providerData ? JSON.stringify(providerData, null, 3) : 'Loading...'}</pre>
          </Paper>
        </Tabs.Panel>
        <Tabs.Panel value="Samples">
          <Paper shadow="xs" p="md">
            <Title order={4}>Samples</Title>
            <pre>
              <Code>todo</Code>
            </pre>
          </Paper>
        </Tabs.Panel>
        <Tabs.Panel value="Intakes">
          <Paper shadow="xs" p="md">
            <Title order={4}>Intakes</Title>
            <pre>
              <Code>todo</Code>
            </pre>
          </Paper>
        </Tabs.Panel>
        <Tabs.Panel value="Invites">
          <Paper shadow="xs" p="md">
            <Title order={4}>Invites</Title>
            <pre>
            <pre>{inviteData ? JSON.stringify(inviteData, null, 3) : 'Loading...'}</pre>
            </pre>
          </Paper>
        </Tabs.Panel>
      </Tabs>
    </Flex>
    </Container>
    
  );
}

const useStyles = createStyles((theme) => ({
  containerBox: {
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.dark[3],
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.dark[2]}`,
  },
}));
