import { Text } from '@mantine/core';

export default function FindingDisclaimer() {
  return (
    <Text mb="md">
      The following are all of the associations we found between your microbiome and any health concerns.{' '}
      <span style={{ fontWeight: 700 }}>
        It&apos;s important to understand that these associations are not clinical diagnoses.
      </span>{' '}
      They instead mean that your microbiome signature shares similarities with those documented in clinical research
      with these same issues.
    </Text>
  );
}
