import * as Yup from 'yup';
import { Text, Box, TextInput, createStyles, Textarea, Center, Group, Button } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import Card from 'components/Card';

const editClientFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required').default(''),
  lastName: Yup.string().required('Last name is required').default(''),
  email: Yup.string().email('Invalid email').required('Email is required').default(''),
  internalClientId: Yup.string().optional().nullable(),
  internalNotes: Yup.string().optional().nullable(),
});
export interface ClientData extends Yup.InferType<typeof editClientFormSchema> {}

interface EditClientFormProps {
  client: ClientData;
  onSaveClient: (data: ClientData) => Promise<void>;
  onCancel: () => void;
}

export default function AddClientForm({ client, onSaveClient, onCancel }: EditClientFormProps) {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      ...client,
    },
    validate: yupResolver(editClientFormSchema),
  });

  const handleSubmit = (values: ClientData) => {
    onSaveClient(values);
  };

  const CartTitle = ({ children }: { children: React.ReactNode }) => (
    <Text className={classes.cardTitle}>{children}</Text>
  );

  return (
    <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
      <CartTitle>client info</CartTitle>
      <Card className={classes.card}>
        <TextInput disabled label="First name" {...form.getInputProps('firstName')} />
        <TextInput disabled label="Last name" {...form.getInputProps('lastName')} />
        <TextInput disabled label="Email" {...form.getInputProps('email')} />
        <TextInput
          label={
            <Text>
              Client ID{' '}
              <Text ml="sm" span c="dimmed">
                optional
              </Text>
            </Text>
          }
          {...form.getInputProps('internalClientId')}
        />
        <Textarea
          label={
            <Text>
              Internal Notes{' '}
              <Text ml="sm" span c="dimmed">
                optional
              </Text>
            </Text>
          }
          {...form.getInputProps('internalNotes')}
        />
      </Card>

      <Center>
        <Group grow w="80%">
          <Button onClick={onCancel} variant="outline">
            Cancel
          </Button>
          <Button type="submit" variant="filled">
            Save
          </Button>
        </Group>
      </Center>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    marginBottom: theme.spacing.lg,
    '& > *:not(:first-of-type)': {
      marginTop: theme.spacing.lg,
    },
  },
  cardTitle: {
    marginBottom: theme.spacing.xs,
    color: theme.colors.dark[2],
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: theme.fontSizes.xs,
  },
}));
