import { AppState, Auth0Provider } from '@auth0/auth0-react';
import Forbidden from 'pages/Forbidden';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import devApi from 'services/dev';

interface Props {
  children?: React.ReactNode;
}

const DevRoute = ({ children }: Props) => {
  const navigate = useNavigate();

  const { isLoading: authLoading, error: authError } = devApi.endpoints.testAuth.useQuery();

  if (authError && 'data' in authError) {
    if (authError.status !== 200) {
      return <Forbidden />;
    }
  }

  return <>{children}</>;
};

export default DevRoute;
