import { Box, Flex, Group, List, Paper, Text, createStyles } from '@mantine/core';
import InlineMD from 'components/ReportV2/elements/InlineMarkdown';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import { OrganismRange } from 'components/ReportV2/elements/Range';
import { BLOCK } from 'components/ReportV2/elements/constants';
import { GuidanceString } from './GuidanceString';
import ResearchBadge from './ResearchBadge';
import { FC } from 'react';
import { ReportOrganism } from 'types/V2Types';
import Card from 'components/Card';

interface OrganismCardProps extends ReportOrganism {
  action?: string;
  association?: string;
  grayscale?: boolean;
  useRangeForColors?: boolean;
}

const OrganismCard: FC<OrganismCardProps> = ({
  name,
  description,
  one_line_description,
  research_status,
  papers_count,
  score,
  guidance,
  range,
  action,
  association,
  grayscale,
  useRangeForColors,
}) => {
  const { classes } = useStyles();
  return (
    <Card radius="sm">
      <Flex direction="column" gap="md" h="100%">
        <Flex>
          <ResearchBadge researchStatus={research_status} />
        </Flex>
        <OrganismCardTitle title={name} popoverContent={description} totalPapers={papers_count || 0} />
        <Text fz="sm" className={classes.grow}>
          {one_line_description}
        </Text>
        <List size="xs" fs="italic">
          {!!action ? (
            <List.Item mb="sm">
              <InlineMD>{action}</InlineMD> is known to{' '}
              <Text span fw="700">
                {score < 0 ? 'decrease' : 'increase'}
              </Text>{' '}
              the abundance of {name}
            </List.Item>
          ) : (
            <List.Item mb="sm">
              <Text span fw="700">
                {score < 0 ? 'Lower' : 'Higher'} levels
              </Text>{' '}
              are linked to {association}.{' '}
            </List.Item>
          )}
          <List.Item>
            <GuidanceString guidance={guidance} />
          </List.Item>
        </List>
        <OrganismRange
          guidance={guidance}
          score={score}
          range={range}
          grayscale={grayscale}
          useRangeForColors={useRangeForColors}
          action={action}
        />
      </Flex>
    </Card>
  );
};

export default OrganismCard;

const useStyles = createStyles(() => ({
  itemCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.60)',
    borderColor: 'rgba(255, 255, 255, 0.80) !important',
  },
  grow: {
    flexGrow: 1,
  },
  header: {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
  },
  icons: {
    flexWrap: 'nowrap',
    '.popover-content + svg': {
      verticalAlign: '0.5em',
    },
    '> div:last-child': {
      whiteSpace: 'nowrap',
    },
  },
}));

interface OrganismCardTitleProps {
  totalPapers: number;
  title: string;
  popoverContent: string;
}

function OrganismCardTitle({ title, totalPapers, popoverContent }: OrganismCardTitleProps) {
  const { classes } = useStyles();
  return (
    <Group position="apart" className={classes.header}>
      <Text fw="500" fs="italic">
        {title}
      </Text>
      <Group spacing="12px" align="center" className={classes.icons}>
        <TitlePopover content={popoverContent} />
        <Box>{BLOCK.NUMBER_PAPERS_ICON(totalPapers)}</Box>
      </Group>
    </Group>
  );
}
