import { Box, createStyles } from '@mantine/core';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export default function Column({ children, className }: Props) {
  const { classes, cx } = useStyles();

  return <Box className={cx(classes.container, className)}>{children}</Box>;
}

const useStyles = createStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));
