import { Center, Stack, Title, Text, Box, UnstyledButton } from '@mantine/core';
import userApi from 'services/user';
import { PageHeader } from 'ui/PageHeader';
import { Error } from 'ui/ErrorPage';
import Loading from 'components/Loading';
import { BranchIcon } from 'ui/Jona-v1/Icons';
import ProviderInvite from 'ui/Jona-v1/Invites';
import Table from 'ui/Jona-v1/Table';
import { useAppDispatch, useNotifications } from 'hooks';
import { useEffect, useState } from 'react';
import { TagTypes } from 'services/emptyApi';
import { PageContainer } from 'ui/Containers';

// helper function to format Date type to October 14, 2023
const formatDate = (date: Date) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });
};

export default function Providers() {
  const dispatch = useAppDispatch();

  const { showNotification, NotificationType } = useNotifications();

  const [pendingRejected, setPendingRejected] = useState<number[]>([]);

  const [rejectInvite] = userApi.endpoints.rejectInvite.useMutation();
  const [acceptInvite] = userApi.endpoints.acceptInvite.useMutation();
  const [resetInvite] = userApi.endpoints.resetInvite.useMutation();

  const {
    data: providers,
    isLoading: providersIsLoading,
    error: providersError,
  } = userApi.endpoints.getProviders.useQuery();
  const {
    data: invites,
    isLoading: invitesIsLoading,
    error: invitesError,
  } = userApi.endpoints.getPendingInvites.useQuery();

  // reset the rejected invites if the user navigates away
  useEffect(() => {
    return () => {
      if (pendingRejected.length > 0) {
        dispatch(userApi.util.invalidateTags([{ type: TagTypes.invitePending }, { type: TagTypes.userProviders }]));
      }
    };
  }, [pendingRejected]);

  if (invitesError) return <Error error={invitesError} />;
  if (providersError) return <Error error={providersError} />;
  if (providersIsLoading || invitesIsLoading) return <Loading />;

  const noProvidersYet = (!invites || invites.length === 0) && (!providers || providers.length === 0);

  const providerTableColumns = [{ label: 'Name' }, { label: 'Email' }, { label: 'Date Added' }];
  const providerTableData = providers?.map(({ createdAt, provider: { name, email } }) => ({
    [providerTableColumns[0].label]: <Text transform="capitalize">{name}</Text>,
    [providerTableColumns[1].label]: (
      <UnstyledButton
        component="a"
        style={{
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        href={`mailto:${email}`}
      >
        <Text truncate>{email}</Text>
      </UnstyledButton>
    ),
    [providerTableColumns[2].label]: formatDate(createdAt),
  }));

  const handleAcceptInvite = async (inviteId: number) => {
    const invite = invites?.find(({ id }) => id === inviteId);
    if (!invite) return;
    try {
      const result = await acceptInvite({ inviteId });
      if ('data' in result) {
        showNotification({
          type: NotificationType.SUCCESS,
          message: result.data?.message || 'Invite accepted successfully',
        });
      } else if ('error' in result) {
        throw result.error;
      }
    } catch (error) {
      showNotification({
        type: NotificationType.ERROR,
        message: 'There was an error accepting the invite',
      });
    }
  };

  const handleRejectInvite = async (inviteId: number) => {
    const invite = invites?.find(({ id }) => id === inviteId);
    if (!invite) return;
    try {
      const result = await rejectInvite({ inviteId });
      if ('data' in result) {
        showNotification({
          type: NotificationType.DENY,
          message: result.data?.message || 'Invite rejected successfully',
          callBack: () => resetInvite({ inviteId }),
          callBackText: 'Undo',
        });
        setPendingRejected((prev) => [...prev, inviteId]);
      } else if ('error' in result) {
        throw result.error;
      }
    } catch (error) {
      showNotification({
        type: NotificationType.ERROR,
        message: 'There was an error rejecting the invite',
      });
    }
  };

  const handleUndo = (inviteId: number) => {
    try {
      const result = resetInvite({ inviteId });
      if ('data' in result) {
        setPendingRejected((prev) => prev.filter((id) => id !== inviteId));
      } else if ('error' in result) {
        throw result.error;
      }
    } catch (error) {
      showNotification({
        type: NotificationType.ERROR,
        message: 'There was an error undoing the invite rejection',
      });
    }
  };

  return (
    <>
      <PageHeader title="My Providers" showBackButton={false} />
      <PageContainer size="md">
        {noProvidersYet ? (
          <Center mx="auto">
            <Stack spacing="xs">
              <BranchIcon size={420} />
              <Title order={3} align="center" mb="md">
                No providers yet
              </Title>
              <Text align="center">Check here for your latest invitations from providers.</Text>
            </Stack>
          </Center>
        ) : (
          <Box mb="lg">
            {!!invites?.length && (
              <Box mb="xl">
                {invites.map(({ id, fromProvider, rejected }) => (
                  <ProviderInvite
                    key={id}
                    providerName={fromProvider?.name ?? ''}
                    onDeny={() => handleRejectInvite(id)}
                    onAccept={() => handleAcceptInvite(id)}
                    rejected={rejected || pendingRejected.includes(id)}
                    onUndo={() => handleUndo(id)}
                  />
                ))}
              </Box>
            )}
            {!!providers?.length && providerTableData && (
              <Table columns={providerTableColumns} data={providerTableData} />
            )}
          </Box>
        )}
      </PageContainer>
    </>
  );
}
