import { Box, Text, createStyles } from '@mantine/core';
import { useState } from 'react';

import Label from 'components/Label';
import { getEvidenceLabel } from 'utilsV2';

export default function ActionImpactGroupItem(item: any) {
  const { classes, cx } = useStyles();
  const [_, setIsHovering] = useState(false);

  function startHover() {
    setIsHovering(true);
  }

  function stopHover() {
    setIsHovering(false);
  }

  function getDiffLabel(item: any) {
    let text;
    let className;
    if (item.action === 'Adopt') {
      className = classes.positiveLabel;
      text = item.action;
    } else {
      className = classes.negativeLabel;
      text = item.action;
    }
    return <Label className={cx(classes.label, className)}>{text}</Label>;
  }

  return (
    <Box className={classes.container} onMouseEnter={startHover} onMouseLeave={stopHover}>
      <Box className={classes.labelBox}>{getDiffLabel(item)}</Box>
      <Text ml="sm" className={classes.name}>
        {item.name}
      </Text>
      <Text ml="auto" size="sm" className={classes.dots}>
        {getEvidenceLabel(item.association_score)}
      </Text>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '230px',
    paddingTop: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.dark[5]}`,
    '&:hover': {
      background: theme.colors.blue[2],
      // cursor: 'pointer',
    },
  },
  labelBox: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '80px',
  },
  label: {
    width: 'fit-content',
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
  },
  negativeLabel: {
    color: theme.colors.red[7],
    background: theme.colors.red[1],
  },
  neutralLabel: {
    background: theme.colors.gray[1],
  },
  positiveLabel: {
    color: theme.colors.blue[6],
    background: theme.colors.blue[1],
  },
  name: {
    textTransform: 'capitalize',
    fontSize: theme.fontSizes.sm,
  },
  dots: {
    color: theme.colors.gray[6],
  },
}));
