import { Box, Divider, SimpleGrid, Text, Title } from '@mantine/core';
import LinkedSectionHead from 'ui/LinkedSectionHead';
import LiteratureAccordion from 'components/ReportV2/elements/LiteratureAccordion';
import OrganismCard from 'components/ReportV2/elements/OrganismCard';
import OrganismsList from 'components/ReportV2/elements/OrganismsList';
import PaperCard from 'components/ReportV2/elements/PaperCard';
import { BLOCK } from 'components/ReportV2/elements/constants';
import Section, { SectionContentType } from 'ui/Section';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { type FC } from 'react';

import { type ReportOrganism, type ReportAction } from 'types/V2Types';
import DescriptionsSection from 'components/SharedReport/DescriptionSection';
import { useDisclosure } from '@mantine/hooks';

// TODO: create types for findings, organisms, papers
export interface ActionProps extends ReportAction {
  barcode: string;
}

const Action: FC<ActionProps> = ({
  barcode,
  name,
  description,
  one_line_description,
  bullet_point_description,
  organisms,
  papers,
  foods_to_avoid,
  recommended_foods,
  findingName,
  other_recommended_foods,
}) => {
  const flags = useFlags();

  const [opened, { toggle }] = useDisclosure(false);

  const isSuppplement = false; // TODO: check if action is a supplement from category

  return (
    <>
      <Title order={4} mb="sm">
        Overview
      </Title>
      <DescriptionsSection
        one_line_description={one_line_description}
        description={description}
        bullet_point_descriptions={bullet_point_description}
        opened={opened}
      />

      {/* Food section */}
      {/* ---------------------------- */}
      {recommended_foods && recommended_foods?.length > 0 && (
        <Section
          title={`Jona AI-recommended ${name}-containing foods`}
          description={
            <Text size="sm">
              These are the foods containing {name} that our Jona AI found to be effective and less likely to negatively
              affect your microbiome. We curated the list to remove potentially conflicting dietary reccomendations.
            </Text>
          }
          contentType={SectionContentType.LIST}
          listItems={recommended_foods}
          collapsible
          showN={5}
        />
      )}
      {/* Other recommended foods that have contradictions - use gray scale images */}
      {flags['foodsContradictions'] && other_recommended_foods && other_recommended_foods?.length > 0 && (
        <Section
          title={`Other ${name}-containing foods`}
          description={
            <Text size="sm">
              These are the foods containing {name} that our Jona AI found to have potentially negative impacts on your
              microbiome or conflict with other dietary recommendations.
            </Text>
          }
          contentType={SectionContentType.LIST}
          listItems={other_recommended_foods}
          collapsible
          showN={5}
          imgGrayscale
        />
      )}
      {/* Foods to avoid */}
      {foods_to_avoid && foods_to_avoid.length > 0 && (
        <Section
          title={`${name}-containing foods to be cautious of`}
          description={<Text size="sm">Here are some Diets you may want to avoid.</Text>}
          contentType={SectionContentType.LIST}
          listItems={foods_to_avoid}
          collapsible
          showN={5}
          imgGrayscale
        />
      )}
      {/* ---------------------------- */}

      {organisms.length > 0 && (
        <Box my="60px">
          <Divider my="lg" variant="dashed" />
          <LinkedSectionHead
            title="Key Organisms"
            to={!isSuppplement ? '#all-organisms' : undefined}
            tooltip={BLOCK.ORGANISMS}
            tooltipDirection="bottom-right"
          />
          <Text mt="md" mb="lg">
            Research suggests that implementing this action could change the abundance of the following organisms in
            your microbiome. We identified {organisms.length} organism(s) in your sample that could be changed to
            improve your association to {findingName}.
          </Text>
          <SimpleGrid cols={3}>
            {organisms.slice(0, 3).map((organism: ReportOrganism, i: number) => (
              <OrganismCard key={i} {...organism} action={name} grayscale />
            ))}
          </SimpleGrid>
        </Box>
      )}

      {!isSuppplement && papers.length > 0 && (
        <Box my="60px">
          <Divider my="lg" variant="dashed" />
          <LinkedSectionHead
            title="Scientific evidence"
            to="#related-literature"
            tooltip={BLOCK.SCIENTIFIC_EVIDENCE}
            tooltipDirection="bottom-right"
          />
          <Text mb="lg">Here are the top two research papers linking this action to changes in the microbiome.</Text>
          <SimpleGrid cols={2}>
            {papers.slice(0, 2).map((paper: any, i: number) => (
              <PaperCard key={i} paper={paper} />
            ))}
          </SimpleGrid>
        </Box>
      )}

      {!isSuppplement && papers.length > 0 && (
        <Box my="60px">
          <Divider my="lg" variant="dashed" />
          <LiteratureAccordion name={name} papers={papers} />
        </Box>
      )}

      {!isSuppplement && organisms.length > 0 && (
        <Box my="60px">
          <Divider my="lg" variant="dashed" />
          <LinkedSectionHead title="All organisms" id="all-organisms" to={`/reports/${barcode}/organisms?back=true`} />
          <Text mb="lg">
            We identified {organisms.length} organism(s) that can be influenced by <strong>{name}</strong> based on the
            latest scientific literature. Jona AI ranked these organisms in the order of their association strength,
            from strongest to weakest.
          </Text>
          <OrganismsList organisms={organisms} action={name} grayscale />
        </Box>
      )}
    </>
  );
};

export default Action;
