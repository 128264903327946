import { useParams } from 'react-router-dom';
import { Grid, Group, Stack } from '@mantine/core';
import { PageHeader } from 'ui/PageHeader';
import { PageContainer } from 'ui/Containers';
import reportApi from 'services/reportV2';
import { Error } from 'ui/ErrorPage';
import Loading from 'components/Loading';
import { FindingOverview } from 'types/V2Types';
import {
  AboutCard,
  ExploreCard,
  Goal,
  GoalsOverview,
  KeyAssociations,
  NavButton,
  TopArticles,
} from 'ui/Jona-v1/Overview';
import userApi from 'services/user';
import { useEffect } from 'react';

export default function ReportSummary() {
  const { barcode } = useParams() as { barcode: string };
  const { data: userData, error: userError, isLoading: isUserLoading } = userApi.endpoints.getUser.useQuery();
  const [updateFlag] = userApi.endpoints.updateFlag.useMutation();

  useEffect(() => {
    if (userData && userData.flags?.firstReportOpenTime === null) {
      updateFlag({
        field: 'firstReportOpenTime',
        value: new Date().toISOString(),
      });
    }
  }, [updateFlag, userData]);

  const {
    data: allFindings,
    error: findingsError,
    isLoading: isFindingsLoading,
  } = reportApi.useGetAllFindingsQuery(barcode);

  const primaryGoal = allFindings?.goals?.primaryGoal;
  const {
    data: primaryGoalOverview,
    isLoading: isPrimaryGoalOverviewLoading,
    error: primaryGoalOverviewError,
  } = reportApi.useGetOverviewQuery(
    {
      barcode,
      findingId: primaryGoal?.overview?.finding_id as string,
    },
    {
      skip: !primaryGoal?.overview,
    }
  );

  const secondaryGoal = allFindings?.goals?.secondaryGoal;
  const {
    data: secondaryGoalOverview,
    isLoading: isSecondaryGoalOverviewLoading,
    error: secondaryGoalOverviewError,
  } = reportApi.useGetOverviewQuery(
    {
      barcode,
      findingId: secondaryGoal?.overview?.finding_id as string,
    },
    {
      skip: !secondaryGoal?.overview,
    }
  );

  // fetch Phyla Composition data
  const {
    data: phylaData,
    isLoading: isPhylaLoading,
    error: phylaError,
  } = reportApi.useGetReportOrganismPhylaQuery({
    barcode,
  });

  // helper function to map the data for the goals so it fits the GoalOverview component
  const mapGoals = (goal: FindingOverview): Goal => {
    const { diet_actions, food_triggers, lifestyles, treatments } = goal;
    return {
      title: goal.name,
      actions: [
        ...diet_actions.map(({ guidance, name }) => `${guidance} ${name}`),
        ...food_triggers.map(({ guidance, name }) => `${guidance} ${name}`),
        ...lifestyles.map(({ guidance, name }) => `${guidance} ${name}`),
        ...treatments.map(({ guidance, name }) => `${guidance} ${name}`),
      ],
    };
  };

  // if a goal has no overview it has no associations
  const allGoals = [];
  if (primaryGoal?.name)
    primaryGoalOverview
      ? allGoals.push(mapGoals(primaryGoalOverview))
      : allGoals.push({ title: primaryGoal.name, noAssociation: true });
  if (secondaryGoal?.name)
    secondaryGoalOverview
      ? allGoals.push(mapGoals(secondaryGoalOverview))
      : allGoals.push({ title: secondaryGoal.name, noAssociation: true });

  // fetch the top articles
  const {
    data: topArticles,
    error: topArticlesError,
    isLoading: isTopArticlesLoading,
  } = reportApi.useGetTopArticlesQuery({ barcode, amount: 8 });

  if (findingsError || phylaError || primaryGoalOverviewError || secondaryGoalOverviewError || topArticlesError || userError)
    return (
      <Error
        error={
          findingsError || phylaError || primaryGoalOverviewError || secondaryGoalOverviewError || topArticlesError || userError
        }
      />
    );

  if (
    isFindingsLoading ||
    isPhylaLoading ||
    isPrimaryGoalOverviewLoading ||
    isSecondaryGoalOverviewLoading ||
    isTopArticlesLoading || 
    isUserLoading
  )
    return <Loading />;

  return (
    <>
      <PageHeader title="Report Summary" />
      <PageContainer>
        <Stack spacing="lg">
          {/* Navigation buttons */}
          <Group grow>
            <NavButton fullWidth to={`/reports/${barcode}/plan`}>
              Explore Action Plan
            </NavButton>
            <NavButton fullWidth to={`/reports/${barcode}/intake`}>
              View Health Survey
            </NavButton>
            <NavButton fullWidth to={`/reports/${barcode}/metabolisms`}>
              Food Digestion
            </NavButton>
          </Group>

          {/* Summary and Goals Section */}
          <Grid gutter="lg">
            <Grid.Col md={12} lg={5}>
              <AboutCard />
            </Grid.Col>
            <Grid.Col md={12} lg={7}>
              <GoalsOverview h="100%" goals={allGoals} goalsPageUrl={`/reports/${barcode}/goals`} />
            </Grid.Col>
          </Grid>

          {/* Key Associations */}
          <KeyAssociations barcode={barcode} conditions={allFindings?.conditions} symptoms={allFindings?.symptoms} />

          {/* Explore your microbiome */}
          <Grid gutter="lg" align="flex-start">
            <Grid.Col md={12} lg={6}>
              <ExploreCard
                alphaDiversity={allFindings?.alphaDiversity}
                numberOfDetectedPathogens={allFindings?.numberOfDetectedPathogens as number}
                organismsUrl={`/reports/${barcode}/organisms`}
                phylaData={phylaData}
              />
            </Grid.Col>
            <Grid.Col md={12} lg={6}>
              <TopArticles articles={topArticles} />
            </Grid.Col>
          </Grid>
        </Stack>
      </PageContainer>
    </>
  );
}
