import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserState {
  firstName?: string;
  lastName?: string;
  email?: string;
  auth0id?: string;
}

const initialState: UserState = {
  firstName: '',
  lastName: '',
  email: '',
  auth0id: '',
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.auth0id = action.payload.auth0id;
    },
  },
});

export const { setUser } = user.actions;

export default user;
