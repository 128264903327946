import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, UnstyledButton, Text, Badge, Center, createStyles } from '@mantine/core';
import { PageContainer } from 'ui/Containers';
import { Link } from 'react-router-dom';
import { PageHeader } from 'ui/PageHeader';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks';
import { resetClientKit } from 'slices/clientKit';
import ActiveClients from 'components/ClientList/ActiveClients';
import providerApi from 'services/provider';
import userApi from 'services/user';
import Loading from 'components/Loading';
import { Error } from 'ui/ErrorPage';
import InvitedClients, { InvitedClientStatusFilters } from 'components/ClientList/InvitedClients';

enum ClientStatusFilters {
  Active = 'Active Clients',
  Pending = 'Pending Clients',
  Denied = 'Denied Clients',
}

export default function Clients() {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  // reset the selected client when the page is loaded
  useEffect(() => {
    dispatch(resetClientKit());
  }, [dispatch]);

  const [activeTab, setActiveTab] = useState<string | null>(ClientStatusFilters.Active);

  const {
    data: userStaffProviderData,
    isLoading: userStaffProviderIsLoading,
    error: userStaffProviderError,
  } = userApi.endpoints.getStaffProvider.useQuery();
  const providerId = userStaffProviderData?.id || 0;
  const {
    data: clientsCount,
    isLoading: isLoadingClientsCount,
    error: clientsCountError,
  } = providerApi.endpoints.getClientsCount.useQuery({ providerId }, { skip: !providerId });
  const { acceptedClients, invitedClients, deniedClients } = clientsCount || {};

  const countMap = {
    [ClientStatusFilters.Active]: acceptedClients,
    [ClientStatusFilters.Pending]: invitedClients,
    [ClientStatusFilters.Denied]: deniedClients,
  };

  const TabItem = ({ value }: { value: ClientStatusFilters }) => (
    <Tabs.Tab value={value}>
      <Center>
        <Text color={activeTab === value ? 'dark' : 'dark.3'} align="center">
          {value}
        </Text>
        <Badge ml="sm" radius="sm" size="sm" color={activeTab === value ? 'blue.4' : 'dark.2'}>
          {countMap[value]}
        </Badge>
      </Center>
    </Tabs.Tab>
  );

  if (userStaffProviderIsLoading || isLoadingClientsCount) return <Loading />;

  if (userStaffProviderError) return <Error error={userStaffProviderError} />;
  if (clientsCountError) return <Error error={clientsCountError} />;

  return (
    <>
      <PageHeader
        title="Clients"
        extraInfo={
          <UnstyledButton mb="sm" component={Link} to="/add-client">
            <FontAwesomeIcon icon={faCirclePlus} />
          </UnstyledButton>
        }
      />
      <PageContainer size="md">
        <Tabs value={activeTab} onTabChange={setActiveTab} mb="xl" classNames={{ tabsList: classes.tabsList }}>
          <Tabs.List>
            <TabItem value={ClientStatusFilters.Active} />
            <TabItem value={ClientStatusFilters.Pending} />
            <TabItem value={ClientStatusFilters.Denied} />
          </Tabs.List>

          <Tabs.Panel value={ClientStatusFilters.Active}>
            <ActiveClients />
          </Tabs.Panel>
          <Tabs.Panel value={ClientStatusFilters.Pending}>
            <InvitedClients status={InvitedClientStatusFilters.Pending} />
          </Tabs.Panel>
          <Tabs.Panel value={ClientStatusFilters.Denied}>
            <InvitedClients status={InvitedClientStatusFilters.Denied} />
          </Tabs.Panel>
        </Tabs>
      </PageContainer>
    </>
  );
}
const useStyles = createStyles((theme) => ({
  tabsList: {
    borderBottom: `2px solid ${theme.colors.dark[1]}`,
  },
}));
