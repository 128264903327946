import { Box, type BoxProps, Loader, createStyles } from '@mantine/core';

import Logo from 'components/Logo';

const Loading: React.FC<BoxProps> = ({ ...props }) => {
  const { classes } = useStyles();

  return (
    <Box mx="auto" className={classes.container} {...props}>
      <Logo />
      <Loader variant="bars" color="black" className={classes.loader} />
    </Box>
  );
};

export default Loading;

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '256px',
  },
  loader: {
    marginTop: theme.spacing.lg,
  },
}));
