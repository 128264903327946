import { Badge, Tooltip, createStyles } from '@mantine/core';
import { getResearchStatusDescription } from 'utilsV2';

interface BadgeProps {
  researchStatus: string;
  size?: string;
}

export default function ResearchBadge({ researchStatus, size = 'md' }: BadgeProps) {
  const { classes } = useStyles();

  const tooltipText = getResearchStatusDescription(researchStatus);

  if (researchStatus === undefined || !tooltipText) return null;

  return (
    <Tooltip width={220} multiline label={tooltipText} disabled={!tooltipText} withArrow withinPortal>
      <Badge size={size} className={classes.researchBadge}>
        {researchStatus}
      </Badge>
    </Tooltip>
  );
}

const useStyles = createStyles((theme) => ({
  researchBadge: {
    minWidth: '72px',
    backgroundColor: theme.colors.blue[1],
    border: `1px solid ${theme.colors.dark[2]}`,
    borderRadius: '4px',
    color: 'black',
    fontWeight: 500,
    textTransform: 'none',
  },
}));
