import { Box, Title, createStyles } from '@mantine/core';
import { usePageMetadata } from 'hooks';
import Logo from 'components/Logo';
import { Link } from 'react-router-dom';

export default function Maintenance() {
  usePageMetadata({ title: 'Maintenance' });
  const { classes } = useStyles();

  return (
    <Box mx="auto" className={classes.container}>
      <Link to="https://jona.health" target="_blank" rel="noopener noreferrer">
        <Logo></Logo>
      </Link>
      <Title order={3} mt="xl">
        Temporarily offline for maintenance.
      </Title>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '256px',
  },
  loader: {
    marginTop: theme.spacing.lg,
  },
}));
