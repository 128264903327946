import React from 'react';
import { Code, createStyles, type CodeProps } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';

export interface BarcodeBadgeProps extends Omit<CodeProps, 'children'> {
  barcode: string;
}

const BarcodeBadge: React.FC<BarcodeBadgeProps> = ({ barcode, ...props }) => {
  const { classes } = useStyles();
  return (
    <Code className={classes.barcode} {...props}>
      <FontAwesomeIcon className={classes.icon} icon={faBarcode} />
      {barcode}
    </Code>
  );
};
const useStyles = createStyles((theme) => ({
  icon: {
    marginRight: theme.spacing.xs,
    marginLeft: theme.spacing.xs,
  },
  barcode: {
    lineHeight: 1,
    marginLeft: 'auto',
    fontSize: theme.fontSizes.sm,
    textDecoration: 'none',
  },
}));

export default BarcodeBadge;
