import { createStyles, getStylesRef } from '@mantine/core';

const useNavbarStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor: theme.colors.blue[6],
    color: '#ffffff',
    height: '100vh',
    padding: theme.spacing.md,
    paddingRight: theme.spacing.sm,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '0',
  },

  navbarMain: {
    flex: 1,
    height: '100%',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
  },

  logo: {
    marginLeft: theme.spacing.xs,
    width: '54px',
    height: '54px',
  },

  selectRole: {
    width: '100%',
    paddingRight: theme.spacing.sm,
    marginBottom: theme.spacing.md,
    color: theme.colors.dark[9],
  },

  navbarSection: {
    paddingRight: theme.spacing.sm,
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
  },

  navbarSkelton: {
    '&:before': {
      background: theme.colors.blue[8],
    },
    '&:after': {
      background: theme.colors.blue[7],
    },
  },

  navbarSectionLabel: {
    color: 'inherit',
    fontSize: theme.fontSizes.xs,
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: 'initial',
    marginBottom: theme.spacing.sm,
  },

  control: {
    fontWeight: 400,
    display: 'block',
    width: '100%',
    color: 'inherit',
    fontSize: theme.fontSizes.sm,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    lineHeight: 1.25,
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,

    '&[data-active]': {
      [`& .${getStylesRef('controlLabel')}`]: {
        backgroundColor: '#ffffff',
        color: theme.colors.blue[6],
      },
    },
  },

  controlLinkHover: {
    [`&:hover .${getStylesRef('controlLabel')}`]: {
      backgroundColor: '#ffffff',
      color: theme.colors.blue[6],
    },
  },

  controlDropdownHover: {
    [`&:hover .${getStylesRef('controlLabel')}`]: {
      textDecoration: 'underline',
    },
  },

  controlLabel: {
    ref: getStylesRef('controlLabel'),
    width: '100%',
    padding: theme.spacing.xs,
    color: 'inherit',
    borderRadius: theme.radius.sm,
    marginLeft: theme.spacing.xs,
  },

  subLink: {
    marginLeft: theme.spacing.lg,
    paddingRight: theme.spacing.lg,
    padding: theme.spacing.xs,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: `1px solid ${theme.colors.gray[4]}`,
  },

  linkIcon: {
    marginRight: theme.spacing.xs,
    width: '12px',
    height: '12px',
    stroke: '1px',
  },

  chevron: {
    transition: 'transform 200ms ease',
  },
}));

export default useNavbarStyles;
