import { Box, Button, Container, Paper, Text, createStyles } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { closeAllModals } from 'slices/app';
import { AppDispatch } from 'store';

export default function ModalUnavailable() {
  const dispatch: AppDispatch = useDispatch();

  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Box>
        <Paper className={classes.paper} withBorder radius={2} p="lg" mt="lg">
          <Text color="dark.4" size="lg" mb="sm" fw="600">
            This content is unavailable
          </Text>
          <Text color="gray.7" size="sm">
            You don&#39;t have access or the link is invalid.
          </Text>
          <Text color="gray.7" size="sm" mb="lg">
            Please contact support if you need assistance <a href="mailto:hello@jona.health">hello@jona.health</a>
          </Text>
          <div>
            <Button onClick={() => dispatch(closeAllModals())} variant="light" mr="sm">
              Close
            </Button>
          </div>
        </Paper>
      </Box>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(4, 74, 126, 0.06)',
    borderColor: `${theme.colors.gray[4]} !important`,
    borderRadius: '7px',
  },
}));
