import ReactMarkdown from 'react-markdown';

interface Props {
  children: string | string[];
}

export default function InlineMD({ children }: Props) {
  const content = Array.isArray(children) ? children.join(' ') : children;

  return <ReactMarkdown components={{ p: ({ node, ...props }) => <>{props.children}</> }}>{content}</ReactMarkdown>;
}
