import { faExternalLink, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Anchor, Badge, Button, Collapse, Flex, List, Paper, Stack, Text, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Card from 'components/Card';
import { ReportPaper } from 'types/V2Types';
import { countStr } from 'utilsV2';

interface Props {
  paper: ReportPaper;
}

export default function PaperCard({ paper }: Props) {
  const { classes } = useStyles();
  const lf = new Intl.ListFormat('en');

  const [opened, { toggle }] = useDisclosure(false);

  return (
    <Stack className={classes.itemStack} spacing="sm" align="flex-start" h="100%">
      <Card radius="sm">
        <Badge fz="xs" fw="500" radius="xs" maw="300px" p="sm" color="dark.3" mb="md">
          {paper.publisher}
        </Badge>
        <Text fw="500">{paper.paper_title}</Text>
        <List className={classes.list} icon="-" size="xs">
          {paper.organismNames && (
            <List.Item mb="sm">
              This paper links to {countStr(paper.organismNames, 'of the key organism', 'of the key organisms')} you
              have an imbalance with
              <br />
              <Text fs="italic">{lf.format(paper.organismNames)}</Text>
            </List.Item>
          )}
        </List>

        {/* bottom links */}
        <Flex mt="lg" justify="space-between" w="100%">
          {paper.paper_abstract ? (
            <>
              <Button
                ff="Sora"
                fz="xs"
                fw="500"
                compact
                size="xs"
                variant="subtle"
                onClick={toggle}
                color="dark"
                style={{ padding: '5px 8px' }}
                m="xs"
              >
                {opened ? 'Less' : 'View Abstract'}
              </Button>
            </>
          ) : (
            <em>Abstract is not available</em>
          )}
          <Button compact component="a" href={`https://pubmed.ncbi.nlm.nih.gov/${paper._id}/`} target="_blank">
            <FontAwesomeIcon icon={faExternalLink}></FontAwesomeIcon>
            <Text ml="sm">View study</Text>
          </Button>
        </Flex>
        <Collapse mt="sm" in={opened}>
          <Text size="sm">
            Abstract: <span dangerouslySetInnerHTML={{ __html: paper.paper_abstract }} />
          </Text>
        </Collapse>
      </Card>
    </Stack>
  );
}

const useStyles = createStyles(() => ({
  itemStack: {
    width: '100%',
  },
  list: {
    flexGrow: 1,
  },
}));
