import { Text } from '@mantine/core';

interface GuidanceStringProps {
  guidance: string;
}

export function GuidanceString({ guidance }: GuidanceStringProps): JSX.Element {
  switch (guidance) {
    case 'Above Median':
      return (
        <span>
          Your level is{' '}
          <Text span fw="700">
            increased
          </Text>{' '}
          - above the 50th percentile of the healthy population.
        </span>
      );
    case 'Below Median':
      return (
        <span>
          Your level is{' '}
          <Text span fw="700">
            decreased
          </Text>{' '}
          - below the 50th percentile of the healthy population.
        </span>
      );
    case 'Detected':
      return (
        <span>
          This pathogen was{' '}
          <Text span fw="700">
            detected
          </Text>
        </span>
      );
    case 'Not Detected':
      return (
        <span>
          This pathogen was{' '}
          <Text span fw="700">
            not detected
          </Text>
        </span>
      );
    default:
      return (
        <span>
          Your level is{' '}
          <Text span fw="700">
            typical
          </Text>{' '}
          - at the 50th percentile of the healthy population.
        </span>
      );
  }
}
