import { Link } from 'react-router-dom';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Flex, Group, List, Paper, Text, createStyles } from '@mantine/core';
import InlineMD from 'components/ReportV2/elements/InlineMarkdown';
import { FindingDiff } from 'types/V2Types';
import { NewBadge, UpdatesBadge } from 'ui/Badges';

interface ReportUpdatesProps {
  findings: FindingDiff[];
  barcode: string;
  handleModalClose: () => void;
}

const ReportUpdates: React.FC<ReportUpdatesProps> = ({ findings, barcode, handleModalClose }) => {
  const { classes } = useReportModalStyles();

  return (
    <Container p="0" pt="md">
      {findings?.map(
        (finding, index) =>
          !finding.isRemoved && (
            <Paper key={`finding-${index}`} className={classes.newItemContainer}>
              <Link
                to={`/reports/${barcode}/${finding.kb}/${finding._id}`}
                className={classes.newItemButton}
                onClick={handleModalClose}
              >
                <Flex align="center" justify="space-between">
                  <Flex align="center">
                    <Text size="md" fw="600">
                      <InlineMD>{finding.name}</InlineMD>
                    </Text>
                    <Text ml="sm" inline size="xs" fw="600" color="gray.6">
                      {finding.kb}
                    </Text>
                  </Flex>
                  <Group spacing="sm">
                    {finding.isNewItem && <NewBadge />}
                    {(finding?.actions?.length || finding.changes) && <UpdatesBadge />}
                    <FontAwesomeIcon className={classes.newItemButtonIcon} icon={faChevronRight} />
                  </Group>
                </Flex>
                {(finding?.actions?.length || finding.changes) && (
                  <List className={classes.newItemList}>
                    {finding?.actions?.length ? (
                      <List.Item>{`${finding.actions.length} new action(s)`}</List.Item>
                    ) : null}
                    {finding.changes?.association_score ? (
                      <List.Item>{`Updated association ${finding.changes?.association_score?.old} -> ${finding.changes?.association_score?.new}`}</List.Item>
                    ) : null}
                  </List>
                )}
              </Link>
            </Paper>
          )
      )}
    </Container>
  );
};

export default ReportUpdates;

const useReportModalStyles = createStyles((theme) => ({
  newItemContainer: {
    width: '100%',
    backgroundColor: theme.colors.gray[2],
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    marginBottom: theme.spacing.md,

    '&:hover': {
      backgroundColor: theme.colors.gray[3],
    },
  },
  newItemButton: {
    textDecoration: 'none',
    color: theme.black,
    padding: '10px 20px',
    display: 'block',
  },
  newItemList: {
    marginLeft: theme.spacing.md,
    marginTop: theme.spacing.sm,
    width: `calc(100% - ${theme.spacing.lg})`,
    fontSize: theme.fontSizes.xs,
    fontStyle: 'italic',
  },
  newItemButtonIcon: {
    color: theme.colors.gray[7],
    marginLeft: theme.spacing.lg,
  },
}));
