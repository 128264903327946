import { Switch as MantineSwitch, SwitchProps as MantineSwitchProps, Text, createStyles } from '@mantine/core';

interface SwitchProps extends MantineSwitchProps {
  onLabelThumb: string;
  offLabelThumb: string;
}

const Switch: React.FC<SwitchProps> = ({ onLabelThumb, offLabelThumb, checked, onChange, disabled, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <MantineSwitch
      classNames={{
        track: cx(classes.track, disabled && classes.track_disabled),
        thumb: cx(
          checked ? classes.thumb_checked : classes.thumb_unchecked,
          classes.thumb,
          disabled && classes.thumb_disabled
        ),
      }}
      size="lg"
      radius="xs"
      color="gray.4"
      {...props}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      thumbIcon={
        checked ? (
          <Text size="xs" inline fw={400}>
            {onLabelThumb}
          </Text>
        ) : (
          <Text size="xs" inline fw={400}>
            {offLabelThumb}
          </Text>
        )
      }
    />
  );
};
const useStyles = createStyles((theme) => ({
  track: {
    height: '25px',
    width: '75px',
    border: `1px solid ${theme.colors.dark[1]}!important`,
    backgroundColor: theme.colors.gray[4],
  },
  track_disabled: {
    backgroundColor: theme.colors.dark[1],
  },
  thumb: {
    width: '40px',
    height: '19px',
    border: '0',
    color: '#fff',
    backgroundColor: theme.colors.dark[9],
  },
  thumb_checked: {
    left: 'calc(100% - 40px - 0.125rem)!important',
  },
  thumb_unchecked: {
    left: '0.125rem',
  },
  thumb_disabled: {
    backgroundColor: `${theme.colors.dark[1]}!important`,
  },
}));
export default Switch;
