import React, { type FC } from 'react';
import { Collapse, Divider, SimpleGrid, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import List, { ListItem } from 'ui/List';
import Card, { CardProps } from 'ui/Card';
import LinkedSectionHead from 'ui/LinkedSectionHead';
import CollapsibleHeader from 'components/ReportV2/elements/CollapsibleHeader';

import useSectionStyles from './useSectionStyles';
import { JonaAiBadge } from 'ui/Badges';

export enum SectionContentType {
  DEFAULT = 'default',
  LIST = 'list',
  CARDS = 'cards',
  // ACCORDION = 'accordion',
}

interface SectionProps {
  title: string;
  contentType?: SectionContentType;
  description?: React.ReactNode;
  collapsible?: boolean;
  showN?: number;
  listItems?: ListItem[];
  cardItems?: CardProps[];
  imgGrayscale?: boolean;
  jonaAiBadge?: boolean;
}

const Section: FC<SectionProps> = ({
  title,
  contentType = SectionContentType.DEFAULT,
  description,
  collapsible = false,
  showN = 3,
  listItems = undefined,
  cardItems = undefined,
  imgGrayscale = false,
  jonaAiBadge = false,
}) => {
  const { classes } = useSectionStyles();

  const [opened, { toggle }] = useDisclosure(false);
  const showButton = (listItems && listItems.length > showN) || (cardItems && cardItems.length > showN);

  const renderList = () => {
    if (!listItems) return null;

    if (!collapsible) return <List items={listItems} />;

    return (
      <>
        <List items={listItems.slice(0, showN)} imgGrayscale={imgGrayscale} />
        <Collapse in={opened}>
          <List items={listItems.slice(showN)} />
        </Collapse>
      </>
    );
  };

  const renderCards = () => {
    if (!cardItems) return null;

    if (!collapsible)
      return (
        <SimpleGrid cols={3} mb="md">
          {cardItems.map((item, index) => (
            <Card {...item} key={index} />
          ))}
        </SimpleGrid>
      );

    return (
      <>
        <SimpleGrid cols={3} mb="md">
          {cardItems.slice(0, showN).map((item, index) => (
            <Card {...item} key={index} />
          ))}
        </SimpleGrid>
        <Collapse in={opened}>
          <SimpleGrid cols={3}>
            {cardItems.slice(showN).map((item, index) => (
              <Card {...item} key={index} />
            ))}
          </SimpleGrid>
        </Collapse>
      </>
    );
  };

  return (
    <>
      <Divider className={classes.divider} variant="dashed" />
      <div className={classes.sectionTitle}>
        {collapsible ? (
          <CollapsibleHeader
            title={title}
            onClick={toggle}
            buttonContent={opened ? 'Collapse' : 'View all'}
            showButton={showButton}
            jonaAiBadge={jonaAiBadge}
          />
        ) : (
          <LinkedSectionHead title={title} />
        )}
      </div>

      {description && <Text className={classes.description}>{description}</Text>}

      {contentType === SectionContentType.LIST && renderList()}

      {contentType === SectionContentType.CARDS && renderCards()}
    </>
  );
};

Section.defaultProps = {
  contentType: SectionContentType.DEFAULT,
  listItems: undefined,
  collapsible: false,
  showN: 3,
};

export default Section;
