import { Flex, Text, createStyles } from '@mantine/core';
import { RelatedPathogen } from 'types/V2Types';
import InlineMD from '../elements/InlineMarkdown';

interface Props {
  pathogen: RelatedPathogen;
}

export default function ActionPlanPathogen({ pathogen }: Props) {
  const { classes } = useStyles();

  return (
    <Flex className={classes.cardListItem} direction="column" wrap="wrap" mt="sm" mx="xl" mb="xl">
      <Flex align="center" mb="sm">
        <Text fw="600">
          <InlineMD>{pathogen.name}</InlineMD>
        </Text>
      </Flex>
      <Flex>
        <Text>{pathogen.one_line_description}</Text>
      </Flex>
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  cardListItem: {
    // borderBottom: '0.5px solid #AAAAAA',
    fontSize: theme.fontSizes.sm,
  },
  linkBtn: {
    flexGrow: 1,
  },
}));
