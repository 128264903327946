import React from 'react';
import ReactDOM from 'react-dom/client';

import { Global, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { GetCSPValues } from 'config';
import { persistor, store } from 'store';
import global from 'styles/global';
import theme from 'styles/theme';

import 'styles/index.css';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import Auth0ProviderWithNavigate from 'contexts/auth0';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import ldConfig from 'appConfig/ldConfig';
import { IntercomProvider } from 'react-use-intercom';

(async () => {
  const LDProvider = await asyncWithLDProvider(ldConfig);

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  const INTERCOM_APP_ID = 'xwm0g6su';
  // if storybook, we use an empty string for the API_URL
  const API_URL =
    process.env.STORYBOOK === 'true'
      ? ''
      : process.env.NODE_ENV === 'production'
      ? window.env.REACT_APP_API_URL
      : process.env.REACT_APP_API_URL;

  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <Helmet>
          <meta httpEquiv="Content-Security-Policy" content={GetCSPValues()}></meta>
          {API_URL && <link rel="preconnect" href={API_URL} />}
        </Helmet>
        <LDProvider>
          <MantineProvider theme={theme}>
            <Global styles={global} />
            <Notifications
              transitionDuration={0}
              containerWidth="calc(100vw - 240px)" // 240px is the width of the sidenav
              position="top-right"
              style={{ position: 'fixed', top: 0, right: 0 }}
            />
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                  <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={true}>
                    <Auth0ProviderWithNavigate>
                      <App />
                    </Auth0ProviderWithNavigate>
                  </IntercomProvider>
                </BrowserRouter>
              </PersistGate>
            </Provider>
          </MantineProvider>
        </LDProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
})();
