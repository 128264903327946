import { Box, Button } from '@mantine/core';
import { usePageMetadata } from 'hooks';
import { Link, useNavigate } from 'react-router-dom';
import { JonaEmblemBlackCircleIcon } from 'ui/Logo';

export default function NotFound() {
  const navigate = useNavigate();
  usePageMetadata({ title: 'Not Found' });

  const goBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <Box pl="xl" pr="xl">
      <div style={{ width: '54px', height: '54px' }}>
        <Link to="/">
          <JonaEmblemBlackCircleIcon size={34} />
        </Link>
      </div>
      <h1>Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <Button onClick={goBack} variant="outline" mr="sm">
        Go Back
      </Button>
      <Button component={Link} to="/" variant="outline">
        Go to Homepage
      </Button>
    </Box>
  );
}
