export const TermsAndPrivacyMarkdown: string = `
# Jona Product & Services Terms of Use
					
### _Updated as of April 26, 2023_

## Introduction

Jona Inc (“Jona”) is an AI-driven, personalized microbiome interpretation platform that sequences, analyzes, and interprets human biology for the prevention and early detection of chronic diseases, and an application that empowers people with data and technology based on the functional analysis of gut microbiome. Our services include access to the Jona public website, web application, personalized nutrition counseling services, and microbiome testing services, including the collection and analysis of stool samples. 

Using the sample collection kits (“Kits”), customers collect the biological samples (“Sample” or “Samples”) and submit them to Jona for testing and analysis. The Samples received by Jona are subjected to a sequencing process that generates sample data ("Sample Data") used for producing the test data (“Test Data”). Sample Data together with Test Data and customer-provided information are analyzed using Jona proprietary technology to generate your unique test results (“Test Results”) that Jona will use to detect molecular features that are associated with certain diseases, and for making personalized food, diet, and lifestyle recommendations via Jona website located at www.jona.health, web applications, and other methods.

Customers will be required to create an account on Jona website or the web application (“Jona Account” or “Account”) and will be asked to complete an initial questionnaire in full in order to start the Sample testing process by Jona partner laboratories and view the results and recommendations sent by Jona.



PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING THE SERVICE (AS DEFINED BELOW), YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE AND ALL TERMS, CONDITIONS, POLICIES, GUIDELINES AND DISCLOSURES INCORPORATED BY REFERENCE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS OF SERVICE, DO NOT USE THE SERVICE.
					
Jona, Inc. (“Jona”, “us”, “we”, or “our”) maintains this Jona App, Jona (“the Jona App”, or “this App”), and each of its subdomains (collectively “the Websites”) as a service to our clients and user community, which are subject to these Terms of Use (“Terms of Use” or “Terms”) and govern your (“you” or the “User”) access to and use of the Websites, applications, online services, microbiome services, software, SaaS and PaaS offerings, including our products and services (each a “Jona App”) and, including access (either through Jona or Third -Party App providers) that are accessible or provided on, through, or in connection with such services (the “Platform”). For the avoidance of doubt, access to third-party products or services made available via the Platform, from time to time, will be made available to you when purchased by your Institution (defined below) via a third-party app order form (each a “Third-Party App”) that is made available by a Third-Party App Provider) and will be governed by terms of use of the Third-Party App.
					
In these Terms of Use, “Institution” is an organization, company, or other authorized person that has invited or authorized you to access and use the Platform and has entered into a separate agreement with Jona for use of the Platform. These Terms of Use govern your access and use of the Platform, so please read them carefully. By accessing the Platform in any manner, creating an account or otherwise using the Platform, these Terms of Use apply to you as an end user of the Platform.
					
These Terms of Use, which also incorporate by reference the terms of our Jona PLATFORM PRIVACY POLICY, govern your access and use of the Platform as agreed to by your Institution. By accessing the Platform, you are agreeing to be bound by these Terms of Use and our Jona PLATFORM PRIVACY POLICY including all applicable laws and regulations. If you do not agree with any of these Terms of Use, you are prohibited from using or accessing the Platform. The Materials (defined below) and information available through the Platform are protected by applicable intellectual property laws.
					
We reserve the right to change these Terms of Use from time to time for any reason, which shall be affected by posting the updated Terms of Use here. Your continued use of the Platform after any changes are posted constitutes your acceptance of the new Terms of Use.
					
THIS AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
					
### Your Use of Our Platform
					
Most user concerns can be resolved quickly and to the user’s satisfaction by emailing user support. In the unlikely event that our user care team is unable to resolve a complaint you may have (or if Jona has not been able to resolve a dispute it has with you after attempting to do so informally), we each agree to resolve those disputes through binding arbitration, mediation, or small claims court instead of in courts of general jurisdiction. Arbitration, which is often cheaper, faster and less formal than a lawsuit in court, uses a neutral arbitrator instead of a judge or jury. Arbitrators can award the same damages and relief that a court can award. If you initiate an arbitration in which you seek more than $75,000 in damages, the payment of attorneys’ fees will be governed by the AAA Rules (as defined below). Any arbitration under this Agreement will take place on an individual basis; class arbitrations and class actions are not permitted.
					
### Use License
					
Jona grants you a personal, non-transferable, non-exclusive right to access and use the Platform subject to these Terms of Use. You may temporarily download one copy of the Materials (defined below) available through the Platform where the Platform expressly permits or provides for downloads, and you may print pages displayed by the Platform. As used herein, the term “Material” means all information, result reports, text, images, data, links, software or other material accessible through the Platform, whether created by us or provided by another person for display on the Platform. This is the grant of a license, not a transfer of title, and under this license you may not:
					
- Modify, translate, copy or create derivative works based on any element of the Platform;
 						
- Use the Materials for any commercial purpose, or for any public display or distribution (commercial or non-commercial);
 						
- Attempt to decompile, reverse engineer, disassemble or otherwise attempt to discover the object code, source code or underlying ideas or algorithms of the any portion of the Platform;
 						
- Use any robot, spider, site search/retrieval application, or other automated device, process, or means to access, retrieve, scrape, or index any portion of the Platform;
 						
- Incorporate links to the Platform or frame any elements of the Platform within any other website;
 						
- Remove any copyright or other proprietary notations from the Materials;
 						
- Obtain unauthorized access to any computer system through the Platform;
 						
- Introduce viruses, worms, Trojan horses and/or harmful code to any portion of the Platform;
 						
- Interrupt or attempt to interrupt the operation of the Platform in any way;
 						
- Rent, lease, distribute, sell, resell, assign, or otherwise transfer the Materials to another person or “mirror” the Materials on any other server; or use the Platform to re-identify or attempt to re-identify any individuals, family members, or household members contained within de-identified Customer Data (as defined below).
 									 					
This license, which includes without limitation all rights granted by Jona in this Section, shall automatically terminate if you violate any of these restrictions. Further, Jona may terminate all your rights under this Section at any time. Upon terminating your viewing of these Materials or upon the termination of this license, you must destroy any downloaded Materials in your possession whether in electronic or printed format.

### User Content
Some features of our Service may include functionality enabling you to post user content, whether publicly posted or privately transmitted, such as profiles, posts, emails, feedback, experiences, suggestions, notes, messages, photos, and videos (“User Content”) that you have made available to Jona and other users of the Service. You are solely responsible for all User Content that you choose to post or otherwise transmit to or through the Service.Jona has the right to refuse accepting or publishing any User Content. You agree that Jona, at its sole discretion, has the right (but not the obligation) to screen, reject, retain, or remove any User Content, or any portion thereof, posted using the Service that infringes Jona’s or any third party’s intellectual property or other rights, violates these Terms or our other policies, or is otherwise objectionable. Jona reserves the right to disclose any User Content as necessary to satisfy any applicable law, regulation, legal process, or governmental request. Jona has the right to suspend or terminate the Service at any time without preserving your User Content.
In using the Service, you agree not to post any User Content that (i) is false, deceptive, deceitful, misleading, unauthorized, unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, harmful to minors, fraudulent or otherwise objectionable; (ii) would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law; (iii) may infringe any patent, trademark, trade secret, copyright or other intellectual or proprietary right of any party; (iv) impersonates any person or entity or otherwise misrepresents your affiliation with a person or entity; (v) contains private or confidential information of any third party, including, without limitation, addresses, phone numbers, email addresses, Social Security numbers and credit card numbers; (vi) contains viruses, spyware, malware, corrupted data or other harmful, disruptive or destructive files; (vii) in the sole judgment of Jona, may expose Jona to any harm or liability of any type; or (viii) harasses, degrades, intimidates or is hateful toward an individual or group of individuals for any reason and especially on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability.

By sending User Content, you grant Jona and its affiliates a perpetual, irrevocable, worldwide, royalty-free, freely transferable and sub-licensable (through unlimited levels of sublicense) non-exclusive license and right to use, reproduce, modify, transmit, translate, publish, publicly perform, display, distribute, commercialize, share with third parties, transmit or distribute over public network and media, and create derivative works of such User Content for purposes specified in these Terms and for delivering the Service to you and other users of the Service. You represent and warrant that: (i) you own and control all of the rights to the User Content that you post or you otherwise have the right to post such User Content and to grant the rights granted herein; (ii) the User Content is accurate and not misleading; (iii) distribution of the User Content you supply in accordance with your direction does not violate these Terms; and (iv) will not violate any rights of or cause injury to any person or entity. You hereby waive any moral rights you may have in such User Content under the laws of any jurisdiction.
				
### Intellectual Property
					
Except for stool sample, related data, metadata and, if and when available, diagnostic information (e.g., therapeutic, clinical, clinical outcome, and molecular information), which may include Personally Identifiable Information (“Customer Data”)Customer Data, all Materials and information available through the Platform are the sole and exclusive property of Jona and its affiliates, suppliers or licensors ( “Jona Related Parties”), and are protected from unauthorized copying and dissemination by United States copyright law, trademark law, international conventions and other intellectual property laws. The service marks and trademarks of Jona, including without limitation Jona® and the Jona logos are trademarks and service marks owned by Jona, Inc. Any other trademarks, service marks, logos and/or trade names appearing via our Platform are the property of their respective owners. You may not copy or use any of these marks, logos or trade names without the express prior written consent of the owner. If you provide us with any suggestions for improvements to our Platform or relating to any prospective products or services, be aware that any comments or suggestions you make to Jona are non-confidential and become the property of Jona, and you hereby grant to us a non-exclusive, irrevocable, perpetual, transferable, sublicensable (through one or more tiers) license to copy, use and modify such suggestions and to make, have made, use, import, offer to sell and sell any products or services incorporating such suggestions, without acknowledgment or compensation to you.
					
### No Warranty
					
THE PLATFORM IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE OF THE PLATFORM IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE PLATFORM IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM Jona OR THROUGH THE PLATFORM WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING
					
THE FOREGOING, Jona, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS LICENSORS DO NOT WARRANT THAT THE CONTENT, ITS USE OR RESULTS OF THE PLATFORM (INCLUDING BUT NOT LIMITED TO MECHANISMS FOR THE DOWNLOADING OF MATERIAL) ARE ACCURATE, EFFECTIVE, RELIABLE, TIMELY, COMPLETE, OR FREE OF ERRORS; THAT THE PLATFORM WILL MEET YOUR REQUIREMENTS; THAT THE PLATFORM WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE PLATFORM IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE OF THE PLATFORM.
					
Jona DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE PLATFORM OR ANY HYPERLINKED WEBSITE OR SERVICE, AND Jona WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
					
FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. THESE TERMS GIVE YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THESE TERMS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
					
YOU AGREE AND ACKNOWLEDGE THAT: (1) Jona DOES NOT PROVIDE MEDICAL ADVICE AND NOTHING ON THE PLATFORM SHOULD BE CONSTRUED AS AN ATTEMPT BY Jona TO OFFER OR RENDER A MEDICAL OPINION OR OTHERWISE ENGAGE IN THE PRACTICE OF MEDICINE; AND (2) THE PLATFORM SHOULD NOT REPLACE CLINICIAN JUDGMENT UNLESS AND ONLY TO THE EXTENT THAT SPECIFIC ELEMENTS OF THE PLATFORM HAVE REGULATORY APPROVAL FOR THE APPLICABLE INDICATIONS.
					
### Limitation of Liability
					
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Jona, ITS AFFILIATES OR ANY OF THEIR AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE, THIS PLATFORM. UNDER NO CIRCUMSTANCES WILL Jona BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE PLATFORM OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
					
TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Jona ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY
					
OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR PLATFORM; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE PLATFORM; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR PLATFORM BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE PLATFORM; AND/OR (VII) CONTENT PROVIDED BY A THIRD PARTY OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY. IN NO EVENT SHALL Jona, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO Jona HEREUNDER OR $100.00, WHICHEVER IS GREATER.
					
THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF Jona HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
					
SOME STATES, TERRITORIES, AND COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE PLATFORM OR WITH THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE PLATFORM.
					
Unless otherwise explicitly agreed upon in an agreement between your Institution and us all Materials found on the Platform are solely directed to individuals, companies, or other entities located in the region in which your Institution is located. You may not use the Platform if you are a resident of a country embargoed by the United States, or are a foreign person or entity blocked or denied by the United States government. Jona makes no representations that the Platform is appropriate or available for use in locations outside of the region in which your Institution is located. Those who access or use the Platform from other jurisdictions do so at their own volition and are entirely responsible for compliance with all applicable local laws and regulations, including but not limited to export and import regulations.
					
### Damages
					
By using the Platform, you agree to release, discharge and hold harmless Jona and the Jona Related Parties from any and all losses, damages, rights, claims, actions of any kind and injury (including death) arising out of or relating to the Platform or any act or omission by any person, including without limitation, any dispute between you and any other person or regarding any content posted on the Platform. If you are a California resident, you waive California Civil Code §1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist in favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.” This release includes, without limitation, any claim resulting from delay and the criminal acts of others.
					
### Data
					
By accessing or using the Platform you agree that Jona may collect data and compile statistics relating to (i) your activity with the Platform or related products or services, such as your browsing or uploading history; (ii) the uploading of, your access to or use of Customer Data; and (iii) any other information collected from or about or otherwise relating to your access to or use of the Platform or related products or services, whether in individual or aggregate form (“Usage Data”) for use in connection with our business purposes to analyze, maintain, develop, and improve the Platform and related products and services, and to provide other products and services, including but not limited to the Third-Party Apps.
					
## Your Representations and Warranties
					
### You represent and warrant that:
					
You (or your Institution) have obtained all other legally required licenses, permissions, and patient consents to share Customer Data for the purposes described in our Privacy Policy (the “Permitted Purposes”), and doing so does not violate any applicable agreement, license, confidentiality obligation, patent, copyright, trademark, trade secret, or other intellectual property right;
					
You have provided the appropriate notice(s) to the appropriate parties under the applicable local and federal privacy and security laws and regulations, including but not limited to the Health Insurance Portability and Accountability Act of 1996 and the rules and regulations promulgated thereunder, as the same may be amended from time to time (collectively, “HIPAA”), the EU General Data Protection Regulation 2016/679 (“GDPR”) and UK General Data Protection Regulation (“UK GDPR"); and
					
Your provision of the Customer Data for the Permitted Purposes complies with all applicable foreign, federal, state, and local laws, regulations, rules, codes of conduct, contractual requirements, and any other applicable requirements.
					
### Notification Procedures and Changes to these Terms of Use
					
Jona may, in its sole discretion, modify or update these Terms from time to time, and so you should review this page periodically. When we change these Terms in a material manner, we will update the ‘last modified’ date at the bottom of this page. Your use of the Platform after any such change constitutes your acceptance of the new Terms. If you do not agree to any of these terms or any future Terms of Use, do not use or access (or continue to access) the Platform. Jona is not responsible for any automatic filtering you or your network provider may apply to email notifications we send to the email address you provide us.
					
### Indemnification
					
To the maximum extent permitted by law, you shall defend, indemnify and hold harmless Jona and its affiliates and each of their officers, directors, employees, contractors and agents, jointly and severally, from and against any and all claims, suits, actions, proceedings, liabilities, losses, damages, or expenses, including any legal and expert fees and expenses and all costs of investigation, which arise out of or relate to (i) your actual or alleged breach of any term or provision of these Terms of Use; (ii) the Usage Data in any manner; (iii) your violation of any third-party right, including without limitation any right of privacy or intellectual property rights; (iv) your violation of any applicable law, rule or regulation; (v) any content you submit including, without limitation, any misleading, false, or inaccurate information; or (vi) your willful or intentional misconduct. You shall reimburse Jona for all expenses (including counsel fees and expenses) as incurred by Jona in connection with any such actions or claims.
					
### Governing Law and Venue
					
You understand that where your Institution enters into an agreement with us explicitly specifying EU or UK processing, EU, Member State, or UK laws shall apply, respectively, and in all other instances, you agree that: (i) the Platform shall be deemed solely based in New York; and (ii) the Platform shall be deemed a passive one that does not give rise to personal jurisdiction over us, either specific or general, in jurisdictions other than New York. This Agreement shall be governed by the internal substantive laws of the State of New York, without respect to its conflict of laws principles. You agree to submit to the personal jurisdiction of the federal and state courts located in New York County, New York for any actions for which we retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of our copyrights, trademarks, trade secrets, patents, or other intellectual property or proprietary rights, as set forth in the Arbitration provision below, including any provisional relief required to prevent irreparable harm. You agree that Kings County, New York is the proper forum for any appeals of an arbitration award or for trial court proceedings in the event that the arbitration provision below is found to be unenforceable.
					
### Dispute Resolution
					
Most user concerns can be resolved quickly and to the user’s satisfaction by emailing user support. In the unlikely event that our user care team is unable to resolve a complaint you may have (or if Jona has not been able to resolve a dispute it has with you after attempting to do so informally), we each agree to resolve those disputes through binding arbitration, mediation, or small claims court instead of in courts of general jurisdiction. Arbitration, which is often cheaper, faster and less formal than a lawsuit in court, uses a neutral arbitrator instead of a judge or jury. Arbitrators can award the same damages and relief that a court can award. If you initiate an arbitration in which you seek more than $75,000 in damages, the payment of attorneys’ fees will be governed by the AAA Rules (as defined below). Any arbitration under this Agreement will take place on an individual basis; class arbitrations and class actions are not permitted.
					
### Arbitration Agreement
					
Jona and you agree to arbitrate all disputes and claims between us. This agreement to arbitrate is intended to be broadly interpreted. It includes, but is not limited to:					
claims arising out of or relating to any aspect of the relationship between us, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory;				
claims that arose before this or any prior agreements (including, but not limited to, claims relating to advertising);
claims that are currently the subject of purported class action litigation in which you are not a member of a certified class; and claims that may arise after the termination of these Terms of Use.				
References to ‘Jona’, ‘you’, and ‘us’ include our respective subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or devices under this or prior agreements between us. Notwithstanding the foregoing, either party may bring an individual action in small claims court. This arbitration agreement does not preclude you from bringing issues to the attention of federal, state or local agencies, including, for example, the Federal Communications Commission. Such agencies can, if the law allows, seek relief against us on your behalf. You agree that, by entering into these Terms of Use, you and Jona are each waiving the right to a trial by jury or to participate in a class action. These Terms of Use evidence a transaction in the interstate commerce, and thus the Federal Arbitration Act governs the interpretation and enforcement of this provision. This arbitration provision shall survive termination of these Terms of Use.
					
A party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute (‘Notice’). The Notice to Jona should be addressed to Jona, Inc. at 17 West 20th St. New York, NY 10011 (‘Arbitration Notice Address’). The Notice must (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought (‘Demand’). If Jona and you do not reach an agreement to resolve the claim within sixty (60) calendar days after the Notice is received, you or Jona may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by Jona or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or Jona is entitled.
					
The arbitration will be governed by the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (collectively, ‘AAA Rules’) of the American Arbitration Association (‘AAA’), as modified by these Terms of Use, and will be administered by the AAA. The AAA Rules are available online at adr.org, by calling the AAA at 1-800-778-7879, or by writing to the Arbitration Notice Address. The arbitrator is bound by the terms of these Terms. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of the arbitration provision. Unless Jona and you agree otherwise, any arbitration hearings will take place in a reasonably convenient location for both parties with due consideration of their ability to travel and other pertinent circumstances. If the parties are unable to agree on a location, the determination shall be made by AAA. If your claim is for $10,000 or less, we agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds
					
$10,000, the right to a hearing will be determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasoned written decision sufficient to explain the essential findings and conclusions on which the award is based. Jona will pay all AAA filing, administration, and arbitrator fees for any arbitration initiated in accordance with the AAA Rules. However, if you initiate an arbitration in which you seek more than $75,000 in damages, the payment of these fees will be governed by the AAA Rules.
					
The arbitrator may make rulings and resolve disputes as to the payment and reimbursement of fees and expenses at any time during the proceeding and upon request from either party made within 14 days of the arbitrator's ruling on the merits.
					
The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party's individual claim. YOU AND Jona AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and Jona agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this arbitration provision shall be null and void.
					
Notwithstanding any provision in these Terms to the contrary, we agree that if Jona makes any future change to this arbitration provision (other than a change to the Arbitration Notice Address) while you are a user of the Platform, you may reject any such change by sending us written notice within thirty (30) calendar days of the change to the Arbitration Notice Address provided above. By rejecting any future change, you are agreeing that you will arbitrate any dispute between us in accordance with the language of this provision.
					
### Miscellaneous
					
You may not assign or transfer these Terms of Use in whole or in part to any third party without the prior written consent of Jona. These Terms of Use shall bind and inure to the benefit of you and Jona to these Terms of Use and their respective successors, permitted transferees, and permitted assigns. Jona and you are independent contractors and are not partners, joint venturers, agents, employees or representatives of the other party. These Terms of Use contain the entire understanding of you and Jona with respect to the transactions and matters contemplated hereby, supersede all previous communications, understandings and agreements (whether oral or written) other than any click-through or end user license agreement provided directly to you by Jona, and cannot be amended except by a writing by both you and Jona or by Jona’s posting of an amended version of these Terms of Use available through the Platform. The headings and captions used in these Terms of Use are used for convenience only and are not to be considered in construing or interpreting these Terms of Use. If any part of these Terms of Use is held to be unlawful, void, or unenforceable, that part will be deemed severable and shall not affect the validity and enforceability of the remaining provisions. These Terms of Use shall continue to apply with respect to any access or use you have made of the Platform even after such access or use terminates. Jona reserves the right, in its sole discretion, to restrict, suspend and/or terminate your access to all or any part of the Platform at any time without prior notice or liability for any reason or no reason. Jona may change, suspend, discontinue or disable all or any aspect of the Platform at any time, including the availability of any feature, database, or service, without prior notice or liability.
					
If you have any questions about these Terms of Use, please contact info@Jona Jona PLATFORM PRIVACY POLICY
					
## YOUR PRIVACY IS IMPORTANT TO US
					
We take your privacy very seriously and we are committed to protecting it. We believe that you should easily know what information we collect and use, as well as to understand your rights with respect to your personal information.
					
This Privacy Policy sets out how Jona, Inc. (“Jona,” “us,” “we,” or “our”) collects, uses, and discloses information obtained through our websites, including app.Jona, manage.Jona.health, Jona and their respective subdomains, (collectively, “Websites”) and the online services we operate (collectively, the “Services”) and applies solely to information collected by, or in connection with, the Websites and the Services and not those sites that Jona does not operate.
					
Your access and use of the Websites and Services are subject to this Privacy Policy, and any separate Terms of Use for any of the Websites and Services that may be in effect at any time.
					
Please read the following carefully to understand our policies and practices regarding your information and how we will treat it. If there are any terms in this Privacy Policy that you do not agree with, please discontinue use of our Websites and our Services. You may contact privacy@Jona.health with any questions or concerns related to this policy.
					
For our Customers in the United States: Jona provides test kits and software to individuals (“Customers”) to enable microbiome testing and results, storage, management and communication functionality and other services including health coaching services under agreements with such Customers. These agreements (the “Provider Agreements”) govern our use and disclosure of their patients’ Protected Health Information as that term is defined in the Health Insurance Portability and Accountability Act of 1996 and its implementing regulations (“HIPAA”) and other Personal Information disclosed in the provision of the Services.
					
If you are a patient of one of our Provider channels and have questions about your treatment or handling of your health-related information, you should check with your health care provider. Jona is primarily responsible for determining how we use and disclose the Personal Information including Protected Health Information we collect through the Services other than our public website. Your health care provider’s collection, use and disclosure of information about you is governed, in turn, by the provider’s notice of privacy practices, privacy policies, and terms and conditions pursuant to applicable laws such as HIPAA.
					
### WHAT INFORMATION WE COLLECT
					
“Personal Information” is information that can be used to identify you by itself or when it is combined with other information, such as a name, address, cell phone number, or e-mail address.
					
We may collect Personal Information from you in various ways, such as:
					
Direct identifiers, such as your name, email address, telephone number, or any other online identifier. Certain aspects of our Services may require you or your employer to complete an account creation form and create a username and password, which may require you to provide certain direct identifiers. Upon creating an account for use of our Services, additional information may be automatically generated such as any preferences you indicate.					
Geolocation and use information for our Websites and Services that may be automatically generated when you use the Websites and Services. We collect this information through Google Analytics when you visit the Websites or use our Services. Such information may include, without limitation, your internet protocol (IP) address, geolocation as provided by the location services and GPS features of the device you are using to access one of our Websites or Services when location services have been enabled, browser type, operating system, the dates and times of page views, the duration of time spent at those pages, and if you linked to one of our Websites from another website, the address of that other website.
					
### WHAT WE DO WITH THE INFORMATION WE GATHER
					
Our primary purpose in collecting this information is to understand your interest in our products and services and to provide you with better service. We will not use, disclose, or share your information except as described in this Privacy Policy.
					
#### How we use the information
					
In particular, we may use your information for the following purposes: Manage the Services we provide to you;
					
Send you technical notices, updates, security alerts, information regarding changes to our policies, and support and administrative messages;
					
Prevent and address fraud, breach of policies or terms, and threats or harm; Monitor and analyze trends, usage, and activities;
 Conduct research, including focus groups and surveys;
 Identify and authenticate an account holder for our Services;
					
Deliver the Services to you;
					
Improve of our Services and related products, other Jona websites, applications and marketing efforts;
					
Provide access to free and/or purchased downloads and other similar content;
					
If you have opted-in to receive additional communications, we may periodically send notifications about new products or other information which you have indicated are of interest to you;
					
We may use the information to customize the website content presented to you and to align with your computer’s operating system, device, and/or browser you are using;
					
To correspond with you, respond to your comments, questions, and requests, and provide customer service; and
					
To conduct our business and perform related research, develop analytics, and aggregated data that allow us and our business partners to develop or improve our Services and related products and services.
					
How we share the information
					
We do not sell, trade, or otherwise transfer to outside parties Personal Information we collect from you without your consent, except as described below:
					
We may share aggregated data to conduct our business, improve the delivery of our Services, to develop analytics, and to enable us and our business partners to improve and promote our products and services.
					
We may share Personal Information made available by users in public areas, such as any message boards or blogs we make available as part of our Services, for purposes related to marketing products, services, special deals, and promotions offered by us or one of our partners.
					
We may share Personal Information to authorized agents or third-party contractors whom we employ to perform tasks on our behalf and to the extent we need to share information with them to conduct our business or to provide our products, services, and offers to you.
We may share your Personal Information with the Provider Customers with whom you have a relationship in accordance with our agreements with those Provider Customers and consistent with applicable law.
					
We may disclose Personal Information to third parties if we believe it is necessary to investigate potential violations of our Terms of Use, or to enforce those Terms of Use.
					
We may disclose Personal Information under the following uncommon circumstances:
					
As required by law;
					
If we believe that disclosure is necessary to protect ours or others’ rights, property, or safety;
					
To comply with a judicial proceeding, court order, or legal process served on us or the Services;
					
In the event that we sell or buy any business or assets, in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;
					
If Jona or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets; or
					
In connection with any other actual or proposed corporate transaction or insolvency proceeding involving all or part of the business or assets to which the information pertains.
					
### Length
					
We keep your Personal Information only for as long as we continue to have a business purpose for it or as otherwise required by law.
					
### Communications from Us and Other Third Parties
					
When you send email or other communications to Jona, we may retain those communications in order to process your inquiries, respond to your requests, and improve our Websites and related products and services. When you exchange messages with Jona, we may collect and maintain information associated with those messages.
					
We may send you information by email on products, services, and promotions offered by us. If you are an existing customer, we will only contact you by email with such information where you have consented for us to do.
					
For promotional offers, we will also provide an option to elect not to receive these types of communications. You can also exercise the right at any time by contacting us at privacy@Jona.health.
					
### Service-Related Announcements
					
We may send you service-related announcements on rare occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email.
					
Generally, you may not opt out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account with us.
					
### Customer Service
					
Based upon the Personal Information you provide us, we may send you a welcome email to verify your username and password for the Services. We will communicate with you in response to your inquiries, to provide the services you request, and to manage your account. We will communicate with you by email or telephone, in accordance with your preference. You may contact customer service at support@Jona.health
					
### Storage
The information that we collect directly from you is stored in the region in which your institution is located by us and/or our business partners, unless otherwise agreed upon in a separate contract between you or your institution and Jona. By submitting your Personal Information, you agree to this storage.
					
All information you provide directly to us is stored on our secured servers which are located in the region in which your institution is located. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Services, you are responsible for keeping this password confidential. Our Terms of Use for our Services require you to not share your password with anyone.
					
Some of the information collected through the use of cookies may be stored outside of the region in which your institution is located. See our section below on Cookies to review what information we collect through such cookies.
					
## SECURITY
					
We will take technical, administrative, and physical steps reasonably necessary to restrict access to Personal Information to authorized individuals.
					
We strive to use reasonable physical, technical, and administrative measures to protect information under our control. To the extent we provide your Personal Information to any third parties, we will request that they use substantially the same level security measures to protect your information. If you create copies of information from the Websites or our Services, we cannot protect the security and privacy of the information contained in such copies
					
### CONTROLLING YOUR PERSONAL INFORMATION			
Under applicable data protection laws in the European Union, the following rights may apply:
Right to access and obtain a copy of your personal information: you are entitled to request confirmation whether we process any of your personal information. Where this is the case, you may have access to your personal information and to certain information about how it is processed. In some cases, you can ask us to provide you with an electronic copy of your information. In some limited circumstances, you also have the right to request portability of your personal information.
					
Right to correct your personal information: If you can demonstrate that the personal information we hold about you is not correct, you can ask that this information is updated or otherwise corrected.
					
Right to be forgotten/have data deleted: In certain circumstances you have the right to have your personal data deleted. You may make such a request at any time. However this right is subject to any legal rights or obligations we may have to retain data. For situations where in accordance with the law, we determine that your request to have your personal information deleted must be granted, we will do so without undue delay.
					
Right to restrict or object to the processing of your data: In certain circumstances you have the right to obtain restriction of the processing of your personal information, or to object on certain processing thereof on grounds relating to your particular situation.
					
You can also lodge a complaint about our processing of your personal information with the data protection authority of the European Member State of your habitual residence, of your place of work, or of an alleged infringement of your rights.
					
## INFORMATION YOU POST TO PUBLIC AREAS OR PROVIDE TO US				
Please keep in mind that whenever you voluntarily make Personal Information or other private information available in public areas for viewing by third parties online (for example, on discussion forums, or other community posting sites available via our Websites), such information can be seen, collected, and used by others besides us. We cannot be responsible for any unauthorized third-party use of such information, nor for the substance of any such user-generated content.	

## MINORS				
Our Websites and Services, and any other products and service we may provide through our Websites are not directed nor marketed to people under 18 years of age. We do not knowingly collect Personal Information from children under 18. The Websites and the services are not designed or intended to attract, and is not directed to, children under eighteen (18) years of age. If we become aware that a child under 18 has provided us with Personal Information, we will take reasonable steps to remove such information from our system and terminate the applicable account. If you become aware that a child has provided us with Personal Information without your consent, please contact us at privacy@Jona.
					
## COOKIES			
Certain pages of the Websites and/or HTML email correspondence may use session cookies, persistent cookies or web beacons to anonymously track unique visitors, save website preferences and to allow us to recognize visits from the same computer and browser. Our Website uses “cookies” required for the operation of our Site (“Essential Cookies”). These cookies enable services you have specifically requested. Some cookies are used to collect anonymous information on the pages visited (“Performance Cookies”). For example, we might use Performance Cookies to keep track of which pages are most popular, which method of linking between pages is most effective, and to determine why some pages are receiving error messages. Some cookies remember choices you make to improve your experience (“Functionality Cookies”). These Functionality Cookies remain on your device for an extended period of time. When you revisit our Websites, we recognize Functionality Cookies and remember your preferences or can automatically log you on to the website. Personal information on our system may be associated with Functionality Cookies.
					
Further general information about cookies and how they work is available at www.allaboutcookies.org. We may from time to time allow selected third parties to place cookies through the Websites to provide us with better insights into the use of the Websites or user demographics or to provide relevant advertising to you. These third parties may collect information about a consumer’s online activities over time and across different websites when he or she uses our Websites. We may also permit third party service providers to place cookies through our Websites to perform analytic or marketing functions where you are notified of them and you have consented to the usage. We do not control the use of such third-party cookies or the resulting information and we are not responsible for any actions or policies of such third parties.
					
We do not use technology that recognizes a “do-not-track” signal from your web browser.
					
### CHANGES TO OUR PRIVACY POLICY			
Jona may update this Privacy Policy from time to time. If we decide to change our Privacy Policy, we will post those changes on this page and update the Privacy Policy modification date at the top of this page and, where appropriate, notify you of any material changes we make to the Privacy Policy by email. Please check back frequently to see any updates or changes to our Privacy Policy. This Privacy Policy shall be deemed effective as of the "Effective Date."
					
If you have questions, suggestions, wish to make a complaint, or require a copy of your information, please contact us at:
					
privacy@Jona.health
					
If you wish to contact our Data Protection Officer, you can do so by post to: Data Protection Officer
 Jona, Inc.
 17 West 20th St.
					
New York, NY 10011
					
If you are in the European Union and wish to contact our Article 27 Representative, you can do so using the following contact information:
					
Instant EU GDPR Representative Ltd.
 69 Esker Woods Drive
 Lucan Co., Dublin, Ireland
 email: contact@gdprlocal.com Tel: + 353 15 549 700 Effective Date: March 15, 2022
				
			
		
						
_Copyright © 2020-2022 Jona, Inc. All Rights Reserved._
`;

export const ConsentFormMarkdown = `
# INFORMED CONSENT FORM

Jona is asking you to participate in a research study. This form is designed to give you information about this study. Jona will describe this study to you and answer any questions you may have.

**Project Title: Studying Microbiome Association with Health Data and Progression**

Principal Investigator: Leo Grady, PhD

Provided is a summary of this study to help you decide whether you want to participate.

-   The primary objective of this study is to gather stronger and more definitive evidence for associations between the microbiome and a variety of diseases, health conditions, symptoms, metabolism, progressions and interventions.
-   As part of this participation, you may be asked to engage in follow-up conversations or surveys. 
-   Your participation in this research is voluntary. You can decide not to participate or withdraw from the study any time within the duration of participation. Your decision will not result in any penalty or loss of benefits to which you are otherwise entitled.

### **What does it mean to participate in Jona's research**

-   The **purpose** of Jona’s Research is to make new **discoverie** about the microbiome and other factors behind diseases, food intolerances, and treatments. 
-   We intend to enroll at least 10,000 subjects in this research study. 
-   **If you agree to this consent** , you **allow Jona researchers to use certain de-identified information** (including your Microbiome and Health Information and your responses to research surveys) to study a wide variety of research topics.
-   To protect your **privacy** , Jona conducts research with information that has been **stripped of your name and contact information** and combined with similar information from many research participants.
-   Giving consent means that you agree to let Jona researchers use certain information (including such information provided prior to giving consent) for Jona Research. By consenting to this process, you acknowledge that your de-identified and tokenized data may be aggregated to create datasets that can be further analyzed or used in conjunction with third party partners for research, development, or other purposes. These datasets may contribute to scientific advancements, medical research, or other beneficial applications. 
-   Your de-identified and tokenized data may be shared with trusted partners, researchers, academic institutions, or other entities that have a legitimate interest in utilizing the data for research or related purposes. We take appropriate measures to ensure the protection and confidentiality of your tokenized data during these exchanges. 

Some Jona Research is conducted in **collaboration with third parties** , such as non-profit organizations, life sciences companies, or academic institutions. **We may share summaries of research results, which do not identify any particular individual** , with qualified research collaborators and in scientific publications.Your de-identified microbiome data may be combined with other de-identified data and medical records. **Note that your personal information (name, contact information, etc.) will never be shared with any 3rd parties.**

**This study is collecting data and biospecimens from you. We would like to make your de-identified data and biospecimens available for other research studies that may be done in the future. The research may be about similar diseases or conditions to this study. However, research could also be about unrelated diseases, conditions, or other types of research. These studies may be done by researchers at Jona or other institutions, including commercial entities. Our goal is to make more research possible.**

**Your de-identified data and biospecimens may be shared with researchers around the world. However, the decision to share your data and biospecimens is controlled by Jona. To get your data and biospecimens, future researchers must seek approval from Jona. The researchers must agree not to try to identify you.**

**We will protect the confidentiality of your information to the extent possible. Your data and biospecimens will be coded to protect your identity before they are shared with other researchers. Jona will have a code key that can be used to link to your identifying information. The code key will not be shared with anyone outside Jona and will be securely stored.**


### **Benefits/Risks**

There are no direct risks or benefits to subjects recruited to this study.  You and others may benefit in the future from discoveries that lead to healthcare advances or scientific research. There will be no study interventions and no direct influence on the subject's results; additionally, the subjects’ identities will not be identifiable in publications resulting from this study. Potential risks may include having the subject’s privacy or confidentiality compromised in the case of a security breach.  However, every reasonable effort will be made to protect the subject’s information while their data is used as part of this study.

### **Incentives for Participation**

There are no incentives for participating in this study.  Research insights gained from the study may help the subject or other people in the future. 

I hereby consent to participate in the research study offered by Jona. I understand that this research involves the analysis of my microbiome profiling results in connection with other collected data. 

I acknowledge that I am 18 years of age or older and have read and understood the information provided to me regarding the microbiome results analysis program. I agree to allow Jona to analyze my microbiome report and use my health data for the purposes of analysis and research. 

I understand that the analysis and research may involve the use of my de-identified health data, which means that my personal identifying information will be removed from any data that is used or disclosed. I understand that my de-identified health data may be used by Jona and its affiliates, agents, and contractors for research, development and commercial purposes. 

I acknowledge that I have the right to withdraw my consent at any time by contacting Jona. I understand that my withdrawal of consent will not affect any of my rights or obligations under this agreement or any other agreement I have with Jona 

I understand that my participation in the study is entirely voluntary and that I may choose not to participate or to withdraw my consent at any time. I also understand that there are no known risks associated with the study. 

I have read and understood this consent form and I consent to participate in the microbiome study conducted by Jona. 

### **Who do I contact if I have questions?**

### If you have _ **general questions and need help with** _ Jona's product or services, please contact: hello@jona.health

`;
