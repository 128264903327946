import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserReport } from 'types/V2Types';

interface ClientKitState {
  currentClientId?: string | number;
  currentClientName?: string;
  currentClientKits?: UserReport[];
}

const initialState: ClientKitState = {
  currentClientId: undefined,
  currentClientName: undefined,
  currentClientKits: undefined,
};

const clientKitSlice = createSlice({
  name: 'clientKit',
  initialState,
  reducers: {
    setCurrentClientKits: (state, action: PayloadAction<UserReport[]>) => {
      state.currentClientKits = action.payload;
    },
    setCurrentClientId: (state, action: PayloadAction<string | number>) => {
      state.currentClientId = action.payload;
    },
    setCurrentClientName: (state, action: PayloadAction<string>) => {
      state.currentClientName = action.payload;
    },
    resetClientKit: (state) => {
      state.currentClientId = undefined;
      state.currentClientName = undefined;
      state.currentClientKits = undefined;
    },
  },
});

export const { setCurrentClientKits, setCurrentClientId, setCurrentClientName, resetClientKit } =
  clientKitSlice.actions;
export default clientKitSlice;
