import { Group, Paper, Text, Title, createStyles } from '@mantine/core';
import Card from 'components/Card';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import { ReactNode } from 'react';

interface Props {
  findingName: string;
  n: number;
}

export default function NoResearchBlock({ findingName, n }: Props) {
  const { classes } = useStyles();

  return (
    <Card className="jona-card" mb="lg">
      <Group position="apart" mb="lg">
        <Title order={4}>
          {n}. Your goal: Reduce and manage {findingName.toLowerCase()}
        </Title>
        <Paper className={classes.itemCardYellow} withBorder radius={4} fz="sm" p="lg">
          <Text>
            Jona’s database does not currently cover associations between {findingName.toLowerCase()} and the gut
            microbiome as the current research is too limited to provide substantial recommendations. We recommend
            discussing this symptom with your doctor to investigate other potential root causes and create a treatment
            plan.
          </Text>
        </Paper>
      </Group>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  itemCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderColor: 'white !important',
    height: '100%',
  },
  itemCardBlue: {
    backgroundColor: '#c4d6ed5f',
    borderColor: `${theme.colors.blue[3]} !important`,
    height: '100%',
    borderRadius: '15px',
  },
  itemCardYellow: {
    backgroundColor: '#E0D3BA3F',
    borderColor: `#AD8323 !important`,
    height: '100%',
    borderRadius: '15px',
  },
  list: {
    color: 'white',
  },
  card: {
    // backgroundColor: 'rgba(4, 74, 126, 0.05)',
    backgroundColor: 'rgba(255, 255, 255, 0.30)',
    borderColor: `${theme.colors.gray[5]} !important`,
    borderRadius: '10px',
  },
  reccomendationsBadge: {
    color: 'white',
    backgroundColor: '#AD8323',
    // backgroundColor: theme.colors.yellow[8],
    opacity: '75%',
  },
}));

interface SymptomBlockTitleProps {
  title: string;
  content: ReactNode;
}
function SymptomBlockTitle({ title, content }: SymptomBlockTitleProps) {
  return (
    <Group position="apart" mb="md">
      {title} <TitlePopover content={content} direction="top-left" iconVariant="gray" />
    </Group>
  );
}
