import CustomAccordion from './CustomAccordion/CustomAccordion';
import CustomAccordionContent from './CustomAccordion/CustomAccordionContent';
import CustomAccordionTitle from './CustomAccordion/CustomAccordionTitle';
import { type ReportFindingOverview } from 'types/V2Types';
import { useParams } from 'react-router-dom';
import { Paper, Text } from '@mantine/core';

interface PanelMetabolismProps {
  metabolisms: ReportFindingOverview[];
}

const PanelMetabolism: React.FC<PanelMetabolismProps> = ({ metabolisms }) => {
  const { barcode } = useParams<string>();

  const groupedByCategory = metabolisms.reduce((acc, metabolism) => {
    const category = metabolism.category ?? '';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(metabolism);
    return acc;
  }, {} as Record<string, ReportFindingOverview[]>);

  return (
    <>
      {!metabolisms.length && (
        <Paper withBorder radius={2} fz="sm" p="md">
          <Text>We did not find any metabolisms in your sample.</Text>
        </Paper>
      )}
      {Object.keys(groupedByCategory).map((category) => {
        return (
          <CustomAccordion
            key={category}
            title={category}
            description={`Below are the findings related to your ${category} digestion.`}
            showN={groupedByCategory[category].length}
            items={groupedByCategory[category].map((metabolism) => {
              return {
                id: metabolism.finding_id,
                title: (
                  <CustomAccordionTitle
                    title={metabolism.preferred_name ?? metabolism.name}
                    isNewItem={metabolism.isNewItem}
                    hasChanges={!!metabolism.changes}
                  />
                ),
                content: (
                  <CustomAccordionContent
                    id={metabolism.finding_id}
                    linkUrl={`/reports/${barcode}/metabolisms/${metabolism.finding_id}`}
                    name={metabolism.preferred_name ?? metabolism.name}
                    description={metabolism.one_line_description ?? metabolism.description}
                    barcode={barcode}
                    symptoms={metabolism.symptoms}
                    totalSymptomsLength={metabolism.relations.causes.symptoms.length}
                    organisms={metabolism.organisms}
                  />
                ),
              };
            })}
          />
        );
      })}
    </>
  );
};

export default PanelMetabolism;
