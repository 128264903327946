import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserReport } from 'types/V2Types';

interface KitState {
  currentKit?: UserReport;
}

const initialState: KitState = {
  currentKit: undefined,
};

const kit = createSlice({
  name: 'kit',
  initialState,
  reducers: {
    setCurrentKitToMostRecent: (state, action: PayloadAction<UserReport[]>) => {
      const userReports: UserReport[] = action.payload;

      const mostRecent = userReports.reduce((prevReport, currentReport) => {
        const prevDate = new Date(prevReport.created_at);
        const currentDate = new Date(currentReport.created_at);

        return currentDate > prevDate ? currentReport : prevReport;
      }, userReports[0]);

      state.currentKit = mostRecent;
    },
    setCurrentKit: (state, action: PayloadAction<UserReport | undefined>) => {
      state.currentKit = action.payload;
    },
    setCurrentKitUsingBarcode: (
      state,
      action: PayloadAction<{
        barcode: string;
        userReports?: UserReport[];
      }>
    ) => {
      const { barcode, userReports } = action.payload;
      const kit = userReports?.find((report) => report.barcode === barcode);

      if (kit) {
        state.currentKit = kit;
      }
    },
  },
});

export const { setCurrentKit, setCurrentKitToMostRecent, setCurrentKitUsingBarcode } = kit.actions;

export const selectCurrentKit = createSelector(
  (state: { kit: KitState }) => state.kit,
  ({ currentKit }) => {
    return { currentKit };
  }
);

export default kit;
