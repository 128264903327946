import { Button, type ButtonProps } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface NavButtonProps extends ButtonProps {
  to: string;
}

const NavButton: React.FC<NavButtonProps> = ({ children, to, ...props }) => (
  <Button
    component={Link}
    to={to}
    color="blue"
    styles={(theme) => ({
      inner: {
        justifyContent: 'space-between',
      },
      rightIcon: {
        marginLeft: theme.spacing.md,
      },
    })}
    rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
    {...props}
  >
    {children}
  </Button>
);
export default NavButton;
