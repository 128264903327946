import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { UserMode } from 'slices/app';

export default function HandleIndexRoute() {
  const { userMode } = useSelector((state: RootState) => state.app);

  if (userMode === UserMode.PROVIDER) {
    return <Navigate to="/clients" />;
  }

  return <Navigate to="/reports" />;
}
