import { Button, Modal, Title, Text, Checkbox, ModalProps, Stack, Flex } from '@mantine/core';
import { useState } from 'react';
import userApi from 'services/user';

interface ClientAccessModalProps extends ModalProps {
  providerId: string | number;
  onYesCallback: () => void;
  isOpen?: boolean;
}

const ClientAccessModal: React.FC<ClientAccessModalProps> = ({ providerId, onYesCallback, onClose, ...props }) => {
  const [dontAskMeAgain, setDontAskMeAgain] = useState(false);

  const [updatePreferences] = userApi.endpoints.updateProviderStaffPreferences.useMutation();

  const handleYes = () => {
    dontAskMeAgain && updatePreferences({ clientAccessDontAskAgain: true, providerId });
    onYesCallback && onYesCallback();
  };

  return (
    <Modal radius="md" size="lg" centered onClose={onClose} {...props}>
      <Stack px="xl" pt="0" pb="md">
        <Title order={2} fw={400}>
          Are you sure you want to grant the client access to their report?
        </Title>
        <Text size="md" c="dimmed">
          This action can&apos;t be undone.
        </Text>
        <Checkbox
          checked={dontAskMeAgain}
          onChange={(event) => setDontAskMeAgain(event.currentTarget.checked)}
          color="dark"
          size="xs"
          radius="xs"
          label={
            <Text size="xs" c="dimmed">
              Don&apos;t ask me again
            </Text>
          }
        />
        <Flex justify="space-around" w="100%" pt="md">
          <Button w="187px" onClick={onClose} variant="outline" color="dark">
            No
          </Button>
          <Button w="187px" onClick={handleYes} color="dark">
            Yes
          </Button>
        </Flex>
      </Stack>
    </Modal>
  );
};
export default ClientAccessModal;
