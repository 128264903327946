import { Button, Flex, Text, createStyles } from '@mantine/core';

interface ProviderInviteProps {
  providerName: string;
  onAccept: () => void;
  onDeny: () => void;
  rejected: boolean;
  onUndo: () => void;
}

const ProviderInvite: React.FC<ProviderInviteProps> = ({ providerName, onAccept, onDeny, rejected, onUndo }) => {
  const { classes } = useStyles();

  return !rejected ? (
    <Flex align="center" justify="space-between" className={classes.providerInvite}>
      <Flex direction="column" mr="sm">
        <Text mr="md">
          <Text span className={classes.name}>
            {providerName}
          </Text>{' '}
          is inviting you to join their client list.
        </Text>
        <Text c="dimmed" size="xs">
          This will allow them to access your Jona reports and health survey.
        </Text>
      </Flex>
      <Flex>
        <Button size="xs" mr="sm" color="blue" className={classes.button} onClick={onAccept}>
          Accept
        </Button>
        <Button size="xs" variant="outline" className={classes.button} onClick={onDeny}>
          Deny
        </Button>
      </Flex>
    </Flex>
  ) : (
    <Flex align="center" justify="space-between" className={classes.providerInvite}>
      <Text>
        <Text span className={classes.name}>
          {providerName}
        </Text>{' '}
        has not been added to your providers.
      </Text>
      <Button size="xs" variant="outline" className={classes.button} onClick={onUndo}>
        Undo
      </Button>
    </Flex>
  );
};
const useStyles = createStyles((theme) => ({
  providerInvite: {
    minHeight: '68px',
    width: '100%',
    backgroundColor: theme.colors.gray[0],
    border: `1px solid #ffffff`,
    padding: theme.spacing.sm,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    borderRadius: theme.radius.sm,
    marginBottom: theme.spacing.md,
  },
  name: { textTransform: 'capitalize' },
  button: { width: '105px', fontSize: theme.fontSizes.sm },
}));

export default ProviderInvite;
