import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, Popover, Text, UnstyledButton } from '@mantine/core';
import { TaxonomyHierarchy } from './TaxonomyHierarchy';

const useStyles = createStyles((theme) => ({
  infoButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    border: '1px solid black',
    position: 'absolute',
    top: 0,
    right: 0,
  },
  popoverDropdown: {
    pointerEvents: 'none',
    border: '1px solid white',
    background: theme.colors.gray[1],
  },
}));

export function TaxonomyPopover() {
  const { classes } = useStyles();

  return (
    <Popover width={600} position="right-end" shadow="sm" radius="0">
      <Popover.Target>
        <UnstyledButton className={classes.infoButton}>
          <FontAwesomeIcon size="xs" icon={faInfo} />
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown p="md" className={classes.popoverDropdown}>
        <Text size="md" fw={700} inline mb="md">
          Microbiome Taxonomy
        </Text>
        <Text component="p" size="sm">
          Gut microbiome taxonomy is the classification system used to organize the various microorganisms living in the
          human digestive tract. It categorizes these microorganisms into hierarchical groups such as phyla, families,
          genera, and species based on their genetic makeup and structural traits.
        </Text>
        <TaxonomyHierarchy />
      </Popover.Dropdown>
    </Popover>
  );
}
