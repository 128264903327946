import { Box, createStyles, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { organismHasNegativeImpact, round } from 'utilsV2';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type RangeSection = {
  start: number;
  end: number;
  color: string;
  className?: string;
};

type RangeTick = {
  position: number;
  label?: string;
};

interface RangeProps {
  min?: number;
  max?: number;
  sections?: RangeSection[];
  ticks?: RangeTick[];
  tickStyle?: 'contain' | 'grow';
  baseColor: string;
  className?: string;
  score: number;
  action?: string;
}

export function Range({
  min = 0,
  max = 1,
  sections,
  tickStyle = 'grow',
  className,
  baseColor,
  ticks,
  score,
  action,
}: RangeProps) {
  const { classes, cx } = useStyles({ baseColor, tickStyle });
  let pointRight = false;

  if (action && score > 0) {
    pointRight = true;
  }

  if (!action && score < 0) {
    pointRight = true;
  }

  return (
    <Box className={cx(classes.baseBar, className)}>
      {sections?.map((section, index) => {
        let { end, start } = section;
        start = clamp(start, min, max);
        end = clamp(end, min, max);

        const wPercentage = ((end - start) * 100) / (max - min);
        const leftPercentage = (start * 100) / (max - min);

        return (
          <Box
            key={`bar-${index}`}
            bg={section.color}
            w={`${wPercentage}%`}
            className={cx(section.className, classes.sectionBar)}
            left={`${leftPercentage}%`}
          />
        );
      })}
      {ticks?.map((tick, index) => {
        const { label } = tick;
        const position = round(tick.position, 2);
        const leftPercentage = clamp((position * 100) / (max - min), 0, 100);
        const errorLabelColor = position > max || position < min ? 'red' : undefined;

        return (
          <React.Fragment key={`tick-${index}`}>
            <Box className={cx(className, classes.tick)} left={`${leftPercentage}%`} />

            <Text className={cx(classes.tickLabel, 'tick-label')} left={`${leftPercentage}%`} bg={errorLabelColor}>
              {!pointRight && <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />}
              {label}
              {pointRight && <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: '5px' }} />}
            </Text>
          </React.Fragment>
        );
      })}
    </Box>
  );
}

interface StyleProps {
  baseColor: RangeProps['baseColor'];
  tickStyle: RangeProps['tickStyle'];
}

const useStyles = createStyles((_, { baseColor, tickStyle = 'grow' }: StyleProps) => ({
  baseBar: {
    width: '100%',
    height: '30px',
    position: 'relative',
    overflow: 'visible',
    backgroundColor: baseColor,
    zIndex: 1,

    marginBottom: '12px',
    '&::before, &::after': { position: 'absolute', fontSize: '10px', top: '100%' },
    '&::before': { content: '"0"' },
    '&::after': { content: '"100"', right: '0' },

    // to show the rails around the bar
    ...(tickStyle === 'grow' && {
      borderTop: '0.5px solid #AAAAAA',
      borderBottom: '0.5px solid #AAAAAA',
      backgroundClip: 'content-box',
      padding: '5px 0px',
      boxSizing: 'content-box',
      transform: 'translateY(5px)',
    }),
  },

  sectionBar: {
    position: 'absolute',
    top: tickStyle === 'grow' ? '5px' : '0',
    height: '30px',
    zIndex: 2,
  },

  tick: {
    position: 'absolute',
    width: '2px',
    minHeight: '30px',
    zIndex: 3,
    backgroundColor: 'black',

    // the bottom tick growth
    ...(tickStyle === 'grow' && {
      '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '2px',
        backgroundColor: 'black',
        top: '100%',
        height: '6px',
      },
    }),

    // the top tick growth
    ...(tickStyle === 'grow' && {
      '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        width: '2px',
        backgroundColor: 'black',
        bottom: '100%',
        height: '6px',
      },
    }),
  },

  tickLabel: {
    position: 'absolute',
    fontSize: '10px',
    zIndex: 3,
    color: 'white',
    padding: '1px 5px',
    backgroundColor: 'black',
    borderRadius: '2px',
    fontWeight: 600,
    transform: 'translate(-50%, -50%)',
    top: '50%',
    width: 'max-content',
  },
}));

function clamp(value: number, min: number, max: number): number {
  return Math.max(Math.min(value, max), min);
}

interface OrganismRangeProps {
  guidance: string;
  range: number;
  relative_abundance?: number;
  score: number;
  grayscale?: boolean;
  useRangeForColors?: boolean;
  useRelativeAbundanceForColor?: boolean;
  is_pathogen?: boolean;
  action?: string;
}

const percFormatter = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 1 });

export const OrganismRange = ({
  guidance,
  range,
  score,
  relative_abundance,
  grayscale,
  useRangeForColors,
  useRelativeAbundanceForColor,
  is_pathogen,
  action,
}: OrganismRangeProps) => {
  const rangeLabel = percFormatter.format(range);

  const [highlightColor, setHighlightColor] = useState<string>(() =>
    calculateHighlightColor(
      guidance,
      score,
      range,
      useRangeForColors,
      grayscale,
      useRelativeAbundanceForColor,
      relative_abundance,
      is_pathogen
    )
  );

  useEffect(() => {
    const newHighlightColor = calculateHighlightColor(
      guidance,
      score,
      range,
      useRangeForColors,
      grayscale,
      useRelativeAbundanceForColor,
      relative_abundance,
      is_pathogen
    );
    setHighlightColor(newHighlightColor);
  }, [
    guidance,
    score,
    range,
    useRangeForColors,
    grayscale,
    useRelativeAbundanceForColor,
    relative_abundance,
    is_pathogen,
  ]);

  const baseColor = `${highlightColor}B2`; // 70% opacity

  return <Range baseColor={baseColor} ticks={[{ position: range, label: rangeLabel }]} score={score} action={action} />;
};

const RED = '#F38686';
const GREEN = '#68E083';
const GRAY = '#AAAAAA';
const BLUE = '#4A90E2';

const calculateHighlightColor = (
  guidance: string,
  score: number,
  range: number,
  useRangeForColors?: boolean,
  grayscale?: boolean,
  useRelativeAbundanceForColor?: boolean,
  relative_abundance?: number,
  is_pathogen?: boolean
): string => {
  if (grayscale) return GRAY; // gray

  if (useRangeForColors) {
    return range < 0.5 ? RED : BLUE;
  }

  if (useRelativeAbundanceForColor && relative_abundance !== undefined) {
    return relative_abundance > 0 ? (is_pathogen ? RED : BLUE) : GRAY;
  }

  if (organismHasNegativeImpact(guidance, score)) {
    return RED;
  }

  return BLUE;
};
