import { MantineTheme } from '@mantine/core';
import bg3 from 'assets/bg3.webp';
import { useFlags } from 'launchdarkly-react-client-sdk';

const global = (theme: MantineTheme): any => {
  const flags = useFlags();

  return {
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },

    body: {
      margin: 0,
      fontFamily: 'Sora, sans-serif',
      background: `url(${bg3})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      color: theme.colors.dark[5],
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },

    a: {
      '&.stretched-link': {
        textDecoration: 'none',
        color: 'inherit',

        '&::after': {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          pointerEvents: 'auto',
          content: '""',
          backgroundColor: 'rgba(0,0,0,0)',
        },
      },
    },
  };
};

export default global;
