import { Badge, Flex, Text, Tooltip, createStyles } from '@mantine/core';
import AssociationSection from 'components/ReportV2/elements/AssociationSection';
import LinkButton from 'components/ReportV2/elements/LinkButton';
import { FC } from 'react';
import { ReportOrganism } from 'types/V2Types';
import List, { ListItem } from 'ui/List';
import InlineMD from '../elements/InlineMarkdown';

interface DietRecommendationProps {
  finding_id: string;
  barcode: string;
  action_id?: string;
  guidance: string;
  one_line_description: string;
  description: string;
  recommended_foods: ListItem[];
  foods_to_avoid: ListItem[];
  organisms: ReportOrganism[];
  name: string;
  preferred_name: string;
  association_score: number;
  research_status: string;
}

const DietRec: FC<DietRecommendationProps> = ({
  finding_id,
  barcode,
  action_id = 'high_score',
  guidance,
  one_line_description,
  description,
  name,
  preferred_name,
  association_score,
  research_status,
  recommended_foods,
  foods_to_avoid,
  organisms,
}) => {
  const { classes, cx } = useStyles();

  const getBadgeClass = () => {
    let className;
    if (guidance === 'Adopt') {
      className = classes.positiveLabel;
    } else {
      className = classes.negativeLabel;
    }
    return cx(classes.listItemBadge, classes.label, className);
  };

  const getOrganismBadgeClass = (organism: ReportOrganism) => {
    let className;
    if (organism.score >= 0) {
      className = classes.positiveLabel;
    } else {
      className = classes.negativeLabel;
    }
    return cx(classes.listItemBadge, classes.label, className);
  };

  const getOrganismShortDescription = (organism: ReportOrganism) => {
    let shortDescription = organism.one_line_description;
    shortDescription = shortDescription || organism.description?.split('\n\n')[0];
    return shortDescription;
  };

  const organismHasDescription = (organism: any) => {
    return getOrganismShortDescription(organism) === '';
  };

  const _description = one_line_description !== '' ? one_line_description : description;
  const _name = preferred_name ?? name;

  return (
    <Flex className={classes.cardListItem} py="sm" direction="column" mx="xl" mb="lg">
      <Flex align="center" mb="lg">
        <Badge size="md" mr="sm" className={getBadgeClass()}>
          {guidance}
        </Badge>
        <Text mr="sm" fw="600">
          <InlineMD>{_name}</InlineMD>
        </Text>
        <AssociationSection associationScore={association_score} researchStatus={research_status} />
        <LinkButton ml="sm" h="20px" size="xs" to={`/reports/${barcode}/symptoms/${finding_id}/actions/${action_id}`}>
          Details
        </LinkButton>
      </Flex>
      <Flex>
        <Text mb="sm">{_description}</Text>
      </Flex>
      {recommended_foods && recommended_foods.length > 0 && (
        <>
          <Text mb="sm" fw="600">
            Foods to eat
          </Text>
          <List items={recommended_foods.slice(0, 3)} />
        </>
      )}
      {foods_to_avoid && foods_to_avoid.length > 0 && (
        <>
          <Text mt="md" mb="sm" fw="600">
            Foods to avoid
          </Text>
          <List items={foods_to_avoid.slice(0, 3)} />
        </>
      )}
      {!recommended_foods && !foods_to_avoid && <Text mb="sm">{_description}</Text>}
      {organisms && organisms.length > 0 && (
        <Flex direction="column">
          <Text mt="md" mb="sm" fw="600">
            Impact on microbiome
          </Text>
          <Flex direction="row" justify="flex-start" gap="sm" align="center" mb="md" wrap="wrap">
            {organisms.slice(0, 3).map((organism: ReportOrganism, index: number) => (
              <Flex key={index}>
                <Tooltip
                  disabled={organismHasDescription(organism)}
                  multiline
                  w={400}
                  withArrow
                  label={getOrganismShortDescription(organism)}
                >
                  <Badge size="md" className={getOrganismBadgeClass(organism)}>
                    {organism.score < 0 ? 'Decrease' : 'Increase'} <i>{organism.name}</i>
                  </Badge>
                </Tooltip>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default DietRec;

DietRec.defaultProps = {
  action_id: 'high_score',
};

const useStyles = createStyles((theme) => ({
  label: {
    border: `1px solid ${theme.colors.gray[5]}`,
    borderRadius: '3px',
  },
  positiveLabel: {
    backgroundColor: `${theme.colors.green[1]} !important`,
  },
  negativeLabel: {
    backgroundColor: `${theme.colors.red[1]} !important`,
  },
  neutralLabel: {
    backgroundColor: `${theme.colors.gray[1]} !important`,
  },
  cardListItem: {
    // borderBottom: '0.5px solid #AAAAAA',
    fontSize: theme.fontSizes.sm,
  },
  listItemBadge: {
    minWidth: '72px',
    backgroundColor: '#eeeeee',
    color: 'black',
    fontWeight: 500,
    textTransform: 'none',
    borderRadius: '3px',
  },
  linkBtn: {
    flexGrow: 1,
  },
}));
