import { Box, Button, Flex, Modal, Paper, createStyles, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import UnknownError from './UnknownError';
import { faHome, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

interface ErrorPopupProps {
  error?: FetchBaseQueryError | SerializedError;
  message?: string;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({ error, message }) => {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(true);

  return (
    <Modal title={<Text color="red">Error</Text>} opened={opened} onClose={close} centered radius="md">
      {error && 'status' in error && Number(error.status) >= 400 && Number(error.status) < 500 ? (
        <Box>
          <Paper className={classes.paper} withBorder radius={2} p="lg">
            <Flex direction="column" align="center">
              <Text color="dark.5" size="lg" mb="sm" fw="600">
                This action is unavailable
              </Text>
              <Text align="center" color="gray.8" size="md" mb="xs">
                {message || "You don't have access to this link or this link is invalid."}
              </Text>
              <Text align="center" color="gray.8" size="md" mb="lg">
                Please contact support if you need assistance <a href="mailto:hello@jona.health">hello@jona.health</a>
              </Text>
              <Flex w="100%" align="center" justify="space-evenly">
                <Button component={Link} variant="light" to="/">
                  <FontAwesomeIcon icon={faHome} />
                  <Text ml="xs">Go to Homepage</Text>
                </Button>
                <Button component={Link} to="/support" variant="light">
                  <FontAwesomeIcon icon={faQuestionCircle} />
                  <Text ml="xs">Help</Text>
                </Button>
              </Flex>
            </Flex>
          </Paper>
        </Box>
      ) : (
        <UnknownError />
      )}
      <br />
    </Modal>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(4, 74, 126, 0.06)',
    borderColor: `${theme.colors.gray[4]} !important`,
    borderRadius: '7px',
  },
}));
export default ErrorPopup;
