import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverProps, Space, Text, UnstyledButton } from '@mantine/core';

interface DiversityPopoverProps extends Omit<PopoverProps, 'children'> {}

const DiversityPopover: React.FC<DiversityPopoverProps> = (props) => (
  <Popover width={600} position="top-end" shadow="sm" radius="0" {...props}>
    <Popover.Target>
      <UnstyledButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          border: '1px solid black',
        }}
      >
        <FontAwesomeIcon size="xs" icon={faInfo} />
      </UnstyledButton>
    </Popover.Target>
    <Popover.Dropdown
      p="md"
      sx={(theme) => ({ pointerEvents: 'none', border: '1px solid white', background: theme.colors.gray[1] })}
    >
      <Text size="md" fw={700} inline mb="md">
        Microbiome Diversity Score
      </Text>
      <Text size="sm">
        The Shannon Index measures how diverse the microbial community in your gut is. It considers how many different
        types of species are present and how evenly they are distributed throughout the gut. A higher Index means that
        your gut has a wide variety of microbes, and this is generally a sign of a healthy microbiome.
        <Space h="md" />
        To understand whether your diversity is below average, average, or above average, Jona looks at your results in the
        context of thousands of real people’s microbiome data.
        <Space h="md" />
        <Text italic>What’s Below Average?</Text>
        The number and evenness of species in your microbiome falls below the average person’s diversity by a certain
        amount called a standard deviation.
        <Space h="md" />
        <Text italic>What’s Average?</Text>
        The number and evenness of species in your microbiome is similar to that of the average person in our dataset.
        In other words, it’s within one standard deviation of the average.
        <Space h="md" />
        <Text italic>What’s Above Average?</Text>
        The number and evenness of species in your microbiome exceeds the average person’s diversity by a certain amount
        called a standard deviation.
      </Text>
    </Popover.Dropdown>
  </Popover>
);
export default DiversityPopover;
