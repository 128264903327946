import { PageContainer } from 'ui/Containers';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'ui/PageHeader';
import userApi from 'services/user';
import providerApi from 'services/provider';
import Loading from 'components/Loading';
import { Error } from 'ui/ErrorPage';
import KitTable, { KitTableData } from 'ui/Jona-v1/Components/KitTable';
import { useAppDispatch, useAppSelector } from 'hooks';
import ClientTable from 'ui/Jona-v1/Components/ClientTable';
import { Button, Stack, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { setCurrentClientKits } from 'slices/clientKit';
import { BadgeStatus } from 'ui/Jona-v1/Badges';
import { IndicatorStatus } from 'ui/Jona-v1/Indicators';

export default function Client() {
  const dispatch = useAppDispatch();
  const { clientId } = useParams() as { clientId: string };
  const { userMode } = useAppSelector((state) => state.app);

  const {
    data: userStaffProviderData,
    isLoading: userStaffProviderIsLoading,
    error: userStaffProviderError,
  } = userApi.endpoints.getStaffProvider.useQuery();
  const { id: providerId, preferences, clientAccessDontAskAgain } = userStaffProviderData || { id: 0 };
  const {
    data: clientData,
    isLoading: clientIsLoading,
    error: clientError,
  } = providerApi.endpoints.getClient.useQuery({ providerId, clientId }, { skip: !providerId || !clientId });
  const { firstName, lastName, email, samples, internalClientId, internalNotes } = clientData || {};

  const clientFullName = `${firstName || ''} ${lastName || ''}`;
  const isValidOrderUrl = userStaffProviderData?.orderUrl && userStaffProviderData?.orderUrl.startsWith('http');
  const clientSamples = samples || [];

  useEffect(() => {
    const clientReports = clientSamples.map(({ barcode, intake, reportReadyAt, createdAt, userAccess }) => ({
      barcode,
      intakeComplete: intake?.completedAt ? true : false,
      reportReady: reportReadyAt ? true : false,
      created_at: createdAt,
      userAccess,
    }));

    dispatch(setCurrentClientKits(clientReports));
  }, [clientSamples, dispatch]);

  if (userStaffProviderIsLoading || clientIsLoading) return <Loading />;

  if (userStaffProviderError) return <Error error={userStaffProviderError} />;
  if (clientError) return <Error error={clientError} />;

  return (
    <>
      <PageHeader title={clientFullName} showBreadcrumbs={false} />
      <PageContainer size="md">
        <Stack spacing="xl">
          <ClientTable
            name={clientFullName}
            clientId={clientId}
            internalClientId={internalClientId || ''}
            clientStatus={BadgeStatus.active}
            email={email || ''}
            internalNotes={internalNotes || ''}
          />
          <KitTable
            data={
              clientSamples.map(
                ({ id, barcode, createdAt, releasedToClient, sampleStatus, userAccess }): KitTableData => {
                  return {
                    sampleId: id,
                    kitId: barcode,
                    sampleDate: createdAt,
                    kitStatus: sampleStatus,
                    clientAccess: !!releasedToClient,
                    clientAccessDontAskAgain,
                    manualReportRelease: preferences?.manualReportRelease,
                    userAccess,
                  };
                }
              ) || []
            }
            clientId={clientId}
            providerId={providerId}
            userMode={userMode}
          />
          <Stack>
            {!isValidOrderUrl && (
              <Text size="sm" align="center">
                <FontAwesomeIcon icon={faInfoCircle} /> You do not have a valid order URL. Please contact{' '}
                <a href="mailto:support@jona.health">support@jona.health</a> to get the order link.
              </Text>
            )}
            <Button
              disabled={!isValidOrderUrl}
              component="a"
              href={userStaffProviderData?.orderUrl}
              target="_blank"
              w={128}
              m="auto"
              leftIcon={<FontAwesomeIcon icon={faCirclePlus} />}
              color="blue.4"
            >
              Order Kit
            </Button>
          </Stack>
        </Stack>
      </PageContainer>
    </>
  );
}
