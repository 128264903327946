import { type AnchorProps, Text, Anchor } from '@mantine/core';
import { ArrowUpRight } from '../Icons';

interface AnchorWithIconProps extends AnchorProps {
  href: string;
  // target?: HTMLAnchorElement['target'];
  // component?: React.FC<any>;
}

const AnchorWithIcon: React.FC<AnchorWithIconProps> = ({ children, ...props }) => (
  <Anchor target="_blank" color="blue.4" size="xs" {...props}>
    {children}
    <Text inline span ml="xs" sx={{ position: 'relative', top: '4px' }}>
      <ArrowUpRight size="sm" />
    </Text>
  </Anchor>
);
export default AnchorWithIcon;
