import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from 'store';
import { IndicatorType, ModalType, closeModal, turnOffIndicator, turnOnIndicator } from 'slices/app';
import reportApi from 'services/reportV2';

import { Button, Container, Flex, Modal, Paper, Text, Title, createStyles } from '@mantine/core';
import { Error } from 'ui/ErrorPage';
import { ChangelogMarkdown } from 'ui/Markdown';
import ReportUpdates from './ReportUpdates';
import { Link } from 'react-router-dom';
import FeatureRequestButton from 'components/FeatureRequestButton';
import { PageHeader } from 'ui/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';

export default function ChangelogModal() {
  const { classes } = useReportModalStyles();

  const dispatch: AppDispatch = useDispatch();
  const { isChangelogModalOpen } = useSelector((state: RootState) => state.app.modals);

  const { currentKit } = useSelector((state: RootState) => state.kit);
  const { appVersion } = useSelector((state: RootState) => state.app);
  const barcode = currentKit?.barcode || '';

  // Get the changelog
  const {
    data: { title, markdownDetails, publishedAt } = {},
    error: changelogError,
    isLoading: changelogIsLoading,
  } = reportApi.endpoints.getChangelog.useQuery({ barcode });

  // Get the diff
  const {
    data: { _id: diffId, viewedByUser, findings } = {},
    error: diffError,
    isLoading: diffIsLoading,
  } = reportApi.endpoints.getReportDiff.useQuery({ barcode });
  const [setDiffAsViewed] = reportApi.useSetDiffAsViewedMutation();
  const diffNotFound =
    (diffError && 'status' in diffError && Number(diffError.status) === 404) || findings?.length === 0;

  // Close the modal and mark the diff as viewed
  const handleModalClose = async () => {
    if (!diffNotFound) {
      if (diffId && barcode && !viewedByUser) {
        await setDiffAsViewed({ barcode, diffId });
      }
    }
    dispatch(turnOffIndicator(IndicatorType.ReportUpdates));
    dispatch(closeModal(ModalType.Changelog));
  };

  // Turn on the indicator if there are updates
  useEffect(() => {
    if (diffNotFound || viewedByUser || !findings) {
      dispatch(turnOffIndicator(IndicatorType.ReportUpdates));
    } else {
      dispatch(turnOnIndicator(IndicatorType.ReportUpdates));
    }
  }, [diffNotFound, viewedByUser, findings]);

  // Format the date
  const date = publishedAt ? new Date(publishedAt) : new Date();
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Render the content of the Changelog
  const changelogContent = () => {
    if (changelogError) return <Error error={changelogError} isModal />;
    if (changelogIsLoading) return <div>Loading...</div>;
    return <ChangelogMarkdown>{markdownDetails}</ChangelogMarkdown>;
  };

  // Render the content of the Diff
  const diffContent = () => {
    if (diffNotFound || !findings)
      return (
        <Paper shadow="xs" pl="md" pr="md" withBorder>
          <Text align="center" p="sm">
            We currently do not have any updates for your report.
            <br />
            Please check back later.
          </Text>
        </Paper>
      );
    if (diffError) return <Error error={diffError} isModal />;
    if (diffIsLoading) return <div>Loading...</div>;
    return <ReportUpdates findings={findings} barcode={barcode} handleModalClose={handleModalClose} />;
  };

  return (
    <Modal
      classNames={{
        content: classes.modalBackgroundColor,
        header: classes.modalBackgroundColor,
        title: classes.modalTitle,
        body: classes.modalBackgroundColor,
      }}
      opened={isChangelogModalOpen}
      onClose={handleModalClose}
      size="xl"
      withCloseButton={false}
      title={
        <PageHeader
          title="Release Notes"
          titleOrder={2}
          pageTitle={formattedDate}
          extraInfo={
            <Flex align="baseline" color="dark.4">
              <Text size="sm">{formattedDate}</Text>
              <Text ml="sm" mr="sm">
                |
              </Text>
              <Text size="sm">JONA {appVersion}</Text>
              <Text ml="sm" mr="sm">
                |
              </Text>
              <Text size="sm">
                <FontAwesomeIcon icon={faBarcode} /> {barcode}
              </Text>
            </Flex>
          }
        />
      }
    >
      <Container pt="md" pb="md">
        <Title order={3} fw="500">
          {title}
        </Title>
        {changelogContent()}
      </Container>

      <Container pt="lg">
        <Title order={3} fw="500">
          Updates In Your Report
        </Title>
        {diffContent()}
      </Container>

      <Container pt="md">
        <Flex align="center" justify="end">
          <Button
            compact
            component={Link}
            to="/support"
            variant="subtle"
            ff="Sora"
            fz="sm"
            fw="500"
            onClick={handleModalClose}
          >
            Help
          </Button>
          |
          <FeatureRequestButton viewChangelog compact variant="subtle" fz="sm" withIcon>
            See all changes
          </FeatureRequestButton>
        </Flex>
      </Container>
    </Modal>
  );
}

const useReportModalStyles = createStyles((theme) => ({
  titleIcon: {
    color: theme.colors.gray[9],
  },
  tooltip: {
    color: theme.colors.gray[9],
  },
  modalTitle: {
    width: '100%',
    paddingRight: theme.spacing.md,
  },
  modalBackgroundColor: {
    backgroundColor: theme.colors.gray[1],
  },
  modalLink: {
    color: theme.colors.gray[9],
    textDecoration: 'none',
    marginRight: theme.spacing.md,
    '&:hover': {
      color: theme.colors.gray[9],
    },
  },
}));
