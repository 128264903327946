import { FC } from 'react';
import { Group, Text, Tooltip, Box } from '@mantine/core';
import AssociationSection from '../AssociationSection';
import useCustomAccordionStyles, { AccordionVariant } from './useCustomAccordionStyles';
import React from 'react';

/**
 * CUSTOM ACCORDION TITLE
 */
export interface CustomAccordionTitleProps {
  title: string;
  guidance?: string;
  associationScore?: number;
  researchStatus?: string;
  intakeCheck?: boolean;
  guidanceTooltip?: string;
  variant?: AccordionVariant;
  category?: string;
  isNewItem?: boolean;
  hasChanges?: boolean;
}

export const CustomAccordionTitle: FC<CustomAccordionTitleProps> = React.memo(
  ({
    title,
    guidance,
    associationScore,
    researchStatus,
    guidanceTooltip,
    variant = AccordionVariant.Default,
    category,
    isNewItem,
    hasChanges,
  }) => {
    const { classes } = useCustomAccordionStyles({ variant });
    return (
      <Group position="apart" className={classes.accTitle}>
        <Box>
          <Text size="md" fw="600" className={classes.accTitle_Text}>
            {title}
            {category && <Text span> - {category}</Text>}
          </Text>
        </Box>
        <Group>
          {guidance &&
            (guidanceTooltip ? (
              <Tooltip label={guidanceTooltip} withArrow multiline withinPortal width={500}>
                <Text className={classes.accTitle_guidance}>{guidance}</Text>
              </Tooltip>
            ) : (
              <Text className={classes.accTitle_guidance}>{guidance}</Text>
            ))}

          {associationScore !== undefined && (
            <AssociationSection
              associationScore={associationScore}
              researchStatus={researchStatus}
              showPopover={variant === AccordionVariant.Default}
              isNewItem={isNewItem}
              hasChanges={hasChanges}
            />
          )}
        </Group>
      </Group>
    );
  }
);

export default CustomAccordionTitle;
