import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

import reportSlice from 'slices/report';
import appSlice from 'slices/app';
import surveyDialogSlice from 'slices/surveyDialog';
import kitSlice from 'slices/kit';
import userSlice from 'slices/user';
import clientKitSlice from 'slices/clientKit';

import { emptySplitApi } from 'services/emptyApi';

// Migrations object
const migrations: any = {
  3: (state: any) => {
    return {};
  },
};

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['surveyDialog', 'report', 'api', 'user'], // Add any slice name here you don't want to persist
  version: 3, // update this version number if you want to add a migration
  migrate: createMigrate(migrations, { debug: process.env.NODE_ENV === 'development' }),
};

const rootReducer = combineReducers({
  [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  report: reportSlice.reducer,
  app: appSlice.reducer,
  surveyDialog: surveyDialogSlice.reducer,
  kit: kitSlice.reducer,
  user: userSlice.reducer,
  clientKit: clientKitSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types in the serializable check middleware
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PURGE', 'persist/REGISTER'],
      },
    }).concat(emptySplitApi.middleware),
});

export const persistor = persistStore(store, null, async () => {
  // check the appVersion and if it changed, clear the persisted state
  const state = store.getState();
  const prevAppVersion = state.app.appVersion;
  const nextAppVersion = await fetch('/version.json')
    .then((response) => response.json())
    .then((data) => `V${data.version}`);

  if (prevAppVersion !== nextAppVersion) {
    // set the new appVersion
    store.dispatch(appSlice.actions.setAppVersion(nextAppVersion));
    console.log('App version changed');
    return;
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
