import { Flex, Text } from '@mantine/core';
import { getAssociationText } from 'utilsV2';
import Dots from './Dots';
import { AssociationPopover } from './Popovers';
import ResearchBadge from './ResearchBadge';
import { NewBadge, UpdatesBadge } from 'ui/Badges';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface AssociationProps {
  associationScore: number;
  researchStatus?: string;
  size?: string;
  showPopover?: boolean;
  popoverOpenDirection?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'bottom' | 'top';
  showAssociationText?: boolean;
  isNewItem?: boolean;
  hasChanges?: boolean;
}

export default function AssociationSection({
  associationScore,
  researchStatus,
  showPopover = false,
  size = 'md',
  showAssociationText = false,
  popoverOpenDirection,
  isNewItem,
  hasChanges,
}: AssociationProps) {
  var associationText = getAssociationText(associationScore);
  if (associationScore === 0) {
    associationText = `${associationText} association`;
  }

  const flags = useFlags();

  return (
    <Flex gap="sm" align="center">
      {flags['reportDiff'] && isNewItem && <NewBadge />}
      {flags['reportDiff'] && hasChanges && <UpdatesBadge />}
      {!!researchStatus && <ResearchBadge size={size} researchStatus={researchStatus} />}
      <Dots n={associationScore} />
      {showAssociationText && (
        <Text color="#C92323" fw="700" fz="22px">
          {associationText}
        </Text>
      )}
      {showPopover && <AssociationPopover openDirection={popoverOpenDirection} />}
    </Flex>
  );
}
