import { faArrowAltCircleLeft, faHome, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Flex, Paper, Text, createStyles } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';

export default function PageUnavailable() {
  const navigate = useNavigate();

  const { classes } = useStyles();

  const goBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <Container className={classes.container}>
      <Box>
        <Paper className={classes.paper} withBorder radius={2} p="lg" mt="lg">
          <Flex direction="column" align="center">
            <Text color="dark.5" size="lg" mb="sm" fw="600">
              This page is unavailable
            </Text>
            <Text color="gray.8" size="md">
              You don&#39;t have access to this link or this link is invalid.
            </Text>
            <Text color="gray.8" size="md" mb="lg">
              Please contact support if you need assistance <a href="mailto:hello@jona.health">hello@jona.health</a>
            </Text>
            <Flex w="100%" align="center" justify="space-evenly">
              <Button onClick={goBack} variant="light">
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
                <Text ml="xs">Go Back</Text>
              </Button>
              <Button component={Link} variant="light" to="/">
                <FontAwesomeIcon icon={faHome} />
                <Text ml="xs">Go to Homepage</Text>
              </Button>
              <Button component={Link} to="/support" variant="light">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <Text ml="xs">Help</Text>
              </Button>
            </Flex>
          </Flex>
        </Paper>
      </Box>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(4, 74, 126, 0.06)',
    borderColor: `${theme.colors.gray[4]} !important`,
    borderRadius: '7px',
  },
}));
