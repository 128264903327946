import { FC, ReactElement, useMemo } from 'react';
import { Accordion, AccordionProps, Collapse, Divider, Text, Title } from '@mantine/core';
import { CustomAccordionTitleProps } from './CustomAccordionTitle';
import { CustomAccordionContentProps } from './CustomAccordionContent';
import useCustomAccordionStyles, { AccordionVariant } from './useCustomAccordionStyles';
import CollapsibleHeader from '../CollapsibleHeader';
import { useDisclosure } from '@mantine/hooks';

/**
 * CUSTOM ACCORDION
 */
interface PanelAccordionItem {
  id: string;
  category?: string;
  title: ReactElement<CustomAccordionTitleProps>;
  content: ReactElement<CustomAccordionContentProps>;
}

interface CustomAccordionProps extends Omit<AccordionProps, 'variant' | 'children'> {
  colapsable?: boolean;
  description?: string | ReactElement;
  groupByCategory?: boolean;
  initiallyOpen?: boolean;
  items: PanelAccordionItem[];
  showN?: number;
  title?: string;
  variant?: AccordionVariant;
}

const COLLAPSE_BUTTON_TEXT = 'Collapse';
const EXPAND_BUTTON_TEXT = 'View all';

const CustomAccordion: FC<CustomAccordionProps> = ({
  colapsable = false,
  description,
  groupByCategory = false,
  initiallyOpen = false,
  items,
  showN = 5,
  title,
  variant = AccordionVariant.Default,
  ...accordionProps
}) => {
  const { classes } = useCustomAccordionStyles({ variant });
  const [opened, { toggle }] = useDisclosure(initiallyOpen);

  const groupedItems = useMemo(() => {
    if (groupByCategory) {
      return items.reduce((acc: any, item: any) => {
        const category = item.category || 'default';
        if (acc[category]) {
          acc[category].push(item);
        } else {
          acc[category] = [item];
        }
        return acc;
      }, {});
    } else {
      // Return items directly if not grouping by category
      return { default: items };
    }
  }, [items, groupByCategory]);

  if (!Object.keys(groupedItems).length) {
    return null;
  }

  const renderAccordionItem = ({ id, title, content }: PanelAccordionItem, index: number) => (
    <Accordion.Item key={`${id}-${index}`} value={`${id}-${index}`}>
      <Accordion.Control>{title}</Accordion.Control>
      <Accordion.Panel>{content}</Accordion.Panel>
    </Accordion.Item>
  );

  const renderGroupedAccordionItems = () =>
    Object.keys(groupedItems).map((category: any) => (
      <div key={category}>
        {category !== 'default' && (
          <Divider className={classes.acc_divider} label={<Title order={6}>{category}</Title>} />
        )}
        {groupedItems[category].map((panel: PanelAccordionItem, index: number) => renderAccordionItem(panel, index))}
      </div>
    ));

  const renderCollapsableAccordionItems = () => (
    <>
      {groupedItems.default
        .slice(0, showN)
        .map((panel: PanelAccordionItem, index: number) => renderAccordionItem(panel, index))}
      <Collapse in={opened} mt="sm">
        {groupedItems.default
          .slice(showN)
          .map((panel: PanelAccordionItem, index: number) => renderAccordionItem(panel, index))}
      </Collapse>
    </>
  );

  const renderDefaultAccordionItems = () =>
    groupedItems.default.map((panel: PanelAccordionItem, index: number) => renderAccordionItem(panel, index));

  return (
    <Accordion
      classNames={{
        item: classes.acc_item,
        panel: classes.acc_panel,
        label: classes.acc_label,
        control: classes.acc_control,
        chevron: classes.acc_chevron,
      }}
      radius="xs"
      variant="separated"
      {...accordionProps}
    >
      {title && (
        <CollapsibleHeader
          title={title}
          onClick={toggle}
          buttonContent={opened ? COLLAPSE_BUTTON_TEXT : EXPAND_BUTTON_TEXT}
          showButton={items.length > showN || colapsable}
        />
      )}

      {description && <Text className={classes.acc_description}>{description}</Text>}

      {groupByCategory
        ? renderGroupedAccordionItems()
        : colapsable
        ? renderCollapsableAccordionItems()
        : // default
          renderDefaultAccordionItems()}
    </Accordion>
  );
};

CustomAccordion.defaultProps = {
  groupByCategory: false,
  colapsable: false,
  variant: AccordionVariant.Default,
  showN: 5,
};

export default CustomAccordion;
