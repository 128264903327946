import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Title, createStyles } from '@mantine/core';

import { type UserReport } from 'types/V2Types';

import { AppDispatch } from 'store';
import { SurveySource, openSurveyDialog } from 'slices/surveyDialog';
import userApi, { IsIntakeComplete, ShowUserSurvey } from 'services/user';
import reportApi from 'services/reportV2';

import Card from 'components/Card';
import Label from 'components/Label';
import ReportsListItem from 'components/SharedReport/ReportsListItem';
import ReportsListItemEmpty from 'components/SharedReport/ReportsListItemEmpty';
import Loading from 'components/Loading';
import { PageHeader } from 'ui/PageHeader';
import { Error } from 'ui/ErrorPage';
import ProviderReportList from 'components/Providers/ProviderReportList';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PageContainer } from 'ui/Containers';

export default function Reports() {
  const { classes } = useStyles();
  const { data: userData, isLoading: isUserLoading, error: userError } = userApi.endpoints.getUser.useQuery();
  const { data: reports, isLoading, error } = reportApi.endpoints.getAllReports.useQuery();
  const { providerWorkflow, useAssignedSamples } = useFlags();

  const dispatch: AppDispatch = useDispatch();
  const [updateFlag] = userApi.endpoints.updateFlag.useMutation();
  useEffect(() => {
    const showSurvey = userData && ShowUserSurvey(userData);
    if (showSurvey) {
      dispatch(openSurveyDialog(SurveySource.FIRST_OPEN));
      updateFlag({
        field: 'firstSurveyOpenTime',
        value: new Date().toISOString(),
      });
    }
  }, [userData]);

  if (error || userError) return <Error error={error || userError} />;
  if (isLoading || isUserLoading) return <Loading />;
  if (!userData || !reports) return <Title m="xl">Reports not found</Title>;

  const hasIntakeV2 = IsIntakeComplete(userData);

  if (userData && reports) {
    if (
      reports.userReports.length === 0 &&
      reports.providerReports.length === 0 &&
      reports.authorizedReports.length === 0
    ) {
      return <Navigate to={useAssignedSamples ? '/activate' : '/register'} />;
    }

    if (userData.barcodes?.length > 0 && !hasIntakeV2) {
      return <Navigate to="/intake" />;
    }
  }

  const userReports = [...reports.userReports].sort(
    (a: UserReport, b: UserReport) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );
  const patientReports = [...reports.providerReports].sort(
    (a: UserReport, b: UserReport) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  let reportsProcessing: string[] = [];
  if (userData.barcodes) {
    reportsProcessing = userData.barcodes.filter(
      (barcode: string) => !userReports.some((report: UserReport) => report.barcode.toString() === barcode)
    );
  }

  return (
    <>
      <PageHeader title="Reports" />
      <PageContainer size="md">
        {providerWorkflow && patientReports && patientReports.length > 0 && (
          <Card>
            <Label className={classes.reportsLabel}>Patient Reports</Label>
            <ProviderReportList reports={patientReports} />
          </Card>
        )}
        <Card mt="lg">
          <Label className={classes.reportsLabel}>My Reports</Label>
          {userReports.map((props: UserReport) => (
            <ReportsListItem key={props.barcode} {...props} hasIntakeV2={hasIntakeV2} />
          ))}
        </Card>
        {reportsProcessing.length > 0 && (
          <Card mt="lg">
            <Label className={classes.reportsLabel}>Processing</Label>
            {reportsProcessing.map((barcode: string) => (
              <ReportsListItemEmpty key={barcode} text={`Status: processing results`} barcode={Number(barcode)} />
            ))}
          </Card>
        )}
      </PageContainer>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  reportsLabel: {
    marginBottom: theme.spacing.md,
    color: theme.colors.dark[7],
  },
}));
