import { Badge, type BadgeProps } from '@mantine/core';

const UpdatesBadge: React.FC<BadgeProps> = ({ children, ...props }) => {
  return (
    <Badge color="orange.6" variant="filled" {...props}>
      {children ?? 'updated'}
    </Badge>
  );
};

export default UpdatesBadge;
