import { Box, Button, Text, Tooltip, createStyles } from '@mantine/core';
import { Link } from 'react-router-dom';

import Label from 'components/Label';
import Row from 'components/Row';
import { type UserReport } from 'types/V2Types';
import { FC } from 'react';
import { setCurrentKit } from 'slices/kit';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch } from 'hooks';

interface ReportListItemProps extends UserReport {
  hasIntakeV2: Boolean;
}

const ReportsListItem: FC<ReportListItemProps> = ({ barcode, kb_version, created_at, hasIntakeV2, userAccess }) => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { useAssignedSamples } = useFlags();

  const handleSetKit = () => {
    dispatch(setCurrentKit({ barcode, intakeComplete: true, reportReady: true, kb_version, created_at, userAccess }));
  };

  return (
    <Row className={classes.container}>
      <Box className={classes.titleContainer}>
        <Text size="md" className={classes.title}>
          {new Intl.DateTimeFormat(undefined, { dateStyle: 'long' }).format(new Date(created_at))}
        </Text>
        <Label>{barcode}</Label>
      </Box>

      <Tooltip label={userAccess?.message} withArrow disabled={userAccess?.message == null}>
        <div>
          <Button
            size="xs"
            variant="outline"
            component={Link}
            className={classes.linkBtn}
            ml="sm"
            to={`/reports/${barcode}`}
            onClick={handleSetKit}
            disabled={!userAccess?.hasAccess}
          >
            Report
          </Button>
        </div>
      </Tooltip>

      {hasIntakeV2 && (
        <Button
          size="xs"
          variant="outline"
          component={Link}
          className={classes.linkBtn}
          ml="sm"
          to={`/reports/${barcode}/plan`}
          onClick={handleSetKit}
        >
          Action Plan
        </Button>
      )}

      {useAssignedSamples && (
        <Button
          size="xs"
          variant="outline"
          component={Link}
          className={classes.linkBtn}
          ml="sm"
          to={`/reports/${barcode}/intake`}
          onClick={handleSetKit}
        >
          Health Survey
        </Button>
      )}
    </Row>
  );
};

export default ReportsListItem;

const useStyles = createStyles((theme) => ({
  container: {
    alignItems: 'flex-start',
    marginTop: theme.spacing.md,
    borderBottom: `1px solid ${theme.colors.dark[5]}`,
    maxWidth: '750px',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    // minWidth: '300px',
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    // marginRight: multiplyPixels(theme.spacing.xl, 3),
    borderTopRightRadius: '2px',
    borderTopLeftRadius: '2px',
  },
  linkBtn: {
    '&:hover': {
      opacity: 1,
    },
  },
  title: {
    // marginRight: multiplyPixels(theme.spacing.xl, 3),
    alignItems: 'center',
  },
  tag: {
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '2px',
    paddingBottom: '2px',
    fontSize: '10px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: theme.colors.gray[5],
    background: theme.colors.gray[2],
    borderRadius: '2px',
  },
  imgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing.lg,
  },
  openIcon: {
    marginLeft: theme.spacing.sm,
  },
}));
