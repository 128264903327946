import { Box, Button, List, Modal, Stack, Text, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Dots from 'components/ReportV2/elements/Dots';
import { ReactNode, SVGProps, forwardRef } from 'react';

const DarkPopoverIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg {...props} ref={ref} xmlns="http://www.w3.org/2000/svg" width="14" height="17" fill="none">
    <path
      fill="#000"
      fillRule="evenodd"
      d="M9.6 11.3h1.5c1 0 1.7-1 1.7-2v-5c0-1.2-.7-2.2-1.7-2.2H2.9c-1 0-1.7 1-1.7 2.1v5c0 1.2.7 2.1 1.7 2.1h1.5c.2 0 .3.1.4.3l1.7 2.6c.3.3.7.3 1 0l1.7-2.6c0-.2.2-.3.4-.3ZM4.1 5.7c0-.4.2-.7.6-.7h4.6c.4 0 .6.3.6.7 0 .4-.2.7-.6.7H4.7c-.4 0-.6-.3-.6-.7Zm0 2c0-.3.2-.6.6-.6h4.6c.4 0 .6.3.6.7 0 .4-.2.7-.6.7H4.7c-.4 0-.6-.3-.6-.7Z"
      clipRule="evenodd"
    />
  </svg>
));

const LightPopoverIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg {...props} ref={ref} xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none">
    <path
      fill="#fff"
      fillOpacity=".9"
      d="M12.7 12.7H11c-.2 0-.4 0-.5.3l-2 2.9c-.2.4-.8.4-1 0l-2-3a.6.6 0 0 0-.5-.2H3.3c-1 0-2-1-2-2.4V4.7c0-1.3 1-2.3 2-2.3h9.4c1 0 2 1 2 2.4v5.5c0 1.3-1 2.4-2 2.4Z"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M5.3 6.3h5.4M5.3 8.7h5.4m2 4H11c-.2 0-.4 0-.5.3l-2 2.9c-.2.4-.8.4-1 0l-2-3a.6.6 0 0 0-.5-.2H3.3c-1 0-2-1-2-2.4V4.7c0-1.3 1-2.3 2-2.3h9.4c1 0 2 1 2 2.4v5.5c0 1.3-1 2.4-2 2.4Z"
    />
  </svg>
));

const GrayPopoverIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props: SVGProps<SVGSVGElement>, ref) => (
  <svg {...props} ref={ref} xmlns="http://www.w3.org/2000/svg" width="15" height="17" fill="none">
    <path
      stroke="#596B7C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M5.33 5.67H10M5.33 7.79H10m1.75 3.54h-1.47c-.18 0-.35.1-.45.27l-1.71 2.58c-.23.36-.68.36-.91 0L5.5 11.6a.55.55 0 0 0-.46-.27H3.58c-.97 0-1.75-.94-1.75-2.12V4.25c0-1.18.78-2.13 1.75-2.13h8.17c.97 0 1.75.95 1.75 2.13v4.96c0 1.18-.78 2.12-1.75 2.12Z"
    />
  </svg>
));

const PopoverIcons = {
  light: <LightPopoverIcon />,
  dark: <DarkPopoverIcon />,
  gray: <GrayPopoverIcon />,
};

interface BasePopoverProps {
  iconVariant?: keyof typeof PopoverIcons;
  children: ReactNode;
  childWrapperClassName?: string;
  openDirection?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'bottom' | 'top';
  className?: string;
}

export function GenericPopover({
  iconVariant = 'dark',
  children,
  childWrapperClassName,
  openDirection = 'bottom-right',
  className,
}: BasePopoverProps) {
  const { classes, cx } = useBasePopoverStyles({ openDirection });

  return (
    <Box className={cx(classes.genericPopover, className)}>
      <Box className={cx(childWrapperClassName, 'popover-content')}>{children}</Box>
      {PopoverIcons[iconVariant]}
    </Box>
  );
}

interface BasePopoverStyleProps {
  openDirection: BasePopoverProps['openDirection'];
}
const useBasePopoverStyles = createStyles((theme, { openDirection = 'bottom-right' }: BasePopoverStyleProps) => {
  const positionalStyles: Record<NonNullable<BasePopoverStyleProps['openDirection']>, {}> = {
    'top-right': {
      left: '-8px',
      bottom: '-6px',
      paddingLeft: '32px',
    },
    'top-left': {
      bottom: '-4px',
      right: '-8px',
    },
    'bottom-right': {
      left: '-8px',
      top: '-50%',
    },
    'bottom-left': {
      top: '-8px',
      right: '-8px',
    },
    bottom: {},
    top: {},
  };

  return {
    genericPopover: {
      position: 'relative',
      display: 'inline-block',
      lineHeight: 0,
      zIndex: 999999, // set z-index to be above left sidebar
      margin: '0 4px',

      '.popover-content': {
        display: 'none',
        position: 'absolute',
        width: 'max-content',
        fontSize: '14px',
        outline: '1px solid white',
        boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.2)',
        padding: theme.spacing.lg,
        borderRadius: '6px',
        color: 'white',
        whiteSpace: 'pre-line',
        ...positionalStyles[openDirection],
      },

      '&:hover > :last-child': {
        filter: 'invert(1)',
      },

      '&:hover > .popover-content': {
        display: 'block',
      },
    },
  };
});

interface AssociationPopoverProps {
  openDirection?: BasePopoverProps['openDirection'];
  className?: BasePopoverProps['className'];
}
export function AssociationPopover({ openDirection = 'top-left', className }: AssociationPopoverProps) {
  const { classes } = useAssociationStyles();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <GenericPopover
        openDirection={openDirection}
        childWrapperClassName={classes.associationPopoverContent}
        className={className}
      >
        <Text mb="sm">
          The dots represent Jona&rsquo;s <br />
          association score.
        </Text>
        <table className={classes.associationTable}>
          <tbody>
            <tr>
              <td>
                <Dots n={3} /> 3 dots
              </td>
              <td>➞ high association</td>
            </tr>
            <tr>
              <td>
                <Dots n={2} /> 2 dots
              </td>
              <td>➞ medium association</td>
            </tr>
            <tr>
              <td>
                <Dots n={1} /> 1 dot
              </td>
              <td>➞ low association</td>
            </tr>
          </tbody>
        </table>

        <Button
          component="a"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            open();
          }}
          className={classes.associationButton}
        >
          Learn More
        </Button>
      </GenericPopover>

      <Modal
        styles={{ content: { background: 'rgba(237, 237, 237)' } }}
        opened={opened}
        onClose={close}
        title="Association Score"
        centered
        size="xl"
      >
        <Stack spacing="md" mt="lg" mb="md" mx="md">
          <Text>
            <Dots n={3} disabled /> is assigned if the association is supported by substantial evidence; a study with at
            least 500 participants or was published in a journal with a minimum impact score of 20; Of all overabundant
            (underabundant) organisms studied associated with a condition, your microbiome has a median overabundance
            (underabundance) of at least 55% (at most 45%).
          </Text>
          <Text>
            <Dots n={2} disabled /> assigned if the association is supported by some evidence; a study with at least 100
            participants or was published in a journal with the minimum impact score of 5; Of all overabundant
            (underabundant) organisms studied associated with a condition, your microbiome has a median overabundance
            (underabundance) of at least 50% (at most 50%).
          </Text>
          <Text>
            <Dots n={1} disabled /> is assigned if, of all overabundant (underabundant) organisms studied associated
            with a condition, your microbiome has a median overabundance (underabundance) of at least 45% (at most 55%).
          </Text>
          <Text>
            <Dots n={0} disabled /> Your unique microbiome has not been associated in the scientific literature at this
            time with this marker. There are a few reasons why we may not have found associations for you currently:
            <ul style={{ margin: '0.5rem 0' }}>
              <li>
                The specific microbes or microbial signatures related to your symptom/condition/sensitivity are not yet
                firmly established in research. As more studies elucidate these connections, new associations may be
                possible in the future.
              </li>
              <li>
                For some conditions, the microbiome is just part of the puzzle and the source of the condition may not
                be found primarily in your microbiome.
              </li>
            </ul>
          </Text>
          <Text>The microbiome is not static and can change over time due to diets, lifestyle and exposures.</Text>
        </Stack>
      </Modal>
    </>
  );
}

const useAssociationStyles = createStyles((theme) => ({
  associationPopoverContent: {
    backgroundColor: 'rgba(0, 0, 0, 0.80)',
    width: '100%',
    fontWeight: 600,
  },
  associationButton: {
    backgroundColor: 'rgba(56, 110, 165, 0.80)',
    marginTop: theme.spacing.md,
    border: '0.5px solid #6293c5',
    padding: '4px 8px',
    height: '30px',
  },
  associationTable: {
    td: {
      padding: '4px 1px',
    },
  },
}));

interface TitlePopoverProps {
  title?: string;
  content: ReactNode;
  direction?: 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'bottom' | 'top';
  iconVariant?: BasePopoverProps['iconVariant'];
  className?: string;
}
export function TitlePopover({
  title,
  content,
  direction = 'top-left',
  iconVariant = 'light',
  className,
}: TitlePopoverProps) {
  const { classes, cx } = useActionTitlePopoverStyles();
  return (
    <GenericPopover
      childWrapperClassName={cx(classes.popoverContent, className)}
      iconVariant={iconVariant}
      openDirection={direction}
    >
      {!!title && (
        <Text fw="bold" mb="md" fs="italic">
          {title}
        </Text>
      )}
      {typeof content === 'string' ? <Text>{content}</Text> : content}
    </GenericPopover>
  );
}

const useActionTitlePopoverStyles = createStyles((theme) => ({
  popoverContent: {
    backgroundColor: theme.black,
    maxWidth: '330px',
    maxHeight: '400px',
    overflowY: 'auto',
    fontSize: theme.fontSizes.xs,
    zIndex: 999999,
  },
}));

export function NegativeAnalysisPopover() {
  const { classes } = useNegativeAnalysisPopoverStyles();
  return (
    <TitlePopover
      direction="top-right"
      content={
        <Stack>
          <Text fw="bold">0 dots - No significant microbiome association found</Text>
          <Text>
            Your unique microbiome has not been associated in the scientific literature at this time with this marker.
            Our AI system conducts an extensive analysis, cross-referencing your microbiome composition with findings
            from thousands of research studies on the gut microbiome. However, the science connecting the gut microbiota
            to health outcomes is a rapidly evolving field, and new findings may arise in future studies.
          </Text>
          <Text>There are a few reasons why we may not have found associations for you currently:</Text>
          <List className={classes.list} size="14px" spacing="0px" color="white">
            <List.Item>
              The specific microbes or microbial signatures related to your symptom/condition/sensitivity are not yet
              firmly established in research. As more studies elucidate these connections, we will be able to make new
              associations in the future.
            </List.Item>
            <List.Item>The microbiome may not be the primary cause behind your condition.</List.Item>
          </List>
          <Text>
            An absence of a finding does not mean no connection exists or may emerge in the future between your gut
            microbiome and health status. An absence of an association should not be interpreted as a negative
            diagnosis. We advise working closely with your healthcare provider regarding any symptoms or conditions of
            concern. As the microbiome field progresses, we will continue uncovering and incorporating these new
            findings into Jona to provide updates to your microbiome profile.
          </Text>
        </Stack>
      }
    />
  );
}

const useNegativeAnalysisPopoverStyles = createStyles(() => ({
  list: {
    color: 'white',
  },
}));
