import React, { useEffect, useState } from 'react';
import Switch from '../Switch';
import providerApi from 'services/provider';
import { ErrorPopup } from 'ui/ErrorPage';
import ClientAccessModal from './ClientAccessModal';
import { useDisclosure } from '@mantine/hooks';

interface ClientAccessSwitchProps {
  sampleId: string | number;
  clientAccess: boolean;
  providerId: number;
  clientId: string;
  dontAskAgain?: boolean;
}

const ClientAccessSwitch: React.FC<ClientAccessSwitchProps> = ({
  sampleId,
  clientAccess,
  providerId,
  clientId,
  dontAskAgain,
}) => {
  const [checked, setChecked] = useState(clientAccess);
  const [opened, { open, close }] = useDisclosure(false);

  const [setReleaseToClient, { error: setReleaseToClientError, data: setReleaseToClientData }] =
    providerApi.endpoints.setReleasedToClient.useMutation();

  // set the checked value to the value from the server
  useEffect(() => {
    if (setReleaseToClientData?.sampleId === sampleId) {
      setChecked(setReleaseToClientData.releasedToClient);
    } else {
      setChecked(clientAccess);
    }
  }, [setReleaseToClientData, setReleaseToClientError]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!dontAskAgain && !checked) {
      open();
    } else {
      handleSetClientAccess(event.currentTarget.checked);
    }
    setChecked(event.currentTarget.checked);
  };

  const handleOnClose = () => {
    close();
    setChecked(!checked);
  };

  const handleSetClientAccess = (clientAccess: boolean) => {
    setReleaseToClient({ providerId, clientId, sampleId, releasedToClient: clientAccess });
  };

  return (
    <>
      {setReleaseToClientError && <ErrorPopup error={setReleaseToClientError} />}
      <ClientAccessModal
        opened={opened}
        onClose={handleOnClose}
        providerId={providerId}
        onYesCallback={() => {
          close();
          handleSetClientAccess(true);
        }}
      />

      <Switch
        disabled={checked}
        onChange={handleSwitchChange}
        checked={checked}
        onLabelThumb="Yes"
        offLabelThumb="No"
      />
    </>
  );
};
export default ClientAccessSwitch;
