import * as React from 'react';
import { type IconProps, useIconProps } from './types';

export const JonaKitIcon = React.memo(
  React.forwardRef<SVGSVGElement, IconProps>((props, forwardedRef) => {
    const { 'aria-label': ariaLabel = 'Jona Kit Icon', size, ...rest } = useIconProps(props);
    return (
      <svg
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 1232 1252"
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        aria-label={ariaLabel}
        ref={forwardedRef}
        {...rest}
      >
        <g transform="matrix(.1 0 0 -.1 0 1252)">
          <path d="m2650 11498c-314-45-579-282-667-598-17-62-18-257-18-4665v-4600l23-78c80-277 283-482 564-569l73-23 3445-3c2584-2 3464 0 3520 9 224 34 425 165 551 357 54 82 102 198 118 286 8 43 11 1371 11 4625 0 4489 0 4567-20 4647-70 293-284 511-585 596l-80 23-3435 1c-2068 1-3461-2-3500-8zm6932-279c144-30 289-144 357-279 23-47 43-105 50-147 9-50 11-1258 9-4598l-3-4530-26-72c-61-167-199-295-364-339-41-11-653-14-3491-14-3405 0-3443 0-3506 20-161 50-298 188-351 355l-22 70v4555 4555l26 71c49 135 137 238 256 302 124 66-169 61 3590 61 2456 1 3437-2 3475-10z" />
          <path d="m4550 9175v-135h1570 1570v135 135h-1570-1570z" />
          <path d="m4990 6316c0-2618-3-2475 56-2651 57-172 147-318 274-446 342-342 854-430 1286-219 176 86 334 222 442 382 85 127 136 245 175 413l21 90 3 2418 3 2417h-140-140v-2392c0-2331-1-2395-20-2487-34-167-126-328-258-453-286-269-731-302-1057-78-164 113-289 292-348 499l-22 76-3 2418-2 2417h-135-135z" />
        </g>
      </svg>
    );
  })
);
