import { Button, NumberInput, Select, TextInput, createStyles, Box } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Row from 'components/Row';
import ethnicities from 'constants/ethnicities';
import heights, { getHeightInches } from 'constants/heights';
import states from 'constants/states';
import userApi from 'services/user';
import { IntakeDemographics } from 'types/intakeV2';
import Card from 'components/Card';
import sexes from 'constants/sexes';
import income from 'constants/income';

const schema = Yup.object().shape({
  firstName: Yup.string().required('Name is a required field'),
  lastName: Yup.string().required('Last name is a required field'),
  zip: Yup.string(),
  birthdate: Yup.date(),
  height: Yup.string(),
  heightInches: Yup.number(),
  weight: Yup.number().typeError('Please enter a number'),
  bmi: Yup.number(),
  gender: Yup.string(),
  gender_other: Yup.string(),
  ethnicity: Yup.string(),
  state: Yup.string(),
  income: Yup.string(),
});

export default function DemographicsForm() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: new IntakeDemographics(),
    validate: yupResolver(schema),
    transformValues: (values) => {
      const heightInches = getHeightInches(values.height);
      const bmi = heightInches > 0 ? (values.weight / (heightInches * heightInches)) * 703 : 0;
      return {
        ...values,
        heightInches,
        bmi,
      };
    },
  });
  const [updateIntake] = userApi.endpoints.updateIntakeV2.useMutation();
  const { data, isLoading } = userApi.endpoints.getUser.useQuery();

  useEffect(() => {
    if (!data?.intake_v2?.demographics) return;

    if (!isLoading) {
      form.setValues({
        ...data.intake_v2.demographics,
        birthdate: data.intake_v2.demographics.birthdate ? new Date(data.intake_v2.demographics.birthdate) : new Date(),
      });
    }
  }, [isLoading]);

  async function handleSubmit(values: any) {
    values.completed = true;

    let update = {};
    if (data) {
      update = { ...data.intake_v2, demographics: values };
    } else {
      update = { demographics: values };
    }

    await updateIntake({
      fields: { intake_v2: update },
    });
    navigate('/intake'); // go back to keep user's place in the flow
  }

  return (
    <Card>
      <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput
          name="firstName"
          className={classes.input}
          label="What is your first name?"
          placeholder="Enter your first name..."
          {...form.getInputProps('firstName')}
        />
        <TextInput
          name="lastName"
          className={classes.input}
          label="What is your last name?"
          placeholder="Enter your last name..."
          {...form.getInputProps('lastName')}
        />
        <DateInput
          id="birthdate"
          className={classes.input}
          label="When is your birthday?"
          placeholder="Select your birthdate..."
          {...form.getInputProps('birthdate')}
        />
        <Select
          id="height"
          searchable
          className={classes.input}
          label="How tall are you?"
          data={heights}
          placeholder="Select your height..."
          {...form.getInputProps('height')}
        />
        <NumberInput sx={{ display: 'none' }} {...form.getInputProps('heightInches')} />
        <NumberInput sx={{ display: 'none' }} {...form.getInputProps('bmi')} value={form.values.bmi ?? 0} />
        <NumberInput
          className={classes.input}
          label="How much do you weigh? (lbs)"
          min={10}
          max={1000}
          placeholder="Enter your weight..."
          {...form.getInputProps('weight')}
        />
        <Select
          id="sex"
          className={classes.input}
          label="What best describes your sex?"
          data={sexes}
          placeholder=""
          {...form.getInputProps('sex')}
        />
        <Select
          id="ethnicity"
          className={classes.input}
          label="What best describes your ethnicity?"
          description="We collect ethnicity information to better understand and serve the diverse health needs of our community."
          data={ethnicities}
          placeholder="Select your ethnicity..."
          {...form.getInputProps('ethnicity')}
        />
        <Select
          id="state"
          searchable
          className={classes.input}
          label="Where do you live?"
          data={states}
          placeholder="Select your state..."
          {...form.getInputProps('state')}
        />
        <TextInput
          name="zipcode"
          className={classes.input}
          label="What is your zipcode?"
          placeholder="Enter your zipcode..."
          {...form.getInputProps('zip')}
        />
        <Select
          id="income"
          className={classes.input}
          label="During the last year, which of the following salary ranges did your total household income fall into?"
          placeholder="Select your income..."
          data={income}
          {...form.getInputProps('income')}
        />
        <Row className={classes.buttonRow}>
          <Button fullWidth className={classes.button} type="submit">
            Submit
          </Button>
        </Row>
      </Box>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  input: {
    marginBottom: theme.spacing.lg,
  },
  buttonRow: {
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
  },
}));
