import { Box, Text, createStyles } from '@mantine/core';

import Label from 'components/Label';
import Row from 'components/Row';
import { multiplyPixels } from 'utils';

interface Props {
  text: string;
  barcode: number;
}

export default function ReportsListItemEmpty({ barcode, text }: Props) {
  const { classes } = useStyles();

  return (
    <Row className={classes.container}>
      <Box className={classes.titleContainer}>
        <Text size="md" className={classes.title}>
          {text}
        </Text>
        <Label>{barcode}</Label>
      </Box>
    </Row>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    alignItems: 'flex-start',
    marginTop: theme.spacing.sm,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    minWidth: '300px',
    paddingTop: theme.spacing.sm,
    paddingBottom: theme.spacing.sm,
    borderTopRightRadius: '2px',
    borderTopLeftRadius: '2px',
    borderBottom: `1px solid ${theme.colors.dark[5]}`,
    // cursor: 'pointer',
    // '&:hover': {
    //   background: theme.colors.blue[2],
    // },
  },
  title: {
    marginRight: multiplyPixels(theme.spacing.xl, 3),
  },
}));
