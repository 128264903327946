import { Image } from '@mantine/core';
import { FC } from 'react';

interface ImageProps extends React.ComponentPropsWithoutRef<typeof Image> {
  src: string;
  grayscale?: boolean; // Option to enable grayscale
}

const BASE_URL = 'https://d7cssqfnh2azf.cloudfront.net/';

const CloudFrontImage: FC<ImageProps> = ({ src, grayscale = false, ...rest }) => {
  const imageUrl = `${BASE_URL}${src}`;

  return <Image src={imageUrl} style={{ filter: grayscale ? 'grayscale(100%)' : 'none' }} {...rest} />;
};

export default CloudFrontImage;
