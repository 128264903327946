import { Button, Image, Modal, SimpleGrid } from '@mantine/core';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import userApi from 'services/user';
import { ModalType, closeModal } from 'slices/app';
import { AppDispatch, RootState } from 'store';

export default function ReportTutorialModal() {
  const dispatch: AppDispatch = useDispatch();
  const { isReportTutorialOpen } = useSelector((state: RootState) => state.app.modals);

  const [step, setStep] = useState(0);
  const [updateFlag] = userApi.endpoints.updateFlag.useMutation();

  const images = Array.from(
    { length: 15 },
    (_, i) => `https://d7cssqfnh2azf.cloudfront.net/app/report-tutorial-v2/tutorial-000${String(i + 1)}.png`
  );
  const finalStep = images.length - 1;

  function handleClick(direction: number) {
    if (step === finalStep) {
      handleClose();
    } else {
      setStep(step + direction);
    }
  }

  function handleClose() {
    dispatch(closeModal(ModalType.ReportTutorial));

    updateFlag({
      field: 'reportTutorialCompleted',
      value: true,
    });
    setTimeout(() => {
      setStep(0);
    }, 500);
  }

  return (
    <Modal opened={isReportTutorialOpen} onClose={handleClose} size="75%" withCloseButton={false}>
      <Image src={images[step]} maw="1280px" alt={`Tutorial Step ${step + 1}`} />
      <SimpleGrid cols={2} spacing="md" mt="md">
        <Button variant="light" onClick={() => handleClick(-1)} disabled={step === 0} fullWidth size="lg">
          Back
        </Button>
        <Button onClick={() => handleClick(1)} fullWidth size="lg">
          {step === finalStep ? 'View Report' : 'Next'}
        </Button>
      </SimpleGrid>
    </Modal>
  );
}
