import { FC } from 'react';
import { Flex, SimpleGrid, Text } from '@mantine/core';
import LinkButton from '../LinkButton';
import AssociatedFindingsCard from './AssociatedFindingsCard';
import React from 'react';
import useCustomAccordionStyles, { AccordionVariant } from './useCustomAccordionStyles';
import type { ReportFindingOverview, ReportOrganism } from 'types/V2Types';

/**
 * CUSTOM ACCORDION CONTENT
 */
export interface CustomAccordionContentProps {
  id: string;
  description: string;
  name?: string;
  symptoms?: ReportFindingOverview[];
  totalSymptomsLength?: number;
  conditions?: ReportFindingOverview[];
  organisms?: ReportOrganism[];
  barcode?: string;
  variant?: AccordionVariant;
  linkUrl?: string;
}

export const CustomAccordionContent: FC<CustomAccordionContentProps> = React.memo(
  ({
    id,
    name,
    description,
    symptoms,
    totalSymptomsLength,
    conditions,
    organisms,
    barcode,
    variant = AccordionVariant.Default,
    linkUrl,
  }) => {
    const { classes } = useCustomAccordionStyles({ variant });

    return (
      <>
        <Text className={classes.accContent_description}>{description}</Text>
        {(symptoms || conditions || organisms) && <Text className={classes.accContent_cardTitle}>Key findings</Text>}

        <SimpleGrid cols={2} spacing="lg">
          {symptoms && (
            <AssociatedFindingsCard
              title="Symptoms"
              items={symptoms ?? []}
              description={`We identified ${
                symptoms?.length ?? 0
              } out of ${totalSymptomsLength} symptoms uniquely associated with 
                your microbiome that are related to ${name}`}
            />
          )}

          {conditions && (
            <AssociatedFindingsCard
              title="Conditions"
              items={conditions ?? []}
              description={`${name}  is a symptom of the following conditions that we found associated with your microbiome.`}
            />
          )}

          {organisms && (
            <AssociatedFindingsCard
              title="Organisms"
              items={organisms ?? []}
              description={`We identified ${
                organisms?.length ?? 0
              } organisms in your sample that are associated with ${name}. Here are the
                organisms with the strongest associations`}
              displayBadge={true}
            />
          )}
        </SimpleGrid>

        {barcode && linkUrl && (
          <Flex className={classes.accContent_linkButton}>
            <LinkButton to={linkUrl}>View analysis</LinkButton>
          </Flex>
        )}
      </>
    );
  }
);

CustomAccordionContent.defaultProps = {
  variant: AccordionVariant.Default,
};

export default CustomAccordionContent;
