import { Flex, Button, Table, Text, createStyles } from '@mantine/core';

import { UserReport } from 'types/V2Types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

interface Props {
  reports: UserReport[];
}

export default function ProviderReportList({ reports }: Props) {
  const { classes } = useStyles();

  return (
    <Table striped highlightOnHover verticalSpacing="sm">
      <thead>
        <th>Barcode</th>
        <th>Patient</th>
        <th>Date</th>
      </thead>
      <tbody>
        {reports &&
          reports.length > 0 &&
          reports.map((report: UserReport, i: number) => {
            return (
              <tr key={i}>
                <td>
                  <Flex justify="center">
                    <Text>{report.barcode}</Text>
                  </Flex>
                </td>
                <td>
                  <Flex justify="center">
                    <Text>{`${report.user?.firstName} ${report.user?.lastName}`}</Text>
                  </Flex>
                </td>
                <td>
                  <Flex justify="center">
                    <Text>
                      {new Intl.DateTimeFormat(undefined, { dateStyle: 'short' }).format(new Date(report.created_at))}
                    </Text>
                  </Flex>
                </td>
                <td>
                  <Flex justify="center">
                    <Button
                      size="xs"
                      variant="outline"
                      component={Link}
                      className={classes.linkBtn}
                      ml="sm"
                      to={`/reports/${report.barcode}`}
                    >
                      Open
                      <FontAwesomeIcon icon={faUpRightFromSquare} className={classes.openIcon} />
                    </Button>
                  </Flex>
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
}

const useStyles = createStyles((theme) => ({
  linkBtn: {
    '&:hover': {
      opacity: 1,
    },
  },
  openIcon: {
    marginLeft: theme.spacing.sm,
  },
}));
