import { useParams } from 'react-router-dom';
import { Title } from '@mantine/core';

import reportApi from 'services/reportV2';

import Loading from 'components/Loading';
import PanelMetabolism from 'components/ReportV2/elements/PanelMetabolism';
import { PageHeader } from 'ui/PageHeader';
import { Disclaimer, DigestionDisclaimer } from 'ui/Disclaimer';
import { FindingTooltip } from 'ui/Tooltip';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';

export default function FoodDigestionPage() {
  const { barcode } = useParams<string>();

  const {
    data: allFindings,
    error: findingsError,
    isLoading: isFindingsLoading,
  } = reportApi.useGetAllFindingsQuery(barcode);

  if (findingsError) return <Error error={findingsError} />;
  if (isFindingsLoading) return <Loading />;
  if (!allFindings) return <Title m="xl">Report not found</Title>;

  return (
    <>
      <PageHeader title="Food Digestion" toolTip={<FindingTooltip />} />
      <PageContainer size="md">
        <DigestionDisclaimer />
        <PanelMetabolism metabolisms={allFindings.metabolisms} />
        <Disclaimer />
      </PageContainer>
    </>
  );
}
