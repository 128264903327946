import { Box, Flex, Text, createStyles, type BoxProps } from '@mantine/core';
import { InfoBadge } from '../Badges';

interface PathogensCardProps extends BoxProps {
  numberOfPathogens: number;
}

const PathogensCard: React.FC<PathogensCardProps> = ({ numberOfPathogens, ...props }) => {
  const { classes } = useStyles();

  const HIGH_DESCRIPTION =
    'We have identified pathogen(s) in your sample.  We recommend consulting a healthcare provider if you have concerns or notice health changes.';
  const NORMAL_DESCRIPTION = 'Our test did not find any pathogens in your sample!';
  const description = numberOfPathogens > 0 ? HIGH_DESCRIPTION : NORMAL_DESCRIPTION;

  return (
    <Box bg="gray.1" p="md" {...props} className={classes.card}>
      <Flex direction="column" h="100%">
        <Text fw={700} inline>
          Pathogens
        </Text>
        <Flex direction="column" mt="md" style={{ flex: 1 }}>
          <Text size="sm" className={classes.description}>
            {description}
          </Text>
          <Box className={classes.diversityBox} mt="auto">
            <Flex align="flex-start" justify="space-between">
              <Box>
                <Text size="sm">
                  Pathogens found:{' '}
                  <Text inline span fw={700} size="sm">
                    {numberOfPathogens}
                  </Text>
                </Text>
                <Text size="sm">Optimal count: 0</Text>
              </Box>
            </Flex>
            <InfoBadge mt="sm" infoBadgeVariant={numberOfPathogens > 0 ? 'red' : 'green'}>
              {numberOfPathogens > 0 ? 'Discuss with Provider' : 'Great'}
            </InfoBadge>
          </Box>
        </Flex>
        <Text fs="italic" mt="md" size="sm">
          Pathogens are infectious organisms that can cause disease.
        </Text>
      </Flex>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  description: {
    flex: 1,
    marginBottom: theme.spacing.md,
  },
  diversityBox: {
    backgroundColor: theme.colors.gray[0],
    padding: theme.spacing.sm,
    border: `1px solid #ffffff`,
  },
}));
export default PathogensCard;
