import {
  IconDefinition,
  faBook,
  faBriefcaseMedical,
  faCirclePlus,
  faCircleUser,
  faFileLines,
  faQrcode,
  faQuestionCircle,
  faSquarePollHorizontal,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { IndicatorType, UserMode } from 'slices/app';
import { UserReport } from 'types/V2Types';

export enum NavbarAction {
  LOGOUT,
  TUTORIAL_MODAL,
  UPDATES_MODAL,
}

export interface NavItem {
  label: string;
  icon?: IconDefinition;
  links?: { label: string; link: string }[];
  link?: string;
  action?: NavbarAction;
  hidden?: boolean;
  exact?: boolean;
  navIndicatorType?: IndicatorType;
}

export const MY_CLIENTS: NavItem[] = [
  { link: '/clients', label: 'Clients', icon: faUsers, exact: true },
  { link: '/add-client', label: 'Add New', icon: faCirclePlus },
];

export const MY_ACCOUNT_PROVIDER: NavItem[] = [
  { link: '/profile', label: 'Profile', icon: faCircleUser },
  { link: '/support', label: 'Support', icon: faQuestionCircle },
  { action: NavbarAction.LOGOUT, label: 'Logout' },
];

export const MY_ACCOUNT_PERSONAL: NavItem[] = [
  { link: '/intake', label: 'Health Survey', icon: faSquarePollHorizontal },
  { link: '/register', label: 'Activate Kit', icon: faQrcode },
  { link: '/profile', label: 'Profile', icon: faCircleUser },
  {
    link: '/providers',
    label: 'My Providers',
    icon: faBriefcaseMedical,
    navIndicatorType: IndicatorType.ProviderInvite,
  },
  { link: '/support', label: 'Support', icon: faQuestionCircle },
  { action: NavbarAction.LOGOUT, label: 'Logout' },
];

export const MY_ACCOUNT_PERSONAL_ASSIGNEDSAMPLES_ENABLED: NavItem[] = [
  { link: '/activate', label: 'Activate Kit', icon: faQrcode },
  { link: '/profile', label: 'Profile', icon: faCircleUser },
  {
    link: '/providers',
    label: 'My Providers',
    icon: faBriefcaseMedical,
    navIndicatorType: IndicatorType.ProviderInvite,
  },
  { link: '/support', label: 'Support', icon: faQuestionCircle },
  { action: NavbarAction.LOGOUT, label: 'Logout' },
];

const MY_REPORT = ({ barcode, created_at, userAccess }: UserReport, useAssignedSamples: boolean = false): NavItem => {
  const links = [
    useAssignedSamples
      ? { link: `/reports/${barcode}/intake`, label: 'Health Survey' }
      : { link: `/intake`, label: 'Health Survey' },
  ];

  if (userAccess?.hasAccess) {
    links.push(
      { link: `/reports/${barcode}/summary`, label: 'Summary' },
      { link: `/reports/${barcode}/goals`, label: 'Health Goals' },
      { link: `/reports/${barcode}/plan`, label: 'Action Plan' },
      { link: `/reports/${barcode}/symptoms`, label: 'Symptoms' },
      { link: `/reports/${barcode}/conditions`, label: 'Conditions' },
      { link: `/reports/${barcode}/metabolisms`, label: 'Food Digestion' },
      { link: `/reports/${barcode}/pathogens`, label: 'Pathogens' },
      { link: `/reports/${barcode}/organisms`, label: 'Organisms' }
    );
  }

  return {
    links,
    label: formatReportName(created_at),
    icon: faFileLines,
  };
};

export const MY_KITS = (kits: UserReport[], useAssignedSamples: boolean = false) => {
  return [
    { link: '/reports', label: 'All Reports', icon: faBook, exact: true },
    ...kits.map((kit) => MY_REPORT(kit, useAssignedSamples)),
  ];
};

export const MY_CLIENT_KITS = (
  clientId: string | number,
  clientName: string,
  kits: UserReport[],
  useAssignedSamples: boolean = true
) => [
  { link: `/client/${clientId}`, label: clientName, icon: faUser },
  ...kits.map((kit) => MY_REPORT(kit, useAssignedSamples)),
];

// helper function to format Date type to 'Report MM-YYYY'
export const formatReportName = (date: Date | string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return `Report ${date.getMonth() + 1}-${date.getFullYear()}`;
};
