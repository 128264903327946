import { createStyles } from '@mantine/core';

const useLinkedSectionHeadStyles = createStyles((theme) => ({
  btn: {
    paddingTop: theme.spacing.xs,
    paddingBottom: theme.spacing.xs,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
  },
}));

export default useLinkedSectionHeadStyles;
