import { Box, Space, Text, createStyles, type BoxProps } from '@mantine/core';
import report_summary_bg from 'assets/report_summary_bg.webp';

interface AboutCardProps extends BoxProps {}

const AboutCard: React.FC<AboutCardProps> = (props) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.summaryBlock} {...props}>
      <Box className={classes.summaryBlockInner}>
        <Text fw="700" mb="md">
          About Your Microbiome
        </Text>
        <Text span inline>
          We have assessed the relationship between your microbiome and various health areas to provide you with a
          heightened understanding of this complex ecosystem. Your microbiome reflects and shapes your mental,
          autoimmune, and metabolic health, and by understanding it, you’re taking a step toward whole-person health.
          <Space h="lg" />
          With insights acquired from the literature, our recommendations aim to guide your journey to adapt your
          microbiome according to your goals. Dive deeper into your health than ever before!
        </Text>
      </Box>
    </Box>
  );
};
const useStyles = createStyles((theme) => ({
  summaryBlock: {
    border: '1px solid #ffffff',
    background: `url(${report_summary_bg})`,
    backgroundSize: 'cover',
  },
  summaryBlockInner: {
    padding: '1rem',
    margin: '1rem',
    background: 'rgba(255, 255, 255, 0.85)',
  },
}));
export default AboutCard;
