import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Group, Mark, Text, Tooltip } from '@mantine/core';
import DisclaimerModal from 'components/ReportV2/elements/DisclaimerModal';
import { NegativeAnalysisPopover } from 'components/ReportV2/elements/Popovers';

export const BLOCK = {
  get NEGATIVE_ANALYSIS() {
    return (
      <>
        <Group spacing="sm" ff="Sora" fz="md" fw="600" mb="sm" position="apart">
          <Group spacing="sm">
            Negative analysis <NegativeAnalysisPopover />
          </Group>
          <DisclaimerModal negative />
        </Group>
        <Text fz="sm" mb="lg">
          We did not detect associations with these conditions based on your sample. This does not mean you do not have
          this condition. Consult your doctor if you are concerned.
        </Text>
      </>
    );
  },

  get NEGATIVE_ANALYSIS_DIGESTION() {
    return (
      <>
        <Group spacing="sm" ff="Sora" fz="md" fw="600" mb="sm" position="apart">
          <Group spacing="sm">
            Negative analysis <NegativeAnalysisPopover />
          </Group>
          <DisclaimerModal negative />
        </Group>
        <Text fz="sm" mb="lg">
          We didn’t detect any abnormality in digestion for these compounds.
        </Text>
      </>
    );
  },

  get NEGATIVE_ANALYSIS_PATHOGEN() {
    return (
      <>
        <Group spacing="sm" ff="Sora" fz="md" fw="600" mb="sm" position="apart">
          <Group spacing="sm">
            Negative analysis <NegativeAnalysisPopover />
          </Group>
          <DisclaimerModal negative />
        </Group>
        <Text fz="sm" mb="lg">
          We didn’t detect abnormality in digestion for these compounds.
        </Text>
      </>
    );
  },

  get INTAKE_CHECK() {
    return (
      <Tooltip label="Self-reported through the intake survey" withArrow multiline withinPortal width={200}>
        <FontAwesomeIcon icon={faCheck} />
      </Tooltip>
    );
  },

  DISCLAIMER: {
    get FINDING() {
      return (
        <ul style={{ paddingLeft: '1.5rem' }}>
          <li>
            Jona provides insight into your microbiome’s potential associations with health conditions, yet does not
            provide definitive diagnoses. This kit does not assess your overall risk of developing certain conditions as
            health outcomes are influenced by various factors, including lifestyle choices and our genetics.{' '}
          </li>
          <li>
            The associations presented here are based on the current available scientific research and literature.
            However, the field of microbiome research is constantly evolving and dynamic, which may impact our
            understanding of these associations in the future.
          </li>
          <li>These results are a momentary snapshot of your microbiome and may not reflect your long-term profile.</li>
          <li>
            We encourage you to use the valuable insights from Jona as a starting point for a proactive and informed
            approach to your health, and strongly advise consulting a healthcare professional for personalized medical
            advice and to interpret the results in the context of your overall health and medical history. This
            collaboration with your healthcare provider and Jona will help ensure the best care and well-informed health
            decisions going forward.
          </li>
        </ul>
      );
    },

    get NEGATIVE() {
      return (
        <ul style={{ paddingLeft: '1.5rem' }}>
          <li>
            While Jona provides valuable insights into potential associations between your microbiome and various health
            conditions, in some cases, no significant associations may be detected. This does not rule out the
            possibility of these conditions, as health outcomes are influenced by multiple factors, including genetics
            and lifestyle choices. The absence of associations in our results should not be interpreted as a definitive
            indication of your health status and does not rule-out disease.
          </li>
          <li>
            Furthermore, the field of microbiome research is dynamic, and our understanding of these associations
            evolves over time. New scientific discoveries and research may reveal previously unrecognized connections
            between your microbiome and health conditions in the future that were not evident in your current results.
          </li>
          <li>These results are a momentary snapshot of your microbiome and may not reflect your long-term profile.</li>
          <li>
            We encourage you to use the valuable insights from Jona as a starting point for a proactive and informed
            approach to your health, and strongly advise consulting a healthcare professional for personalized medical
            advice and to interpret the results in the context of your overall health and medical history. This
            collaboration with your healthcare provider and Jona will help ensure the best care and well-informed health
            decisions going forward.
          </li>
        </ul>
      );
    },
  },

  NUMBER_PAPERS_ICON(n: number) {
    return (
      <Tooltip label="Number of papers related to this finding" withArrow multiline withinPortal>
        <Box fw="bold" fz="sm">
          <FontAwesomeIcon icon={faBookmark} />{' '}
          <Text display="inline-block" fz="sm">
            {n}
          </Text>
        </Box>
      </Tooltip>
    );
  },

  ACTIONABLE_INSIGHTS: [
    'We analyze your unique gut microbes and metabolites using shotgun metagenomic sequencing.',
    'Our proprietary AI then compares your unique microbiome profile to the latest scientific literature linking gut microbes to health conditions, symptoms, and dietary responses.',
    'This linkage allows us to identify a balance of prebiotics, probiotics, and nutrients tailored to your specific microbiome composition.',
    'For example, if low levels of beneficial Bifidobacteria are detected, we suggest consuming prebiotic foods or supplements shown to promote Bifidobacteria growth based on studies.',
    'Or, if high levels of inflammatory compounds are observed, we can recommend anti-inflammatory foods, spices, and microbe-produced metabolites to help correct this.',
    'By linking insights from your unique microbiome compositionto scientific research, our AI generates personalized, evidence-based recommendations to modify your diet, nutrients, and more to improve gut health.',
  ].join('\n\n'),

  get NO_ACTION() {
    return (
      <Text>
        In your stool sample, we <Mark py="2px">did not</Mark> find any direct microbiome-associated actions based on
        the current scientific literature. However, as research evolves, we will share updated insights with you.
      </Text>
    );
  },

  ORGANISMS: [
    "Green indicates that an organism’s relative abundance in your microbiome shows the opposite pattern compared to the pattern seen in people with a certain condition in clinical studies. This suggests that the levels of this organism are different from what is typically observed with individuals with the condition. Red indicates that an organism's relative abundance matches the pattern seen in people with a certain condition in clinical studies. Therefore, red suggests a similarity in the composition of your microbiome to individuals with the condition.",
    'For example, if your Faecalibacterium prausnitzii levels are below the 50th percentile of the healthy population, this means that your levels of Faecalibacterium prausnitzii are lower than 50% of healthy people and our AI can inform you that lower levels of this organism have been linked to inflammatory bowel disease based on multiple studies. Or, if your sample shows levels of Bifidobacterium longum below the 50th percentile of the healthy population, we can alert you that lower levels of this microbe have been connected to anxiety symptoms in published research.',
    'Our proprietary AI compares your unique microbiome profile to the latest scientific literature linking gut microbes to health conditions, symptoms, and dietary responses.',
    'This comparison allows us to pinpoint which of the specific organisms detected in your gut have been scientifically associated with conditions or symptoms relevant to you.',
  ].join('\n\n'),

  SCIENTIFIC_EVIDENCE: [
    'Our scientific knowledge of the microbiome is growing rapidly. In 2022, there were 27,234 scientific articles published in PubMed (leading consolidator of medical journal articles) that discussed the microbiome. The first microbiome therapy was approved by the FDA in 2022 based on successful clinical trials.',
    'Although the scientific community is still learning more about the microbiome, Jona’s AI technology is able to summarize what is known about your microbiome results, the current body of scientific knowledge, and how well the evidence supports these associations. As new studies are published, Jona’s AI technology will continually incorporate these new studies into the results that are presented.',
  ].join('\n\n'),

  PATHOGENS: {
    DETECTED: [
      'We detected the presence of this pathogen in your GI tract, which means the genetic material or DNA of the pathogen as identified in your sample.',
      'This is not a clinical diagnosis and does not indicate you have an active infection or illness, but it may warrant further investigation and assessment by your doctor, especially if you are experiencing any symptoms or are concerned.',
    ].join('\n\n'),
    NOT_DETECTED: [
      'We did not detect the presence of this pathogen in your GI tract, meaning the genetic material or DNA of the pathogen was not found in this sample.',
      'However, it is important to note that the absence of detection in a single sample does not guarantee the complete absence of this pathogen, as its presence can vary over time or be present at levels below the detection threshold.',
    ].join('\n\n'),
  },
};
