import {
  faFingerprint,
  faCheckToSlot,
  faBullhorn,
  faBook,
  faAtom,
  faSquarePollHorizontal,
  faCircleQuestion,
  faSchool,
  faHeartCircleCheck,
  faQrcode,
  type IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { type LDFlagSet } from 'launchdarkly-react-client-sdk';
import { IsIntakeComplete } from 'services/user';
import { IndicatorType } from 'slices/app';
import { type UserReport } from 'types/V2Types';
import { User } from 'types/user';

export enum NavbarAction {
  LOGOUT,
  TUTORIAL_MODAL,
  UPDATES_MODAL,
}

export interface NavItem {
  label: string;
  icon?: IconDefinition;
  links?: { label: string; link: string }[];
  link?: string;
  action?: NavbarAction;
  hidden?: boolean;
  exact?: boolean;
  navIndicatorType?: IndicatorType;
}

const getNavConfig = (currentKit: UserReport | undefined, flags: LDFlagSet, user: User) => {
  const shouldShowNavbar = !['/terms-of-use', '/consent', '/use-computer', '/get-token/', '/get-token'].includes(
    location.pathname
  );

  const myData: NavItem[] =
    !currentKit || !IsIntakeComplete(user)
      ? []
      : [
          {
            link: `/reports/${currentKit?.barcode}/overview`,
            label: 'Health Goals',
            icon: faHeartCircleCheck,
          },
          {
            link: `/reports/${currentKit?.barcode}/plan`,
            label: 'Action Plan',
            icon: faCheckToSlot,
          },
          {
            links: [
              { label: 'Symptoms', link: `/reports/${currentKit?.barcode}/symptoms` },
              { label: 'Conditions', link: `/reports/${currentKit?.barcode}/conditions` },
              {
                label: 'Food Digestion',
                link: `/reports/${currentKit?.barcode}/metabolisms`,
              },
              { label: 'Pathogens', link: `/reports/${currentKit?.barcode}/pathogens` },
            ],
            label: 'My Microbiome',
            icon: faFingerprint,
          },
          {
            link: `/reports/${currentKit?.barcode}/organisms`,
            label: 'Organisms',
            icon: faAtom,
          },
          {
            action: NavbarAction.UPDATES_MODAL,
            label: 'Updates',
            icon: faBullhorn,
            hidden: !flags['reportDiff'],
            navIndicatorType: IndicatorType.ReportUpdates,
          },
        ];

  const myKits: NavItem[] = [
    { link: '/reports', label: 'Reports', icon: faBook, exact: true },
    { link: '/register', label: 'Activate Kit', icon: faQrcode },
    { link: '/intake', label: 'Health Survey', icon: faSquarePollHorizontal },
    { link: '/support', label: 'Help & Support', icon: faCircleQuestion },
    { action: NavbarAction.TUTORIAL_MODAL, label: 'Tutorial', icon: faSchool },
  ];

  const myAccount: NavItem[] = [
    { link: '/profile', label: 'Settings' },
    { action: NavbarAction.LOGOUT, label: 'Logout' },
  ];

  return {
    shouldShowNavbar,
    myData,
    myKits,
    myAccount,
  };
};

export default getNavConfig;
