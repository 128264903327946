import React, { useRef, useState, useEffect } from 'react';
import { ActionIcon, Badge, ScrollArea, createStyles } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface BadgeScrollerProps {
  badges: string[];
}

const BadgeScroller: React.FC<BadgeScrollerProps> = ({ badges }) => {
  const { classes } = useStyles();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [badgeWidths, setBadgeWidths] = useState<number[]>([]);
  const [scrollIndex, setScrollIndex] = useState(0);
  const margin = 4; // Margin between badges

  useEffect(() => {
    if (scrollRef.current) {
      const badgeElements = scrollRef.current.querySelectorAll('.badge');
      const widths = Array.from(badgeElements).map((badge, i) => badge.getBoundingClientRect().width + margin * 2);
      setBadgeWidths(widths);
    }
  }, [badges]);

  const handleScroll = (direction: 'left' | 'right') => {
    if (scrollRef.current && badgeWidths.length > 0) {
      const container = scrollRef.current;
      const maxScrollLeft = container.scrollWidth - container.clientWidth;

      if (direction === 'right') {
        const currentScroll = container.scrollLeft;
        let accumulatedWidth = 0;
        for (let i = 0; i < badgeWidths.length; i++) {
          setScrollIndex(i);
          accumulatedWidth += badgeWidths[i];
          if (accumulatedWidth > currentScroll + container.clientWidth) {
            container.scrollTo({ left: accumulatedWidth - badgeWidths[i], behavior: 'smooth' });
            break;
          } else {
            container.scrollTo({ left: maxScrollLeft, behavior: 'smooth' });
          }
        }
      } else if (direction === 'left') {
        const currentScroll = container.scrollLeft;
        let accumulatedWidth = 0;
        for (let i = 0; i < badgeWidths.length; i++) {
          setScrollIndex(i);
          accumulatedWidth += badgeWidths[i];
          if (accumulatedWidth >= currentScroll) {
            container.scrollTo({ left: accumulatedWidth - badgeWidths[i] - container.clientWidth, behavior: 'smooth' });
            break;
          }
        }
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      {scrollIndex > 0 && (
        <ActionIcon
          className={classes.buttonLeft}
          onClick={() => handleScroll('left')}
          mr="sm"
          size="sm"
          radius="xl"
          variant="filled"
          color="blue"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </ActionIcon>
      )}
      <ScrollArea
        className={classes.scrollArea}
        style={{ width: '100%', overflow: 'hidden' }}
        viewportRef={scrollRef}
        type="always"
        scrollbarSize={0}
      >
        {scrollIndex > 0 && <div className={classes.gradientLeft}></div>}
        <div style={{ display: 'flex' }}>
          {badges.map((badge, index) => (
            <Badge
              key={index}
              radius="0"
              size="lg"
              className="badge"
              styles={(theme) => ({
                root: {
                  background: theme.colors.gray[2],
                  textTransform: 'none',
                  fontWeight: 500,
                  marginRight: margin,
                  fontSize: theme.fontSizes.sm,
                },
              })}
            >
              {badge}
            </Badge>
          ))}
        </div>
        {scrollIndex !== badges.length - 1 && <div className={classes.gradientRight}></div>}
      </ScrollArea>
      {scrollIndex !== badges.length - 1 && (
        <ActionIcon
          className={classes.buttonRight}
          onClick={() => handleScroll('right')}
          ml="sm"
          size="sm"
          radius="xl"
          variant="filled"
          color="blue"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </ActionIcon>
      )}
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 60px)',
  },
  scrollArea: {
    position: 'relative',
    zIndex: 1,
  },
  gradientLeft: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '15px',
    background: `linear-gradient(to right, ${theme.colors.gray[0]}, transparent)`,
    pointerEvents: 'none', // Allows clicks to pass through
  },
  gradientRight: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: '15px',
    background: `linear-gradient(to left, ${theme.colors.gray[0]}, transparent)`,
    pointerEvents: 'none', // Allows clicks to pass through
  },
  buttonLeft: {
    position: 'absolute',
    left: -30,
    top: 0,
    bottom: 0,
    zIndex: 2,
  },
  buttonRight: {
    position: 'absolute',
    right: -30,
    top: 0,
    bottom: 0,
    zIndex: 2,
  },
}));

export default BadgeScroller;
