import { Badge, Flex, Text, createStyles } from '@mantine/core';
import AssociationSection from 'components/ReportV2/elements/AssociationSection';
import InlineMD from 'components/ReportV2/elements/InlineMarkdown';
import LinkButton from 'components/ReportV2/elements/LinkButton';
import { useNavigate } from 'react-router-dom';
import { ReportActionOverview, ReportFinding } from 'types/V2Types';
import { filterNoScore } from 'utilsV2';

interface Props {
  finding: ReportFinding;
  barcode: string;
}

export default function ActionPlanCondition({ finding, barcode }: Props) {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  const high_impact_actions = finding.actions.filter(filterNoScore);
  let actions = high_impact_actions
    .filter((a: any) => !!a)
    .sort((a: ReportActionOverview, b: ReportActionOverview) => {
      if (a.association_score === b.association_score) return b?.papers_length - a?.papers_length;
      return b.association_score - a.association_score;
    });

  function getBadgeClass(item: ReportActionOverview) {
    let className;
    if (item.guidance === 'Adopt') {
      className = classes.positiveLabel;
    } else {
      className = classes.negativeLabel;
    }
    return cx(classes.listItemBadge, classes.label, className);
  }

  function handleActionBadgeClick(action: ReportActionOverview) {
    navigate(`/reports/${barcode}/${finding.kb}/${finding.finding_id}/actions/${action.action_id}`);
  }

  return (
    <Flex direction="row">
      <Flex className={classes.cardListItem} direction="column" wrap="wrap" mt="sm" mx="xl" mb="md">
        <Flex align="center" mb="sm">
          <Text fw="600" mr="md">
            <InlineMD>{finding.preferred_name ?? finding.name}</InlineMD>
          </Text>
          <AssociationSection associationScore={finding.association_score} researchStatus={finding.research_status} />
          <LinkButton ml="md" h="20px" size="xs" to={`/reports/${barcode}/${finding.kb}/${finding.finding_id}`}>
            Details
          </LinkButton>
        </Flex>
        <Flex mb="lg">
          <Text>{finding.one_line_description}</Text>
        </Flex>
        {actions.length > 0 && (
          <>
            <Text mb="sm" fw="600">
              Targeted Actions
            </Text>
            <Text mb="lg" fs="xs">
              Based on your unique microbiome profile and our analysis of current research, these are the actions that
              may be beneficial. Consult your physician or nutritionist to find out if these actions could help improve
              your {finding.name}.
            </Text>
            <Flex align="center" wrap="wrap">
              {actions.slice(0, 3).map((action: ReportActionOverview, index: number) => (
                <Flex key={index} direction="row" justify="center" align="center" mb="md">
                  <Flex align="center" mr="sm">
                    <Text fw="500">
                      <Badge
                        size="md"
                        className={getBadgeClass(action)}
                        onClick={() => {
                          handleActionBadgeClick(action);
                        }}
                      >
                        {action.guidance} <InlineMD>{action.name}</InlineMD>
                      </Badge>
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

const useStyles = createStyles((theme) => ({
  cardListItem: {
    // borderBottom: '0.5px solid #AAAAAA',
    fontSize: theme.fontSizes.sm,
  },
  linkBtn: {
    flexGrow: 1,
  },
  label: {
    border: `1px solid ${theme.colors.gray[5]}`,
    borderRadius: '3px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  positiveLabel: {
    backgroundColor: `${theme.colors.green[1]} !important`,
  },
  negativeLabel: {
    backgroundColor: `${theme.colors.red[1]} !important`,
  },
  neutralLabel: {
    backgroundColor: `${theme.colors.gray[1]} !important`,
  },
  listItemBadge: {
    minWidth: '72px',
    backgroundColor: '#eeeeee',
    color: 'black',
    fontWeight: 500,
    textTransform: 'none',
    borderRadius: '3px',
  },
}));
