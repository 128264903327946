import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from '@mantine/core';

interface CustomButtonProps extends ButtonProps {
  withIcon?: boolean;
  createFeature?: boolean;
  viewChangelog?: boolean;
}

const FeatureRequestButton: React.FC<CustomButtonProps> = ({
  withIcon,
  createFeature,
  viewChangelog,
  children,
  ...props
}) => {
  const HREFS = {
    createFeature: 'https://jona.canny.io/feature-requests/create',
    viewChangelog: 'https://jona.canny.io/changelog',
    default: 'https://jona.canny.io/feature-requests',
  };
  const href = createFeature ? HREFS.createFeature : viewChangelog ? HREFS.viewChangelog : HREFS.default;
  return (
    <Button component="a" href={href} ff="Sora" fz="xs" fw="500" target="_blank" {...props}>
      {children}
      {withIcon && <FontAwesomeIcon icon={faExternalLink} style={{ marginLeft: '8px' }} />}
    </Button>
  );
};

export default FeatureRequestButton;
