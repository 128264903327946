import { Box, Flex, Text, createStyles, type BoxProps } from '@mantine/core';
import DiversityPopover from './DiversityPopover';
import { InfoBadge } from '../Badges';
import { AlphaDiversityMetric } from 'types/V2Types';

interface DiversityCardProps extends BoxProps {
  diversity?: AlphaDiversityMetric;
}

const DiversityCard: React.FC<DiversityCardProps> = ({ diversity, ...props }) => {
  const { classes } = useStyles();

  if (!diversity) return null;

  const roundedDiversity = Number(diversity?.value.toFixed(2));

  const LOW_DESCRIPTION =
    'Consider broadening the range of plants you eat. Aim for 30 different plants each week - everything from spices to nuts, grains, fruits, and vegetables.';
  const NORMAL_DESCRIPTION = 'Your alpha diversity is in a typical range. Keep up the good work!';
  const HIGH_DESCRIPTION =
    'Wow - your alpha diversity is among the highest we’ve seen! Maintaining a varied diet may contribute to this.';

  const description =
    roundedDiversity < diversity.lower_bound
      ? LOW_DESCRIPTION
      : roundedDiversity > diversity.upper_bound
      ? HIGH_DESCRIPTION
      : NORMAL_DESCRIPTION;

  return (
    <Box bg="gray.1" p="md" {...props} className={classes.card}>
      <Flex direction="column" h="100%">
        <Text fw={700} inline>
          Microbiome Diversity
        </Text>
        <Flex direction="column" mt="md" style={{ flex: 1 }}>
          <Text size="sm" className={classes.description}>
            {description}
          </Text>
          <Box className={classes.diversityBox} mt="auto">
            <Flex align="flex-start" justify="space-between">
              <Box>
                <Text size="sm">
                  Your diversity:{' '}
                  <Text inline span fw={700} size="sm">
                    {roundedDiversity}
                  </Text>
                </Text>
                <Text size="sm">
                  Average range: {diversity.lower_bound.toFixed(1)}-{diversity.upper_bound.toFixed(1)}
                </Text>
              </Box>
              <DiversityPopover />
            </Flex>
            <InfoBadge mt="sm" infoBadgeVariant={roundedDiversity < diversity.lower_bound ? 'red' : 'green'}>
              {roundedDiversity < diversity.lower_bound
                ? 'Below Average'
                : roundedDiversity > diversity.upper_bound
                ? 'Above Average'
                : 'Average'}
            </InfoBadge>
          </Box>

          <Text fs="italic" mt="md" size="sm">
            Diversity measures the number and distribution of different organisms.
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};
const useStyles = createStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  description: {
    flex: 1,
    marginBottom: theme.spacing.md,
  },
  diversityBox: {
    backgroundColor: theme.colors.gray[0],
    padding: theme.spacing.sm,
    border: `1px solid #ffffff`,
  },
}));
export default DiversityCard;
