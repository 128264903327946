import { Flex, Text } from '@mantine/core';
import { forwardRef } from 'react';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label?: string;
  description?: string;
}

const CustomSelectItem = forwardRef<HTMLDivElement, ItemProps>(({ label, description, ...others }: ItemProps, ref) => (
  <div ref={ref} {...others}>
    <Flex gap="none" justify="flex-start" align="flex-start" direction="column">
      {label && <Text>{label}</Text>}
      {description && (
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      )}
    </Flex>
  </div>
));

export default CustomSelectItem;
