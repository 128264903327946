const sexes = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
  {
    value: 'intersex',
    label: 'Intersex',
  },
  {
    value: 'noAnswer',
    label: 'Prefer not to answer',
  },
];

export default sexes;
