import { Badge, type BadgeProps } from '@mantine/core';

const NewBadge: React.FC<BadgeProps> = ({ children, ...props }) => {
  return (
    <Badge color="red.5" variant="filled" {...props}>
      {children ?? 'new'}
    </Badge>
  );
};

export default NewBadge;
