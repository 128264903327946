import { Box, Flex, Indicator, UnstyledButton, Text, type UnstyledButtonProps } from '@mantine/core';
import useNavbarStyles from './useNavbarStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { isPageActive } from '.';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { NavItem } from 'appConfig';
import { IndicatorType } from 'slices/app';

interface NavbarLinkProps extends UnstyledButtonProps, NavItem {
  hasLinks?: boolean;
  opened?: boolean;
  onClick?: () => void;
  to?: string;
  className?: string;
}

const NavbarLink: React.FC<NavbarLinkProps> = ({
  label,
  icon,
  hasLinks = false,
  opened = false,
  onClick,
  to,
  exact,
  navIndicatorType,
  className,
  ...props
}) => {
  const { cx, classes } = useNavbarStyles();

  const { navIndicators, numberOfInvites } = useSelector((state: RootState) => state.app);

  // Render as Link or UnstyledButton based on the presence of 'to' prop
  const Content = ({ children }: { children: React.ReactNode }) =>
    to ? (
      <UnstyledButton
        component={Link}
        data-active={isPageActive(to, exact)}
        to={to}
        {...props}
        className={cx(classes.control, classes.controlLinkHover, className)}
      >
        {children}
      </UnstyledButton>
    ) : (
      <UnstyledButton
        onClick={onClick}
        {...props}
        className={cx(classes.control, classes.controlDropdownHover, className)}
      >
        {children}
      </UnstyledButton>
    );

  return (
    <Indicator
      size={18}
      position="middle-end"
      color="white"
      withBorder
      styles={{
        indicator: {
          border: '1.6px solid #02235D',
        },
      }}
      label={
        numberOfInvites && navIndicatorType === IndicatorType.ProviderInvite ? (
          <Text color="blue.6" fw="bold" inline mt="1px">
            {numberOfInvites}
          </Text>
        ) : (
          <FontAwesomeIcon icon={faCircleExclamation} />
        )
      }
      disabled={!(navIndicatorType && navIndicators.includes(navIndicatorType))}
    >
      <Content>
        <Flex align="center">
          {icon && <FontAwesomeIcon icon={icon} className={classes.linkIcon} />}
          <Flex justify="space-between" align="center" className={classes.controlLabel}>
            <Box>{label}</Box>
            {hasLinks && (
              <FontAwesomeIcon
                className={classes.chevron}
                icon={faChevronUp}
                style={{
                  width: 12,
                  height: 12,
                  transform: opened ? 'none' : 'rotate(-180deg)',
                }}
              />
            )}
          </Flex>
        </Flex>
      </Content>
    </Indicator>
  );
};

export default NavbarLink;
