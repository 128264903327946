import { useAuth0 } from '@auth0/auth0-react';
import { Box, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// if storybook, we use an empty string for the API_URL
const TOKEN_URL =
  process.env.STORYBOOK === 'true'
    ? ''
    : process.env.NODE_ENV === 'production'
    ? window.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;
const TOKEN_API = TOKEN_URL + '/api/user/canny-token';

export default function GetToken() {
  const [error, setError] = useState('');
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchTokenAndRedirect = async () => {
      const accessToken = await getAccessTokenSilently().catch((error) => {
        console.error('Error getting access token', error);
        setError(error);
      });

      const ssoToken = await fetch(TOKEN_API, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((res) => res.json())
        .catch((error) => {
          console.error('Error fetching SSO token', error);
          setError(error);
        });

      if (!ssoToken) {
        return null;
      }

      function getQueryParameterByName(name: string) {
        var pairStrings = window.location.search.slice(1).split('&');
        var pairs = pairStrings.map(function (pair) {
          return pair.split('=');
        });
        return pairs.reduce(function (value, pair) {
          if (value) return value;
          return pair[0] === name ? decodeURIComponent(pair[1]) : null;
        }, null as string | null); // Explicitly set initial value as null
      }

      function getRedirectURL(ssoToken: string) {
        var redirectURL = getQueryParameterByName('redirect');
        var companyID = getQueryParameterByName('companyID');
        if (redirectURL?.indexOf('https://') !== 0 || !companyID) {
          setError('Invalid redirect URL or company ID');
          return null;
        }
        return (
          'https://canny.io/api/redirects/sso?companyID=' +
          companyID +
          '&ssoToken=' +
          ssoToken +
          '&redirect=' +
          redirectURL
        );
      }
      var redirectURL = getRedirectURL(ssoToken);
      if (redirectURL) {
        window.location.assign(redirectURL);
      }
    };

    if (!isLoading && isAuthenticated) {
      fetchTokenAndRedirect();
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  return (
    <Box p="xl">
      <Text c="dimmed" align="center">
        {error ? 'An error occurred: ' + error : 'Redirecting...'}
      </Text>
      <Text c="dimmed" align="center">
        If you are not redirected, or an error occurred, please return <Link to="/">Home</Link>.
      </Text>
    </Box>
  );
}
