import { useParams } from 'react-router-dom';
import { Title } from '@mantine/core';

import reportApi from 'services/reportV2';

import PanelSymptoms from 'components/ReportV2/elements/PanelSymptoms';
import Loading from 'components/Loading';
import { PageHeader } from 'ui/PageHeader';
import { Disclaimer, FindingDisclaimer } from 'ui/Disclaimer';
import { FindingTooltip } from 'ui/Tooltip';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';

export default function SymptomsPage() {
  const { barcode } = useParams() as { barcode: string };

  const {
    data: allFindings,
    error: findingsError,
    isLoading: isFindingsLoading,
  } = reportApi.useGetAllFindingsQuery(barcode);

  if (findingsError) return <Error error={findingsError} />;
  if (isFindingsLoading) return <Loading />;
  if (!allFindings) return <Title m="xl">Report not found</Title>;

  return (
    <>
      <PageHeader title="Symptoms" toolTip={<FindingTooltip />} />
      <PageContainer size="md">
        <FindingDisclaimer />
        <PanelSymptoms symptoms={allFindings.symptoms} />
        <Disclaimer />
      </PageContainer>
    </>
  );
}
