import Loading from 'components/Loading';
import userApi from 'services/user';
import IntakeTasklist from './Tasklist';

export default function IntakeLanding() {
  const { data, isLoading } = userApi.endpoints.getUser.useQuery();

  if (isLoading || !data) {
    return <Loading />;
  }

  return <IntakeTasklist intake={data.intake_v2} />;
}
