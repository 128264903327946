import { Box, Text, Flex, Group, Mark, Paper } from '@mantine/core';
import AssociationSection from 'components/ReportV2/elements/AssociationSection';
import { BLOCK } from 'components/ReportV2/elements/constants';

import { type FC } from 'react';

import useCardStyles from './useCardStyles';

export interface CardProps {
  showIcons?: boolean;
  categoricalScore: number;
  totalPapers: number;
  impact: string;
  findingName: string;
  description: string;
}

const Card: FC<CardProps> = ({ showIcons = true, categoricalScore, totalPapers, impact, findingName, description }) => {
  const { classes, cx } = useCardStyles();

  return (
    <Paper className={cx(classes.itemCard, impact === 'Improve' ? 'improve' : 'worsen')} withBorder radius={8} p="lg">
      <Flex direction="column" gap="md" h="100%">
        {showIcons && (
          <Group position="apart">
            <AssociationSection associationScore={categoricalScore} />
            <Box className={classes.papersBadge}>{BLOCK.NUMBER_PAPERS_ICON(totalPapers)}</Box>
          </Group>
        )}
        <Text fw="500">
          <Mark className="action-tag">{impact}</Mark> <Mark>{findingName}</Mark>
        </Text>
        <Text fz="sm" className={classes.grow}>
          {description?.split('\n\n')[0]}
        </Text>
      </Flex>
    </Paper>
  );
};

Card.defaultProps = {
  showIcons: true,
};

export default Card;
