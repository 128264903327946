export const balancedData = {
  name: 'root',
  abundance: 0,
  children: [
    { name: 'Bacteroidota', children: [], abundance: 30.5 },
    { name: 'Firmicutes', children: [], abundance: 28.7 },
    { name: 'Proteobacteria', children: [], abundance: 15.3 },
    { name: 'Actinobacteriota', children: [], abundance: 13.2 },
    { name: 'Verrucomicrobiota', children: [], abundance: 7.8 },
    { name: 'Fusobacteriota', children: [], abundance: 4.5 },
  ],
};

export const dominantPhylumData = {
  name: 'root',
  abundance: 0,
  children: [
    { name: 'Firmicutes', children: [], abundance: 55.2 },
    { name: 'Bacteroidota', children: [], abundance: 22.8 },
    { name: 'Proteobacteria', children: [], abundance: 9.5 },
    { name: 'Actinobacteriota', children: [], abundance: 6.3 },
    { name: 'Verrucomicrobiota', children: [], abundance: 3.7 },
    { name: 'Fusobacteriota', children: [], abundance: 2.5 },
  ],
};

export const diverseData = {
  name: 'root',
  abundance: 0,
  children: [
    { name: 'Bacteroidota', children: [], abundance: 25.6 },
    { name: 'Firmicutes', children: [], abundance: 23.4 },
    { name: 'Proteobacteria', children: [], abundance: 12.8 },
    { name: 'Actinobacteriota', children: [], abundance: 10.5 },
    { name: 'Verrucomicrobiota', children: [], abundance: 8.7 },
    { name: 'Fusobacteriota', children: [], abundance: 6.2 },
    { name: 'Cyanobacteria', children: [], abundance: 4.9 },
    { name: 'Spirochaetota', children: [], abundance: 3.8 },
    { name: 'Synergistota', children: [], abundance: 2.8 },
    { name: 'Tenericutes', children: [], abundance: 0.5 },
  ],
};
