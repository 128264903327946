import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';

import Loading from 'components/Loading';
import { UserMode } from 'slices/app';
import { PageUnavailable } from 'ui/ErrorPage';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface Props {
  component: React.ComponentType;
  allowedModes?: UserMode[];
}

export default function ProtectedRoute({ component, allowedModes }: Props) {
  const flags = useFlags();

  const { userMode } = useSelector((state: RootState) => state.app);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <Loading />,
  });

  if (!flags['providerWorkflow']) return <Component />;

  if (allowedModes?.length && !allowedModes.includes(userMode)) {
    return <PageUnavailable />;
  } else {
    return <Component />;
  }
}
