import { Collapse, Flex, Group, List, Tabs, Text } from '@mantine/core';
import { DescriptionList } from 'types/V2Types';

interface Props {
  description: string;
  one_line_description?: string;
  bullet_point_descriptions?: DescriptionList[];
  opened: boolean;
}

export default function DescriptionsSection({ description, one_line_description, bullet_point_descriptions, opened }: Props) {

  return (
    <>
      <Flex direction='column'>
        {one_line_description && one_line_description?.length > 0 ? (
          <Text mb="md">{one_line_description}</Text>
        ) : (
          <>
            <Collapse in={!opened}>
              <Text>
                {description.split('\n\n').map((description: string, i: number) => {
                  return (
                    <Text key={i}>
                      <br />
                      {description}
                    </Text>
                  );
                })}
              </Text>
            </Collapse>
          </>
        )}

        {bullet_point_descriptions && bullet_point_descriptions.length > 0 && (
          <Tabs color="dark" defaultValue={bullet_point_descriptions[0].header}>
            <Tabs.List>
              {bullet_point_descriptions.map((description: DescriptionList, i: number) => {
                return (
                  <Tabs.Tab mt='sm' fw={700} key={i} value={description.header}>
                    {description.header}
                  </Tabs.Tab>
                );
              })}
            </Tabs.List>

            {bullet_point_descriptions.map((description: DescriptionList, i: number) => {
              return (
                <Tabs.Panel key={i} value={description.header} mt="md" mr="md">
                  <List spacing="sm">
                    {description.descriptions.map((description: string, j: number) => {
                      return <List.Item key={j}>{description}</List.Item>;
                    })}
                  </List>
                </Tabs.Panel>
              );
            })}
          </Tabs>
        )}
      </Flex>
    </>
  );
}
