import { Button, Group, Title, TitleOrder, createStyles } from '@mantine/core';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import { ReactNode } from 'react';
import { JonaAiBadge } from 'ui/Badges';

interface Props {
  title: ReactNode;
  order?: TitleOrder;
  id?: string;
  onClick: VoidFunction;
  tooltip?: ReactNode;
  tooltipDirection?: any;
  buttonContent: ReactNode;
  showButton?: boolean;
  jonaAiBadge?: boolean;
}

export default function CollapsibleHeader({
  title,
  order = 4,
  id,
  onClick,
  tooltip,
  tooltipDirection = 'top-right',
  buttonContent,
  showButton = false,
  jonaAiBadge = false,
}: Props) {
  const { classes } = useStyles();

  return (
    <Group position="apart" mb="sm">
      <Group spacing="sm">
        <Title id={id} ff="Sora" order={order}>
          {title}
        </Title>
        {!!tooltip && <TitlePopover content={tooltip} direction={tooltipDirection} />}
        {jonaAiBadge && <JonaAiBadge />}
      </Group>

      {showButton && (
        <Button
          ff="Sora"
          fz="xs"
          fw="500"
          compact
          size="xs"
          variant="outline"
          className={classes.btn}
          onClick={onClick}
        >
          {buttonContent}
        </Button>
      )}
    </Group>
  );
}

const useStyles = createStyles(() => ({
  btn: {
    padding: '5px 8px',
  },
}));
