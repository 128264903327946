import { Code, Flex, Table, Box, createStyles } from '@mantine/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { balancedData, diverseData, dominantPhylumData } from 'mock/phyla';
import { PhylaVisualization } from 'ui/Jona-v1/Overview';

export default function DevFlags() {
  const { classes } = useStyles();
  const allFlags = useFlags();

  const renderValue = (flagValue: any) => {
    if (flagValue === 'true') {
      return <Code color="teal">{flagValue}</Code>;
    }
    if (flagValue === 'false') {
      return <Code color="red">{flagValue}</Code>;
    }
    return <Code color="blue">{flagValue}</Code>;
  };

  const renderFlagValues = (flags: any) => {
    return Object.keys(flags).map((key: string) => {
      return (
        <tr key={key}>
          <td>
            <Code>{key}</Code>
          </td>
          <td>{renderValue(flags[key].toString())}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Box className={classes.containerBox}>
        <Flex direction="column" align="center">
          <PhylaVisualization data={diverseData} />
          <PhylaVisualization data={balancedData} />
          <PhylaVisualization data={dominantPhylumData} />
        </Flex>
      </Box>
      <Box className={classes.containerBox}>
        <Flex>
          <Table>
            <thead>
              <tr>
                <th>Flag</th>
                <th>Value</th>
              </tr>
            </thead>
            {renderFlagValues(allFlags)}
          </Table>
        </Flex>
      </Box>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  containerBox: {
    padding: theme.spacing.xl,
    backgroundColor: theme.colors.dark[0],
    borderRadius: theme.radius.md,
    border: `1px solid ${theme.colors.dark[2]}`,
  },
}));
