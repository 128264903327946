import { Paper, Text } from '@mantine/core';
import CustomAccordion from './CustomAccordion/CustomAccordion';
import CustomAccordionContent from './CustomAccordion/CustomAccordionContent';
import CustomAccordionTitle from './CustomAccordion/CustomAccordionTitle';
import { type FindingsPrioritized } from 'types/V2Types';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getHealthGoalsLink } from 'utilsV2';

interface PanelSymptomsProps {
  symptoms: FindingsPrioritized;
}

const PanelSymptoms: React.FC<PanelSymptomsProps> = ({ symptoms }) => {
  const { barcode } = useParams() as { barcode: string };
  const { useAssignedSamples } = useFlags();
  const locationState: { openAccordions?: boolean } = useLocation().state || { openAccordions: false };

  const intakeLink = getHealthGoalsLink(barcode, useAssignedSamples);

  const { intakePriority, highPriority, lowPriority } = symptoms;

  return (
    <>
      <CustomAccordion
        title="Self-reported Symptoms"
        initiallyOpen={locationState.openAccordions}
        description={
          !!intakePriority.length ? (
            <Text italic>
              Below are the findings related to your self-reported symptoms in your{' '}
              <Link to={intakeLink}>health survey.</Link>
            </Text>
          ) : (
            <Paper withBorder radius={2} fz="sm" p="md">
              <Text>
                Based on your sample, you do not have any self-reported symptoms from your{' '}
                <Link to={intakeLink}>health survey.</Link>
              </Text>
            </Paper>
          )
        }
        items={intakePriority.map((symptom) => {
          return {
            id: symptom.finding_id,
            category: symptom.category,
            title: (
              <CustomAccordionTitle
                title={symptom.preferred_name ?? symptom.name}
                guidance={symptom.guidance}
                associationScore={symptom.association_score}
                researchStatus={symptom.research_status}
                category={symptom.category}
                isNewItem={symptom.isNewItem}
                hasChanges={!!symptom.changes}
              />
            ),
            content: (
              <CustomAccordionContent
                id={symptom.finding_id}
                linkUrl={`/reports/${barcode}/symptoms/${symptom.finding_id}`}
                name={symptom.preferred_name ?? symptom.name}
                description={symptom.one_line_description ?? symptom.description}
                barcode={barcode}
                organisms={symptom.organisms}
                conditions={symptom.conditions}
              />
            ),
          };
        })}
      />

      <CustomAccordion
        mt="md"
        title="Associated Symptoms"
        initiallyOpen={locationState.openAccordions}
        description={
          !!highPriority.length ? (
            <Text italic>Below are the closest associated symptoms to your microbiome signature.</Text>
          ) : (
            <Paper withBorder radius={2} fz="sm" p="md">
              <Text>Based on your sample, you do not have any high microbiome associations.</Text>
            </Paper>
          )
        }
        items={highPriority.map((symptom) => {
          return {
            id: symptom.finding_id,
            category: symptom.category,
            title: (
              <CustomAccordionTitle
                title={symptom.preferred_name ?? symptom.name}
                guidance={symptom.guidance}
                associationScore={symptom.association_score}
                researchStatus={symptom.research_status}
                category={symptom.category}
                isNewItem={symptom.isNewItem}
                hasChanges={!!symptom.changes}
              />
            ),
            content: (
              <CustomAccordionContent
                id={symptom.finding_id}
                linkUrl={`/reports/${barcode}/symptoms/${symptom.finding_id}`}
                name={symptom.preferred_name ?? symptom.name}
                description={symptom.one_line_description ?? symptom.description}
                barcode={barcode}
                organisms={symptom.organisms}
                conditions={symptom.conditions}
              />
            ),
          };
        })}
      />

      {!!lowPriority.length && (
        <CustomAccordion
          mt="md"
          title="All Symptoms"
          initiallyOpen={locationState.openAccordions}
          description={
            <Text italic>Below are the rest of our findings associating any symptoms to your microbiome.</Text>
          }
          colapsable={true}
          showN={0}
          items={lowPriority.map((symptom) => {
            return {
              id: symptom.finding_id,
              category: symptom.category,
              title: (
                <CustomAccordionTitle
                  title={symptom.preferred_name ?? symptom.name}
                  guidance={symptom.guidance}
                  associationScore={symptom.association_score}
                  researchStatus={symptom.research_status}
                  category={symptom.category}
                  isNewItem={symptom.isNewItem}
                  hasChanges={!!symptom.changes}
                />
              ),
              content: (
                <CustomAccordionContent
                  id={symptom.finding_id}
                  linkUrl={`/reports/${barcode}/symptoms/${symptom.finding_id}`}
                  name={symptom.preferred_name ?? symptom.name}
                  description={symptom.one_line_description ?? symptom.description}
                  barcode={barcode}
                  organisms={symptom.organisms}
                  conditions={symptom.conditions}
                />
              ),
            };
          })}
        />
      )}
    </>
  );
};

export default PanelSymptoms;
