import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import userApi from 'services/user';
import { IndicatorType, setNumberOfInvites, turnOffIndicator, turnOnIndicator } from 'slices/app';
import { AppDispatch } from 'store';
import { Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from 'hooks';

const InviteMessage = ({ invite }: { invite: any }) => {
  return (
    <Text span>
      <Text span style={{ textTransform: 'capitalize' }}>
        {invite.fromProvider?.name}
      </Text>{' '}
      is inviting you to join their client list. Accepting this invite will allow them to access your Jona reports and
      health survey.
    </Text>
  );
};

// This hook is used to show notifications to the user when they have pending invites
// and to show an indicator on the navbar when they have pending invites
function useInviteNotifications() {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { showNotification, NotificationType } = useNotifications();

  const {
    data: invites,
    isLoading: invitesIsLoading,
    error: invitesError,
  } = userApi.endpoints.getPendingInvites.useQuery();

  const [shownNotifications, setShownNotifications] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    if (invites && invites.length > 0) {
      dispatch(turnOnIndicator(IndicatorType.ProviderInvite));
      dispatch(setNumberOfInvites(invites.length));
      invites.forEach((invite) => {
        // Dont show notification if the invite has been rejected
        if (!invite.rejected && !shownNotifications.includes(invite.id)) {
          showNotification({
            type: NotificationType.INFO,
            message: InviteMessage({ invite }),
            callBack: () => navigate('/providers'),
            callBackText: 'View My Providers',
          });
          setShownNotifications((prev: any) => [...prev, invite.id]);
        }
      });
    } else {
      dispatch(turnOffIndicator(IndicatorType.ProviderInvite));
    }
  }, [invites, dispatch]);

  return { invitesIsLoading, invitesError };
}
export default useInviteNotifications;
