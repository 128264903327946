import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { selectCurrentKit, setCurrentKit } from 'slices/kit';
import { setUser } from 'slices/user';
import userApi from 'services/user';

import { useAppDispatch, useAppSelector, useInviteNotifications, useRedirectIfMobile } from 'hooks';

import * as Pages from 'pages';
import * as Intake from 'components/IntakeV2';
import * as SampleIntake from 'components/SampleIntake';

import { AppShell, Container } from '@mantine/core';

import JonaV1Navbar from 'ui/Jona-v1/Navbar';
import { Error } from 'ui/ErrorPage';

import ProtectedRoute from 'components/ProtectedRoute';
import HandleIndexRoute from 'components/HandleIndexRoute';
import Loading from 'components/Loading';
import DevFlags from 'components/Dev/DevFlags';
import Diagnostic from 'components/Dev/Diagnostic';
import reportApi from 'services/reportV2';
import providerApi from 'services/provider';
import { UserMode, setUserMode } from 'slices/app';

export default function RoutesComponent() {
  const dispatch = useAppDispatch();
  const { currentKit } = useAppSelector(selectCurrentKit);
  const { userMode } = useAppSelector((state) => state.app);
  const flags = useFlags();

  const {
    data: allReports,
    isLoading: allReportsIsLoading,
    error: allReportsError,
  } = reportApi.endpoints.getAllReports.useQuery();
  const { data: userData, isLoading: isUserDataLoading, error: userDataError } = userApi.endpoints.getUser.useQuery();
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    error: userProfileError,
  } = userApi.endpoints.getProfile.useQuery();
  const {
    data: isProvider,
    isLoading: isProviderDataLoading,
    error: providerDataError,
  } = providerApi.endpoints.hasProviderRole.useQuery();

  const { userReports } = allReports ?? {};

  useEffect(() => {
    if (!isProviderDataLoading && !providerDataError && !isProvider && userMode === UserMode.PROVIDER) {
      dispatch(setUserMode(UserMode.PERSONAL));
    }
  }, [isProvider, dispatch]);

  useEffect(() => {
    dispatch(
      setUser({
        firstName: userProfile?.firstName,
        lastName: userProfile?.lastName,
        email: userProfile?.email,
        auth0id: userProfile?.auth0id,
      })
    );
  }, [userProfile, dispatch]);

  // reset the current kit if the user leaves report
  useEffect(() => {
    if (currentKit && !location.pathname.includes('/reports/')) {
      dispatch(setCurrentKit(undefined));
    }
  }, [location.pathname, currentKit, dispatch]);

  useRedirectIfMobile();
  const { invitesIsLoading, invitesError } = useInviteNotifications();

  const isLoading =
    allReportsIsLoading || isUserDataLoading || isProviderDataLoading || invitesIsLoading || isUserProfileLoading;

  if (userDataError) return <Error error={userDataError} />;
  if (allReportsError) return <Error error={allReportsError} />;
  if (providerDataError) return <Error error={providerDataError} />;
  if (invitesError) return <Error error={invitesError} />;
  if (userProfileError) return <Error error={userProfileError} />;

  const shouldShowNavbar = !['/terms-of-use', '/consent', '/use-computer', '/get-token/', '/get-token'].includes(
    location.pathname
  );

  if (flags['maintenanceMode']) {
    return <Pages.Maintenance />;
  }

  return (
    <AppShell
      hidden={!shouldShowNavbar}
      navbar={<JonaV1Navbar loading={isLoading} userReports={userReports} isProvider={isProvider || false} />}
    >
      <Container
        size="lg"
        sx={(theme) => ({
          padding: 0,
          paddingTop: theme.spacing.xs,
          [theme.fn.largerThan('md')]: {
            padding: theme.spacing.lg,
            paddingTop: theme.spacing.sm,
          },
        })}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Routes>
            <Route path="/" element={<ProtectedRoute component={HandleIndexRoute} />} />
            {/* UserMode: ALL ************************************************************************** */}
            {/* Base */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route path="/terms-of-use" element={<Pages.Terms />} />
            <Route path="/consent" element={<Pages.Consent />} />
            <Route path="/maintenance" element={<Pages.Maintenance />} />
            <Route path="/use-computer" element={<Pages.UseComputer />} />
            <Route path="/get-token" element={<Pages.GetToken />} />

            {/* Profile */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route path="/profile" element={<ProtectedRoute component={Pages.Profile} />} />

            {/* Help & Support */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route path="/support" element={<ProtectedRoute component={Pages.Support} />} />

            {/* Reportv2 */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route path="/reports/:barcode" element={<ProtectedRoute component={Pages.ReportV2} />}>
              <Route path="summary" element={<Pages.ReportSummary />} />
              <Route path="goals" element={<Pages.HealthGoals />} />
              <Route path="findings/:findingId" element={<Pages.FindingPage />} />
              <Route path="findings/:findingId/actions/:actionId" element={<Pages.ActionPage />} />
              <Route path="organisms" element={<Pages.Organisms />} />
              <Route path="plan" element={<Pages.ActionPlan />} />
              <Route path="symptoms" element={<Pages.SymptomsPage />} />
              <Route path="symptoms/:findingId" element={<Pages.FindingPage />} />
              <Route path="symptoms/:findingId/actions/:actionId" element={<Pages.ActionPage />} />
              <Route path="conditions" element={<Pages.ConditionsPage />} />
              <Route path="conditions/:findingId" element={<Pages.FindingPage />} />
              <Route path="conditions/:findingId/actions/:actionId" element={<Pages.ActionPage />} />
              <Route path="metabolisms" element={<Pages.FoodDigestionPage />} />
              <Route path="metabolisms/:findingId" element={<Pages.FindingPage />} />
              <Route path="metabolisms/:findingId/actions/:actionId" element={<Pages.ActionPage />} />
              <Route path="pathogens" element={<Pages.PathogensPage />} />

              <Route
                path="intake"
                element={
                  <ProtectedRoute component={Pages.IntakeV2} allowedModes={[UserMode.PERSONAL, UserMode.PROVIDER]} />
                }
              >
                <Route index element={<SampleIntake.IntakeLanding />} />
                <Route path="kit" element={<SampleIntake.KitRegistration />} />
                <Route path="consent" element={<SampleIntake.ConsentFormV2 />} />
                <Route path="demographics" element={<SampleIntake.DemographicsForm />} />
                <Route path="history" element={<SampleIntake.MedicalHistoryFormV2 />} />
                <Route path="lifestyle" element={<SampleIntake.LifestyleAndNutritionForm />} />
                <Route path="completed" element={<SampleIntake.CompletedV2 />} />
              </Route>

              <Route index element={<Navigate to="summary" />} />
            </Route>

            {/* UserMode: PERSONAL ********************************************************************* */}
            {/* Reports */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route
              path="/reports"
              element={<ProtectedRoute component={Pages.Reports} allowedModes={[UserMode.PERSONAL]} />}
            />

            {/* IntakeV2 */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route
              path="/intake"
              element={<ProtectedRoute component={Pages.IntakeV2} allowedModes={[UserMode.PERSONAL]} />}
            >
              <Route index element={<Intake.IntakeLanding />} />
              <Route path="kit" element={<Intake.KitRegistration />} />
              <Route path="consent" element={<Intake.ConsentFormV2 />} />
              <Route path="demographics" element={<Intake.DemographiscForm />} />
              <Route path="history" element={<Intake.MedicalHistoryFormV2 />} />
              <Route path="lifestyle" element={<Intake.LifestyleAndNutritionForm />} />
              <Route path="completed" element={<Intake.CompletedV2 />} />
            </Route>

            {/* Registering Kit */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route
              path="/register"
              element={<ProtectedRoute component={Pages.Landing} allowedModes={[UserMode.PERSONAL]} />}
            />

            {/* Activate Kit */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route
              path="/activate"
              element={<ProtectedRoute component={Pages.ActivationPage} allowedModes={[UserMode.PERSONAL]} />}
            />

            {/* Providers */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            {flags['providerWorkflow'] && (
              <Route
                path="/providers"
                element={<ProtectedRoute component={Pages.Providers} allowedModes={[UserMode.PERSONAL]} />}
              />
            )}

            {/* UserMode: PROVIDER ********************************************************************* */}
            {/* Clients */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            {flags['providerWorkflow'] && (
              <Route
                path="/clients"
                element={<ProtectedRoute component={Pages.Clients} allowedModes={[UserMode.PROVIDER]} />}
              />
            )}

            {/* Add Client */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            {flags['providerWorkflow'] && (
              <Route
                path="/add-client"
                element={<ProtectedRoute component={Pages.AddClient} allowedModes={[UserMode.PROVIDER]} />}
              />
            )}

            {/* View Client Reports */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            {flags['providerWorkflow'] && (
              <Route
                path="/client/:clientId"
                element={<ProtectedRoute component={Outlet} allowedModes={[UserMode.PROVIDER]} />}
              >
                <Route index element={<Pages.Client />} />
                <Route path="edit" element={<Pages.EditClient />} />
              </Route>
            )}

            {/* UserMode: NONE ************************************************************************* */}
            {/* Dev */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route path="/dev" element={<ProtectedRoute component={Pages.Developer} />}>
              <Route path="flags" element={<DevFlags />} />
              <Route path="diag" element={<Diagnostic />} />
              <Route index element={<Navigate to="/" />} />
            </Route>

            {/* Not Found */}
            {/* ************************************************************* */}
            {/* ************************************************************* */}
            <Route path="/*" element={<ProtectedRoute component={Pages.NotFound} />} />
          </Routes>
        )}
      </Container>
    </AppShell>
  );
}
