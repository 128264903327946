// TrackingRoute.tsx
import React, { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useAuth0 } from '@auth0/auth0-react';
import userApi from 'services/user';

const DEBUG = process.env.NODE_ENV === 'development' && false; // Set to true to enable debug mode

interface TrackingRouteProps {
  children: React.ReactNode;
}

const TrackingRoute: React.FC<TrackingRouteProps> = ({ children }) => {
  // Get user from Auth0
  const { isAuthenticated, user } = useAuth0();

  // Get user from API
  const { data: userTrackingData } = userApi.endpoints.getUserTrackingData.useQuery(undefined);
  const [updateFlag] = userApi.endpoints.updateFlag.useMutation();

  // ----------------------------------
  // Initialize Mixpanel with user data
  // ----------------------------------
  useEffect(() => {
    const mixpanelToken =
      process.env.NODE_ENV === 'production'
        ? window.env.REACT_APP_MIXPANEL_TOKEN
        : process.env.REACT_APP_MIXPANEL_TOKEN;

    if (!mixpanelToken) {
      console.error('Mixpanel token is missing. Cannot initialize Mixpanel.');
      return;
    }

    if (!isAuthenticated) {
      console.error('User is not authenticated. Cannot initialize Mixpanel.');
      return;
    }

    mixpanel.init(mixpanelToken, {
      debug: DEBUG,
      track_pageview: true,
      persistence: 'localStorage',
      ignore_dnt: true,
    });

    if (isAuthenticated && user && userTrackingData) {
      try {
        mixpanel.identify(user.sub);

        mixpanel.people.set({ ...userTrackingData });

        // Track user sign up
        if (!userTrackingData.trackUserSignUp) {
          updateFlag({
            field: 'trackUserSignUp',
            value: true,
          });
          mixpanel.track('Sign Up');
        } else {
          mixpanel.track('Login');
        }
      } catch (error) {
        console.error('Error getting access token:', error);
      }
    }
  }, [isAuthenticated, user, userTrackingData]);

  // ------------------------------
  // Track scroll progress
  // ------------------------------
  // const content = document.getElementById('dashboard-content');

  // useEffect(() => {
  //   if (!content) return;

  //   const scrollHandler = () => {
  //     const pageTitle = document.title;
  //     const pageName = location.pathname;

  //     const pageHeight = content.scrollHeight - content.clientHeight;
  //     const scrollPosition = content.scrollTop;
  //     const scrollPercentage = Math.round((scrollPosition / pageHeight) * 100);

  //     // Check if the scroll percentage crosses each 10% threshold
  //     const roundedScrollPercentage = Math.round(scrollPercentage / 10) * 10;

  //     // Check if the scroll percentage has changed since the last tracking
  //     if (
  //       roundedScrollPercentage % 10 === 0 &&
  //       roundedScrollPercentage.toString() !== content.dataset.lastScrollPercentage
  //     ) {
  //       console.log('Tracking scroll percentage:', roundedScrollPercentage);

  //       mixpanel.track('Scroll Percentage', {
  //         scrollPercentage: roundedScrollPercentage,
  //         page: pageName,
  //         title: pageTitle,
  //       });

  //       // Update the last tracked scroll percentage
  //       content.dataset.lastScrollPercentage = roundedScrollPercentage.toString();
  //     }
  //   };

  //   content.addEventListener('scroll', scrollHandler);

  //   return () => {
  //     content.removeEventListener('scroll', scrollHandler);
  //   };
  // }, [content, document.title, location.pathname]);

  return <>{children}</>;
};

export default TrackingRoute;
