// styles for custom accordion component
// -----------------------------------------------------------------------------
import { createStyles, CSSObject, DefaultProps } from '@mantine/core';

export enum AccordionVariant {
  Default = 'default',
  Small = 'small',
  Paper = 'paper',
}

interface UseCustomAccordionStylesProps extends DefaultProps {
  variant?: AccordionVariant;
}

const useCustomAccordionStyles = createStyles(
  (theme, { variant = AccordionVariant.Default }: UseCustomAccordionStylesProps) => {
    const baseStyles: Record<string, CSSObject> = {
      // Accordion Styles
      // ----------------
      acc_item: {
        backgroundColor: `${theme.colors.gray[1]}`,
        border: '1px solid white',
        borderRadius: theme.radius.sm,
        transition: 'border-color 0.3s',
        padding: theme.spacing.sm,

        '&[data-active]': {
          backgroundColor: theme.colors.gray[1],
          border: '1px solid white',
        },

        '& + &': {
          marginTop: '12px',
        },
      },
      acc_panel: {},
      acc_label: {},
      acc_control: {},
      acc_chevron: {
        marginLeft: '9px',

        '& + .mantine-Accordion-label': {
          overflow: 'initial', // for tooltip
        },
      },
      acc_description: {
        marginBottom: theme.spacing.md,
      },
      acc_divider: {
        marginTop: theme.spacing.md,
        marginBottom: theme.spacing.sm,
        color: theme.colors.gray[4],
      },
      // ----------------

      // Accordion Title Styles
      // ----------------
      accTitle: {},
      accTitle_Text: {
        fontWeight: 500,
        fontSize: theme.fontSizes.sm,
        width: '100%',

        // responsive styles
        [theme.fn.largerThan('md')]: {
          fontSize: theme.fontSizes.md,
        },

        '& span': {
          fontSize: theme.fontSizes.xs,
          color: theme.colors.dark[3],
        },
      },
      accTitle_guidance: {},
      // ----------------

      // Accordion Content Styles
      // ----------------
      accContent_description: {
        fontSize: theme.fontSizes.sm,
      },
      accContent_cardTitle: {
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,
        marginTop: theme.spacing.lg,
        marginBottom: theme.spacing.sm,
        fontFamily: 'Sora',
      },
      accContent_linkButton: {
        marginTop: theme.spacing.md,
        justifyContent: 'flex-end',
      },
      // ----------------

      // Associated Findings Card Styles
      // ----------------
      findingCard: {
        backgroundColor: theme.colors.gray[2],
        borderColor: 'white !important',
        height: '100%',
        padding: theme.spacing.lg,
        borderRadius: theme.radius.md,
      },
      findingCard_title: {
        fontWeight: 500,
        marginBottom: theme.spacing.md,
      },
      findingCard_description: {
        fontSize: theme.fontSizes.sm,
        marginBottom: theme.spacing.md,
      },
      findingCard_listItem: {
        borderBottom: '0.5px solid #AAAAAA',
        fontSize: theme.fontSizes.xs,
        flexWrap: 'nowrap',
        paddingTop: theme.spacing.sm,
        paddingBottom: theme.spacing.sm,
        ':last-of-type': {
          borderBottom: 'none',
        },
      },
      findingCard_listItem_badge: {
        minWidth: '72px',
        backgroundColor: '#eeeeee',
        color: 'black',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: '3px',
      },
      findingCard_listItem_text: {
        fontStyle: 'italic',
        fontWeight: 600,
      },
      // ----------------
    };

    // Variant-specific styles
    const variantStyles: Record<string, CSSObject> =
      {
        [AccordionVariant.Default]: {
          accTitle_guidance: {
            maxWidth: '90px',
            fontSize: '12px',
            lineHeight: '1',
            color: '#C92323',
            fontWeight: 700,
          },
        },
        [AccordionVariant.Small]: {
          acc_item: {
            backgroundColor: theme.colors.gray[1],
            border: '1px solid white',

            '&[data-active]': {
              backgroundColor: theme.colors.gray[1],
              border: '1px solid white',
            },

            '& + &': {
              marginTop: '8px',
            },
          },
          acc_panel: {
            whiteSpace: 'pre-line' as CSSObject['whiteSpace'],
          },
          acc_label: {
            padding: '0px',
          },
          acc_control: {
            padding: '8px 16px 0px 16px',
          },
          acc_chevron: {
            marginBottom: '8px',
          },

          accTitle: {
            marginBottom: theme.spacing.sm,
          },
          accTitle_Text: {
            fontWeight: 500,
            fontSize: theme.fontSizes.sm,
          },
          accTitle_guidance: {
            fontWeight: 500,
            fontSize: theme.fontSizes.xs,
            fontFamily: 'Sora',
          },
        },
        [AccordionVariant.Paper]: {
          acc_item: {
            backgroundColor: 'rgba(254, 255, 240, 0.60)',
            borderColor: '#FAFFBB !important',

            '&[data-active]': {
              backgroundColor: 'rgba(254, 255, 240, 0.60)',
              borderColor: '#FAFFBB !important',
            },

            '& + &': { marginTop: '8px' },
          },
          acc_panel: {
            paddingTop: theme.spacing.sm,
          },
        },
      }[variant] || {};

    return { ...baseStyles, ...variantStyles };
  }
);

export default useCustomAccordionStyles;
