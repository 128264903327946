import { useParams } from 'react-router-dom';
import { Title } from '@mantine/core';

import { FINDING_KB } from 'types/V2Types';

import reportApi from 'services/reportV2';

import Loading from 'components/Loading';
import Finding, { FindingProps } from 'components/ReportV2/Finding/Finding';
import HeadAssociationBlock from 'components/ReportV2/elements/HeadAssociationBlock';
import { Disclaimer } from 'ui/Disclaimer';
import { PageHeader } from 'ui/PageHeader';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';

export default function FindingPage() {
  const { barcode, findingId } = useParams() as { barcode: string; findingId: string };

  const { data: finding, error, isLoading } = reportApi.useGetFindingQuery({ barcode, findingId });

  if (error) return <Error error={error} />;
  if (isLoading) return <Loading />;
  if (!finding) return <Title m="xl">Report not found</Title>;

  const findingProps: FindingProps = {
    ...finding,
    barcode,
  };

  return (
    <>
      <PageHeader title={finding.name} showBackButton />

      <PageContainer size="md">
        <Finding {...findingProps} />
        <Disclaimer />
      </PageContainer>
    </>
  );
}
