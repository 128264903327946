// Shared constants for intake form

import { SelectItem } from '@mantine/core';

// ----------------
export const OtherPleaseSpecify = { value: 'other_please_specify', label: 'Other, please specify' };
export const OtherResponse = { value: 'other', label: 'Other' };
export const CancerPleaseSpecify = { value: 'cancer_please_specify', label: 'Cancer, please specify' };
export const CVDPleaseSpecify = {
  value: 'cardiovascular_disease_please_specify',
  label: 'Cardiovascular disease, please specify',
};
export const NAResponse = { value: 'na', label: 'N/A' };
export const UnknownResponse = { value: 'unknown', label: "I don't know" };
export const YesNoInput = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];
export const YesNoOtherInput = [...YesNoInput, OtherPleaseSpecify];
export const NoConditions = { value: 'na', label: 'No conditions' };
export const NoSymptoms = { value: 'na', label: 'No symptoms' };
export const NoMedications = { value: 'no_medications', label: 'I am not taking medications' };

export const IntakeConditions = (conditionsData: SelectItem[]) => [
  NoConditions,
  ...conditionsData,
  OtherPleaseSpecify,
  CancerPleaseSpecify,
  CVDPleaseSpecify,
];

export const IntakeSymptoms = (symptomsData: SelectItem[]) => [NoSymptoms, ...symptomsData, OtherPleaseSpecify];

export const IntakeSensitivities = (sensitivitiesData: SelectItem[]) => [
  NAResponse,
  ...sensitivitiesData,
  OtherPleaseSpecify,
];

export const IntakeTreatments = (treatmentsData: SelectItem[]) => [NAResponse, ...treatmentsData, OtherPleaseSpecify];

// Functions to determine if a value triggers a text input
// ----------------
export function ValueTriggersTextInput(values: string[]): Boolean {
  return values.includes(OtherPleaseSpecify.value);
}

export function ProviderValueTriggersTextInput(values: string[]): Boolean {
  return values.includes('provider_recommendation');
}

export function ValueTriggersCancerInput(values: string[]): Boolean {
  return values.includes(CancerPleaseSpecify.value);
}

export function ValueTriggersCVDInput(values: string[]): Boolean {
  return values.includes(CVDPleaseSpecify.value);
}

export function ValueTriggersCustomFollowup(values: string[], triggerValue: string): Boolean {
  return values.includes(triggerValue);
}

export function NAValueTriggersResponse(values: string[]): Boolean {
  if (values.length === 1 && values[0] === NAResponse.value) return false;
  return values.length > 0;
}

// Intake form constants
// Health Goals Form ----------------

export const IntakePregnancyStatus = [
  NAResponse,
  { value: 'pregnant', label: 'I am pregnant' },
  { value: 'menstruating', label: 'I am menstruating' },
  { value: 'digestive_symptom_flare', label: 'I am experiencing a digestive symptom flare' },
  OtherPleaseSpecify,
];

export const IntakeSeverity = [
  { value: 'mild', label: 'Mild' },
  { value: 'moderate', label: 'Moderate' },
  { value: 'severe', label: 'Severe' },
  NAResponse,
];

export const IntakeSymptomsBeganAfter = [
  { value: 'infection', label: 'An infection' },
  { value: 'stomach_illness', label: 'A stomach illness' },
  { value: 'concussion', label: 'A concussion' },
  { value: 'surgery', label: 'Surgery' },
  { value: 'pregnancy', label: 'Pregnancy' },
  { value: 'stressful_event', label: 'Stressful life event' },
  NAResponse,
];

export const IntakeGISymptomImpacts = [
  {
    value: 'work_productivity',
    label: 'Work and productivity',
    description: 'My symptoms make it difficult to concentrate and perform daily tasks at work',
  },
  {
    value: 'social_life',
    label: 'Social life',
    description:
      'My symptoms can be embarrassing and uncomfortable, making it difficult to participate in social activities and events',
  },
  {
    value: 'mental_health',
    label: 'Mental health',
    description: 'Chronic symptoms can cause anxiety, depression, and other mental health issues',
  },
  {
    value: 'diet_nutrition',
    label: 'Diet and nutrition',
    description:
      'My symptoms can make it difficult to eat a healthy and balanced diet, as I need to avoid or limit certain foods',
  },
  {
    value: 'sleep',
    label: 'Sleep',
    description: 'My symptoms interfere with my sleep quality and lead to fatigue and daytime sleepiness',
  },
  {
    value: 'physical_activity',
    label: 'Physical activity',
    description: 'My symptoms make it difficult to engage in physical activity',
  },
  OtherPleaseSpecify,
  NAResponse,
];

export const IntakeBiggestHealthGoal = [
  { value: 'diagnosis', label: 'Confirm a diagnosis' },
  { value: 'risk_management', label: 'Know the clinical conditions I am at risk for, and take preventative measures' },
  { value: 'understand_causes', label: 'Understand the root causes of my symptoms' },
  { value: 'symptom_management', label: 'Manage and alleviate my symptoms' },
  { value: 'inflammation_management', label: 'Manage inflammation' },
  { value: 'digestive_health', label: 'Improve digestive/gut health' },
  { value: 'overall_health', label: 'Improve overall health and well-being' },
  { value: 'healthy_weight', label: 'Achieve and maintain a healthy weight' },
  { value: 'nutrient_absorption', label: 'Improve nutrient absorption and balance' },
  { value: 'stress_management', label: 'Manage stress' },
  { value: 'microbiome_health', label: 'Improve my microbiome diversity' },
  { value: 'reduce_complications', label: 'Reduce the risk of complications' },
  OtherPleaseSpecify,
];

export const IntakeLearn = [
  { value: 'risk_conditions', label: 'Risk Conditions', description: 'Clinical conditions I am at risk for' },
  { value: 'root_causes', label: 'Root Causes', description: 'Understand the root causes of my symptoms' },
  { value: 'food_triggers', label: 'Food Triggers', description: 'Food triggers for my symptoms' },
  { value: 'inflammation_sources', label: 'Inflammation Sources', description: 'Potential sources of inflammation' },
  {
    value: 'nutrient_deficiencies',
    label: 'Nutrient Deficiencies',
    description: 'Identify and address any essential nutrient deficiencies impacting my health',
  },
  {
    value: 'pathogens_infections',
    label: 'Pathogens & Infections',
    description: 'Learn about pathogens and infections that may be affecting my health',
  },
  { value: 'dietary_recommendations', label: 'Dietary Advice', description: 'Personalized dietary recommendations' },
  {
    value: 'lifestyle_recommendations',
    label: 'Lifestyle Advice',
    description: 'Personalized lifestyle recommendations like sleep, exercise',
  },
  {
    value: 'treatment_options',
    label: 'Treatment Options',
    description: 'Discover effective treatment strategies tailored to my specific health needs',
  },
  {
    value: 'microbiome_science',
    label: 'Microbiome Science',
    description: "Current science and literature on the microbiome that's relevant to my health",
  },
  { value: 'microbiome_health', label: 'Microbiome Health', description: 'My overall microbiome diversity and health' },
  OtherPleaseSpecify,
];

export const IntakeReferrer = [
  {
    value: 'curiosity',
    label: 'Curiosity',
    description: 'I am curious about my microbiome and want to learn more',
  },
  {
    value: 'probiotic_interest',
    label: 'Probiotic or supplement impact',
    description: 'I am interested in how a probiotic or supplement I am taking is impacting my gut health',
  },
  {
    value: 'current_condition',
    label: 'Current condition',
    description: 'I want help addressing a current condition',
  },
  {
    value: 'managing_symptoms',
    label: 'Symptom management',
    description: 'I want help managing symptoms',
  },
  {
    value: 'microbiome_risk_factors',
    label: 'Risk factors',
    description: 'I want to decrease my risk for future conditions',
  },
  {
    value: 'antibiotic_concern',
    label: 'Antibiotic concern',
    description: 'I am concerned about antibiotic use',
  },
  {
    value: 'digestive_health',
    label: 'Digestive and metabolic health',
    description: 'I am interested in digestive and metabolic health',
  },
  {
    value: 'weight_loss',
    label: 'Weight management',
    description: 'I would like to lose/manage weight',
  },
  {
    value: 'provider_recommendation',
    label: 'Provider recommendation',
    description: 'My provider recommended it to me',
  },
  {
    value: 'recommended_by_other',
    label: 'Recommended by others',
    description: 'My friend/family member recommended it to me',
  },
  OtherPleaseSpecify,
];

export const IntakeProvider = [
  { value: 'metabolix', label: 'Metabolix' },
  { value: 'moov', label: 'MOOV' },
  { value: 'andreanne_martin', label: 'Andreanne Martin' },
  { value: 'alex_grinberg', label: 'Alex Grinberg' },
  NAResponse,
  OtherPleaseSpecify,
];

export const IntakeDigestiveHealthConcern = [
  { value: 'symptom_management', label: 'Managing and alleviating symptoms' },
  { value: 'digestive_health_promotion', label: 'Promoting general digestive/gut health' },
  { value: 'healthy_weight', label: 'Achieving or maintaining a healthy weight' },
  { value: 'nutrient_absorption', label: 'Improving nutrient absorption and balance' },
  { value: 'stress_management', label: 'Managing stress' },
  { value: 'microbiome_diversity', label: 'Improve microbiome diversity' },
  { value: 'treatment_optimization', label: 'Optimizing my Treatment Strategies' },
  OtherPleaseSpecify,
];

// Lifestyle and Nutrition Form ----------------
export const IntakeExerciseFrequency = [
  NAResponse,
  { value: '1_2_times_per_week', label: '1-2 times per week' },
  { value: '3_4_times_per_week', label: '3-4 times per week' },
  { value: '5_times_or_more', label: '5 times or more' },
];

export const IntakeTravelOutsideUSFrequency = [
  NAResponse,
  { value: 'once_a_decade', label: 'Once a decade' },
  { value: 'once_every_few_years', label: 'Once every few years' },
  { value: 'about_once_a_year', label: 'About once a year' },
  { value: 'multiple_times_a_year', label: 'Multiple times a year' },
  OtherResponse,
];

export const IntakeAreaClassification = [
  { value: 'urban', label: 'Urban' },
  { value: 'suburban', label: 'Suburban' },
  { value: 'rural', label: 'Rural' },
  { value: 'unsure', label: 'Unsure' },
  OtherResponse,
];

export const IntakeSleepHours = [
  { value: 'less_than_5_hours', label: 'Less than 5 hours' },
  { value: '6_7_hours', label: '6-7 hours' },
  { value: '8_9_hours', label: '8-9 hours' },
  { value: 'more_than_10_hours', label: 'More than 10 hours' },
  { value: 'highly_variable', label: 'Highly variable' },
];

export const IntakeSleepQuality = [
  { value: 'mostly_good', label: 'Mostly good' },
  { value: 'mostly_okay', label: 'Mostly okay' },
  { value: 'mostly_bad', label: 'Mostly bad' },
  { value: 'highly_variable', label: 'Highly variable' },
  OtherResponse,
];

export const IntakeFoods = [
  {
    label: 'Fruits',
    formValue: 'fruits',
    tooltip: 'berries, citrus fruits, apples, pears, not in the form of juices',
  },
  {
    label: 'Green or leafy vegetables',
    formValue: 'greensOrLeafyVegetables',
    tooltip: 'spinach, kale, arugula, bok choy, swiss chard, collard greens',
  },
  {
    label: 'Starchy vegetables',
    formValue: 'starchyVegetables',
    tooltip: 'corn, peas, potatoes, sweet potatoes, lotus root',
  },
  {
    label: 'Grains',
    formValue: 'grains',
    tooltip: 'oats, rye, quinoa, brown/wild rice, wheat, white rice, white breads, pasta',
  },
  {
    label: 'Red meat',
    formValue: 'redMeat',
    tooltip: 'beef, goat, ham, lamb, pork',
  },
  {
    label: 'Fish or seafood',
    formValue: 'fishOrSeafood',
    tooltip: 'salmon, tuna, shrimp',
  },
  {
    label: 'Fermented foods',
    formValue: 'fermentedFoods',
    tooltip: 'kefir, kombucha, kimchi, tempeh, natto, miso, sauerkraut',
  },
  {
    label: 'Olive oil',
    formValue: 'oliveOil',
    tooltip: '',
  },
  {
    label: 'Desserts or sweets',
    formValue: 'dessertsOrSweets',
    tooltip: 'cake, baked foods, ice cream, candy',
  },
  {
    label: 'Sweetened beverages',
    formValue: 'sweetenedBeverages',
    tooltip: 'soda, sugar in coffee, fruit juices',
  },
  {
    label: 'Dairy products',
    formValue: 'dairyProducts',
    tooltip: "cow's milk, soft and hard cheeses, yogurt, ice cream, whey protein, butter, margarine",
  },
];

// Medical History Form ----------------
export const IntakeBloodType = [
  { value: 'o_negative', label: 'O negative' },
  { value: 'o_positive', label: 'O positive' },
  { value: 'a_negative', label: 'A negative' },
  { value: 'a_positive', label: 'A positive' },
  { value: 'b_negative', label: 'B negative' },
  { value: 'b_positive', label: 'B positive' },
  { value: 'ab_negative', label: 'AB negative' },
  { value: 'ab_positive', label: 'AB positive' },
  UnknownResponse,
];

export const GLP1: string = 'GLP-1 Receptor Agonists (e.g. Ozempic, Saxenda, Wegovy)';
export const IntakeMedications = [
  NoMedications,
  { value: 'antibiotics', label: 'Antibiotics (e.g. Ciprofloxacin, Metronidazole)' },
  { value: 'infliximab', label: 'Infliximab (e.g. Remicade)' },
  { value: 'adalimumab', label: 'Adalimumab (e.g. Humira)' },
  { value: 'vedolizumab', label: 'Vedolizumab (e.g., Entyvio)' },
  { value: 'hyoscyamine', label: 'Hyoscyamine (e.g., Levsin)' },
  { value: 'immunomodulators', label: 'Immunomodulators (e.g. Azathioprine, 6-Mercaptopurine)' },
  { value: 'corticosteroids', label: 'Corticosteroids (e.g. Prednisone, Budesonid)' },
  { value: 'aminosalicylates', label: 'Aminosalicylates (e.g. Mesalamine)' },
  { value: 'nsaids', label: 'Non-Steroidal Anti-Inflammatories (e.g. Aspirin, Ibuprofen)' },
  { value: 'bismuth_subsalicylate', label: 'Bismuth Subsalicylate' },
  { value: 'ppi', label: 'Proton pump inhibitors (e.g. Prilosec, Nexium)' },
  { value: 'h2_blockers', label: 'H2 blockers (e.g. Tagamet, Pepcid)' },
  { value: 'antacids', label: 'Antacids (e.g. TUMS, Maalox)' },
  { value: 'rifaximin', label: 'Rifaximi' },
  { value: 'laxatives', label: 'Laxatives (e.g. PEG, Lactulose)' },
  { value: 'tricyclic_antidepressants', label: 'Tricyclic antidepressants (e.g. Amitriptyline, Nortriptyline)' },
  { value: 'antispasmodics', label: 'Antispasmodics (e.g. Dicyclomine, Hyoscyamine)' },
  { value: 'birth_control', label: 'Birth control' },
  { value: 'glp1', label: GLP1 },
  OtherPleaseSpecify,
];

export const IntakeSupplements = [
  NAResponse,
  { value: 'culturelle', label: 'Culturelle' },
  { value: 'align', label: 'Align' },
  { value: 'florastor', label: 'Florastor' },
  { value: 'garden_of_life', label: 'Garden of Life' },
  { value: 'renew_life', label: 'Renew Life' },
  { value: 'jarrow_formulas', label: 'Jarrow Formulas' },
  { value: 'now_foods', label: 'NOW Foods' },
  { value: 'megafood', label: 'MegaFood' },
  { value: 'bio_kult', label: 'Bio-Kult' },
  { value: 'natren', label: 'Natren' },
  { value: 'probiogen', label: 'Probiogen' },
  { value: 'hyperbiotic', label: 'Hyperbiotic' },
  { value: 'nordic_naturals', label: 'Nordic Naturals' },
  { value: 'dr_ohhiras', label: "Dr. Ohhira's" },
  { value: 'rainbow_light', label: 'Rainbow Light' },
  { value: 'schiff_digestive_advantage', label: 'Schiff Digestive Advantage' },
  { value: 'vsl3', label: 'VSL#3' },
  { value: 'smartypants', label: 'SmartyPants' },
  { value: 'thorne_research', label: 'Thorne Research' },
  { value: 'gaia_herbs', label: 'Gaia Herbs' },
  { value: 'seed', label: 'Seed' },
  { value: 'viome', label: 'Viome' },
  { value: 'bioschwartz', label: 'BioSchwartz' },
  { value: 'pure_encapsulations', label: 'Pure Encapsulations' },
  OtherPleaseSpecify,
];

export const IntakeBowelMovementFrequency = [
  { value: 'more_than_once_per_day', label: 'More than once per day' },
  { value: 'once_per_day', label: 'Once per day' },
  { value: 'once_every_2_3_days', label: 'Once every 2-3 days' },
  { value: 'once_per_week', label: 'Once per week' },
  { value: 'less_than_once_per_week', label: 'Less than once per week' },
  OtherPleaseSpecify,
];

export const GLP1Brands = [
  { value: 'semaglutide_wegovy', label: 'Semaglutide (Wegovy)' },
  { value: 'semaglutide_rybelsus', label: 'Semaglutide (Rybelsus)' },
  { value: 'liraglutide_saxenda', label: 'Liraglutide (Saxenda)' },
  { value: 'liraglutide_victoza', label: 'Liraglutide (Victoza)' },
  { value: 'liraglutide_ozempic', label: 'Liraglutide (Ozempic)' },
  { value: 'tirzepatide_mounjaro', label: 'Tirzepatide (Mounjaro)' },
  { value: 'dulaglutide_trulicity', label: 'Dulaglutide (Trulicity)' },
  { value: 'exenatide_byetta', label: 'Exenatide (Byetta)' },
  { value: 'lixisenatide_adlyxin', label: 'Lixisenatide (Adlyxin)' },
  OtherResponse,
];

export const IntakeAntibiotics = [
  { value: 'never_taken', label: 'I have never taken antibiotics' },
  { value: 'currently_taking', label: 'I am currently taking antibiotics' },
  { value: 'past_month', label: 'Within the past month' },
  { value: 'past_3_months', label: 'Within the past 3 months' },
  { value: 'past_6_months', label: 'Within the past 6 months' },
  { value: 'past_year', label: 'Within the past year' },
  { value: 'past_2_years', label: 'Within the past 2 years' },
  { value: 'more_than_2_years_ago', label: 'More than 2 years ago' },
  OtherPleaseSpecify,
];
