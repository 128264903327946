import { ActionIcon, Box, Text, UnstyledButton, createStyles } from '@mantine/core';
import Card from 'components/Card';
import CompactTable, { RowTitle } from '../CompactTable';
import StatusBadge, { BadgeStatus } from '../Badges';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

interface ClientTableProps {
  name: string;
  clientId: string;
  internalClientId?: string;
  clientStatus?: BadgeStatus;
  email: string;
  internalNotes: string;
}

const ClientTable: React.FC<ClientTableProps> = ({
  name,
  clientId,
  internalClientId,
  clientStatus,
  email,
  internalNotes,
}) => {
  const { classes } = useStyles();

  const tableData = [
    [
      <RowTitle key={0} label="Name" value={name} />,
      <RowTitle key={1} label="Client ID" value={internalClientId || clientId} />,
      <RowTitle
        key={2}
        label="Client Status"
        value={<StatusBadge w="120px" status={clientStatus || BadgeStatus.pending} />}
      />,
    ],
    [
      <RowTitle
        key={0}
        label="Email"
        value={
          <UnstyledButton
            component="a"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            href={`mailto:${email}`}
          >
            {email}
          </UnstyledButton>
        }
      />,
    ],
    [<RowTitle key={0} label="Internal Notes" value={internalNotes} />],
  ];
  return (
    <Box>
      <Text className={classes.cardTitle} inline>
        Client Info
        {clientStatus === BadgeStatus.active && (
          <ActionIcon component={Link} to={`/client/${clientId}/edit`} color="dark.2" variant="transparent">
            <FontAwesomeIcon size="xs" icon={faPencil} />
          </ActionIcon>
        )}
      </Text>
      <Card p="lg" pt="0">
        <CompactTable divider={false} data={tableData} />
      </Card>
    </Box>
  );
};
export default ClientTable;
const useStyles = createStyles((theme) => ({
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing.xs,
    color: theme.colors.dark[2],
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: theme.fontSizes.xs,
  },
}));
