import { Badge, Flex, Group, Paper, Text, Title, createStyles } from '@mantine/core';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import { ReactNode, useEffect } from 'react';
import { sortByAssociationScore } from 'utilsV2';
import AssociationSection from './AssociationSection';
import LinkButton from './LinkButton';
import {
  ActionType,
  RelatedPathogen,
  ReportAction,
  ReportActionOverview,
  ReportFinding,
  ReportFindingOverview,
} from 'types/V2Types';
import reportApi from 'services/reportV2';
import Loading from 'components/Loading';
import Card from 'components/Card';
import HealthGoalsCard, { HealthGoalsVariants } from 'ui/HealthGoalsCard';

interface Props {
  barcode: string;
  n: number;
  symptom: ReportFindingOverview;
}

export default function SymptomBlock({ barcode, n, symptom }: Props) {
  const { classes } = useStyles();
  const {
    data: overview,
    isLoading: isOverviewLoading,
    error,
  } = reportApi.endpoints.getOverview.useQuery({
    barcode,
    findingId: symptom.finding_id,
  });

  useEffect(() => {
    if (!overview) return;
  }, [overview]);

  if (error) {
    console.error('Error loading health goal: ', error);
    return (
      <Card className="jona-card" mb="lg">
        <Title order={4}>
          {n}. {symptom.intakeConcern && 'Your goal:'} Reduce and manage {symptom.name}
        </Title>
        <Paper className={classes.paperError} withBorder radius={2} p="lg" mt="lg">
          <Text color="red.9">There was an error loading this health goal. Please try again later.</Text>
        </Paper>
      </Card>
    );
  }

  if (isOverviewLoading || !overview) {
    return <Loading />;
  }

  const symptomName = symptom.preferred_name ?? symptom.name;
  const lowerCaseSymptomName = symptomName.toLowerCase();

  let metabolisms = overview.metabolisms
    .filter((m: ReportFinding) => m.association_score !== 0)
    .filter((m) => m.relations.causes.symptoms.includes(overview.kb_id));

  const negative_impact_organisms = overview.organisms;

  let foodTriggers: ReportAction[] = [];
  metabolisms.map((metabolism: ReportFinding) => {
    const actions = metabolism.actions.filter(
      (action: ReportActionOverview) => action.action_type === ActionType.FOODTRIGGER
    );

    let sorted_actions = [...actions].sort(sortByAssociationScore);
    foodTriggers = [...foodTriggers, ...sorted_actions] as ReportAction[];
    return 0;
  });

  const detectedPathogens = overview.pathogens.filter((pathogen: RelatedPathogen) => pathogen.guidance === 'Detected');

  let reccomendationsWidth = '33%';
  if (
    overview.diet_actions.length > 0 &&
    foodTriggers.length > 0 &&
    overview.treatments.length > 0 &&
    overview.lifestyles.length > 0
  ) {
    reccomendationsWidth = '25%';
  }

  const noAction =
    !overview.diet_actions.length && !foodTriggers.length && !overview.treatments.length && !overview.lifestyles.length;

  return (
    <Card className="jona-card" mb="lg">
      <Group position="apart" mb="lg">
        <Title order={4}>
          {n}. {symptom.intakeConcern && 'Your goal:'} Reduce and manage {lowerCaseSymptomName}
        </Title>
        <AssociationSection
          associationScore={symptom.association_score}
          researchStatus={symptom.research_status}
          showPopover={true}
        />
      </Group>

      <Badge size="lg" variant="filled" color="blue" mb="sm">
        <Text ff="Sora" fw="500">
          Key findings
        </Text>
      </Badge>
      <Flex direction="column">
        <Flex direction={{ base: 'column', md: 'row' }} gap={{ xs: 'xl', sm: 'md' }}>
          {!!metabolisms.length && (
            <HealthGoalsCard
              variant={HealthGoalsVariants.Metabolisms}
              guidanceRow={metabolisms.slice(0, 3).map(({ guidance, name }) => ({
                guidance,
                name,
              }))}
              itemName={lowerCaseSymptomName}
              totalItems={metabolisms.length}
            />
          )}

          {!!detectedPathogens.length && (
            <HealthGoalsCard
              variant={HealthGoalsVariants.Pathogens}
              guidanceRow={detectedPathogens.slice(0, 3).map(({ guidance, name }) => ({
                guidance,
                name,
              }))}
              itemName={lowerCaseSymptomName}
              totalItems={detectedPathogens.length}
            />
          )}

          {!!negative_impact_organisms.length && (
            <HealthGoalsCard
              variant={HealthGoalsVariants.Organisms}
              guidanceRow={negative_impact_organisms.slice(0, 3).map(({ guidance, name }) => ({
                guidance,
                name,
              }))}
              itemName={lowerCaseSymptomName}
              totalItems={negative_impact_organisms.length}
            />
          )}
        </Flex>
        <Flex mt="lg" justify="flex-end">
          <LinkButton to={`/reports/${barcode}/symptoms/${overview.finding_id}`}>View key findings</LinkButton>
        </Flex>
      </Flex>

      {!noAction && (
        <>
          <Badge size="lg" variant="filled" className={classes.reccomendationsBadge} mb="sm" mt="md">
            <Text ff="Sora" fw="500">
              Recommendations
            </Text>
          </Badge>
          <Flex direction="column">
            <Flex direction={{ base: 'column', md: 'row' }} gap={{ xs: 'xl', sm: 'md' }}>
              {!!overview.diet_actions.length && (
                <HealthGoalsCard
                  variant={HealthGoalsVariants.Nutrition}
                  guidanceRow={overview.diet_actions.slice(0, 3).map(({ guidance, name }) => ({
                    guidance,
                    name,
                  }))}
                  itemName={lowerCaseSymptomName}
                  totalItems={overview.diet_actions.length}
                />
              )}

              {!!foodTriggers.length && (
                <HealthGoalsCard
                  variant={HealthGoalsVariants.FoodTriggers}
                  guidanceRow={foodTriggers.slice(0, 3).map(({ guidance, name }) => ({
                    guidance,
                    name,
                  }))}
                  itemName={lowerCaseSymptomName}
                  totalItems={foodTriggers.length}
                />
              )}

              {!!overview.lifestyles.length && (
                <HealthGoalsCard
                  variant={HealthGoalsVariants.Lifestyle}
                  guidanceRow={overview.lifestyles.slice(0, 3).map(({ guidance, name }) => ({
                    guidance,
                    name,
                  }))}
                  itemName={lowerCaseSymptomName}
                  totalItems={overview.lifestyles.length}
                />
              )}

              {!!overview.treatments.length && (
                <HealthGoalsCard
                  variant={HealthGoalsVariants.Supplements}
                  guidanceRow={overview.treatments.slice(0, 3).map(({ guidance, name }) => ({
                    guidance,
                    name,
                  }))}
                  itemName={lowerCaseSymptomName}
                  totalItems={overview.treatments.length}
                />
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  itemCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderColor: 'white !important',
    height: '100%',
  },
  itemCardBlue: {
    backgroundColor: '#c4d6ed5f',
    borderColor: `${theme.colors.blue[3]} !important`,
    height: '100%',
    borderRadius: '15px',
  },
  itemCardYellow: {
    backgroundColor: '#E0D3BA3F',
    borderColor: `#AD8323 !important`,
    height: '100%',
    borderRadius: '15px',
  },
  list: {
    color: 'white',
  },
  paper: {
    backgroundColor: theme.colors.gray[1],
    border: `1px solid #ffffff`,
    borderRadius: theme.radius.lg,
  },
  paperError: {
    color: theme.colors.red[9],
    backgroundColor: 'rgba(126, 4, 4, 0.03)',
    borderColor: `${theme.colors.red[4]} !important`,
    borderRadius: '7px',
  },
  reccomendationsBadge: {
    color: 'white',
    backgroundColor: '#AD8323',
    // backgroundColor: theme.colors.yellow[8],
    opacity: '75%',
  },
}));

interface SymptomBlockTitleProps {
  title: string;
  content: ReactNode;
}
function SymptomBlockTitle({ title, content }: SymptomBlockTitleProps) {
  return (
    <Group position="apart" mb="md">
      {title} <TitlePopover content={content} direction="top-left" iconVariant="gray" />
    </Group>
  );
}
