import { Dialog, CloseButton, Center, Title } from '@mantine/core';
import { Widget } from '@typeform/embed-react';
import { closeSurveyDialog, setSurveyId } from 'slices/surveyDialog';
import devApi from 'services/dev';
import { useEffect } from 'react';
import { IUserProfile, User } from 'types/user';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from 'hooks';

const SurveyDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isOpen, surveyId, source } = useAppSelector((state) => state.surveyDialog);
  const { firstName, lastName, email, auth0id } = useAppSelector((state) => state.user);
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading || !isAuthenticated) {
    return null;
  }

  const { data: surveyData, isLoading: isSurveyDataLoading, isError: isSurveyDataError } = devApi.useGetSurveyIdQuery();

  const style = {
    width: 500,
    height: 600,
    margin: 'auto',
  };

  const handleCloseDialog = () => {
    dispatch(closeSurveyDialog());
  };

  useEffect(() => {
    if (surveyData?.value) {
      dispatch(setSurveyId(surveyData.value as string));
    }
  }, [surveyData, dispatch]);

  if (isSurveyDataLoading || isSurveyDataError) {
    return null;
  }

  return (
    <Dialog opened={isOpen} withCloseButton={false} size="500" radius="sm" style={{ backgroundColor: '#F0E9DD' }}>
      <CloseButton
        onClick={handleCloseDialog}
        size="md"
        style={{ position: 'absolute', top: 10, right: 10, zIndex: '999', backgroundColor: '#F0E9DD', color: '#000' }}
      />
      {surveyId && !isSurveyDataError ? (
        <Widget
          id={surveyId as string}
          style={style}
          hidden={{
            email: email || 'not found',
            name: firstName || lastName ? `${firstName} ${lastName}` : 'not found',
            auth0_id: auth0id || 'not found',
            source: source || 'web_app_default',
          }}
        />
      ) : (
        <Center style={style}>
          <Title ta="center" order={6}>
            We apologize, but we are unable to load the survey at this time. Please try again later.
          </Title>
        </Center>
      )}
    </Dialog>
  );
};

export default SurveyDialog;
