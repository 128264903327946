import * as React from 'react';
import { createStyles } from '@mantine/core';

import { type IconProps, useIconProps, VARIANT } from './types';

export const JonaEmblemBlackCircleIcon = React.memo(
  React.forwardRef<SVGSVGElement, IconProps>((props, forwardedRef) => {
    const { classes, cx } = useNavbarStyles();
    const { 'aria-label': ariaLabel = 'Jona Emblem Black', size, variant, ...rest } = useIconProps(props);
    return (
      <div className={cx(classes.logo, classes[variant])}>
        <svg
          id="jona-emblem-black-circle-icon"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          role="img"
          width={size}
          height={size}
          aria-label={ariaLabel}
          ref={forwardedRef}
          version="1.0"
          viewBox="0 0 2650 3092"
          {...rest}
        >
          <path d="M2353 .7c-209.6 4.6-415.1 75.2-583 200.1-167.4 124.6-292.2 297.6-355.8 493.2-26.3 81-40.5 155.8-47.4 250.5-.8 11.8-1.3 170.5-1.8 600.5-.7 639.1-.2 587.2-6 632-32.6 248.8-189.5 466.4-417.5 578.9-13.2 6.6-30.8 14.8-39.2 18.2-8.4 3.5-15.2 6.8-15.3 7.2 0 1.1 32.9 33.1 48 46.7 163.3 146.9 368.4 237.5 586 258.9 40.6 4 52.9 4.6 103 4.6 50.2 0 62-.5 103-4.6 162.1-16 320.1-71.8 457.5-161.7 101.3-66.3 191.4-151 263.7-248.2 49.1-66 91.4-140.1 122.5-214.5 40-95.6 65.3-197.2 74.8-300 4.1-45.4 4-6.7 4-1108.5V.5H2507c-78.4-.1-147.7 0-154 .2" />
          <path d="M13.5 1897.2c-.9 2.7-4.4 23-6.4 36.8C-8.2 2041.8 2 2151.3 37 2253.5c69.4 202.7 232.2 362.6 436.3 428.4 51 16.5 100.3 26.2 158.7 31.3 18.9 1.7 83.9 1.6 103 0 61.4-5.4 111.8-15.7 167.6-34.4 11.7-3.9 21.8-7.4 22.5-7.9 1-.6.3-2.2-2.9-6.6-151.9-212-217.3-465.9-186.7-724.8 1.4-11.6 3.2-25.1 4-30 .8-5 1.5-10 1.5-11.3v-2.2H377.5c-290 0-363.6.3-364 1.2" />
        </svg>
      </div>
    );
  })
);
JonaEmblemBlackCircleIcon.displayName = 'JonaEmblemBlackCircleIcon';

const useNavbarStyles = createStyles((theme) => ({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 4,
    paddingTop: 3,
    borderRadius: '50%',
    width: '54px',
    height: '54px',
  },

  [VARIANT.DARK]: {
    backgroundColor: theme.colors.dark[5],

    svg: {
      color: theme.colors.gray[1],
    },
  },

  [VARIANT.LIGHT]: {
    backgroundColor: '#ffffff',

    svg: {
      color: theme.colors.blue[6],
    },
  },
}));

export default useNavbarStyles;
