import { Button, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router';

import Container from 'components/Container';
import { usePageMetadata } from 'hooks';

export default function CompletedV2() {
  const navigate = useNavigate();
  usePageMetadata({ title: 'Completed' });

  function handleHomeClick() {
    navigate('/');
  }

  return (
    <Container>
      <Title mb="sm">Stay tuned for your results!</Title>
      <Text>
        Your AI-generated microbiome profiling report should be available 3-4 weeks after we receive your sample.
      </Text>
      <Button mt="lg" fullWidth onClick={handleHomeClick}>
        Return Home
      </Button>
    </Container>
  );
}
