const ethnicities = [
  {
    value: 'american-indian',
    label: 'American Indian or Alaskan Native',
  },
  {
    value: 'asian',
    label: 'Asian',
  },
  {
    value: 'black',
    label: 'Black or African American',
  },
  {
    value: 'hispanic',
    label: 'Hispanic or Latino',
  },
  {
    value: 'white',
    label: 'White',
  },
  {
    value: 'native-hawaiian',
    label: 'Native Hawaiian or Pacific Islander',
  },
  {
    value: 'middle-eastern',
    label: 'Middle Eastern or North African',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export default ethnicities;
