import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Indicator, createStyles, type IndicatorProps } from '@mantine/core';

export enum IndicatorStatus {
  pending = 'Pending',
  processing = 'Processing',
  ready = 'Ready for Review',
  reviewed = 'Reviewed',
}

interface StatusIndicatorProps extends Omit<IndicatorProps, 'children'> {
  status: IndicatorStatus;
  selected?: boolean;
}

const color = {
  [IndicatorStatus.pending]: 'orange.5',
  [IndicatorStatus.processing]: 'blue.4',
  [IndicatorStatus.ready]: 'green.5',
  [IndicatorStatus.reviewed]: 'gray.5',
};

const StatusBadge: React.FC<StatusIndicatorProps> = ({ status, selected = false, ...props }) => {
  const { classes } = useStyles();
  return (
    <Indicator
      className={selected ? classes.selected : undefined}
      size={9}
      position="middle-start"
      color={color[status]}
      offset={-12}
      ml="16px"
      label={selected ? <FontAwesomeIcon icon={faCircle} fontSize="4px" /> : undefined}
      {...props}
    >
      {status}
    </Indicator>
  );
};
const useStyles = createStyles((theme) => ({
  selected: {
    color: theme.colors.dark[3],
  },
}));
export default StatusBadge;
