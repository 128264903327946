import IntakeV2 from 'types/intakeV2';
import { TagTypes, emptySplitApi } from './emptyApi';
import { SampleMetadata } from 'types/V2Types';


const sampleApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getSampleIntake: builder.query<IntakeV2, any>({
      query: ({ barcode }) => ({
        url: `/api/sample/${barcode}/intake`,
      }),
      providesTags: (result, error, { barcode }) => (result ? [{ type: TagTypes.report, id: barcode }] : []),
    }),
    updateSampleIntake: builder.mutation<IntakeV2, {intake: IntakeV2, barcode: string}>({
      query: ({ intake, barcode }) => ({
          url: `/api/sample/${barcode}/intake`,
          method: 'POST',
          body: {
            intake,
          },
      }),
      invalidatesTags: (result, error, { barcode }) => [{ type: TagTypes.report, id: barcode }],
    }),
    getSampleMetadata: builder.query<SampleMetadata, any>({
      query: ({ barcode }) => ({
        url: `/api/sample/${barcode}/metadata`,
      }),
      providesTags: (result, error, { barcode }) => (result ? [{ type: TagTypes.report, id: barcode }] : []),
    }),
  }),
});

export default sampleApi;
