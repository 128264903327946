import { faBrain, faBacteria, faPerson, IconDefinition } from '@fortawesome/free-solid-svg-icons';

const CONDITION_CATEGORIES = [
  'Dermatological Disorder',
  'Cardiovascular Disorder',
  'Neurological Disorder',
  'Gastrointestinal Disorder',
  'Endocrine Disorder',
  'Neurodevelopmental Disorder',
  'Mental Health Disorder',
  'Allergic Disorder',
  'Neurodegenerative Disorder',
  'Neuromuscular Disorder',
  'Autoimmune Disorder',
  'Musculoskeletal Disorder',
  'Respiratory Disorder',
  'Metabolic Disorder',
  'Inflammatory Disorder',
  'Oncological Disorder',
  'Renal Disorder',
  'Immunological Disorder',
  'Genetic Disorder',
  'Geriatric Disorder',
  'Reproductive Disorder',
  'Rheumatological Disorder',
  'Hematological Disorder',
  'Neurocognitive Disorder',
  'Infectious Disorder',
  'Urological Disorder',
  'Genitourinary Disorder',
];

const SYMPTOM_CATEGORIES = [
  'Neuropsychiatric Symptom',
  'Gastrointestinal Symptom',
  'Mental Health Symptom',
  'Neurological Symptom',
  'Metabolic Symptom',
  'Musculoskeletal Symptom',
  'Cardiovascular Symptom',
  'Respiratory Symptom',
  'General Symptom',
  'Dermatological Symptom',
  'Ocular Symptom',
  'Urological Symptom',
  'Laryngological Symptom',
];

// Icon mapping
const ICON_MAPPING: Record<string, IconDefinition> = {
  // Neurological and mental health
  'Neurological Disorder': faBrain,
  'Neurodevelopmental Disorder': faBrain,
  'Mental Health Disorder': faBrain,
  'Neurodegenerative Disorder': faBrain,
  'Neuromuscular Disorder': faBrain,
  'Neurocognitive Disorder': faBrain,
  'Neuropsychiatric Symptom': faBrain,
  'Neurological Symptom': faBrain,
  'Mental Health Symptom': faBrain,

  // Infectious and immune-related
  'Infectious Disorder': faBacteria,
  'Immunological Disorder': faBacteria,
  'Allergic Disorder': faBacteria,
  'Autoimmune Disorder': faBacteria,
  'Inflammatory Disorder': faBacteria,

  // General health and other body systems
  'Dermatological Disorder': faPerson,
  'Cardiovascular Disorder': faPerson,
  'Gastrointestinal Disorder': faPerson,
  'Endocrine Disorder': faPerson,
  'Musculoskeletal Disorder': faPerson,
  'Respiratory Disorder': faPerson,
  'Metabolic Disorder': faPerson,
  'Oncological Disorder': faPerson,
  'Renal Disorder': faPerson,
  'Genetic Disorder': faPerson,
  'Geriatric Disorder': faPerson,
  'Reproductive Disorder': faPerson,
  'Rheumatological Disorder': faPerson,
  'Hematological Disorder': faPerson,
  'Urological Disorder': faPerson,
  'Genitourinary Disorder': faPerson,

  'Gastrointestinal Symptom': faPerson,
  'Metabolic Symptom': faPerson,
  'Musculoskeletal Symptom': faPerson,
  'Cardiovascular Symptom': faPerson,
  'Respiratory Symptom': faPerson,
  'General Symptom': faPerson,
  'Dermatological Symptom': faPerson,
  'Ocular Symptom': faPerson,
  'Urological Symptom': faPerson,
  'Laryngological Symptom': faPerson,
};

// function to map the category to the corresponding icon
const getCategoryIcon = (category: string): IconDefinition => ICON_MAPPING[category] || faBrain;

export { CONDITION_CATEGORIES, SYMPTOM_CATEGORIES, ICON_MAPPING, getCategoryIcon };
