import { MantineThemeOverride } from '@mantine/core';

const theme: MantineThemeOverride = {
  fontFamily: 'Sora, sans-serif',
  headings: {
    fontFamily: 'Sora, serif',
    sizes: {
      h1: { fontSize: '32px' },
      h2: { fontSize: '28px' },
    },
  },
  primaryColor: 'dark',
  primaryShade: 4,
  colors: {
    blue: [
      '#e3ecf6',
      '#b0c7e3',
      '#c4d6ed',
      '#a8bfe2',
      '#5e7db6',
      '#476ca5',
      '#02235D',
      '#294581',
      '#01235d',
      '#0A1F4F',
    ],
    beige: [
      '#FAF6F1',
      '#F7F1E8',
      '#F4ECDF',
      '#F2E7D6',
      '#F0E9DD',
      '#EAD9C8',
      '#E4C9B3',
      '#DEB99E',
      '#D8A989',
      '#D29974',
    ],
    gold: [
      '#E6D8B4',
      '#DCCB94',
      '#D2BE74',
      '#C8B155',
      '#AD8323',
      '#9B7320',
      '#89641D',
      '#77551A',
      '#654617',
      '#533714',
    ],
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '48px',
  },
  shadows: {
    md: '0 14px 40px rgb(0 0 0 / 10%)',
  },
  components: {
    Button: {
      styles: (theme) => ({
        root: {
          fontWeight: 700,
          paddingTop: '2px',
          paddingBottom: '2px',
          paddingLeft: '16px',
          paddingRight: '16px',
        },
      }),
    },
    Input: {
      styles: (theme) => ({
        input: {
          height: 'auto',
          padding: '6px 12px',
          '&:disabled': {
            background: theme.colors.gray[4],
          },
        },
      }),
    },
    Textarea: {
      styles: () => ({
        input: {
          padding: '6px 12px',
          paddingTop: '12px !important',
          paddingBottom: '12px !important',
        },
      }),
    },
    Tooltip: {
      styles: () => ({
        tooltip: {
          textAlign: 'center',
          textTransform: 'initial',
          padding: '4px 6px',
        },
      }),
    },
    Modal: {
      styles: () => ({
        title: {
          fontSize: '18px',
          fontWeight: 600,
        },
      }),
    },
    Mark: {
      styles: () => ({
        root: {
          backgroundColor: '#596B7C',
          borderRadius: '2px',
          color: 'white',
          padding: '4px 6px 5px',
        },
      }),
    },
  },
};

export default theme;
