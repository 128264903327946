import { Text, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';

import reportApi from 'services/reportV2';

import Loading from 'components/Loading';
import PanelPathogens from 'components/ReportV2/elements/PanelPathogens';
import { PageHeader } from 'ui/PageHeader';
import { Disclaimer, FindingDisclaimer } from 'ui/Disclaimer';
import { FindingTooltip } from 'ui/Tooltip';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';

export default function PathogensPage() {
  const { barcode } = useParams<string>();

  const {
    data: allFindings,
    error: findingsError,
    isLoading: isFindingsLoading,
  } = reportApi.useGetAllFindingsQuery(barcode);

  if (findingsError) return <Error error={findingsError} />;
  if (isFindingsLoading) return <Loading />;
  if (!allFindings) return <Title m="xl">Report not found</Title>;

  return (
    <>
      <PageHeader title="Pathogens" toolTip={<FindingTooltip />} />
      <PageContainer size="md">
        <FindingDisclaimer />

        <Text mb="lg">
          Our list of pathogens is curated through using recommendations and reports published by the Centers for
          Disease Control and Prevention (CDC) on infectious diarrhea, gastroenteritis, food safety, intestinal
          parasites as well as the CDC Yellow Book.
        </Text>

        <PanelPathogens pathogens={allFindings.pathogens} />

        <Disclaimer />
      </PageContainer>
    </>
  );
}
