import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRedirectIfMobile = (redirectPath = '/use-computer') => {
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isMobile = /Mobi|Android/i.test(userAgent);

    if (isMobile) {
      navigate(redirectPath);
    }
  }, [navigate, redirectPath]);
};

export default useRedirectIfMobile;
