import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuthHeader } from './token';

// if storybook, we use an empty string for the API_URL
const API_URL =
  process.env.STORYBOOK === 'true'
    ? ''
    : process.env.NODE_ENV === 'production'
    ? window.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;

export enum TagTypes {
  user = 'user',
  report = 'report',
  finding = 'finding',
  action = 'action',
  organism = 'organism',
  survey = 'survey',
  diff = 'diff',
  invite = 'invite',
  invitePending = 'invitePending',
  userProviders = 'userProviders',
  staffProvider = 'staffProvider',
  client = 'client',
  invites = 'invites',
  invitedClient = 'invitedClient',
  clientCount = 'clientCount',
  clients = 'clients',
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}`,
    prepareHeaders: getAuthHeader,
  }),
  tagTypes: [
    TagTypes.user,
    TagTypes.report,
    TagTypes.finding,
    TagTypes.action,
    TagTypes.organism,
    TagTypes.survey,
    TagTypes.diff,
    TagTypes.invite,
    TagTypes.invitePending,
    TagTypes.userProviders,
    TagTypes.staffProvider,
    TagTypes.client,
    TagTypes.invites,
    TagTypes.invitedClient,
    TagTypes.clientCount,
    TagTypes.clients,
  ],
  endpoints: () => ({}),
});
