import { useLocation, useNavigate } from 'react-router-dom';

interface UseQueryHook {
  (): [URLSearchParams, (key: string, value: string | null) => void];
}

const useQuery: UseQueryHook = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const setQueryParam = (key: string, value: string | null) => {
    if (value === null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    const newSearch = searchParams.toString();
    navigate(`${location.pathname}?${newSearch}`);
  };

  // Explicitly return a tuple
  return [searchParams, setQueryParam];
};

export default useQuery;
