import { createStyles } from '@mantine/core';
import ActionImpactGroupItem from '../ActionImpactGroupItem';

interface Props {
  action: any;
}

export default function HighestImpactAction({ action }: Props) {
  const { classes } = useStyles();

  return <ActionImpactGroupItem key={action.name} {...action} />;
}

const useStyles = createStyles((theme) => ({
  itemCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderColor: 'white !important',
  },
  itemCardBlue: {
    backgroundColor: '#c4d6ed5f',
    borderColor: `${theme.colors.blue[3]} !important`,
    borderRadius: '15px',
  },
  itemCardYellow: {
    backgroundColor: '#E0D3BA3F',
    borderColor: `#AD8323 !important`,
    borderRadius: '15px',
  },
  list: {
    color: 'white',
  },
  paper: {
    // backgroundColor: 'rgba(4, 74, 126, 0.05)',
    backgroundColor: 'rgba(4, 74, 126, 0.05)',
    borderColor: `${theme.colors.gray[4]} !important`,
    borderRadius: '7px',
  },
  reccomendationsBadge: {
    color: 'white',
    backgroundColor: '#AD8323',
    // backgroundColor: theme.colors.yellow[8],
    opacity: '75%',
  },
}));
