import { Container, type ContainerProps } from '@mantine/core';

const PageContainer: React.FC<ContainerProps> = ({ children, ...rest }) => (
  <Container
    size="auto"
    {...rest}
    sx={(theme) => ({
      padding: 0,
      paddingTop: theme.spacing.lg,
      marginBottom: theme.spacing.md,
      [theme.fn.largerThan('md')]: {
        paddingTop: theme.spacing.xl,
      },
    })}
  >
    {children}
  </Container>
);
export default PageContainer;
