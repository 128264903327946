import React, { useState } from 'react';
import {
  Navbar as MantineNavbar,
  type NavbarProps as MantineNavbarProps,
  Group,
  Text,
  Divider,
  Select,
  ScrollArea,
} from '@mantine/core';
import useNavbarStyles from './useNavbarStyles';
import { LinksGroup } from './LinksGroup';
import { Link, useNavigate } from 'react-router-dom';
import { JonaEmblemBlackCircleIcon } from 'ui/Logo';
import NavbarLoading from './NavbarLoading';
import { NavItem } from 'appConfig';
import { VARIANT } from 'ui/Logo/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { UserMode, setUserMode } from 'slices/app';
import {
  MY_ACCOUNT_PERSONAL,
  MY_ACCOUNT_PERSONAL_ASSIGNEDSAMPLES_ENABLED,
  MY_ACCOUNT_PROVIDER,
  MY_CLIENTS,
  MY_CLIENT_KITS,
  MY_KITS,
} from 'appConfig/navbarConfig';
import { UserReport } from 'types/V2Types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppDispatch, useAppSelector } from 'hooks';

interface NavbarProps extends Omit<MantineNavbarProps, 'children'> {
  loading?: boolean;
  isProvider?: boolean;
  userReports?: UserReport[];
  clientUserReports?: UserReport[];
  clientName?: string;
}

const Navbar: React.FC<NavbarProps> = ({
  loading,
  isProvider = false,
  userReports,
  clientName,
  clientUserReports,
  className,
  ...props
}) => {
  props.width = { sm: 240 };
  const { classes, cx } = useNavbarStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const flags = useFlags();

  const { currentClientId, currentClientName, currentClientKits } = useAppSelector((state) => state.clientKit);
  const { firstName } = useAppSelector((state) => state.user);
  const { userMode } = useAppSelector((state) => state.app);

  // sort user reports by created_at date
  const sorted_userReports = userReports && [...userReports].sort(sortReports);
  const sorted_clientUserReports = currentClientKits && [...currentClientKits].sort(sortReports);

  const myKits = sorted_userReports && MY_KITS(sorted_userReports, flags['useAssignedSamples']);
  const myClientKits =
    sorted_clientUserReports && currentClientName !== undefined && currentClientId !== undefined
      ? MY_CLIENT_KITS(currentClientId, currentClientName, sorted_clientUserReports)
      : null;

  const [openedKitLabel, setOpenedKitLabel] = useState('');

  const toggelOpenLinkGroup = (openId: string) => {
    openId === openedKitLabel ? setOpenedKitLabel('') : setOpenedKitLabel(openId);
  };

  const getLinks = (data: NavItem[]) =>
    data
      .filter((item) => !item.hidden)
      .map((item, index) => (
        <LinksGroup
          key={item.label + index}
          {...item}
          opened={openedKitLabel === item.label + index}
          openId={item.label + index}
          toggleOpen={toggelOpenLinkGroup}
        />
      ));

  if (loading) {
    return <NavbarLoading className={className} {...props} />;
  }

  const PERSONAL_MODE_LINKS = flags['useAssignedSamples']
    ? MY_ACCOUNT_PERSONAL_ASSIGNEDSAMPLES_ENABLED
    : MY_ACCOUNT_PERSONAL;

  return (
    <MantineNavbar hiddenBreakpoint="sm" hidden className={cx(classes.navbar, className)} {...props}>
      <div className={classes.header}>
        <Group align="left" className={classes.header}>
          <div className={classes.logo}>
            <Link to="/">
              <JonaEmblemBlackCircleIcon variant={VARIANT.LIGHT} size={26} />
            </Link>
          </div>
          {firstName && (
            <Text ff="sora" fs="xl" fw={400} mt="sm">
              Welcome back, {firstName}.
            </Text>
          )}

          {/* if the user is a provider give option to set provider mode or personal mode */}
          {isProvider && (
            <Select
              className={classes.selectRole}
              rightSection={<FontAwesomeIcon icon={faChevronDown} />}
              rightSectionWidth={30}
              styles={{
                rightSection: { pointerEvents: 'none' },
                input: {
                  height: 24,
                },
              }}
              data={[UserMode.PROVIDER, UserMode.PERSONAL]}
              defaultValue={userMode}
              onChange={(value: UserMode) => {
                dispatch(setUserMode(value));
                navigate('/');
              }}
            />
          )}
        </Group>
      </div>

      <ScrollArea className={classes.navbarMain}>
        {userMode === UserMode.PROVIDER && (
          <div className={classes.navbarSection}>
            <Divider className={classes.navbarSectionLabel} label={<Text>My Clients</Text>} />
            {getLinks(MY_CLIENTS)}
          </div>
        )}

        {userMode === UserMode.PROVIDER && (
          <div className={classes.navbarSection}>
            <Divider className={classes.navbarSectionLabel} label={<Text>Client Reports</Text>} />
            {myClientKits?.length ? (
              getLinks(myClientKits)
            ) : (
              <Text fs="italic" size="xs">
                Select client to explore reports
              </Text>
            )}
          </div>
        )}

        {userMode === UserMode.PERSONAL && myKits?.length && (
          <div className={classes.navbarSection}>
            <Divider className={classes.navbarSectionLabel} label={<Text>My Reports</Text>} />
            {getLinks(myKits)}
          </div>
        )}
      </ScrollArea>

      {userMode === UserMode.PROVIDER && (
        <div className={classes.navbarSection}>
          <Divider className={classes.navbarSectionLabel} label={<Text>My Account</Text>} />
          {getLinks(MY_ACCOUNT_PROVIDER)}
        </div>
      )}

      {userMode === UserMode.PERSONAL && (
        <div className={classes.navbarSection}>
          <Divider className={classes.navbarSectionLabel} label={<Text>My Account</Text>} />
          {getLinks(PERSONAL_MODE_LINKS)}
        </div>
      )}
    </MantineNavbar>
  );
};

export default Navbar;

// helper function to sort reports by created_at date
function sortReports(a: UserReport, b: UserReport) {
  return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
}
