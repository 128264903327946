import { Stack, Text, StackProps } from '@mantine/core';

interface RowTitleProps extends StackProps {
  label: string;
  value: React.ReactNode;
}

const RowTitle: React.FC<RowTitleProps> = ({ label, value, ...props }) => (
  <Stack spacing="xs" {...props}>
    <Text fw={600} size="sm">
      {label}
    </Text>
    {value}
  </Stack>
);
export default RowTitle;
