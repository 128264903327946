import { SelectItem } from '@mantine/core';
import { emptySplitApi } from './emptyApi';

const intakeApi = emptySplitApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getIntakeConditions: builder.query<SelectItem, void>({
      query: () => `/api/intake/conditions`,
    }),
    getIntakeSymptoms: builder.query<SelectItem, void>({
      query: () => `/api/intake/symptoms`,
    }),
    getIntakeSensitivities: builder.query<SelectItem, void>({
      query: () => `/api/intake/sensitivities`,
    }),
    getIntakeTreatments: builder.query<SelectItem, void>({
      query: () => `/api/intake/treatments`,
    }),
    getIntakeDiets: builder.query<SelectItem, void>({
      query: () => `/api/intake/diets`,
    }),
    getIntakeMetabolisms: builder.query<SelectItem, void>({
      query: () => `/api/intake/metabolisms`,
    }),
    getIntakeWorkouts: builder.query<SelectItem, void>({
      query: () => `/api/intake/workouts`,
    }),
  }),
});

export default intakeApi;
