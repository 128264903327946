import {
  Box,
  Button,
  Flex,
  Input,
  MultiSelect,
  PinInput,
  Select,
  SelectItem,
  TextInput,
  Textarea,
  createStyles,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Row from 'components/Row';
import intakeApi from 'services/intake';
import userApi from 'services/user';
import * as Constants from 'types/intakeConstants';
import { IntakeKitRegistration } from 'types/intakeV2';
import Card from 'components/Card';
import CustomSelectItem from './components/CustomSelectItem';
import sampleApi from 'services/sample';

const kitRegistrationSchema = Yup.object().shape({
  barcode: Yup.string()
    .matches(/^\w{6}$/, 'Please enter a valid kit code')
    .required('Please enter a valid kit code'),
  sampleDate: Yup.date().required('Required field'),
  menstrationOrPregnant: Yup.array().min(1, 'Required field'),
  menstrationOrPregnant_other: Yup.string(),

  diagnosedClinicalConditionsLast6Months: Yup.array().min(1, 'Required field'),
  diagnosedClinicalConditionsLast6Months_other: Yup.string(),
  diagnosedClinicalConditionsMostConcerned: Yup.string(),
  conditionSeverity: Yup.string(),

  symptomsLast6Months: Yup.array().min(1, 'Required field'),
  symptomsLast6Months_other: Yup.string(),
  symptomsMostConcerned: Yup.string(),
  symptomsSeverity: Yup.string(),
  symptomsBeganAfter: Yup.string(),

  wouldLikeToLearn: Yup.array().min(1, 'Required field'),
  wouldLikeToLearn_other: Yup.string(),

  referredBy: Yup.array().min(1, 'Required field'),
  referredBy_other: Yup.string(),

  provider: Yup.string(),

  giSymptomImpact: Yup.array().min(1, 'Required field'),
  giSymptomImpact_other: Yup.string(),

  biggestHealthGoal: Yup.string().required('Required field'),
  biggestHeathGoal_other: Yup.string(),

  foodSensitivitiesOrAllergies: Yup.array().min(1, 'Required field'),
  foodSensitivitiesOrAllergies_other: Yup.string(),
});

export default function HealthGoals() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { barcode } = useParams() as { barcode: string };

  const form = useForm({
    initialValues: new IntakeKitRegistration(),
    validate: yupResolver(kitRegistrationSchema),
  });
  const [updateIntake] = sampleApi.endpoints.updateSampleIntake.useMutation();
  const { data, isLoading } = sampleApi.endpoints.getSampleIntake.useQuery({ barcode });
  const { data: conditionsData = [] } = intakeApi.endpoints.getIntakeConditions.useQuery();
  const { data: symptomsData = [] } = intakeApi.endpoints.getIntakeSymptoms.useQuery();
  const { data: sensitivitiesData = [] } = intakeApi.endpoints.getIntakeSensitivities.useQuery();

  // Reset values based on "N/A" responses
  useEffect(() => {
    // If the user selects "N/A" for a condition, clear the most concerned condition
    if (
      form.values.diagnosedClinicalConditionsLast6Months.includes(Constants.NAResponse.value) &&
      form.values.diagnosedClinicalConditionsMostConcerned !== ''
    ) {
      form.setFieldValue('diagnosedClinicalConditionsMostConcerned', '');
    }

    // If the user selects "N/A" for a symptom, clear the most concerned symptom
    if (
      form.values.symptomsLast6Months.includes(Constants.NAResponse.value) &&
      form.values.symptomsMostConcerned !== ''
    ) {
      form.setFieldValue('symptomsMostConcerned', '');
    }
  }, [form.values]);

  useEffect(() => {
    if (!data) return;

    if (!isLoading) {
      form.setValues({
        ...data.kit_registration,
        sampleDate: data.kit_registration?.sampleDate ? new Date(data.kit_registration.sampleDate) : new Date(),
        barcode: barcode,
      });
    }
  }, [isLoading]);

  const CONDITIONS = Constants.IntakeConditions(conditionsData as SelectItem[]);
  const SYMPTOMS = Constants.IntakeSymptoms(symptomsData as SelectItem[]);
  const SENSITIVITIES = Constants.IntakeSensitivities(sensitivitiesData as SelectItem[]);

  async function handleSubmit(values: any) {
    values.completed = true;

    if (data) {
      const update = { ...data, kit_registration: values };

      await updateIntake({ intake: update, barcode });
      navigate(`/reports/${barcode}/intake`); // go back to keep user's place in the flow
    }
  }

  const findDrowdownValues = (values: string[], data: SelectItem[]) =>
    values.map((item) => data.find((c) => c.value === item) || { label: item, value: item });

  return (
    <Card>
      <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <Input.Wrapper
          label="Enter the 8-digit code from the test tube or upper right corner of the JONA kit box."
          description=" (example: 00123456)"
        >
          <Flex gap="sm" justify="center">
            <PinInput mt="sm" size="xl" length={2} className={classes.input} inputType="text" value="00" disabled />
            <PinInput
              name="barcode"
              mt="sm"
              size="xl"
              length={6}
              className={classes.input}
              inputType="text"
              {...form.getInputProps('barcode')}
              defaultValue={barcode}
            />
          </Flex>
        </Input.Wrapper>

        <DateInput
          id="sampleDate"
          className={classes.input}
          label="When did you acquire your sample?"
          placeholder="Select the sample date..."
          {...form.getInputProps('sampleDate')}
        />
        <MultiSelect
          id="menstrationOrPregnant"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="At the time of sampling, did any of these apply?"
          placeholder="select all that apply..."
          data={Constants.IntakePregnancyStatus}
          {...form.getInputProps('menstrationOrPregnant')}
        />
        {Constants.ValueTriggersTextInput(form.values.menstrationOrPregnant) && (
          <TextInput
            className={classes.input}
            label="Please specify"
            placeholder=""
            {...form.getInputProps('menstrationOrPregnant_other')}
          />
        )}

        {/* CONDITIONS QUESTIONS  */}
        <MultiSelect
          id="diagnosedClinicalConditionsLast6Months"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Do you currently have any clinical conditions?"
          placeholder="Select all that apply..."
          data={CONDITIONS}
          {...form.getInputProps('diagnosedClinicalConditionsLast6Months')}
        />
        {(Constants.ValueTriggersTextInput(form.values.diagnosedClinicalConditionsLast6Months) ||
          Constants.ValueTriggersCancerInput(form.values.diagnosedClinicalConditionsLast6Months) ||
          Constants.ValueTriggersCVDInput(form.values.diagnosedClinicalConditionsLast6Months)) && (
          <Textarea
            className={classes.input}
            label="If applicable, please tell us about any additional conditions that you are currently suffering from."
            description="Name of condition, when it occurred, how long, how severe?"
            placeholder=""
            {...form.getInputProps('diagnosedClinicalConditionsLast6Months_other')}
          />
        )}
        {Constants.NAValueTriggersResponse(form.values.diagnosedClinicalConditionsLast6Months) && (
          <Select
            className={classes.input}
            label="Which condition are you most concerned about?"
            placeholder=""
            data={findDrowdownValues(form.values.diagnosedClinicalConditionsLast6Months, CONDITIONS)}
            {...form.getInputProps('diagnosedClinicalConditionsMostConcerned')}
          />
        )}
        {Constants.NAValueTriggersResponse(form.values.diagnosedClinicalConditionsLast6Months) && (
          <Select
            className={classes.input}
            label="On a scale of 'Mild', 'Moderate', and 'Severe', please rate the severity of your clinical condition(s) at the time of the test."
            placeholder="Mild/Moderate/Severe"
            data={Constants.IntakeSeverity}
            {...form.getInputProps('conditionSeverity')}
          />
        )}

        {/* SYMPTOMS QUESTIONS */}
        <MultiSelect
          id="symptomsLast6Months"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Which symptoms, if any, have you experienced within 6 months of your test?"
          data={SYMPTOMS}
          placeholder="select all that apply..."
          {...form.getInputProps('symptomsLast6Months')}
        />
        {Constants.ValueTriggersTextInput(form.values.symptomsLast6Months) && (
          <TextInput
            className={classes.input}
            label="If applicable, please tell us about any additional symptoms that you have experienced within the last 6 months."
            description="Name of symptom, when it occurred, how long, how severe?"
            placeholder=""
            {...form.getInputProps('symptomsLast6Months_other')}
          />
        )}
        {Constants.NAValueTriggersResponse(form.values.symptomsLast6Months) && (
          <Select
            className={classes.input}
            label="Which symptom are you most concerned about?"
            placeholder=""
            data={findDrowdownValues(form.values.symptomsLast6Months, SYMPTOMS)}
            {...form.getInputProps('symptomsMostConcerned')}
          />
        )}
        {Constants.NAValueTriggersResponse(form.values.symptomsLast6Months) && (
          <Select
            className={classes.input}
            label="On a scale of 'Mild', 'Moderate', and 'Severe', please rate the severity of your symtom(s) at the time of the test"
            data={Constants.IntakeSeverity}
            placeholder="Select your severity..."
            {...form.getInputProps('symptomsSeverity')}
          />
        )}
        {Constants.NAValueTriggersResponse(form.values.symptomsLast6Months) && (
          <Select
            className={classes.input}
            label="Did any of your symptoms start following?"
            placeholder=""
            data={Constants.IntakeSymptomsBeganAfter}
            {...form.getInputProps('symptomsBeganAfter')}
          />
        )}

        {/* SYMPTOM IMPACT QUESTIONS */}
        <MultiSelect
          id="giSymptomImpact"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          label="How do your symptoms negatively impact your day-to-day life?"
          placeholder="select all that apply..."
          itemComponent={CustomSelectItem}
          data={Constants.IntakeGISymptomImpacts}
          {...form.getInputProps('giSymptomImpact')}
        />
        {Constants.ValueTriggersTextInput(form.values.giSymptomImpact) && (
          <TextInput
            className={classes.input}
            label="Please specify how your symptoms negatively impact your day-to-day life."
            placeholder=""
            {...form.getInputProps('giSymptomImpact_other')}
          />
        )}
        <MultiSelect
          id="foodSensitivitiesOrAllergies"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Do you have any food sensitivities or allergies?"
          placeholder="select all that apply..."
          data={SENSITIVITIES}
          {...form.getInputProps('foodSensitivitiesOrAllergies')}
        />
        <Select
          id="biggestHealthGoal"
          searchable
          className={classes.input}
          label="What is your primary health goal?"
          placeholder=""
          data={Constants.IntakeBiggestHealthGoal}
          {...form.getInputProps('biggestHealthGoal')}
        />
        {Constants.ValueTriggersTextInput([form.values.biggestHealthGoal]) && (
          <TextInput
            className={classes.input}
            label="Please specify your biggest health goal."
            placeholder=""
            {...form.getInputProps('biggestHealthGoal_other')}
          />
        )}
        <MultiSelect
          id="wouldLikeToLearn"
          searchable
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          data={Constants.IntakeLearn}
          label="What do you want to learn the from a Microbiome Test?"
          itemComponent={CustomSelectItem}
          placeholder="select all that apply..."
          {...form.getInputProps('wouldLikeToLearn')}
        />
        {Constants.ValueTriggersTextInput(form.values.wouldLikeToLearn) && (
          <TextInput
            className={classes.input}
            label="Please specify what you would like to learn the most from a Microbiome Test."
            placeholder=""
            {...form.getInputProps('wouldLikeToLearn_other')}
          />
        )}
        <MultiSelect
          id="referredBy"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          data={Constants.IntakeReferrer}
          label="What brought you to JONA?"
          itemComponent={CustomSelectItem}
          placeholder="select all that apply..."
          {...form.getInputProps('referredBy')}
        />
        {Constants.ValueTriggersTextInput(form.values.referredBy) && (
          <TextInput
            className={classes.input}
            label="Please specify what brought you to JONA."
            placeholder=""
            {...form.getInputProps('referredBy_other')}
          />
        )}
        {Constants.ProviderValueTriggersTextInput(form.values.referredBy) && (
          <TextInput
            name="provider"
            className={classes.input}
            label="Please list your provider's name."
            placeholder="first and last..."
            {...form.getInputProps('provider')}
          />
        )}
        {Constants.ValueTriggersTextInput(form.values.foodSensitivitiesOrAllergies) && (
          <TextInput
            className={classes.input}
            label="Please specify your food sensitivities or allergies."
            placeholder=""
            {...form.getInputProps('foodSensitivitiesOrAllergies_other')}
          />
        )}
        <Row className={classes.buttonRow}>
          <Button fullWidth className={classes.button} type="submit">
            Submit
          </Button>
        </Row>
      </Box>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  input: {
    marginBottom: theme.spacing.lg,
  },
  buttonRow: {
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
  },
  value: {
    margin: theme.spacing.xs,
    textTransform: 'capitalize',
  },
  item: {
    textTransform: 'capitalize',
  },
}));
