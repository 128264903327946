import { createStyles } from '@mantine/core';

const useSectionStyles = createStyles((theme) => ({
  divider: {
    marginTop: theme.spacing.lg,
    marginBottom: theme.spacing.lg,
  },
  sectionTitle: {
    marginBottom: theme.spacing.md,
  },
  description: {
    marginTop: theme.spacing.md,
    marginBottom: theme.spacing.md,
    whiteSpace: 'pre-line',
  },
}));

export default useSectionStyles;
