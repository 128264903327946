import { FC } from 'react';
import { Text, Title } from '@mantine/core';
import { capitalize, getHealthGoalsLink } from 'utilsV2';
import SymptomBlock from 'components/ReportV2/elements/SymptomBlock';
import type { FindingGoals } from 'types/V2Types';
import NoResearchBlock from '../elements/NoResearchBlock';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

export interface OverviewProps {
  barcode: string;
  userName?: string;
  goals: FindingGoals;
  useAssignedSamples: boolean;
}

const Overview: FC<OverviewProps> = ({ barcode, userName, goals, useAssignedSamples }) => {

  function renderPrimaryHealthGoal() {
    if (goals.primaryGoal?.overview) {
      return <SymptomBlock key="primaryGoal" barcode={barcode} symptom={goals.primaryGoal.overview} n={1} />;
    } else {
      return <NoResearchBlock findingName={goals.primaryGoal.name} n={1} />;
    }
  }

  function renderSecondaryHealthGoal() {
    if (goals.secondaryGoal?.overview) {
      return <SymptomBlock key="secondaryGoal" barcode={barcode} symptom={goals.secondaryGoal.overview} n={2} />;
    }
  }

  const noGoals = !goals.primaryGoal && !goals.secondaryGoal;

  return (
    <>
      <Title mb="md" order={3} fw="500">
        {userName
          ? `Hi ${capitalize(userName)}, here are your top health goals.`
          : `Hi, here are your top health goals.`}
      </Title>
      {/* Health Goals Block  */}
      {noGoals ? (
        <Text mb="md">
          Please complete the <Link to={getHealthGoalsLink(barcode, useAssignedSamples)}>health survey</Link> to receive personalized insights based on your
          self-reported symptoms.
        </Text>
      ) : (
        <>
          <Text mb="md">
            Here are the microbiome symptom associations we identified based on your sequencing results and{' '}
            <Link to={getHealthGoalsLink(barcode, useAssignedSamples)}>Health Survey</Link>. Please note that this is not a medical diagnosis or
            professional recommendation. For guidance on the next steps, we advise consulting with your physician or a
            qualified nutritionist.
          </Text>

          {renderPrimaryHealthGoal()}
          {renderSecondaryHealthGoal()}
        </>
      )}
    </>
  );
};

export default Overview;
