import {
  Accordion,
  Badge,
  Box,
  BoxProps,
  Center,
  Grid,
  Group,
  List,
  Text,
  TextProps,
  UnstyledButton,
  createStyles,
} from '@mantine/core';
import { OrganismRange } from 'components/ReportV2/elements/Range';
import ResearchBadge from './ResearchBadge';
import { GuidanceString } from './GuidanceString';
import { type ReportOrganism } from 'types/V2Types';
import { FC } from 'react';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const percFormatter = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 4 });

interface OrganismsListProps extends BoxProps {
  organisms: ReportOrganism[];
  action?: string;
  association?: string;
  grayscale?: boolean;
  useRangeForColors?: boolean;
  useRelativeAbundanceForColor?: boolean;
  showResearchStatus?: boolean;
  showRank?: boolean;
  sortByRelativeAbundance?: () => void;
  sorted?: boolean;
  reversed?: boolean;
}

const OrganismsList: FC<OrganismsListProps> = ({
  organisms,
  action,
  association,
  grayscale,
  useRangeForColors = false,
  useRelativeAbundanceForColor = false,
  showResearchStatus = true,
  showRank = false,
  sortByRelativeAbundance,
  sorted,
  reversed,
  ...rest
}) => {
  const { classes } = useStyles();

  const icon = reversed ? faChevronUp : faChevronDown;

  if (organisms[0]?.research_status === undefined) {
    showResearchStatus = false;
  }

  let gridSpan = 3;
  if (!showResearchStatus) {
    gridSpan = 4;
  }

  const AccordionTitle: React.FC<TextProps> = ({ children, ...rest }) => (
    <Text className={classes.accordionTitle} {...rest}>
      {children}
    </Text>
  );

  return (
    <Box {...rest}>
      <Grid w="95%" fz="sm" mb="sm">
        <Grid.Col span={gridSpan}>
          <AccordionTitle>Organism Name</AccordionTitle>
        </Grid.Col>
        {showResearchStatus && (
          <Grid.Col span={gridSpan}>
            <AccordionTitle>Research Status</AccordionTitle>
          </Grid.Col>
        )}
        <Grid.Col span={gridSpan}>
          <UnstyledButton onClick={sortByRelativeAbundance} className={classes.sortButton}>
            <Group position="apart">
              <AccordionTitle>Relative Abundance</AccordionTitle>
              {sorted && (
                <Center>
                  <FontAwesomeIcon icon={icon} />
                </Center>
              )}
            </Group>
          </UnstyledButton>
        </Grid.Col>
        <Grid.Col span={gridSpan}>
          <AccordionTitle>General Population Percentile Range</AccordionTitle>
        </Grid.Col>
      </Grid>
      <Accordion
        radius="sm"
        variant="separated"
        classNames={{
          item: classes.accordionItem,
          content: classes.accordionContent,
          label: classes.accordionLabel,
        }}
      >
        {organisms.map(
          ({
            _id,
            name,
            description,
            range,
            guidance,
            research_status,
            relative_abundance,
            score,
            is_pathogen,
            is_probiotic,
            rank,
          }) => (
            <Accordion.Item key={_id} value={_id}>
              <Accordion.Control>
                <Grid align="center" fz="sm">
                  <Grid.Col span={gridSpan}>
                    <Text display="inline" fs="italic" fw="500">
                      {name}
                    </Text>
                    <Text>
                      {is_probiotic && (
                        <Badge color="gray" size="xs" variant="filled" mb="sm">
                          Probiotic
                        </Badge>
                      )}
                      {is_pathogen && (
                        <Badge color="red" size="xs" variant="filled" mb="sm">
                          Pathogen
                        </Badge>
                      )}
                      {showRank && (
                        <Badge color="blue" size="xs" variant="filled" mb="sm">
                          {rank}
                        </Badge>
                      )}
                    </Text>
                  </Grid.Col>
                  {showResearchStatus && research_status && (
                    <Grid.Col span={gridSpan}>
                      <ResearchBadge researchStatus={research_status} />
                    </Grid.Col>
                  )}
                  <Grid.Col span={gridSpan}>
                    <Text>{percFormatter.format(relative_abundance)}</Text>
                  </Grid.Col>
                  <Grid.Col span={gridSpan}>
                    <Box>
                      <OrganismRange
                        guidance={guidance}
                        range={range}
                        relative_abundance={relative_abundance}
                        score={score}
                        grayscale={grayscale}
                        is_pathogen={is_pathogen}
                        useRangeForColors={useRangeForColors}
                        useRelativeAbundanceForColor={useRelativeAbundanceForColor}
                      />
                    </Box>
                  </Grid.Col>
                </Grid>
              </Accordion.Control>
              <Accordion.Panel>
                <Box>
                  <Text fz="xs" w="70%" className={classes.collapseChild}>
                    <List size="xs" fs="italic" mb="md">
                      {!!action && (
                        <List.Item mb="sm">
                          Research suggests {action} can{' '}
                          <Text span fw="700">
                            {score < 0 ? 'decrease' : 'increase'}
                          </Text>{' '}
                          the abundance of {name}
                        </List.Item>
                      )}
                      {!!association && (
                        <List.Item mb="sm">
                          <Text span fw="700">
                            {score < 0 ? 'Lower' : 'Higher'} levels
                          </Text>{' '}
                          are linked to {association}.
                        </List.Item>
                      )}
                      <List.Item>
                        <GuidanceString guidance={guidance} />
                      </List.Item>
                    </List>
                    <Text pb="md">{description}</Text>
                  </Text>
                </Box>
              </Accordion.Panel>
            </Accordion.Item>
          )
        )}
      </Accordion>
    </Box>
  );
};

export default OrganismsList;

const useStyles = createStyles((theme) => ({
  accordionTitle: {
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
  },
  accordionItem: {
    margin: `${theme.spacing.sm} 0px`,
    border: '1px solid white',
    backgroundColor: 'rgba(248, 248, 248, 0.6)',

    '&[data-active="true"]': {
      border: '1px solid rgba(104, 224, 131, 0.5)',
    },
  },
  accordionLabel: {
    padding: `${theme.spacing.sm} ${theme.spacing.lg} ${theme.spacing.sm} ${theme.spacing.sm}`,
  },
  accordionContent: {
    padding: `${theme.spacing.sm} ${theme.spacing.xl}`,
  },
  collapseChild: {
    whiteSpace: 'pre-line',
  },
  sortButton: {
    width: '100%',
    paddingRight: '24px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.colors.gray[7],
    },
  },
}));
