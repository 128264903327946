import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type PaperProps, Paper, Grid, Text, createStyles, Flex } from '@mantine/core';
import Label from 'components/Label';
import { IntakeStep, IntakeTitles } from 'types/intakeV2';

interface IntakeTaskPaperProps extends PaperProps {
  item: IntakeStep;
  onClick: (step: IntakeStep) => void;
  isCompleted: (step: IntakeStep) => boolean;
  isOptional?: boolean;
}

const TaskListItem: React.FC<IntakeTaskPaperProps> = ({ onClick, isCompleted, item, isOptional, ...props }) => {
  const { classes } = useStyles();
  const _isCompleted = isCompleted(item);
  return (
    <Paper
      onClick={() => onClick(item)}
      className={_isCompleted ? classes.completeTask : isOptional ? classes.optionalTodoTask : classes.requiredTodoTask}
      radius="md"
      p="md"
      {...props}
    >
      <Grid align="center" justify="center">
        <Grid.Col span={10}>
          <Flex gap="sm" align="center">
            <Text>{IntakeTitles[item]}</Text>
            {isOptional && <Label>Optional</Label>}
          </Flex>
        </Grid.Col>
        <Grid.Col span={1} offset={1}>
          {_isCompleted && <FontAwesomeIcon id={`${item}-task-item-complete`} color="green" icon={faCheck} />}
        </Grid.Col>
      </Grid>
    </Paper>
  );
};

export default TaskListItem;

const useStyles = createStyles((theme) => ({
  completeTask: {
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: theme.colors.green[0],
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.colors.green[4],
  },
  requiredTodoTask: {
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: theme.colors.red[0],
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.colors.red[4],
  },
  optionalTodoTask: {
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: theme.colors.gray[0],
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.colors.gray[4],
  },
}));
