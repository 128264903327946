import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type TableProps as MantineTableProps,
  createStyles,
  UnstyledButton,
  Text,
  Flex,
  Space,
  Menu,
} from '@mantine/core';
import React, { useState } from 'react';
import { SelectorIcon } from '../Icons';

interface FilterItems {
  item: React.ReactNode;
  selected?: boolean;
  onClick: () => void;
}

export interface TableColumn {
  label: string | '_unset';
  sortOrder?: 'asc' | 'desc';
  sorted?: boolean;
  onSort?: () => void;
  filterOptions?: FilterItems[];
}

interface TableProps extends MantineTableProps {
  data: { [key: string]: string | React.ReactNode }[];
  columns: TableColumn[];
  boldColumns?: string[];
  rowCallbacks?: ((() => void) | undefined)[];
}

const Table: React.FC<TableProps> = ({ data, columns, boldColumns, rowCallbacks, ...props }) => {
  const { classes, cx } = useStyles();

  const [openMenu, setOpenMenu] = useState('');

  const Icon = ({ sorted, sortOrder }: { sorted: boolean; sortOrder: 'asc' | 'desc' }) =>
    sorted ? (
      sortOrder === 'asc' ? (
        <FontAwesomeIcon icon={faChevronUp} size="2xs" color="#A7A3A3" />
      ) : (
        <FontAwesomeIcon icon={faChevronDown} size="2xs" color="#A7A3A3" />
      )
    ) : (
      <SelectorIcon
        height="1em"
        width="1em"
        style={{ verticalAlign: '-0.125em', marginLeft: '-.15em' }}
        strokeWidth={2}
        stroke="#A7A3A3"
      />
    );

  return (
    <table {...props} className={classes.table}>
      <thead>
        <tr>
          {columns.map(({ label, sorted = false, sortOrder = 'asc', onSort, filterOptions }, columnIndex) =>
            onSort ? (
              <UnstyledButton
                key={columnIndex}
                component="th"
                className={classes.th}
                style={{ cursor: 'pointer' }}
                onClick={() => onSort()}
              >
                <Flex align="center">
                  <Text>{label}</Text>
                  <Space w="sm" />
                  <Icon sorted={sorted} sortOrder={sortOrder} />
                </Flex>
              </UnstyledButton>
            ) : filterOptions ? (
              <th key={columnIndex} className={classes.th}>
                <Menu
                  position="bottom-start"
                  closeOnItemClick={false}
                  onChange={() => setOpenMenu(label)}
                  onClose={() => setOpenMenu('')}
                >
                  <Menu.Target>
                    <UnstyledButton className={classes.th} style={{ cursor: 'pointer' }}>
                      <Flex align="center">
                        <Text>{label}</Text>
                        <Space w="sm" />
                        <FontAwesomeIcon
                          icon={openMenu === label ? faChevronUp : faChevronDown}
                          size="2xs"
                          color="#A7A3A3"
                        />
                      </Flex>
                    </UnstyledButton>
                  </Menu.Target>

                  <Menu.Dropdown>
                    {filterOptions.map((option, optionIndex) => (
                      <Menu.Item
                        key={optionIndex}
                        className={cx(classes.dropdownItem, option.selected && classes.dropdownItem_selected)}
                        onClick={option.onClick}
                      >
                        {option.item}
                      </Menu.Item>
                    ))}
                  </Menu.Dropdown>
                </Menu>
              </th>
            ) : (
              <th key={columnIndex} className={classes.th}>
                {label === '_unset' ? '' : label}
              </th>
            )
          )}
        </tr>
      </thead>

      <tbody className={classes.tbody}>
        {data.map((row, rowIndex) => {
          const rowContent = columns.map(({ label }, columnIndex) => (
            <td key={columnIndex} className={cx(classes.td, boldColumns?.includes(label) && classes.bold)}>
              {row[label]}
            </td>
          ));
          return !!rowCallbacks?.[rowIndex] ? (
            <UnstyledButton component="tr" key={rowIndex} className={classes.tr} onClick={rowCallbacks[rowIndex]}>
              {rowContent}
            </UnstyledButton>
          ) : (
            <tr key={rowIndex} className={classes.tr}>
              {rowContent}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const useStyles = createStyles((theme) => {
  const rowBorder = `1px solid #ffffff`;
  return {
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: `0 ${theme.spacing.lg}`,
      marginTop: '-24px',
      tableLayout: 'fixed',
    },

    tbody: {
      color: theme.colors.dark[4],
    },

    th: {
      fontWeight: 700,
      textAlign: 'left',
      textTransform: 'uppercase',
      color: theme.colors.dark[3],
      paddingLeft: theme.spacing.md,
      '&:first-of-type': {
        paddingLeft: '0',
      },
    },

    icon: {
      marginLeft: theme.spacing.xs,
    },

    tr: {
      backgroundColor: theme.colors.gray[0],
    },

    td: {
      maxWidth: '0',
      padding: theme.spacing.md,
      paddingTop: theme.spacing.lg,
      paddingBottom: theme.spacing.lg,
      minHeight: '77px',
      borderTop: rowBorder,
      borderBottom: rowBorder,
      '&:first-of-type': {
        borderTopLeftRadius: theme.radius.sm,
        borderBottomLeftRadius: theme.radius.sm,
        borderLeft: rowBorder,
      },
      '&:last-child': {
        borderTopRightRadius: theme.radius.sm,
        borderBottomRightRadius: theme.radius.sm,
        borderRight: rowBorder,
      },
    },

    bold: {
      fontWeight: 600,
    },

    dropdownItem: {
      padding: theme.spacing.sm,
      borderBottom: `1px solid ${theme.colors.gray[0]}`,
      cursor: 'pointer',
      '&[data-hovered]': {
        backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
        color: theme.white,
      },
      '&:last-of-type': {
        borderBottom: 'none',
      },
    },

    dropdownItem_selected: {
      backgroundColor: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      color: theme.white,
    },
  };
});

export default Table;
