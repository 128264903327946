import { Text } from '@mantine/core';

export default function FindingTooltip() {
  return (
    <Text>
      Jona screens each sample to identify any associations between your microbiome and the microbiome profiles found in
      individuals with specific conditions, symptoms, digestive issues, or pathogens, based on the existing scientific
      literature. We identified associations between your microbiome and the following profiles. These findings provide
      valuable insights into potential health considerations that require further exploration.
      <br />
      <br />
      This analysis of your microbiome does not represent and should not be interpreted as a clinical diagnosis. We
      advise working closely with your healthcare provider regarding any symptoms or conditions of concern.
    </Text>
  );
}
