import * as React from 'react';

interface IconProps extends React.SVGAttributes<SVGElement> {
  children?: never;
  size?: number;
  className?: string;
}

export const SparklingLineIcon = React.memo(
  React.forwardRef<SVGSVGElement, IconProps>(({ size, ...props }, forwardedRef) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        role="img"
        width={size}
        height={size}
        aria-label="Sparkling Line"
        ref={forwardedRef}
        viewBox="0 0 24 24"
        {...props}
      >
        <path d="M14 4.438A2.437 2.437 0 0 0 16.438 2h1.125A2.437 2.437 0 0 0 20 4.438v1.125A2.437 2.437 0 0 0 17.563 8h-1.125A2.437 2.437 0 0 0 14 5.563zM1 11a6 6 0 0 0 6-6h2a6 6 0 0 0 6 6v2a6 6 0 0 0-6 6H7a6 6 0 0 0-6-6zm3.876 1A8.04 8.04 0 0 1 8 15.124 8.04 8.04 0 0 1 11.124 12 8.04 8.04 0 0 1 8 8.876 8.04 8.04 0 0 1 4.876 12m12.374 2A3.25 3.25 0 0 1 14 17.25v1.5A3.25 3.25 0 0 1 17.25 22h1.5A3.25 3.25 0 0 1 22 18.75v-1.5A3.25 3.25 0 0 1 18.75 14z" />
      </svg>
    );
  })
);
SparklingLineIcon.displayName = 'SparklingLineIcon';
