import { Box, Button, Center, Flex, PinInput, createStyles, Text } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import userApi from 'services/user';

interface Props {
  postActivation: (barcode: string) => void;
}

const schema = Yup.object().shape({
  barcode: Yup.string().required('Please enter a valid barcode'),
});

export default function ActivateBarcodeForm({ postActivation }: Props) {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      barcode: '',
    },
    validate: yupResolver(schema),
  });

  const [activateBarcode] = userApi.endpoints.activateBarcode.useMutation();

  async function handleSubmit({ barcode }: { barcode: string }) {
    try {
      await activateBarcode({ barcode }).unwrap();
      postActivation(barcode);
    } catch (error: any) {
      console.error('Error activating barcode:', error);
      if (Number(error?.status) >= 400 && Number(error?.status) < 500) {
        form.setErrors({ barcode: 'Invalid Kit Code. Please try another Code.' });
      } else {
        form.setErrors({ barcode: 'An unexpected error occurred, please try again later.' });
      }
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)} className={classes.formContainer}>
      <Flex gap="sm" justify="center">
        <PinInput mt="sm" size="xl" length={2} className={classes.input} inputType="text" value="00" disabled />
        <PinInput
          mt="sm"
          size="xl"
          length={6}
          className={classes.input}
          inputType="text"
          onFocus={() => form.reset()}
          {...form.getInputProps('barcode')}
        />
      </Flex>
      <Text align="center">
        Your Kit Code is the 8-digit number that can be found on the upper right corner of your kit box or on the test
        tube.
      </Text>
      {form.errors.barcode && (
        <Box className={classes.errorBox}>
          <Center>{form.errors.barcode}</Center>
        </Box>
      )}
      <Button className={classes.button} type="submit" color="blue" disabled={!!form.errors.barcode}>
        Register
      </Button>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing.xl,
    paddingRight: theme.spacing.xl,
    paddingBottom: theme.spacing.sm,
    gap: theme.spacing.lg,
  },

  input: {
    marginBottom: theme.spacing.sm,
    alignItems: 'center',
  },
  button: {
    width: '200px',
  },
  errorBox: {
    color: theme.colors.red[8],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    backgroundColor: theme.colors.red[1],
    borderRadius: theme.radius.md,
    padding: theme.spacing.md,
    width: '100%',
  },
}));
