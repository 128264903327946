import * as React from 'react';

const SIZE_OPTIONS = {
  xs: 10,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
};

export enum VARIANT {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface IconProps extends React.SVGAttributes<SVGElement> {
  children?: never;
  'aria-label'?: string;
  size?: keyof typeof SIZE_OPTIONS | number;
  className?: string;
  variant?: VARIANT;
}

export const useIconProps = ({ size = 'md', variant = VARIANT.DARK, className, ...props }: IconProps) => {
  const numericSize = typeof size === 'string' ? SIZE_OPTIONS[size] : size;
  return { size: numericSize, variant, className, ...props };
};
