import { Button, Flex, PinInput, createStyles } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';

import userApi from 'services/user';

interface Props {
  postActivation: Function;
}

const schema = Yup.object().shape({
  barcode: Yup.string().required('Please enter a valid barcode'),
});

export default function ActivationForm({ postActivation }: Props) {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      barcode: '',
    },
    validate: yupResolver(schema),
  });

  const [addBarcode] = userApi.endpoints.addBarcode.useMutation();

  async function handleSubmit(values: any) {
    await addBarcode({
      barcode: values.barcode,
    });
    form.reset();
    postActivation();
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Flex gap="sm" justify="center">
        <PinInput mt="sm" size="xl" length={2} className={classes.input} inputType="text" value="00" disabled />
        <PinInput
          mt="sm"
          size="xl"
          length={6}
          className={classes.input}
          inputType="text"
          {...form.getInputProps('barcode')}
        />
      </Flex>
      <Flex pt="md">
        <Button className={classes.button} type="submit">
          Submit
        </Button>
      </Flex>
    </form>
  );
}

const useStyles = createStyles((theme) => ({
  input: {
    marginBottom: theme.spacing.sm,
    alignItems: 'center',
  },
  button: {
    width: '100%',
  },
}));
