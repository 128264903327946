import { Anchor, Badge, Flex, Stack, createStyles, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

import CategoryIcon from './CategoryIcon';
import TruncatedText from './TruncatedText';
import { InfoBadge } from '../Badges';
import { capitalize } from 'utilsV2';
import { ArrowUpRight } from '../Icons';
import Dots from 'components/ReportV2/elements/Dots';

interface AssociationCardProps {
  associationScore: number;
  category: string;
  detailsUrl: string;
  researchStatus: string;
  selfReported: boolean;
  title: string;
  variant: 'symptom' | 'condition';
}

function AssociationCard({
  associationScore,
  category,
  detailsUrl,
  researchStatus,
  selfReported,
  title,
  variant,
}: AssociationCardProps) {
  const { classes } = useStyles();

  let badges: string[] = [];

  if (selfReported) {
    badges.push('Self-reported');
  }

  return (
    <article>
      <Stack w="100%" spacing="md" className={classes.container} p="sm">
        <Flex justify="space-between">
          <Flex justify="flex-start">
            <Badge
              radius="0"
              size="sm"
              className="badge"
              styles={(theme) => ({
                root: {
                  background: theme.colors.gray[2],
                  textTransform: 'none',
                  fontWeight: 500,
                  fontSize: theme.fontSizes.xs,
                },
              })}
            >
              {capitalize(variant)}
            </Badge>
          </Flex>
          <Flex align='center' justify="flex-end">
            <Flex >
            {badges.map((badge) => (
                <InfoBadge infoBadgeVariant="gold" key={badge} mt={0} mr='xs'>
                  {badge}
                </InfoBadge>
              ))}
            </Flex>
            <Dots n={associationScore} withBorder/>
          </Flex>
        </Flex>
        <Flex gap="sm" align="center">
          <CategoryIcon className={classes.category} size="sm" category={category} mt={0} mr={0} />
          <TruncatedText className={classes.title} component="h3" fw={700} size="sm">
            {title}
          </TruncatedText>
        </Flex>

        <Flex justify="flex-end">
          <Anchor component={Link} color="blue.4" size="xs" to={detailsUrl}>
            View Analysis
            <Text inline span ml="xs" sx={{ position: 'relative', top: '4px' }}>
              <ArrowUpRight size="sm" />
            </Text>
          </Anchor>
        </Flex>
      </Stack>
    </article>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    backgroundColor: theme.white,
  },
  category: {
    backgroundColor: theme.colors.gray[2],
    width: 25,
    height: 25,
  },
  title: {
    padding: 0,
    margin: 0,
  },
}));

export default AssociationCard;
