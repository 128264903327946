import { Box, Flex, Grid, Group, Text, createStyles, type BoxProps } from '@mantine/core';
import NavButton from './NavButton';
import DiversityCard from './DiversityCard';
import PathogensCard from './PathogensCard';
import { PhylaCompositionCard } from './PhylaComposition/PhylaCompositionCard';
import { AlphaDiversityMetric, OrganismPhylaData } from 'types/V2Types';

interface ExploreCardProps extends BoxProps {
  alphaDiversity?: AlphaDiversityMetric;
  numberOfDetectedPathogens: number;
  organismsUrl: string;
  phylaData?: OrganismPhylaData;
}

const ExploreCard: React.FC<ExploreCardProps> = ({
  alphaDiversity,
  numberOfDetectedPathogens,
  organismsUrl,
  phylaData,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.exploreBlock} {...props}>
      {/* Header */}
      <Flex justify="space-between" align="center">
        <Text fw={700}>Explore Your Microbiome</Text>
        <NavButton to={organismsUrl} color="blue" compact>
          View Organisms
        </NavButton>
      </Flex>

      {/* Body */}
      <Grid gutter="md" mt="md">
        <Grid.Col xl={12}>
          <PhylaCompositionCard data={phylaData} />
        </Grid.Col>
        <Grid.Col xl={6}>
          <DiversityCard h="100%" diversity={alphaDiversity} />
        </Grid.Col>
        <Grid.Col xl={6}>
          <PathogensCard h="100%" numberOfPathogens={numberOfDetectedPathogens} />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
const useStyles = createStyles((theme) => ({
  exploreBlock: {
    backgroundColor: theme.colors.gray[0],
    padding: theme.spacing.lg,
    border: '1px solid #ffffff',
    width: 'auto',

    [`@media (min-width: ${theme.breakpoints.xl}px)`]: {
      height: 1000,
      '& > *': {
        height: '100%',
      },
    },
  },
}));
export default ExploreCard;
