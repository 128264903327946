import { faCheckCircle, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Center, Modal, Stack, Text } from '@mantine/core';
import Loading from 'components/Loading';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';
import userApi from 'services/user';

import { ModalType, closeModal } from 'slices/app';
import { RootState } from 'store';

export default function ClientInviteNotificationModal() {
  const dispatch = useAppDispatch();
  const { isClientInviteNotificationOpen, modalMessage } = useSelector((state: RootState) => state.app.modals);

  const { data: userStaffProviderData, isLoading: userStaffProviderIsLoading } =
    userApi.endpoints.getStaffProvider.useQuery();

  const isValidOrderUrl = userStaffProviderData?.orderUrl && userStaffProviderData?.orderUrl.startsWith('http');

  const handleClose = () => {
    dispatch(closeModal(ModalType.ClientInviteNotification));
  };

  return (
    <Modal
      centered
      opened={isClientInviteNotificationOpen}
      onClose={handleClose}
      closeOnEscape={false}
      closeOnClickOutside={false}
      size="lg"
      withCloseButton={false}
    >
      {userStaffProviderIsLoading ? (
        <Loading />
      ) : (
        <Stack m="xl">
          <Center mb="lg">
            <FontAwesomeIcon style={{ color: '#73BA72', marginRight: '24px', height: '38px' }} icon={faCheckCircle} />
            <Text size="xl" inline>
              {modalMessage}
            </Text>
          </Center>
          {!isValidOrderUrl && (
            <Stack>
              <Text size="sm" color="red" align="center">
                You do not have a valid order URL. Please contact{' '}
                <a href="mailto:support@jona.health">support@jona.health</a> to get the order link.
              </Text>
              <Button variant="light" onClick={handleClose} w={187} m="auto">
                Close
              </Button>
            </Stack>
          )}
          <Button
            disabled={!isValidOrderUrl}
            component="a"
            href={userStaffProviderData?.orderUrl}
            target="_blank"
            w={187}
            m="auto"
            onClick={handleClose}
            color="blue"
            leftIcon={<FontAwesomeIcon icon={faCirclePlus} />}
          >
            Order Kit
          </Button>
        </Stack>
      )}
    </Modal>
  );
}
