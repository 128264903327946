import { createStyles } from '@mantine/core';

const useListStyles = createStyles((theme) => ({
  list: {
    marginTop: theme.spacing.xs,
  },
  listItem: {
    padding: theme.spacing.sm,
    backgroundColor: theme.colors.gray[0],
    borderRadius: theme.radius.sm,
    border: '1px solid #fff',
  },
  listImgText: {
    flexWrap: 'nowrap',
  },
  listItemText: {
    fontSize: theme.fontSizes.xs,
  },
}));

export default useListStyles;
