import { Divider, List, Title } from '@mantine/core';

export default function Disclaimer() {
  return (
    <>
      <Divider my="xl" color="dark.1" />
      <Title order={6} mb="sm">
        Disclaimer
      </Title>
      <List fz="xs">
        <List.Item>
          This report is not a diagnosis. It is for informational purposes only, using the scientific literature to
          point out associations with your microbiome and existing scientific studies. This report is not intended to
          diagnose any conditions and should not be used to make any medical decisions. Consult with a healthcare
          professional if you are concerned about your likelihood of developing a condition, have a personal or family
          history of the condition, or before making any major lifestyle changes.
        </List.Item>
        <List.Item>
          These associations are determined by evaluating current research and may change over time to reflect the most
          up to date research available.{' '}
        </List.Item>
        <List.Item>
          Keep in mind that these reports do not include all possible organisms that could affect these conditions.
          Other factors can also affect your associations with these conditions, including your lifestyle, environment,
          genetics, medical history, and family history.
        </List.Item>
        <List.Item>
          If you have already been diagnosed with a condition by a healthcare professional, it is important to continue
          any treatment plans that have been prescribed, including medications and lifestyle modifications.
        </List.Item>
      </List>
    </>
  );
}
