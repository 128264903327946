import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// if storybook, we use an empty string for the API_URL
const API_URL =
  process.env.STORYBOOK === 'true'
    ? ''
    : process.env.NODE_ENV === 'production'
    ? window.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;
const MAINTENANCE_API = API_URL + '/api/dev/setting-sse/maintenance-status';

const REDIRECT_TO = '/maintenance';

const useMaintenanceRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const eventSource = new EventSource(MAINTENANCE_API);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.value === 'true') {
        navigate(REDIRECT_TO);
      }
    };

    eventSource.onerror = (error) => {
      console.error('Error with SSE:', error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [navigate]);
};

export default useMaintenanceRedirect;
