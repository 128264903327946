import { Card as MantineCard, CardProps, createStyles } from '@mantine/core';
import React from 'react';

interface Props extends CardProps {
  children: React.ReactNode;
  className?: string;
}

export default function Card({ children, className, ...rest }: Props) {
  const { classes, cx } = useStyles();

  return (
    <MantineCard className={cx(classes.label, className)} padding="lg" radius="lg" {...rest}>
      {children}
    </MantineCard>
  );
}

const useStyles = createStyles((theme) => ({
  label: {
    background: theme.colors.gray[1],
    border: '1px solid #fff',
    overflow: 'visible',
  },
}));
