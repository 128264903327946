import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Checkbox,
  NumberInput,
  Select,
  SelectItem,
  SimpleGrid,
  Text,
  Textarea,
  Tooltip,
  createStyles,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Row from 'components/Row';
import userApi from 'services/user';
import * as Constants from 'types/intakeConstants';
import Card from 'components/Card';
import intakeApi from 'services/intake';
import { IntakeLifestyle, IntakeNutrition } from 'types/intakeV2';

const lifestyleSchema = Yup.object().shape({
  // Nutrition
  fruits: Yup.boolean(),
  greensOrLeafyVegetables: Yup.boolean(),
  starchyVegetables: Yup.boolean(),
  grains: Yup.boolean(),
  redMeat: Yup.boolean(),
  fishOrSeafood: Yup.boolean(),
  fermentedFoods: Yup.boolean(),
  oliveOil: Yup.boolean(),
  dessertsOrSweets: Yup.boolean(),
  sweetenedBeverages: Yup.boolean(),
  dairyProducts: Yup.boolean(),
  nutritionNotes: Yup.string(),
  // Lifestyle
  smoker: Yup.string().required('Required field'),
  alcoholicBeveragesPerWeek: Yup.number().required('Required field'),
  recreationalDrugs: Yup.string().required('Required field'),
  exerciseFrequencyPerWeek: Yup.string().required('Required field'),
  exerciseType: Yup.string(),
  changeInPhysicalActivity: Yup.string().required('Required field'),
  changeInPhysicalActivity_other: Yup.string(),
  travelOutsideUSFrequency: Yup.string().required('Required field'),
  livingAreaClassification: Yup.string().required('Required field'),
  stressLevel: Yup.number().required('Required field'),
  hoursSleepPerNight: Yup.string().required('Required field'),
  averageSleepQuality: Yup.string().required('Required field'),
  lifestyleNotes: Yup.string(),
});

export default function LifestyleAndNutritionForm() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const nutritionValues = new IntakeNutrition();
  const lifestyleValues = new IntakeLifestyle();

  const form = useForm({
    initialValues: { ...nutritionValues, ...lifestyleValues },
    validate: yupResolver(lifestyleSchema),
  });
  const [updateIntake] = userApi.endpoints.updateIntakeV2.useMutation();
  const { data, isLoading } = userApi.endpoints.getUser.useQuery();
  const { data: workoutSelectItems = [] } = intakeApi.endpoints.getIntakeWorkouts.useQuery();

  useEffect(() => {
    if (!data?.intake_v2?.lifestyle || !data.intake_v2?.nutrition) return;

    if (!isLoading) {
      form.setValues({ ...data.intake_v2.lifestyle, ...data.intake_v2.nutrition });
    }
  }, [isLoading]);

  const alcoholTooltip: string =
    'Please note that a standard drink is typically equivalent to a 1/2 pint of beer, one glass of wine, or 1 single spirit.';

  const icon = (
    <Tooltip label={alcoholTooltip} withArrow multiline withinPortal width={250}>
      <FontAwesomeIcon icon={faInfoCircle} className={classes.tooltip} />
    </Tooltip>
  );

  const StressIcon = (
    <Tooltip
      label="Please rate your overall stress levels on a scale of 1 to 10, with 1 being the lowest and 10 being the highest."
      withArrow
      multiline
      withinPortal
      width={250}
    >
      <FontAwesomeIcon icon={faInfoCircle} className={classes.tooltip} />
    </Tooltip>
  );

  async function handleSubmit(values: any) {
    values.completed = true;

    let update = {};
    if (data) {
      update = { ...data.intake_v2, lifestyle: values, nutrition: values };
    } else {
      update = { lifestyle: values, nutrition: values };
    }

    await updateIntake({
      fields: { intake_v2: update },
    });
    navigate(-1); // go back to keep user's place in the flow
  }

  return (
    <Card>
      <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <Text fz="sm" fw="500">
          Which of the following foods do you consume regularly?
        </Text>
        <SimpleGrid cols={2} ml="md" mt="sm" mb="lg">
          {Constants.IntakeFoods.map((food, i: number) => (
            <Tooltip key={i} label={food.tooltip} disabled={food.tooltip === ''}>
              <Checkbox label={food.label} {...form.getInputProps(food.formValue, { type: 'checkbox' })} />
            </Tooltip>
          ))}
        </SimpleGrid>

        <Textarea
          className={classes.input}
          label="Is there anything else about your nutrition you want to tell us about?"
          minRows={2}
          {...form.getInputProps('nutritionNotes')}
        />
        <Select
          id="smoker"
          className={classes.input}
          label="Do you smoke (including chewing tobacco and vaping)?"
          data={Constants.YesNoInput}
          placeholder="Select answer..."
          {...form.getInputProps('smoker')}
        />
        <NumberInput
          name="alcoholicBeveragesPerWeek"
          className={classes.input}
          label="How many alcoholic beverages do you consume on average per week?"
          placeholder="Enter your weekly drinks..."
          rightSection={icon}
          {...form.getInputProps('alcoholicBeveragesPerWeek')}
        />
        <Select
          id="recreationalDrugs"
          className={classes.input}
          label="Do you use recreational drugs?"
          data={Constants.YesNoInput}
          placeholder="Select answer..."
          {...form.getInputProps('recreationalDrugs')}
        />
        <Select
          id="exerciseFrequencyPerWeek"
          className={classes.input}
          label="How many days per week do you engage in physical exercise for 30 minutes or more?"
          placeholder=""
          data={Constants.IntakeExerciseFrequency}
          {...form.getInputProps('exerciseFrequencyPerWeek')}
        />
        {form.values.exerciseFrequencyPerWeek !== 'Never' && (
          <Select
            className={classes.input}
            label="What kind of exercise do you engage in?"
            placeholder=""
            data={workoutSelectItems as SelectItem[]}
            {...form.getInputProps('exerciseType')}
          />
        )}
        <Select
          id="changeInPhysicalActivity"
          className={classes.input}
          label="Has your diet or physical activity significantly changed in the last 3 months?"
          placeholder=""
          data={Constants.YesNoOtherInput}
          {...form.getInputProps('changeInPhysicalActivity')}
        />
        {Constants.ValueTriggersTextInput([form.values.changeInPhysicalActivity]) && (
          <Textarea
            className={classes.input}
            label="Please explain how your diet or physical activity has changed in the last 3 months."
            placeholder=""
            {...form.getInputProps('changeInPhysicalActivity_other')}
          />
        )}
        <Select
          id="travelOutsideUSFrequency"
          className={classes.input}
          label="How frequently do you travel out of the US?"
          placeholder=""
          data={Constants.IntakeTravelOutsideUSFrequency}
          {...form.getInputProps('travelOutsideUSFrequency')}
        />
        <Select
          id="livingAreaClassification"
          className={classes.input}
          label="What kind of area do you live in?"
          placeholder=""
          data={Constants.IntakeAreaClassification}
          {...form.getInputProps('livingAreaClassification')}
        />
        <NumberInput
          name="stressLevel"
          className={classes.input}
          label="On a scale of 1 to 10, how would you rate your overall stress levels?"
          placeholder=""
          min={1}
          max={10}
          {...form.getInputProps('stressLevel')}
          rightSection={StressIcon}
        />
        <Select
          id="hoursSleepPerNight"
          className={classes.input}
          label="How many hours of sleep do you typically get per night?"
          placeholder=""
          data={Constants.IntakeSleepHours}
          {...form.getInputProps('hoursSleepPerNight')}
        />
        <Select
          id="averageSleepQuality"
          className={classes.input}
          label="What is your average sleep quality?"
          placeholder=""
          data={Constants.IntakeSleepQuality}
          {...form.getInputProps('averageSleepQuality')}
        />
        <Textarea
          className={classes.input}
          label="Anything you want us to know about your lifestyle?"
          placeholder=""
          {...form.getInputProps('lifestyleNotes')}
        />
        <Row className={classes.buttonRow}>
          <Button fullWidth className={classes.button} type="submit">
            Submit
          </Button>
        </Row>
      </Box>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  input: {
    marginBottom: theme.spacing.lg,
  },
  buttonRow: {
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
  },
  tooltip: {
    color: theme.colors.gray[5],
  },
}));
