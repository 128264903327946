import { FC } from 'react';
import { Badge, Group, Paper, Text } from '@mantine/core';
import useCustomAccordionStyles, { AccordionVariant } from './useCustomAccordionStyles';
import { ReportFinding } from 'types/V2Types';

export interface AssociatedFindingsCardProps {
  title: string;
  items: any[];
  description: string;
  displayBadge?: boolean;
  variant?: AccordionVariant;
}

/**
 * Displays the first three items with an option to display a badge
 */
const AssociatedFindingsCard: FC<AssociatedFindingsCardProps> = ({
  title,
  items,
  description,
  displayBadge = false,
  variant = AccordionVariant.Default,
}) => {
  const { classes } = useCustomAccordionStyles({ variant });
  return (
    <Paper className={classes.findingCard} withBorder>
      <Text className={classes.findingCard_title}>{title}</Text>
      {items.length ? (
        <Text className={classes.findingCard_description}>{description}</Text>
      ) : (
        <Text className={classes.findingCard_description}>
          We did not detect any self-reported or microbiome-associated symptoms.
        </Text>
      )}

      {items.slice(0, 3).map((item: any, index: number) => (
        <Group key={index} spacing="sm" className={classes.findingCard_listItem}>
          {displayBadge && <Badge className={classes.findingCard_listItem_badge}>{item.guidance}</Badge>}
          <Text className={classes.findingCard_listItem_text}>{item.preferred_name ?? item.name}</Text>
        </Group>
      ))}
    </Paper>
  );
};

AssociatedFindingsCard.defaultProps = {
  displayBadge: false,
  variant: AccordionVariant.Default,
};

export default AssociatedFindingsCard;
