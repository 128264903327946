import React from 'react';
import { Badge, type BadgeProps, Group, Text, Tooltip } from '@mantine/core';
import { SparklingLineIcon } from './SparklingLineIcon';

const SIZE_OPTIONS = {
  xs: 8,
  sm: 10,
  md: 13,
  lg: 16,
  xl: 24,
};

export interface JonaAiBadgeProps extends BadgeProps {}

const JonaAiBadge: React.FC<JonaAiBadgeProps> = ({ ...props }) => {
  const getIconSize = () => {
    const size = props.size ? props.size : 'md';
    return SIZE_OPTIONS[size as keyof typeof SIZE_OPTIONS];
  };
  return (
    <Tooltip label="Carefully selected by Jona AI">
      <Badge color="blue" radius="sm" variant="gradient" gradient={{ to: '#5e7db6', from: '#01235d' }} {...props}>
        <Group position="center" spacing="xs">
          <SparklingLineIcon size={getIconSize()} />
          <Text>Jona AI</Text>
        </Group>
      </Badge>
    </Tooltip>
  );
};

export default JonaAiBadge;
