import { PageContainer } from 'ui/Containers';
import providerApi from 'services/provider';
import { Error, ErrorPopup } from 'ui/ErrorPage';

import { PageHeader } from 'ui/PageHeader';
import Loading from 'components/Loading';
import { useNavigate, useParams } from 'react-router-dom';
import userApi from 'services/user';
import { EditClientForm } from 'ui/Jona-v1/Forms';
import { useAppDispatch } from 'hooks';
import { setCurrentClientName } from 'slices/clientKit';

export default function EditClient() {
  const { clientId } = useParams() as { clientId: string };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [saveClient, { error: saveClientError }] = providerApi.endpoints.saveClient.useMutation();

  const {
    data: userStaffProviderData,
    isLoading: userStaffProviderIsLoading,
    error: userStaffProviderError,
  } = userApi.endpoints.getStaffProvider.useQuery();
  const providerId = userStaffProviderData?.id || 0;
  const {
    data: clientData,
    isLoading: clientIsLoading,
    error: clientError,
  } = providerApi.endpoints.getClient.useQuery({ providerId, clientId }, { skip: !providerId || !clientId });

  const handleSaveClient = async (data: any) => {
    if (!providerId) {
      console.error('Provider ID not found');
      return;
    }
    try {
      await saveClient({ providerId, clientId, clientData: data }).unwrap();

      const clientName =
        data.firstName && data.lastName
          ? `${data.firstName} ${data.lastName}`
          : `Client ${clientData?.internalClientId || clientId}`;

      dispatch(setCurrentClientName(clientName));
      navigate(`/client/${clientId}`);
    } catch (error) {
      console.error('Error saving client:', error);
    }
  };

  const handleCancel = () => {
    navigate(`/client/${clientId}`);
  };

  if (userStaffProviderIsLoading || clientIsLoading) return <Loading />;

  if (userStaffProviderError) return <Error error={userStaffProviderError} />;
  if (clientError) return <Error error={clientError} />;

  return (
    <>
      <PageHeader title="Edit Client" />
      <PageContainer size="md">
        {saveClientError && <ErrorPopup error={saveClientError} />}
        {clientData && <EditClientForm client={clientData} onSaveClient={handleSaveClient} onCancel={handleCancel} />}
      </PageContainer>
    </>
  );
}
