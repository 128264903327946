import { createStyles } from '@mantine/core';

const useReportAccordionStyles = createStyles(() => ({
  accItem: {
    backgroundColor: 'rgba(203, 203, 203, 0.30)',
    borderColor: 'white !important',
    padding: '0 14px',
    '&[data-active]': { backgroundColor: 'rgba(203, 203, 203, 0.30)', borderColor: 'white' },
    '& + &': { marginTop: '12px' },
  },
  accChevron: {
    '& + .mantine-Accordion-label': {
      overflow: 'initial', // for tooltip
    },
  },
  itemCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderColor: 'white !important',
    height: '100%',
  },
}));

export default useReportAccordionStyles;
