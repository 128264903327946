import * as React from 'react';

const SIZE_OPTIONS = {
  xs: 10,
  sm: 16,
  md: 24,
  lg: 32,
  xl: 48,
};

export interface IconProps extends React.SVGAttributes<SVGElement> {
  children?: never;
  'aria-label'?: string;
  size?: keyof typeof SIZE_OPTIONS | number;
  className?: string;
}

export const useIconProps = ({ size = 'md', className, ...props }: IconProps) => {
  const numericSize = typeof size === 'string' ? SIZE_OPTIONS[size] : size;
  return { size: numericSize, className, ...props };
};
