import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Center, CenterProps, Stack, Title, Text, Button } from '@mantine/core';
import { Link } from 'react-router-dom';
import { BranchIcon } from 'ui/Jona-v1/Icons';

interface NoClientsProps extends Omit<CenterProps, 'children'> {
  label?: string;
  description?: string;
}

const NoClients: React.FC<NoClientsProps> = ({ label, description, ...props }) => {
  return (
    <Center mx="auto" {...props}>
      <Stack spacing="xs">
        <BranchIcon size={420} />
        <Title order={3} align="center" mb="md">
          {label || 'No clients yet'}
        </Title>
        <Text align="center">{description || 'Invite clients to see their microbiome reports.'}</Text>
        <Button component={Link} mx="auto" mt="xl" size="sm" w={246} color="blue" to="/add-client">
          <FontAwesomeIcon icon={faCirclePlus} />
          <Text inline ml="sm">
            Add New Client
          </Text>
        </Button>
      </Stack>
    </Center>
  );
};
export default NoClients;
