import { Group, Stack, Title, Text, Flex } from '@mantine/core';
import { FC } from 'react';
import useListStyles from './useListStyles';
import CloudfrontImage from 'ui/Image/CloudFrontImage';

export interface ListItem {
  name: string;
  description: string;
  imgPath: string;
}

export interface ListProps {
  items: ListItem[];
  imgGrayscale?: boolean;
}

const List: FC<ListProps> = ({ items, imgGrayscale = false }) => {
  const { classes } = useListStyles();
  return (
    <Stack className={classes.list} spacing="sm">
      {items.map(({ imgPath, name, description }: ListItem, index) => (
        <Group key={index} className={classes.listItem}>
          <Group className={classes.listImgText}>
            {imgPath && <CloudfrontImage src={imgPath} alt={name} width={40} height={50} grayscale={imgGrayscale} />}
            <Flex direction="column">
              <Title order={6}>{name}</Title>
              <Text className={classes.listItemText}>{description}</Text>
            </Flex>
          </Group>
        </Group>
      ))}
    </Stack>
  );
};

export default List;
