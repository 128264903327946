import { Box, createStyles } from '@mantine/core';
import React from 'react';

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler | undefined;
  className?: string;
}

export default function Container({ children, onClick, className }: Props) {
  const { classes, cx } = useStyles();
  return (
    <Box onClick={onClick} className={cx(classes.container, className)}>
      {children}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '608px',
    padding: theme.spacing.xl,
    border: `1px solid ${theme.colors.gray[3]}`,
    borderRadius: '8px',
    background: 'rgba(255,255,255,0.4)',

    '@media only screen and (max-width: 768px)': {
      width: '100%',
      padding: theme.spacing.lg,
    },
  },
}));
