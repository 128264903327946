import { Box, type BoxProps, Text, Flex, Stack, createStyles, Badge, BadgeProps, ScrollArea } from '@mantine/core';
import AnchorWithIcon from './AnchorWithIcon';
import CategoryIcon from './CategoryIcon';
import TruncatedText from './TruncatedText';
import { InfoBadge } from '../Badges';

export interface Article {
  _id: string;
  paper_title: string;
  publisher: string;
  url: string;
  association_score: number;
  self_reported: boolean;
  category: string;
}

interface TopArticlesProps extends BoxProps {
  articles?: Article[];
}

const TopArticles: React.FC<TopArticlesProps> = ({ articles = [], ...props }) => {
  const { classes } = useStyles();

  const ArticleBadge: React.FC<BadgeProps> = ({ children, ...props }) => (
    <Badge size="sm" radius="sm" variant="outline" color="gold" maw={240} {...props}>
      {children}
    </Badge>
  );

  return (
    <Box className={classes.topArticlesOverview} {...props}>
      {/* Header */}
      <Text fw={700}>Articles Relevant to Your Microbiome</Text>

      {/* Body */}
      <ScrollArea h={920} type="scroll" scrollbarSize={6} mt="md">
        {articles?.length > 0 ? (
          articles.map(({ paper_title, publisher, url, category }, index) => (
            <Stack key={index} w="100%" spacing="md" className={classes.articleItem} p="sm">
              <Flex>
                <CategoryIcon category={category} />
                <TruncatedText fw={700}>{paper_title}</TruncatedText>
              </Flex>
              <Flex justify="space-between" align="center">
                {publisher && (
                  <InfoBadge maw={300} infoBadgeVariant="gold">
                    {publisher}
                  </InfoBadge>
                )}
                <AnchorWithIcon href={url}>Read</AnchorWithIcon>
              </Flex>
            </Stack>
          ))
        ) : (
          <Stack mt="lg" spacing="sm">
            <Text fw={700}>No Top Articles</Text>
          </Stack>
        )}
      </ScrollArea>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  topArticlesOverview: {
    backgroundColor: theme.colors.gray[0],
    padding: theme.spacing.lg,
    maxHeight: 1000,
  },
  articleItem: {
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  customThemeIconColor: {
    backgroundColor: theme.colors.gray[2],
  },
}));

export default TopArticles;
