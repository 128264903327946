import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from 'store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { default as useAuthenticatedToken } from './useAuthenticatedToken';
export { default as useInviteNotifications } from './useInviteNotifications';
export { default as useMaintenanceRedirect } from './useMaintenanceRedirect';
export { default as useNotifications } from './useNotifications';
export { default as usePageMetadata } from './usePageMetadata';
export { default as useQuery } from './useQuery';
export { default as useRedirectIfMobile } from './useRedirectIfMobile';
export { default as useReportAccordionStyles } from './useReportAccordionStyles';
