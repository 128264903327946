import { Button, Center, Divider, Group, SimpleGrid, Spoiler, Text } from '@mantine/core';
import ActionsSection from 'components/ReportV2/elements/ActionsSection';
import LinkedSectionHead from 'ui/LinkedSectionHead';
import LiteratureAccordion from 'components/ReportV2/elements/LiteratureAccordion';
import OrganismCard from 'components/ReportV2/elements/OrganismCard';
import OrganismsList from 'components/ReportV2/elements/OrganismsList';
import PaperCard from 'components/ReportV2/elements/PaperCard';
import { BLOCK } from 'components/ReportV2/elements/constants';
import { countStr, getAssociationText } from 'utilsV2';
import { FINDING_KB, type ReportFinding } from 'types/V2Types';
import { unCapitalizeAll } from 'utils';
import { useDisclosure } from '@mantine/hooks';
import DescriptionsSection from 'components/SharedReport/DescriptionSection';
import { RefObject, useRef, useState } from 'react';

export interface FindingProps extends ReportFinding {
  barcode: string;
}

const Finding: React.FC<FindingProps> = ({
  finding_id,
  barcode,
  name,
  description,
  one_line_description,
  bullet_point_description,
  association_score,
  actions,
  organisms,
  papers,
  kb,
  topPapers,
  extended_guidance,
}) => {
  const [opened, { toggle }] = useDisclosure(false);

  const spoilerControlRef_organisms = useRef<HTMLButtonElement>(null);
  const spoilerControlRef_science = useRef<HTMLButtonElement>(null);
  const [openSpoilers, setOpenSpoilers] = useState<RefObject<HTMLButtonElement>[]>([]);
  const handleSpoilerButtonClick = (ref: React.RefObject<HTMLButtonElement>) => {
    if (ref?.current) {
      ref.current.click();
      if (openSpoilers.includes(ref)) {
        setOpenSpoilers(openSpoilers.filter((spoiler) => spoiler !== ref));
      } else {
        setOpenSpoilers([...openSpoilers, ref]);
      }
    }
  };

  const associationText: string =
    association_score > 0 ? `a ${unCapitalizeAll(getAssociationText(association_score))}` : 'no';

  const isMetabolism = kb === FINDING_KB.METABOLISMS;

  const SectionDivider = () => <Divider my="xl" color="dark.1" />;

  return (
    <>
      <Group position="apart">
        {!isMetabolism && (
          <Text fw="500" mt="sm">
            Your microbiome has {associationText} association with {unCapitalizeAll(name)}. This is not a clinical
            diagnosis but rather an insight derived from research into microbiome profiles from the available
            literature. Consult your doctor if you want to learn more about {unCapitalizeAll(name)}.
          </Text>
        )}

        {!one_line_description && (
          <Button
            ff="Sora"
            fz="xs"
            fw="500"
            compact
            size="xs"
            variant="outline"
            onClick={toggle}
            style={{ padding: '5px 8px' }}
          >
            {opened ? 'More' : 'Less'}
          </Button>
        )}
      </Group>

      <DescriptionsSection
        one_line_description={one_line_description}
        description={description}
        bullet_point_descriptions={bullet_point_description}
        opened={opened}
      />

      {/* ACTIONS SECTION  */}
      {/* ------------------ */}
      <SectionDivider />
      <ActionsSection
        name={name}
        finding_kb={kb}
        actions={actions}
        barcode={barcode}
        findingId={finding_id}
        isMetabolism={isMetabolism}
        extended_guidance={extended_guidance}
      />

      {/* ORGANISMS SECTION  */}
      {/* ------------------ */}
      <SectionDivider />
      <LinkedSectionHead
        title="Organisms"
        tooltip={BLOCK.ORGANISMS}
        tooltipDirection="bottom-right"
        spoilerControlRef={spoilerControlRef_organisms}
        openSpoilerClick={handleSpoilerButtonClick}
        spoilerIsOpen={openSpoilers.includes(spoilerControlRef_organisms)}
      />
      <Text mb="lg">
        We identified <strong>{countStr(organisms, 'organism', 'organisms')}</strong> in your sample that are associated
        with <strong>{name}</strong>. {organisms.length >= 3 ? ' Here are your top 3.' : ''}
      </Text>
      <SimpleGrid cols={3}>
        {organisms.slice(0, 3).map((organism: any, i: number) => (
          <OrganismCard key={i} {...organism} association={name.toLocaleLowerCase()} useRangeForColors={isMetabolism} />
        ))}
      </SimpleGrid>

      <Spoiler maxHeight={0} showLabel={false} hideLabel={false} controlRef={spoilerControlRef_organisms}>
        <OrganismsList
          mt="lg"
          organisms={organisms}
          association={name.toLocaleLowerCase()}
          useRangeForColors={isMetabolism}
        />
        <Center mt="lg">
          <Button
            compact
            size="xs"
            variant="outline"
            ref={spoilerControlRef_organisms}
            onClick={() => handleSpoilerButtonClick(spoilerControlRef_organisms)}
          >
            Hide all
          </Button>
        </Center>
      </Spoiler>

      {/* SCIENTIFIC EVIDENCE SECTION  */}
      {/* ------------------ */}
      <SectionDivider />
      <LinkedSectionHead
        title="Scientific evidence"
        tooltip={BLOCK.SCIENTIFIC_EVIDENCE}
        tooltipDirection="bottom-right"
        spoilerControlRef={spoilerControlRef_science}
        openSpoilerClick={handleSpoilerButtonClick}
        spoilerIsOpen={openSpoilers.includes(spoilerControlRef_science)}
      />
      <Text mb="lg">
        These are the two most important scientific papers Jona AI uses to find associations between your gut microbiome
        and <strong>{name}</strong>. The papers are ranked from the highest to lowest quality based on our proprietary
        scoring system.
      </Text>
      <SimpleGrid cols={2}>
        {topPapers?.map((paper: any, i: number) => (
          <PaperCard key={i} paper={paper} />
        ))}
      </SimpleGrid>

      <Spoiler maxHeight={0} showLabel={false} hideLabel={false} controlRef={spoilerControlRef_science}>
        <LiteratureAccordion pt="md" name={name} papers={papers} />
        <Center mt="lg">
          <Button
            compact
            size="xs"
            variant="outline"
            ref={spoilerControlRef_science}
            onClick={() => handleSpoilerButtonClick(spoilerControlRef_science)}
          >
            Hide all
          </Button>
        </Center>
      </Spoiler>
    </>
  );
};

export default Finding;
