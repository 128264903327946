import { Anchor, Box, Flex, Grid, Stack, Text, createStyles } from "@mantine/core";
import NavButton from "./NavButton";
import AssociationCard from "./AssociationCard";
import { useMemo, useState } from "react";
import { FindingsPrioritized, ReportFindingOverview } from "types/V2Types";
import { Link } from "react-router-dom";

function sortByAssociatonScoreAndAlphabetically(a: ReportFindingOverview, b: ReportFindingOverview) {
  if (a.association_score === b.association_score) {
    return a.preferred_name.toLowerCase().localeCompare(b.preferred_name.toLowerCase());
  }
  return b.association_score - a.association_score;
}

interface ExtendedReportFindingOverview extends ReportFindingOverview {
  variant: 'symptom' | 'condition';
}

interface AssociationFindings {
  intakePriority: ExtendedReportFindingOverview[];
  highPriority: ExtendedReportFindingOverview[];
}

function getFindingsInOrder(findings: AssociationFindings): ExtendedReportFindingOverview[] {
  // Associations order
  //   1. Associated + Self-reported (3 dots)
  const sorted = (findings?.intakePriority?.filter((finding) => finding.association_score === 3) ?? [])
    // 2. Associated (3 dots)
    .concat(findings?.highPriority ?? [])
    //  3. Self-reported (any dots)
    .concat(findings?.intakePriority?.filter((finding) => finding.association_score !== 3) ?? []);

  return sorted;
}

const MAX_TO_SHOW_BY_DEFAULT = 6;

interface KeyAssociationsProps {
  barcode: string;
  conditions: FindingsPrioritized | undefined;
  symptoms: FindingsPrioritized | undefined;
}

const KeyAssociations: React.FC<KeyAssociationsProps> = ({
  barcode,
  conditions,
  symptoms,
}) => {
  const { classes } = useStyles();
  
  const associations = useMemo(() => {
    const massagedFindings: AssociationFindings = {
      intakePriority: (conditions?.intakePriority.map((finding) => ({ ...finding, variant: 'condition' })) ?? []).concat(
        symptoms?.intakePriority.map((finding) => ({ ...finding, variant: 'symptom' })) ?? []
      ).sort(sortByAssociatonScoreAndAlphabetically) as ExtendedReportFindingOverview[],
      highPriority: (conditions?.highPriority.map((finding) => ({ ...finding, variant: 'condition' })) ?? []).concat(
        symptoms?.highPriority.map((finding) => ({ ...finding, variant: 'symptom' })) ?? []
      ).sort(sortByAssociatonScoreAndAlphabetically) as ExtendedReportFindingOverview[],
    }
    return getFindingsInOrder(massagedFindings);
  }, [conditions, symptoms]);
  
  const [isAllVisible, setAllVisible] = useState(associations.length > MAX_TO_SHOW_BY_DEFAULT ? false: true);

  const isAnyAssociationAvailable = associations.length > 0;
  const symptomsLink = `/reports/${barcode}/symptoms`
  const conditionsLink = `/reports/${barcode}/conditions`

  return (
    <Stack className={classes.wrapper} spacing="md">
      <Flex gap="xs" justify="space-between">
        <Box>
          <Text className={classes.text} component="h2" fw={700}>Your Microbiome Associations</Text>
          {isAnyAssociationAvailable && (
            <Text className={classes.text} component="p" fw={500} italic size="xs">
              Here is a summary of Jona&apos;s analysis of your microbiome and possible associations to symptoms and conditions.
            </Text>
          )}
        </Box>
        {isAnyAssociationAvailable && (
          <Flex align={"flex-start"} gap="sm">
            <NavButton to={symptomsLink} color="blue" compact>
              View Symptoms
            </NavButton>
            <NavButton to={conditionsLink} color="blue" compact>
              View Conditions
            </NavButton>
          </Flex>
        )}
      </Flex>
      {isAnyAssociationAvailable ? (
        <>
          <Grid>
            {associations.slice(0, isAllVisible ? undefined : MAX_TO_SHOW_BY_DEFAULT).map((finding) => (
              <Grid.Col md={6} lg={4} key={finding.finding_id}>
                <AssociationCard 
                  associationScore={finding.association_score}
                  category={finding.category ?? ''}
                  detailsUrl={`/reports/${barcode}/${finding.variant}s/${finding.finding_id}`}
                  researchStatus={finding.research_status}
                  selfReported={Boolean(finding.intakeConcern)}
                  title={finding.preferred_name}
                  variant={finding.variant}
                />
              </Grid.Col>
            ))}
          </Grid>
          <Flex justify="center">
            <Anchor component="button" type="button" color="blue.4" size="xs" onClick={() => setAllVisible(prev => !prev)}>
            {isAllVisible ? "View Less" : "View More"}
            </Anchor>
          </Flex>
        </>
      ) : (
        <Stack spacing="md" className={classes.noAssociations}>
          <Text className={classes.text} component="h3" fw={700} size="sm">
            No symptoms or conditions found
          </Text>
          <Text className={classes.text} component="p">
            We didn&apos;t find any strong associations with symptoms or conditions, and none were reported in your health survey. 
            Congrats! This means that your microbiome is healthy. Wondering which health conditions we assessed? 
            View your <strong><Anchor color="blue.4" component={Link} to={symptomsLink} state={{ openAccordions: true }}>Symptoms</Anchor></strong>&nbsp;
            and <strong><Anchor color="blue.4" component={Link} to={conditionsLink} state={{ openAccordions: true }}>Conditions</Anchor></strong> pages to see more.
          </Text>
          <Flex justify="flex-end">
            <NavButton to={`/reports/${barcode}/plan`} color="blue" compact>
              View Action Plan
            </NavButton>
          </Flex>
        </Stack>
      )}
    </Stack>
  )
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.colors.gray[0],
    padding: theme.spacing.lg,
    border: `1px solid ${theme.white}`,
  },
  text: {
    margin: 0,
    padding: 0,
  },
  noAssociations: {
    backgroundColor: theme.white,
    padding: theme.spacing.lg,
  }
}));

export default KeyAssociations