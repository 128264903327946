import { List, Stack, Text } from '@mantine/core';

const PathogensPopover = () => {
  return (
    <Stack fz="14px">
      <Text>
        Pathogens are microorganisms that cause disease and can infect the gut, leading to illness. Examples of common
        gut pathogens include:
      </Text>
      <List c="white" size="14px" spacing="0px">
        <List.Item>Bacteria: Salmonella, Clostridium difficile, Campylobacter, pathogenic E. coli</List.Item>
        <List.Item>Viruses: Norovirus, Rotavirus, Adenovirus</List.Item>
        <List.Item>Parasites: Giardia, Cryptosporidium, Entamoeba histolytica</List.Item>
        <List.Item>Fungi: Candida albicans</List.Item>
      </List>
      <Text>
        These pathogens can cause gastroenteritis, diarrhea, vomiting, and other symptoms when they overgrow in the gut.
        They are not normal, predominant resident microbes.
      </Text>
    </Stack>
  );
};

const OrganismsPopover = () => {
  return (
    <Stack fz="14px">
      <Text>
        <b>Above median</b> indicates your levels of an organism are{' '}
        <b>above the 50th percentile of the healthy population</b>, which means your levels are higher than{' '}
        <b>50% of healthy people</b>.
      </Text>
      <Text>
        <b>Below median</b> indicates your levels of an organism are{' '}
        <b>below the 50th percentile of the healthy population</b>, which means your levels are lower than{' '}
        <b>50% of healthy people</b>.
      </Text>
      <Text>
        Gut microbiome organisms refer to the normal commensal bacteria, viruses, fungi, and other microbes that inhabit
        the gut in a symbiotic relationship with the host. Examples of common commensal microbes include:
      </Text>
      <List c="white" size="14px" spacing="0px">
        <List.Item>Bacteria: Bacteroides, Bifidobacterium, Lactobacillus, Ruminococcus</List.Item>
        <List.Item>Archaea: Methanobrevibacter smithii</List.Item>
        <List.Item>Eukaryotes: Saccharomyces cerevisiae</List.Item>
        <List.Item>Viruses: Bacteriophages of gut bacteria</List.Item>
      </List>
      <Text>
        These organisms comprise the complex microbial ecosystem that maintains gut health when in balance. Altering the
        gut microbiota can impact gut barrier function, immunity, and metabolism.
      </Text>
    </Stack>
  );
};

const LifestylePopover = () => {
  return (
    <Stack fz="14px">
      <Text>Not all physical activity impacts your gut equally. Research shows:</Text>
      <Text>Aerobic Cardio Training exercise like running, biking and swimming:</Text>
      <List c="white" size="14px" spacing="0px">
        <List.Item>
          Increases short-chain fatty acid producing bacteria and overall alpha diversity more dramatically
        </List.Item>
        <List.Item>Leads to greater microbial richness and stability</List.Item>
        <List.Item>Provides more anti-inflammatory and metabolism-supporting influences</List.Item>
      </List>
      <Text>Strength/Resistance Training with weights, resistance bands, etc:</Text>
      <List c="white" size="14px" spacing="0px">
        <List.Item>Also enhances microbial diversity, but smaller shifts in composition</List.Item>
        <List.Item>Boosts gut barrier-protecting species like Lactobacillus</List.Item>
        <List.Item>Reduces presence of pathogenic bacteria proportionally</List.Item>
      </List>
    </Stack>
  );
};

const SupplementsPopover = () => {
  return (
    <Stack>
      <Text>
        Based on the low or high levels of specific organisms in your gut microbiome, we can identify specific species
        of organisms to adopt or avoid that will support your health.
      </Text>
      <Text>
        Our advice is formulated by systematically screening your microbiome biomarkers against our regularly updated
        knowledge base, aggregating prebiotic, probiotic, and supplement clinical trials. This evidence-based approach
        ensures that suggested interventions are tailored to your unique profile and supporting evidence from the
        literature is provided.
      </Text>
    </Stack>
  );
};

const MetabolismsPopover = () => {
  return (
    <Text>
      We use metagenomic sequencing to identify all the genes in your gut microbiome. This shows us the microbiome’s
      ability to process carbohydrates, fat, protein and other nutrients. We then match this information with scientific
      studies on how these organisms impact metabolite production. This matching provides a personalized look into how
      your microbiome may influence your metabolism.
    </Text>
  );
};

const NutritionPopover = () => {
  return (
    <Text>
      Based on your unique microbiome associations to symptoms, conditions, and metabolism, we identify specific diets
      and foods supported by scientific literature that can target and change the specific over/under abundance of
      organisms present in your gut. Changing the over/under abundance of these organisms in your gut may alter your
      microbiome risk profile for conditions, symptoms and metabolisms, with the overall goal of balancing and improving
      your microbiome health.
    </Text>
  );
};

const FoodTriggersPopover = () => {
  return (
    <Text>
      Your microbiome and metabolite composition are compared to rigorously curated literature on the microbiome and
      food reactions. This process reveals associations between food and microbes that are linked to antigens, glycans,
      and other compounds that may exacerbate conditions or symptoms.
    </Text>
  );
};

export {
  PathogensPopover,
  OrganismsPopover,
  LifestylePopover,
  SupplementsPopover,
  MetabolismsPopover,
  NutritionPopover,
  FoodTriggersPopover,
};
