import * as Yup from 'yup';
import {
  Text,
  Box,
  TextInput,
  createStyles,
  Button,
  Group,
  Textarea,
  Center,
  Input,
  Flex,
  PinInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import Card from 'components/Card';
import { useState } from 'react';

const addClientFormSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required').default(''),
  lastName: Yup.string().required('Last name is required').default(''),
  email: Yup.string().email('Invalid email').required('Email is required').default(''),
  internalClientId: Yup.string().default(''),
  internalNotes: Yup.string().default(''),
  clientPurchaseKit: Yup.boolean().required().default(false),
  kitId: Yup.string().default(''),
  invitationMessageCustom: Yup.string().required('Please provide an invitation note').default(''),
  invitationMessageReadOnly: Yup.string(),
});
export interface ClientData extends Yup.InferType<typeof addClientFormSchema> {}

const Yes: string = 'Yes';
const No = 'No';

interface AddClientFormProps {
  defaultInviteText?: string;
  inviteReadOnlyText?: string;
  orderAndInviteReadOnlyText?: string;
  onSendInvite: (data: ClientData) => void;
  onCancel: () => void;
}

export default function AddClientForm({
  defaultInviteText,
  inviteReadOnlyText,
  orderAndInviteReadOnlyText,
  onSendInvite,
  onCancel,
}: AddClientFormProps) {
  const { classes } = useStyles();

  const [hasKit, setHasKit] = useState<string | null>(null);
  const [clientWillOrder, setClientWillOrder] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      ...addClientFormSchema.getDefault(),
      clientPurchaseKit: false,
      invitationMessageCustom: defaultInviteText || '',
    },
    validate: yupResolver(addClientFormSchema),
  });

  const handleSubmit = (values: ClientData) => {
    values.clientPurchaseKit = clientWillOrder === Yes;
    values.invitationMessageReadOnly = clientWillOrder === Yes ? orderAndInviteReadOnlyText : inviteReadOnlyText;
    onSendInvite(values);
  };

  const CartTitle = ({ children }: { children: React.ReactNode }) => (
    <Text className={classes.cardTitle}>{children}</Text>
  );

  return (
    <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
      <CartTitle>client info</CartTitle>
      <Card className={classes.card}>
        <TextInput label="First name" {...form.getInputProps('firstName')} />
        <TextInput label="Last name" {...form.getInputProps('lastName')} />
        <TextInput label="Email" {...form.getInputProps('email')} />
        <TextInput
          label={
            <Text>
              Client ID{' '}
              <Text ml="sm" span c="dimmed">
                optional
              </Text>
            </Text>
          }
          {...form.getInputProps('internalClientId')}
        />
        <Textarea
          label={
            <Text>
              Internal Notes{' '}
              <Text ml="sm" span c="dimmed">
                optional
              </Text>
            </Text>
          }
          {...form.getInputProps('internalNotes')}
        />
      </Card>

      <CartTitle>kit order & registration</CartTitle>
      <Card className={classes.card}>
        <Text>Do you already have a kit to give the client?</Text>
        <Group mt="sm">
          <Button onClick={() => setHasKit(Yes)} w="120px" variant={hasKit === Yes ? 'filled' : 'outline'}>
            Yes
          </Button>
          <Button
            onClick={() => setHasKit(No)}
            w="120px"
            variant={hasKit === No ? 'filled' : 'outline'}
            {...form.getInputProps('internalNotes')}
          >
            No
          </Button>
        </Group>

        {hasKit === Yes && (
          <Input.Wrapper label="Please note the kit ID" description=" (example: 00123456)">
            <Flex gap="sm">
              <PinInput mt="sm" size="xl" length={2} inputType="text" value="00" disabled />
              <PinInput name="barcode" mt="sm" size="xl" length={6} inputType="text" {...form.getInputProps('kitId')} />
            </Flex>
          </Input.Wrapper>
        )}

        {hasKit === No && (
          <>
            <Text>Will you be ordering the kit on behalf of the client?</Text>
            <Group mt="sm">
              <Button
                onClick={() => setClientWillOrder(No)}
                w="120px"
                variant={clientWillOrder === No ? 'filled' : 'outline'}
              >
                Yes
              </Button>
              <Button
                onClick={() => setClientWillOrder(Yes)}
                w="120px"
                variant={clientWillOrder === Yes ? 'filled' : 'outline'}
              >
                No
              </Button>
            </Group>
          </>
        )}
      </Card>

      {hasKit === Yes || (hasKit === No && clientWillOrder !== null) ? (
        <>
          <CartTitle>client invite</CartTitle>
          <Card>
            <Text mb="sm">
              Invitation Note
              <Text mt="sm" size="xs" fw="300">
                Click text to edit your message
              </Text>
            </Text>
            <div>
              <Textarea
                classNames={{
                  input: classes.topInput,
                }}
                autosize
                {...form.getInputProps('invitationMessageCustom')}
              />
              <div className={classes.divider} />
              <Textarea
                classNames={{
                  input: classes.bottomInput,
                }}
                disabled
                autosize
                value={clientWillOrder === Yes ? orderAndInviteReadOnlyText : inviteReadOnlyText}
              />
            </div>
          </Card>
          <Center>
            <Group mt="lg" grow w="80%">
              <Button onClick={onCancel} variant="outline">
                Cancel
              </Button>
              <Button type="submit" variant="filled">
                Invite Client
              </Button>
            </Group>
          </Center>
        </>
      ) : null}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    marginBottom: theme.spacing.lg,
    '& > *:not(:first-of-type)': {
      marginTop: theme.spacing.lg,
    },
  },
  cardTitle: {
    marginBottom: theme.spacing.xs,
    color: theme.colors.dark[2],
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: theme.fontSizes.xs,
  },
  topInput: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
    padding: theme.spacing.md,
    paddingTop: '20px!important',
    paddingBottom: '30px!important',
    '&:focus': {
      border: `1px solid ${theme.colors.gray[4]}`,
      borderBottom: 0,
    },
  },
  divider: {
    position: 'relative',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    opacity: 1,
    color: theme.colors.gray[5],
    fontSize: '7pt',
    fontWeight: 700,
    '&::after': {
      content: '"READ-ONLY"',
      position: 'absolute',
      left: '3%',
      right: '3%',
      bottom: 0,
      borderTop: `1px solid ${theme.colors.gray[4]}`,
      paddingTop: '6px',
    },
  },
  bottomInput: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 0,
    padding: theme.spacing.md,
    '&:disabled': {
      backgroundColor: '#ffffff',
      opacity: 1,
    },
  },
}));
