import { Button, Modal, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { BLOCK } from 'components/ReportV2/elements/constants';

interface Props {
  negative?: boolean;
}

export default function DisclaimerModal({ negative }: Props) {
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Button onClick={open} fz="xs" p="sm" w="fit-content" h="auto">
        Disclaimer
      </Button>
      <Modal
        classNames={{ content: classes.modal }}
        opened={opened}
        onClose={close}
        title="Disclaimer and Instructions"
        centered
        size="xl"
      >
        {negative ? BLOCK.DISCLAIMER.NEGATIVE : BLOCK.DISCLAIMER.FINDING}
      </Modal>
    </>
  );
}

const useStyles = createStyles({
  modal: {
    background: 'rgba(237, 237, 237)',

    'li + li': {
      marginTop: '0.5rem',
    },
  },
});
