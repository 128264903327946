import * as React from 'react';
import { type IconProps, useIconProps } from './types';

export const ArrowUpRight = React.memo(
  React.forwardRef<SVGSVGElement, IconProps>((props, forwardedRef) => {
    const { 'aria-label': ariaLabel = 'Selector Icon', size, ...rest } = useIconProps(props);
    return (
      <svg
        width={size}
        height={size}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        color="inherit"
        ref={forwardedRef}
        aria-label={ariaLabel}
        {...rest}
      >
        <path d="M16.0037 9.41421L7.39712 18.0208L5.98291 16.6066L14.5895 8H7.00373V6H18.0037V17H16.0037V9.41421Z"></path>
      </svg>
    );
  })
);
