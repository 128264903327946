import { Box, Container, Paper, Text, createStyles } from '@mantine/core';

export default function UnknownError() {
  const { classes } = useStyles();

  return (
    <Container className={classes.container}>
      <Box>
        <Paper className={classes.paper} withBorder radius={2} p="lg" mt="lg">
          <Text color="dark.4" size="lg" mb="sm" fw="600">
            Something went wrong on our end
          </Text>
          <Text color="gray.7" size="sm">
            Please try again later
          </Text>
        </Paper>
      </Box>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(4, 74, 126, 0.06)',
    borderColor: `${theme.colors.gray[4]} !important`,
    borderRadius: '7px',
  },
}));
