import { type ThemeIconProps, ThemeIcon, createStyles } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCategoryIcon } from './IconCategoryMapping';

interface CategoryIconProps extends Omit<ThemeIconProps, 'children'> {
  category: string;
}

const CategoryIcon: React.FC<CategoryIconProps> = ({ category, ...props }) => {
  const { classes, cx } = useStyles();

  const icon = getCategoryIcon(category);

  return (
    <ThemeIcon
      mt="sm"
      mr="sm"
      radius="xl"
      color="dark"
      variant="light"
      size="lg"
      className={cx(classes.customThemeIconColor, props.className)}
      {...props}
    >
      <FontAwesomeIcon icon={icon} />
    </ThemeIcon>
  );
};
const useStyles = createStyles((theme) => ({
  customThemeIconColor: {
    backgroundColor: theme.colors.gray[2],
  },
}));
export default CategoryIcon;
