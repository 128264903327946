import { Badge, type BadgeProps, createStyles } from '@mantine/core';

type InfoBadgeVariant = 'red' | 'green' | 'blue' | 'gold';

interface InfoBadgeProps extends BadgeProps {
  infoBadgeVariant?: InfoBadgeVariant;
}

const InfoBadge: React.FC<InfoBadgeProps> = ({ infoBadgeVariant = 'blue', children, ...props }) => {
  const { classes, cx } = useStyles();

  const variantClasses: { [key in InfoBadgeVariant]: string } = {
    red: classes.redBadge,
    green: classes.greenBadge,
    blue: classes.blueBadge,
    gold: classes.goldBadge,
  };

  return (
    <Badge
      mt="sm"
      fs="sm"
      fw={400}
      radius="sm"
      className={cx(classes.badge, variantClasses[infoBadgeVariant])}
      {...props}
    >
      {children}
    </Badge>
  );
};
const useStyles = createStyles((theme) => ({
  badge: {
    textTransform: 'none',
    color: theme.colors.dark[9],
  },
  redBadge: {
    backgroundColor: theme.colors.red[1],
    border: `1px solid ${theme.colors.red[9]}`,
  },
  greenBadge: {
    backgroundColor: theme.colors.green[1],
    border: `1px solid ${theme.colors.green[9]}`,
  },
  blueBadge: {
    backgroundColor: theme.colors.blue[1],
    border: `1px solid ${theme.colors.blue[9]}`,
  },
  goldBadge: {
    backgroundColor: theme.colors.gold[0],
    border: `1px solid ${theme.colors.gold[4]}`,
  },
}));
export default InfoBadge;
