import { Box, BoxProps, Flex, Input, Loader, Pagination } from '@mantine/core';
import Loading from 'components/Loading';
import providerApi, { GetClientsQuery } from 'services/provider';
import userApi from 'services/user';
import Table from 'ui/Jona-v1/Table';
import { Error } from 'ui/ErrorPage';
import { useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import StatusIndicator, { IndicatorStatus } from 'ui/Jona-v1/Indicators/StatusIndicator';
import { Client } from 'types/provider';
import { useAppDispatch } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { setCurrentClientId, setCurrentClientName } from 'slices/clientKit';
import NoClients from './NoClients';

export enum SampleStatusFilter {
  pending = 'sampleStatus.pending',
  processing = 'sampleStatus.processing',
  ready_for_review = 'sampleStatus.ready_for_review',
  reviewed = 'sampleStatus.reviewed',
}

const ActiveClients: React.FC<BoxProps> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState<GetClientsQuery>({
    page: 1,
    perPage: 10,
    search: undefined,
    sortBy: undefined,
    sortOrder: undefined,
    filters: [],
  });

  const [debouncedQueryParams] = useDebouncedValue(queryParams, 200);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: 1, search: event.target.value }));
  };

  const handleSortChange = (sortBy: string) => {
    setQueryParams((prevParams) => {
      const isSameSortBy = prevParams.sortBy === sortBy;

      const newSortOrder =
        isSameSortBy && prevParams.sortOrder === 'desc'
          ? undefined
          : isSameSortBy && prevParams.sortOrder === 'asc'
          ? 'desc'
          : 'asc';
      const newSortBy = isSameSortBy && prevParams.sortOrder === 'desc' ? undefined : sortBy;

      return {
        ...prevParams,
        sortBy: newSortBy,
        sortOrder: newSortOrder,
      };
    });
  };

  const handleFilterChange = (filter: string) => {
    const newFilters = queryParams.filters?.includes(filter)
      ? queryParams.filters?.filter((f) => f !== filter)
      : [...(queryParams.filters || []), filter];
    setQueryParams((prevParams) => ({ ...prevParams, filters: newFilters }));
  };

  const handleSelectClient = (clientId: string | number, clientName: string) => {
    dispatch(setCurrentClientId(clientId));
    dispatch(setCurrentClientName(clientName));
    navigate(`/client/${clientId}`);
  };

  const {
    data: userStaffProviderData,
    isLoading: userStaffProviderIsLoading,
    error: userStaffProviderError,
  } = userApi.endpoints.getStaffProvider.useQuery();
  const providerId = userStaffProviderData?.id || 0;
  const {
    data: clientList,
    isLoading: clientsIsLoading,
    error: clientsError,
    isFetching,
  } = providerApi.endpoints.getClients.useQuery({ providerId, query: debouncedQueryParams }, { skip: !providerId });
  const { clients, totalClients, queryPages } = clientList || { clients: [], totalClients: 0, queryPages: 0 };
  const getFullName = (client: Client) =>
    client.firstName && client.lastName
      ? `${client.firstName} ${client.lastName}`
      : `Client ${client?.internalClientId || client.id}`;

  const clientTableColumns = [
    {
      label: 'Name',
      onSort: () => handleSortChange('lastName'),
      sorted: queryParams.sortBy === 'lastName',
      sortOrder: queryParams.sortOrder,
    },
    { label: 'Client ID' },
    {
      label: 'Kit Status',
      filterOptions: [
        {
          item: <StatusIndicator status={IndicatorStatus.ready} />,
          selected: debouncedQueryParams.filters?.includes(SampleStatusFilter.ready_for_review),
          onClick: () => handleFilterChange(SampleStatusFilter.ready_for_review),
        },
        {
          item: <StatusIndicator status={IndicatorStatus.reviewed} />,
          selected: debouncedQueryParams.filters?.includes(SampleStatusFilter.reviewed),
          onClick: () => handleFilterChange(SampleStatusFilter.reviewed),
        },
        {
          item: <StatusIndicator status={IndicatorStatus.pending} />,
          selected: debouncedQueryParams.filters?.includes(SampleStatusFilter.pending),
          onClick: () => handleFilterChange(SampleStatusFilter.pending),
        },
        {
          item: <StatusIndicator status={IndicatorStatus.processing} />,
          selected: debouncedQueryParams.filters?.includes(SampleStatusFilter.processing),
          onClick: () => handleFilterChange(SampleStatusFilter.processing),
        },
      ],
    },
  ];

  const clientTableData = clients?.map((user) => ({
    Name: getFullName(user),
    'Client ID': user.internalClientId || user.id,
    'Kit Status': <StatusIndicator status={user?.mostRecentSampleStatus || IndicatorStatus.pending} />, // Default to pending if no status
  }));

  const clientRowCallbackData = clients?.map((user) => () => handleSelectClient(user?.id, getFullName(user)));

  if (userStaffProviderIsLoading || clientsIsLoading) return <Loading />;

  if (userStaffProviderError) return <Error error={userStaffProviderError} />;
  if (clientsError) return <Error error={clientsError} />;
  if (totalClients === 0) return <NoClients />;

  return (
    <Box mb="md" py="lg" {...props}>
      <Input
        spellCheck={false}
        rightSection={isFetching ? <Loader size="xs" color="gray" variant="bars" /> : null}
        placeholder="Search active clients..."
        value={queryParams.search}
        onChange={handleSearchChange}
        mb="xl"
      />
      {clientTableData && (
        <Table data={clientTableData} columns={clientTableColumns} rowCallbacks={clientRowCallbackData} />
      )}
      {queryPages > 1 && (
        <Flex justify="start" mt="xl">
          <Pagination
            value={queryParams.page}
            total={queryPages}
            onChange={(page) => setQueryParams((prevParams) => ({ ...prevParams, page }))}
          />
        </Flex>
      )}
    </Box>
  );
};
export default ActiveClients;
