import { Divider } from '@mantine/core';
import { BLOCK } from 'components/ReportV2/elements/constants';
import CustomAccordion from './CustomAccordion/CustomAccordion';
import CustomAccordionContent from './CustomAccordion/CustomAccordionContent';
import CustomAccordionTitle from './CustomAccordion/CustomAccordionTitle';
import { AccordionVariant } from './CustomAccordion/useCustomAccordionStyles';

interface Props {
  pathogens: any[];
}

export default function PanelPathogens({ pathogens }: Props) {
  const associated = pathogens.filter((p) => p.guidance === 'Detected');
  const negative = pathogens.filter((p) => p.guidance !== 'Detected');

  return (
    <>
      <CustomAccordion
        items={associated.map((pathogen) => {
          return {
            id: pathogen._id,
            title: (
              <CustomAccordionTitle
                title={pathogen.preferred_name ?? pathogen.name}
                guidance={pathogen.guidance}
                guidanceTooltip={BLOCK.PATHOGENS.DETECTED}
                isNewItem={pathogen.isNewItem}
                hasChanges={!!pathogen.changes}
              />
            ),
            content: (
              <CustomAccordionContent
                id={pathogen._id}
                name={pathogen.preferred_name ?? pathogen.name}
                description={pathogen.one_line_description ?? pathogen.description}
                symptoms={pathogen.related_symptoms}
                totalSymptomsLength={pathogen.symptoms.length}
              />
            ),
          };
        })}
      />

      {!!associated.length && <Divider mt="xl" mb="lg" variant="dashed" />}

      {BLOCK.NEGATIVE_ANALYSIS_PATHOGEN}
      <CustomAccordion
        variant={AccordionVariant.Small}
        items={negative.map((pathogen) => {
          return {
            id: pathogen._id,
            title: (
              <CustomAccordionTitle
                title={pathogen.preferred_name ?? pathogen.name}
                guidance={pathogen.guidance}
                guidanceTooltip={BLOCK.PATHOGENS.NOT_DETECTED}
                variant={AccordionVariant.Small}
                isNewItem={pathogen.isNewItem}
                hasChanges={!!pathogen.changes}
              />
            ),
            content: <CustomAccordionContent id={pathogen._id} description={pathogen.description} />,
          };
        })}
      />
    </>
  );
}
