import { useState, useEffect, useRef, useCallback, SetStateAction } from 'react';
import { Carousel as MantineCarousel } from '@mantine/carousel';
import React from 'react';

interface Slide {
  id: string | number;
  content: React.ReactNode;
}

interface ResizableCarouselProps {
  slides: Slide[];
  slideGap?: number;
  breakpoints?: { maxWidth: number | string; slideSize: string; slideGap?: number | string }[];
}

const ResizableCarousel: React.FC<ResizableCarouselProps> = ({
  slides,
  slideGap = 30,
  breakpoints = [
    { maxWidth: 'md', slideSize: '50%' },
    { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
  ],
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [carouselHeight, setCarouselHeight] = useState<number | string>('auto');
  const slidesRefs = useRef<Array<HTMLDivElement | null>>(slides.map(() => null));

  const updateHeight = useCallback(() => {
    const currentSlideRef = slidesRefs.current[activeSlide];
    if (currentSlideRef) {
      setCarouselHeight(`${currentSlideRef.offsetHeight}px`);
    }
  }, [activeSlide]);

  // Update height when the active slide changes
  useEffect(() => {
    updateHeight();
  }, [slides, activeSlide, updateHeight]);

  // Attach the resize event listener
  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [updateHeight]);

  // Set the slide ref and update height
  const setSlideRef = useCallback(
    (element: HTMLDivElement, index: number) => {
      slidesRefs.current[index] = element;
      if (index === activeSlide) {
        updateHeight();
      }
    },
    [activeSlide, updateHeight]
  );

  return (
    <MantineCarousel
      slideSize="100%"
      slideGap={slideGap}
      breakpoints={breakpoints}
      controlsOffset="xs"
      controlSize={24}
      height={carouselHeight}
      withIndicators
      onSlideChange={(index: SetStateAction<number>) => setActiveSlide(index)}
      styles={{
        viewport: {
          transition: 'height 300ms ease-in-out',
        },
        control: {
          '&[data-inactive]': {
            opacity: 0,
            cursor: 'default',
          },
          color: 'white',
          backgroundColor: 'black',
        },
        indicators: {
          bottom: 0,
        },
        indicator: {
          backgroundColor: 'white',
          '&[data-active]': {
            backgroundColor: 'black',
          },
        },
      }}
      aria-label="Gallery"
    >
      {slides.map((slide, index) => (
        <MantineCarousel.Slide key={slide.id} sx={{ height: '100%' }}>
          <div ref={(el) => setSlideRef(el!, index)} style={{ width: '100%', marginBottom: '24px' }}>
            {slide.content}
          </div>
        </MantineCarousel.Slide>
      ))}
    </MantineCarousel>
  );
};

export default ResizableCarousel;
