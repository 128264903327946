import { Box, Button, MultiSelect, Select, SelectItem, TextInput, Textarea, createStyles } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Constants from 'types/intakeConstants';
import * as Yup from 'yup';

import Row from 'components/Row';
import intakeApi from 'services/intake';
import userApi from 'services/user';
import { IntakeHealthHistory } from 'types/intakeV2';
import Card from 'components/Card';

const schema = Yup.object().shape({
  historyConditions: Yup.array().min(1, 'Required field'),
  historyConditions_other: Yup.string(),
  historySymptoms: Yup.array().min(1, 'Required field'),
  historySymptoms_other: Yup.string(),
  bloodType: Yup.string().required('Required field'),

  currentMedications: Yup.array().min(1, 'Required field'),
  currentMedications_other: Yup.string(),
  currentMedications_explanation: Yup.string(),

  effectiveMedTreatmentBehaviorChange: Yup.array().min(1, 'Required field'),

  currentSupplements: Yup.array().min(1, 'Required field'),
  currentSupplements_other: Yup.string(),

  bariatricSurgery: Yup.string().required('Required field'),

  surgeries: Yup.string(),

  bowelMovementFrequency: Yup.string().required('Required field'),
  bowelMovementFrequency_other: Yup.string(),

  lastAntibioticsUse: Yup.string().required('Required field'),
  lastAntibioticsUse_other: Yup.string(),

  healthNotes: Yup.string(),
});

export default function MedicalHistoryFormV2() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const form = useForm({
    initialValues: new IntakeHealthHistory(),
    validate: yupResolver(schema),
  });
  const [updateIntake] = userApi.endpoints.updateIntakeV2.useMutation();
  const { data, isLoading } = userApi.endpoints.getUser.useQuery();
  const { data: conditionsData = [] } = intakeApi.endpoints.getIntakeConditions.useQuery();
  const { data: symptomsData = [] } = intakeApi.endpoints.getIntakeSymptoms.useQuery();
  const { data: treatmentData = [] } = intakeApi.endpoints.getIntakeTreatments.useQuery();

  useEffect(() => {
    if (!data?.intake_v2?.health_history) return;

    if (!isLoading) {
      form.setValues({ ...data.intake_v2.health_history });
    }
  }, [isLoading]);

  const CONDITIONS = Constants.IntakeConditions(conditionsData as SelectItem[]);
  const SYMPTOMS = Constants.IntakeSymptoms(symptomsData as SelectItem[]);
  const TREATMENTS = Constants.IntakeTreatments(treatmentData as SelectItem[]);

  async function handleSubmit(values: any) {
    values.completed = true;

    let update = {};
    if (data) {
      update = { ...data.intake_v2, health_history: values };
    } else {
      update = { health_history: values };
    }

    await updateIntake({
      fields: { intake_v2: update },
    });
    navigate(-1); // go back to keep user's place in the flow
  }

  return (
    <Card>
      <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <MultiSelect
          id="historyConditions"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Do any of your close relatives (parents, siblings, or grandparents) have a history of the following conditions?"
          placeholder="select all that apply..."
          data={CONDITIONS}
          {...form.getInputProps('historyConditions')}
        />
        {(Constants.ValueTriggersTextInput(form.values.historyConditions) ||
          Constants.ValueTriggersCVDInput(form.values.historyConditions) ||
          Constants.ValueTriggersCancerInput(form.values.historyConditions)) && (
          <Textarea
            className={classes.input}
            label="Other family medical history"
            placeholder="Enter your family medical history..."
            {...form.getInputProps('historyConditions_other')}
          />
        )}
        <MultiSelect
          id="historySymptoms"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Do any of your close relatives (parents, siblings, or grandparents) have a history of the following symptoms?"
          placeholder="select all that apply..."
          data={SYMPTOMS}
          {...form.getInputProps('historySymptoms')}
        />
        {Constants.ValueTriggersTextInput(form.values.historySymptoms) && (
          <Textarea
            className={classes.input}
            label="Other family medical history"
            minRows={2}
            placeholder="Enter your family medical history..."
            {...form.getInputProps('historySymptoms_other')}
          />
        )}
        <Select
          id="bloodType"
          searchable
          className={classes.input}
          label="What is your blood type?"
          data={Constants.IntakeBloodType}
          placeholder="Select blood type..."
          {...form.getInputProps('bloodType')}
        />
        <MultiSelect
          id="currentMedications"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Are you currently taking any medications?"
          description="e.g. prescription medications, over-the-counter medications (OTC), accessories, or pre/probiotics"
          placeholder="select all that apply..."
          data={Constants.IntakeMedications}
          {...form.getInputProps('currentMedications')}
        />
        {Constants.ValueTriggersTextInput(form.values.currentMedications) && (
          <TextInput
            className={classes.input}
            label="Please specify which medications"
            {...form.getInputProps('currentMedications_other')}
          />
        )}
        {Constants.ValueTriggersCustomFollowup(form.values.currentMedications, Constants.GLP1) && (
          <MultiSelect
            classNames={{ root: classes.input, value: classes.value, item: classes.item }}
            searchable
            label="Which brand(s) of GLP-1 medication are you currently taking?"
            placeholder="select all that apply..."
            data={Constants.GLP1Brands}
            {...form.getInputProps('currentMedications_glp1Brands')}
          />
        )}
        {Constants.NAValueTriggersResponse(form.values.currentMedications) && (
          <Textarea
            className={classes.input}
            label="Tell us more about the medications."
            description="When did you start it, what dosage, for how long, what is it for, has it been effective?"
            minRows={2}
            {...form.getInputProps('currentMedications_explanation')}
          />
        )}
        <MultiSelect
          id="effectiveMedTreatmentBehaviorChange"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Which treatments or medications have you tried in the past? Which has been effective in managing your symptoms so far?"
          placeholder="select all that apply..."
          data={TREATMENTS}
          {...form.getInputProps('effectiveMedTreatmentBehaviorChange')}
        />
        <MultiSelect
          id="currentSupplements"
          classNames={{ root: classes.input, value: classes.value, item: classes.item }}
          searchable
          label="Which supplements are you taking right now?"
          placeholder="select all that apply..."
          data={Constants.IntakeSupplements}
          {...form.getInputProps('currentSupplements')}
        />
        {Constants.ValueTriggersTextInput(form.values.currentSupplements) && (
          <TextInput
            className={classes.input}
            label="Please specify which supplements"
            {...form.getInputProps('currentSupplements_other')}
          />
        )}
        <Select
          id="bariatricSurgery"
          className={classes.input}
          label="Have you ever had bariatric surgery?"
          data={Constants.YesNoOtherInput}
          placeholder="Select answer..."
          {...form.getInputProps('bariatricSurgery')}
        />
        {Constants.ValueTriggersTextInput([form.values.bariatricSurgery]) && (
          <TextInput
            className={classes.input}
            label="Please describe the type(s) of surgery you have had and when it was done."
            {...form.getInputProps('surgeries')}
          />
        )}
        <Select
          id="bowelMovementFrequency"
          searchable
          className={classes.input}
          label="How often do you have a bowel movement?"
          data={Constants.IntakeBowelMovementFrequency}
          placeholder="Select answer..."
          {...form.getInputProps('bowelMovementFrequency')}
        />
        {Constants.ValueTriggersTextInput([form.values.bowelMovementFrequency]) && (
          <TextInput
            className={classes.input}
            label="Please specify"
            {...form.getInputProps('bowelMovementFrequency_other')}
          />
        )}
        <Select
          id="lastAntibioticsUse"
          searchable
          className={classes.input}
          label="Have you been on antibiotics in the last 6 months?"
          data={Constants.IntakeAntibiotics}
          placeholder="Select answer..."
          {...form.getInputProps('lastAntibioticsUse')}
        />
        {Constants.ValueTriggersTextInput([form.values.lastAntibioticsUse]) && (
          <TextInput
            className={classes.input}
            label="Please specify"
            {...form.getInputProps('lastAntibioticsUse_other')}
          />
        )}
        <Textarea
          className={classes.input}
          label="Anything else you want to tell us about your health?"
          minRows={2}
          {...form.getInputProps('healthNotes')}
        />
        <Row className={classes.buttonRow}>
          <Button className={classes.button} type="submit">
            Submit
          </Button>
        </Row>
      </Box>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  input: {
    marginBottom: theme.spacing.lg,
  },
  buttonRow: {
    marginTop: theme.spacing.lg,
  },
  button: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing.xs,
  },
  continueButton: {
    marginLeft: theme.spacing.xs,
  },
  value: {
    margin: theme.spacing.xs,
    textTransform: 'capitalize',
  },
  item: {
    textTransform: 'capitalize',
  },
}));
