import { Box, Code, Title, Space, Text, Flex } from '@mantine/core';
import Card from 'components/Card';
import Label from 'components/Label';
import OpenSurveyButton from 'components/OpenSurveyButton';
import { useAppSelector } from 'hooks';

import { SurveySource } from 'slices/surveyDialog';
import { PageContainer } from 'ui/Containers';
import { PageHeader } from 'ui/PageHeader';

export default function Support() {
  const { appVersion } = useAppSelector((state) => state.app);

  return (
    <>
      <PageHeader title="Help & Support" showBackButton={false} />
      <PageContainer size="md">
        {/* Report Tutorial Video */}
        <Card mb="lg">
          <Title order={3} mb="sm">
            Navigating Your Report
          </Title>
          <Box h={480} w={860} m="auto">
            <iframe
              src="https://drive.google.com/file/d/1nS-tEBh-10ReVRE4j6Sqd8sb2WcL0ZVt/preview"
              allow="autoplay"
              title="Report Tutorial Video"
              style={{ border: 'none', height: '100%', width: '100%' }}
              allowFullScreen
            ></iframe>
          </Box>
        </Card>

        {/* Help Support and Survey */}
        <Card>
          <Flex justify="space-around">
            <Card>
              <Text mb="lg">Questions, bug reports, feedback — we are here for it all.</Text>
              <Text>
                Please contact us at <a href="mailto:support@jona.health">support@jona.health</a>
              </Text>
            </Card>
            <Card>
              <Label color="dark" size="sm" mb="lg">
                Help us improve
              </Label>
              <Text mb="md">We&apos;d love to know what you think!</Text>
              <OpenSurveyButton source={SurveySource.REPORTS_BUTTON} withIcon mr="md">
                Open Survey
              </OpenSurveyButton>
            </Card>
          </Flex>
          <Space h="lg" />
          <Code ml="xl" fw="700">
            JONA {appVersion}
          </Code>
        </Card>
      </PageContainer>
    </>
  );
}
