import { Box, Button, Checkbox, Text, createStyles } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import Row from 'components/Row';
import sampleApi from 'services/sample';
import Card from 'components/Card';
import { Link } from 'react-router-dom';
import IntakeV2 from 'types/intakeV2';

const schema = Yup.object().shape({
  tosAndPrivacy: Yup.boolean()
    .required('Terms of Service and Privacy Policy must be accepted.')
    .oneOf([true], 'Terms of Service and Privacy Policy must be accepted.'),
  consent: Yup.boolean()
    .required('Informed Consent must be accepted.')
    .oneOf([true], 'Informed Consent must be accepted.'),
});

export default function ConsentFormV2() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { barcode } = useParams() as { barcode: string };

  const form = useForm({
    initialValues: {
      tosAndPrivacy: false,
      consent: false,
    },
    validate: yupResolver(schema),
  });
  const [updateIntake] = sampleApi.endpoints.updateSampleIntake.useMutation();
  const { data, isLoading } = sampleApi.endpoints.getSampleIntake.useQuery({ barcode });

  useEffect(() => {
    if (!data) return;

    if (!isLoading) {
      form.setValues({
        tosAndPrivacy: data.tosAndPrivacy || false,
        consent: data.consent || false,
      });
    }
  }, [isLoading]);

  async function handleSubmit(values: any) {
    values.completed = true;
    values.creation_date = new Date().toISOString();

    let update: IntakeV2 = { ...values };
    if (data) {
      update = { ...data, ...values };
    } else {
      update = { ...values };
    }

    await updateIntake({ intake: update, barcode });
    navigate(`/reports/${barcode}/intake`); // go back to keep user's place in the flow
  }

  return (
    <Card>
      <Text mb="md">
        Please review and accept our{' '}
        <Link target="_blank" rel="noreferrer noopener" to="/terms-of-use">
          Terms of Service and Privacy Policy
        </Link>
        {'  and '}
        <Link target="_blank" rel="noreferrer noopener" to="/consent">
          Informed Consent Policy
        </Link>{' '}
        to begin the intake process.
      </Text>

      <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <Checkbox
          name="tosAndPrivacy"
          className={classes.input}
          label="I accept the Terms of Service and Privacy Policy"
          {...form.getInputProps('tosAndPrivacy', { type: 'checkbox' })}
        />
        <Checkbox
          name="consent"
          className={classes.input}
          label="I accept the Informed Consent Policy"
          {...form.getInputProps('consent', { type: 'checkbox' })}
        />
        <Row className={classes.buttonRow}>
          <Button className={classes.button} type="submit">
            Submit
          </Button>
        </Row>
      </Box>
    </Card>
  );
}

const useStyles = createStyles((theme) => ({
  input: {
    marginBottom: theme.spacing.sm,
  },
  buttonRow: {
    justifyContent: 'flex-end',
    marginTop: theme.spacing.xl,
  },
  button: {
    width: '100%',
    marginLeft: theme.spacing.xs,
  },
}));
