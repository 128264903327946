import Card from 'components/Card';
import CompactTable, { RowTitle } from '../CompactTable';
import { ActionIcon, Box, Flex, Text, Tooltip, createStyles } from '@mantine/core';
import { JonaKitIcon } from '../Icons';
import StatusIndicator, { IndicatorStatus } from '../Indicators/StatusIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { UserMode } from 'slices/app';
import { Link } from 'react-router-dom';
import ClientAccessSwitch from './ClientAccessSwitch';
import { UserHasAccessResponse } from 'types/V2Types';

export interface KitTableData {
  sampleId: string | number;
  kitId: string;
  sampleDate: string | Date;
  kitStatus?: IndicatorStatus;
  clientAccess: boolean;
  clientAccessDontAskAgain?: boolean;
  manualReportRelease?: boolean;
  userAccess?: UserHasAccessResponse;
}

interface KitTableProps {
  data: KitTableData[];
  userMode?: UserMode;
  clientId: string;
  providerId: number;
}

const KitTable: React.FC<KitTableProps> = ({ userMode = UserMode.PERSONAL, data, clientId, providerId }) => {
  const { classes } = useStyles();

  const _data = data.map(
    (
      {
        sampleId,
        kitId,
        sampleDate,
        kitStatus,
        clientAccess,
        clientAccessDontAskAgain,
        manualReportRelease,
        userAccess,
      },
      i: number
    ) => {
      return [
        <Flex align="center" key="0" ml="-sm">
          <JonaKitIcon size={45} />
          <RowTitle ml="lg" label="Kit ID" value={kitId} />
        </Flex>,
        <RowTitle key="1" label="Sample Date" value={formatKitDate(sampleDate)} />,
        <RowTitle
          key="2"
          style={{ width: '160px' }}
          label="Kit Status"
          value={<StatusIndicator status={kitStatus || IndicatorStatus.pending} />} // Default to pending if no status
        />,
        ...(userMode === UserMode.PROVIDER && manualReportRelease
          ? [
              <RowTitle
                key={sampleId + '3'}
                label="Client Access"
                value={
                  <ClientAccessSwitch
                    sampleId={sampleId}
                    clientAccess={clientAccess}
                    providerId={providerId}
                    clientId={clientId}
                    dontAskAgain={clientAccessDontAskAgain}
                  />
                }
              />,
            ]
          : []),
        <Tooltip key={i} label={userAccess?.message} disabled={userAccess?.message == null}>
          <span>
            <ActionIcon
              component={Link}
              to={`/reports/${kitId}`}
              color="dark"
              size="lg"
              radius="xl"
              variant="outline"
              disabled={!userAccess?.hasAccess}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </ActionIcon>
          </span>
        </Tooltip>,
      ];
    }
  );

  return (
    <Box>
      <Text className={classes.cardTitle}>Reports</Text>
      <Card px="lg" py="0">
        {data.length > 0 ? (
          <CompactTable data={_data} />
        ) : (
          <Text ta="center" p="lg">
            No reports found
          </Text>
        )}
      </Card>
    </Box>
  );
};
export default KitTable;
const useStyles = createStyles((theme) => ({
  cardTitle: {
    marginBottom: theme.spacing.xs,
    color: theme.colors.dark[2],
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: theme.fontSizes.xs,
  },
}));

// helper function to format Date type to 'Report MM-DD-YYYY'
const formatKitDate = (date: string | Date): string => {
  const _date = new Date(date);
  return `${_date.getMonth() + 1}-${_date.getDate()}-${_date.getFullYear()}`;
};
