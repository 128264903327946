import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum SurveySource {
  FIRST_OPEN = 'web_app_first_open',
  BUTTON = 'web_app_button_default',
  REPORTS_BUTTON = 'web_app_reports_button',
  ACTION_PLAN = 'web_app_action_plan',
}

interface DialogState {
  isOpen: boolean;
  surveyId: string | undefined; // if we want to render different surveys in different pages
  source: SurveySource | undefined;
}

const initialState: DialogState = {
  isOpen: false,
  surveyId: undefined,
  source: undefined,
};

const surveyDialog = createSlice({
  name: 'surveyDialog',
  initialState,
  reducers: {
    openSurveyDialog: (state, action: PayloadAction<SurveySource | undefined>) => {
      state.isOpen = true;
      state.source = action.payload;
    },
    closeSurveyDialog: (state) => {
      state.isOpen = false;
    },
    setSurveyId: (state, action: PayloadAction<string>) => {
      state.surveyId = action.payload;
    },
  },
});

export const { openSurveyDialog, closeSurveyDialog, setSurveyId } = surveyDialog.actions;

export default surveyDialog;
