const clientSideID =
  (process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_LD_CLIENT_ID
    : window.env.REACT_APP_LD_CLIENT_ID) || "";

const ldConfig = {
    clientSideID
};
  

export default ldConfig;