import { createStyles } from '@mantine/core';
import Column from 'components/Column';
import DevRoute from 'contexts/DevRoute';
import { Outlet } from 'react-router-dom';

export default function Developer() {
  const { classes } = useStyles();

  return (
    <DevRoute>
      <Column className={classes.contentCol}>
        <Outlet />
      </Column>
    </DevRoute>
  );
}

const useStyles = createStyles(() => ({
  contentCol: {
    width: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
