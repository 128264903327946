import { useNavigate } from 'react-router-dom';
import { IntakeStep, IntakeV2 } from 'types/intakeV2';

import Card from 'components/Card';
import TaskListItem from './components/TaskListItem';
import { Flex, Stack } from '@mantine/core';

interface Props {
  intake: IntakeV2;
}

export default function IntakeTasklist({ intake }: Props) {
  const navigate = useNavigate();

  function NavigateToIntakePage(step: IntakeStep) {
    switch (step) {
      case IntakeStep.ToSConsent:
        navigate('/intake/consent');
        break;
      case IntakeStep.KitRegistration:
        navigate('/intake/kit');
        break;
      case IntakeStep.Demographics:
        navigate('/intake/demographics');
        break;
      case IntakeStep.LifestyleAndNutrition:
        navigate('/intake/lifestyle');
        break;
      case IntakeStep.HealthHistory:
        navigate('/intake/history');
        break;
    }

    return;
  }

  function isCompleted(step: IntakeStep) {
    switch (step) {
      case IntakeStep.ToSConsent:
        return intake?.tosAndPrivacy === true && intake?.consent === true;
      case IntakeStep.KitRegistration:
        return intake?.kit_registration?.completed === true;
      case IntakeStep.Demographics:
        return intake?.demographics?.completed === true;
      case IntakeStep.LifestyleAndNutrition:
        return intake?.lifestyle?.completed === true && intake?.nutrition?.completed === true;
      case IntakeStep.HealthHistory:
        return intake?.health_history?.completed === true;
    }
  }

  return (
    <Card>
      <Stack spacing="lg" p="md">
        <TaskListItem item={IntakeStep.ToSConsent} onClick={NavigateToIntakePage} isCompleted={isCompleted} />
        <TaskListItem item={IntakeStep.Demographics} onClick={NavigateToIntakePage} isCompleted={isCompleted} />
        <TaskListItem item={IntakeStep.KitRegistration} onClick={NavigateToIntakePage} isCompleted={isCompleted} />
        <TaskListItem
          item={IntakeStep.LifestyleAndNutrition}
          onClick={NavigateToIntakePage}
          isCompleted={isCompleted}
        />
        <TaskListItem
          item={IntakeStep.HealthHistory}
          onClick={NavigateToIntakePage}
          isCompleted={isCompleted}
          isOptional={true}
        />
      </Stack>
    </Card>
  );
}
