import Routes from 'Routes';
import ChangelogModal from 'components/Modals/ChangelogModal';
import ClientInviteNotificationModal from 'components/Modals/ClientInviteNotificationModal';
import ReportTutorialModal from 'components/Modals/ReportTutorialModal';
import SurveyDialog from 'components/SurveyDialog';
import TrackingRoute from 'contexts/TrackingRoute';
import { useAuthenticatedToken } from 'hooks';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { setAppVersion } from 'slices/app';
import { AppDispatch } from 'store';
import { AppLoadingState } from 'ui/LoadingStates';

export default function App() {
  const dispatch: AppDispatch = useDispatch();
  const ldClient = useLDClient();
  const flags = useFlags();

  // Set the app version
  useEffect(() => {
    fetch('/version.json')
      .then((response) => response.json())
      .then((data) => dispatch(setAppVersion(data.version)));
  }, []);

  const { user, token, isLoading, isAuthenticated, loginWithRedirect, error } = useAuthenticatedToken();
  const { update: updateIntercom, shutdown: intercomShutdown } = useIntercom();

  // Identify user with LaunchDarkly
  useEffect(() => {
    if (user && ldClient) {
      ldClient.identify({
        kind: 'user',
        key: user.sub,
        name: user.name,
        email: user.email,
      });

      updateIntercom({
        userId: user.sub,
        email: user.email,
        name: user.name,
      });
    }
  }, [user, ldClient]);

  if (isLoading || (!token && isAuthenticated)) {
    return <AppLoadingState />;
  }

  if (!isAuthenticated) {
    intercomShutdown();
    loginWithRedirect();
    return null; // avoid unauthorized access to the app
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <TrackingRoute>
      <Routes />

      {/* Put dialogs and modals here and use redux to control to avoid multiple renders */}
      <SurveyDialog />
      <ReportTutorialModal />
      {flags['reportDiff'] && <ChangelogModal />}
      <ClientInviteNotificationModal />
    </TrackingRoute>
  );
}
