import { Box, BoxProps, Button, Flex, Input, Loader, Pagination } from '@mantine/core';
import Loading from 'components/Loading';
import providerApi, { GetClientsQuery } from 'services/provider';
import userApi from 'services/user';
import Table from 'ui/Jona-v1/Table';
import { Error, ErrorPopup } from 'ui/ErrorPage';
import { useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { Client } from 'types/provider';
import NoClients from './NoClients';

export enum InvitedClientStatusFilters {
  Active = 'clientStatus.active',
  Pending = 'clientStatus.pending',
  Denied = 'clientStatus.rejected',
}

interface ActiveClientsProps extends BoxProps {
  status: InvitedClientStatusFilters;
}

const ActiveClients: React.FC<ActiveClientsProps> = ({ status, ...props }) => {
  const [queryParams, setQueryParams] = useState<GetClientsQuery>({
    page: 1,
    perPage: 10,
    search: undefined,
    sortBy: undefined,
    sortOrder: undefined,
    filters: [status],
  });

  const [debouncedQueryParams] = useDebouncedValue(queryParams, 200);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: 1, search: event.target.value }));
  };

  const handleSortChange = (sortBy: string) => {
    setQueryParams((prevParams) => {
      const isSameSortBy = prevParams.sortBy === sortBy;

      const newSortOrder =
        isSameSortBy && prevParams.sortOrder === 'desc'
          ? undefined
          : isSameSortBy && prevParams.sortOrder === 'asc'
          ? 'desc'
          : 'asc';
      const newSortBy = isSameSortBy && prevParams.sortOrder === 'desc' ? undefined : sortBy;

      return {
        ...prevParams,
        sortBy: newSortBy,
        sortOrder: newSortOrder,
      };
    });
  };

  const [inviteClient, { error: inviteClientError }] = providerApi.endpoints.inviteClient.useMutation();

  const {
    data: userStaffProviderData,
    isLoading: userStaffProviderIsLoading,
    error: userStaffProviderError,
  } = userApi.endpoints.getStaffProvider.useQuery();
  const providerId = userStaffProviderData?.id || 0;
  const {
    data: invitedList,
    isLoading: clientsIsLoading,
    error: clientsError,
    isFetching,
  } = providerApi.endpoints.searchInvites.useQuery({ providerId, query: debouncedQueryParams }, { skip: !providerId });
  const { invites, totalInvites, queryPages } = invitedList || { invites: [], totalInvites: 0, queryPages: 0 };
  const getFullName = (client: Client) => `${client.firstName || ''} ${client.lastName || ''}`;
  const {
    data: clientsCount,
    error: clientsCountError,
    isLoading: clientsCountIsLoading,
  } = providerApi.endpoints.getClientsCount.useQuery({ providerId }, { skip: !providerId });
  const { invitedClients, deniedClients } = clientsCount || {};

  const clientTableColumns = [
    {
      label: 'Name',
      onSort: () => handleSortChange('targetUser.lastName'),
      sorted: queryParams.sortBy === 'targetUser.lastName',
      sortOrder: queryParams.sortOrder,
    },
    { label: 'Client ID' },
    { label: 'Invite Date' },
    { label: '_unset' },
  ];

  const clientTableData = invites?.map(
    ({ targetUser, internalNotes, internalClientId, invitationMessage, createdAt }) => ({
      Name: getFullName(targetUser),
      'Client ID': internalClientId || targetUser.id,
      'Invite Date': new Date(createdAt).toISOString().slice(0, 10),
      _unset: (
        <Button
          onClick={() =>
            inviteClient({
              providerId,
              firstName: targetUser.firstName,
              lastName: targetUser.lastName,
              email: targetUser.email,
              internalClientId,
              internalNotes,
              invitationMessage,
              clientPurchaseKit: false,
            })
          }
          variant="outline"
          compact
          size="sm"
        >
          Resend Invite
        </Button>
      ),
    })
  );

  if (userStaffProviderIsLoading || clientsIsLoading || clientsCountIsLoading) return <Loading />;

  if (userStaffProviderError) return <Error error={userStaffProviderError} />;
  if (clientsError) return <Error error={clientsError} />;
  if (clientsCountError) return <Error error={clientsCountError} />;
  if (totalInvites === 0) return <NoClients />;
  if (status === InvitedClientStatusFilters.Pending && invitedClients === 0)
    return (
      <NoClients
        label="No pending clients yet"
        description="You can find clients who have not accepted your invite here."
      />
    );
  if (status === InvitedClientStatusFilters.Denied && deniedClients === 0)
    return (
      <NoClients label="No denied clients yet" description="You can find clients who have denied your invite here." />
    );

  return (
    <Box mb="md" py="lg" {...props}>
      {inviteClientError && <ErrorPopup error={inviteClientError} />}
      <Input
        spellCheck={false}
        rightSection={isFetching ? <Loader size="xs" color="gray" variant="bars" /> : null}
        placeholder="Search invited clients..."
        value={queryParams.search}
        onChange={handleSearchChange}
        mb="xl"
      />
      {clientTableData && <Table data={clientTableData} columns={clientTableColumns} />}
      {queryPages > 1 && (
        <Flex justify="start" mt="xl">
          <Pagination
            value={queryParams.page}
            total={queryPages}
            onChange={(page) => setQueryParams((prevParams) => ({ ...prevParams, page }))}
          />
        </Flex>
      )}
    </Box>
  );
};
export default ActiveClients;
