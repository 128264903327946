import { Invite, InviteResponse } from 'types/invite';
import { TagTypes, emptySplitApi } from './emptyApi';

import { User, TrackingData, IUserProfile, IUserProfileUpdateQuery, IUserPreferences } from 'types/user';
import { Provider, ProviderStaff, UserProvider } from 'types/provider';

export function ShouldShowIntakeV2(user: User): Boolean {
  return true;
}

export function IsIntakeV2Completed(user: User): Boolean {
  if (
    user.intake_v2?.kit_registration?.completed === true &&
    user.intake_v2?.consent === true &&
    user.intake_v2?.demographics?.completed === true &&
    user.intake_v2?.lifestyle?.completed === true &&
    user.intake_v2?.nutrition?.completed === true
  ) {
    return true;
  }

  return false;
}

export function IsIntakeComplete(user: User): Boolean {
  return IsIntakeV2Completed(user);
}

export function ShowUserSurvey(user: User): Boolean {
  // if it is more than 24 hours since the user first opened the report and we haven't shown the survey, show the survey
  if (user.flags?.firstReportOpenTime && user.flags?.firstSurveyOpenTime === null) {
    const now = new Date();
    const reportOpenTime = new Date(user.flags?.firstReportOpenTime);
    const diff = now.getTime() - reportOpenTime.getTime();
    if (diff > 24 * 60 * 60 * 1000) {
      return true;
    }
  }
  return false;
}

const userApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: `/api/user`,
      }),
      providesTags: (result) => (result?.auth0_id ? [{ type: TagTypes.user, id: result?.auth0_id }] : []),
    }),
    getProfile: builder.query<IUserProfile, void>({
      query: () => ({
        url: `/api/user/profile`,
      }),
      providesTags: (result) => (result?.auth0id ? [{ type: TagTypes.user, id: result?.auth0id }] : []),
    }),
    updateProfile: builder.mutation<IUserProfile, IUserProfileUpdateQuery>({
      query: (update) => ({
        url: `/api/user/profile`,
        method: 'POST',
        body: update,
      }),
      invalidatesTags: (result) => (result?.auth0id ? [{ type: TagTypes.user, id: result?.auth0id }] : []),
    }),
    getUserTrackingData: builder.query<TrackingData, void>({
      query: () => ({
        url: `/api/user/tracking`,
      }),
    }),
    createUser: builder.query<User, void>({
      query: (payload) => ({
        url: '/api/user',
        method: 'POST',
        body: payload,
      }),
    }),
    updateUser: builder.mutation<User, any>({
      query: ({ fields }) => ({
        url: `/api/user`,
        method: 'PATCH',
        body: fields,
      }),
      invalidatesTags: (result) => [{ type: TagTypes.user, id: result?.auth0_id }],
    }),
    updateIntake: builder.mutation<User, any>({
      query: ({ fields }) => ({
        url: `/api/user/intake`,
        method: 'PATCH',
        body: fields,
      }),
      invalidatesTags: (result) => [{ type: TagTypes.user, id: result?.auth0_id }],
    }),
    updateIntakeV2: builder.mutation<User, any>({
      query: ({ fields: IntakeV2 }) => ({
        url: `/api/user/intake/v2`,
        method: 'PATCH',
        body: IntakeV2,
      }),
      invalidatesTags: (result) => [{ type: TagTypes.user, id: result?.auth0_id }, { type: TagTypes.report }],
    }),
    addBarcode: builder.mutation({
      query: ({ barcode }) => ({
        url: `/api/user/barcode/add`,
        method: 'PATCH',
        body: { barcode },
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: TagTypes.user, id: result?.auth0_id }, { type: TagTypes.report }]
          : [{ type: TagTypes.report }],
    }),
    removeBarcode: builder.mutation<User, any>({
      query: ({ barcode }) => ({
        url: `/api/user/barcode/remove`,
        method: 'PATCH',
        body: { barcode },
      }),
      invalidatesTags: (result) => [{ type: TagTypes.user, id: result?.auth0_id }],
    }),
    updateFlag: builder.mutation<User, any>({
      query: ({ field, value }) => ({
        url: `/api/user/flag`,
        method: 'PATCH',
        body: { field, value },
      }),
      invalidatesTags: (result) => [{ type: TagTypes.user, id: result?.auth0_id }],
    }),

    getInvites: builder.query<Invite[], void>({
      query: () => ({
        url: `/api/user/v2/invites`,
      }),
      providesTags: [{ type: TagTypes.invite }],
    }),
    getPendingInvites: builder.query<Invite[], void>({
      query: () => ({
        url: `/api/user/v2/invites/pending`,
      }),
      providesTags: [{ type: TagTypes.invitePending }],
    }),
    acceptInvite: builder.mutation<InviteResponse, { inviteId: number }>({
      query: ({ inviteId }) => ({
        url: `/api/user/v2/invite/${inviteId}/accept`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: TagTypes.invite }, { type: TagTypes.invitePending }, { type: TagTypes.userProviders }],
    }),
    rejectInvite: builder.mutation<InviteResponse, { inviteId: number }>({
      query: ({ inviteId }) => ({
        url: `/api/user/v2/invite/${inviteId}/reject`,
        method: 'POST',
      }),
      // doesn't invalidate userProviders or invites because rejected invites can be undone
    }),
    resetInvite: builder.mutation<void, { inviteId: number }>({
      query: ({ inviteId }) => ({
        url: `/api/user/v2/invite/${inviteId}/reset`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: TagTypes.invite }, { type: TagTypes.invitePending }, { type: TagTypes.userProviders }],
    }),
    getProviders: builder.query<UserProvider[], void>({
      query: () => ({
        url: `/api/user/v2/providers`,
      }),
      providesTags: [{ type: TagTypes.userProviders }],
    }),
    getStaffProvider: builder.query<Provider, void>({
      query: () => ({
        url: `/api/user/v2/staff/provider`,
      }),
      providesTags: (result) => (result?.id ? [{ type: TagTypes.staffProvider, id: result.id }] : []),
    }),
    activateBarcode: builder.mutation({
      query: ({ barcode }) => ({
        url: `/api/user/v2/barcode/activate`,
        method: 'POST',
        body: { barcode },
      }),
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: TagTypes.user, id: result.auth0Id }, { type: TagTypes.report }] : [],
    }),
    updateProviderStaffPreferences: builder.mutation<
      { message: string; providerStaff: ProviderStaff },
      { clientAccessDontAskAgain: boolean; providerId: string | number }
    >({
      query: ({ clientAccessDontAskAgain, providerId }) => ({
        url: `/api/user/v2/staff/${providerId}/preferences`,
        method: 'POST',
        body: { clientAccessDontAskAgain },
      }),
      invalidatesTags: (result, error) => (result && !error ? [{ type: TagTypes.staffProvider }] : []),
    }),
  }),
});

export default userApi;
