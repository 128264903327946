import { useEffect } from 'react';

import { AppDispatch, persistor } from 'store';
import { useDispatch } from 'react-redux';
import { ModalType, openModal } from 'slices/app';
import { useAuth0 } from '@auth0/auth0-react';
import { clearToken } from 'services/token';

import { Collapse } from '@mantine/core';

import { NavbarAction, type NavItem } from 'appConfig';

import useNavbarStyles from './useNavbarStyles';
import NavbarLink from './NavbarLink';
import { isPageActive } from '.';

interface LinksGroupProps extends NavItem {
  opened: boolean;
  openId?: string;
  toggleOpen: (label: string) => void;
}

export function LinksGroup({
  opened,
  openId,
  toggleOpen,
  icon,
  label,
  action,
  link,
  links,
  navIndicatorType,
  exact,
}: LinksGroupProps) {
  const { classes } = useNavbarStyles();
  const dispatch: AppDispatch = useDispatch();
  const { logout } = useAuth0();
  const hasLinks = Array.isArray(links);
  const navLinkProps = { label, icon, hasLinks, opened, link, exact, navIndicatorType };

  // handle logout
  async function handleLogout() {
    clearToken();
    await persistor.purge();
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  // if the sublink is active and if the group is closed, open it
  useEffect(() => {
    if (!opened && openId && hasLinks && links?.some((l) => isPageActive(l.link))) {
      toggleOpen(openId);
    }
  }, [window.location.pathname]);

  // if the navbar item has nested links
  if (hasLinks && openId) {
    return (
      <>
        <NavbarLink {...navLinkProps} onClick={() => toggleOpen(openId)} />
        <Collapse pr="lg" in={opened}>
          {links.map(({ link, label }) => (
            <NavbarLink className={classes.subLink} to={link} key={label} label={label} />
          ))}
        </Collapse>
      </>
    );
  }

  // if the navbar item has an action (like opening a modal or logout)
  if (action === NavbarAction.UPDATES_MODAL) {
    <NavbarLink {...navLinkProps} onClick={() => dispatch(openModal(ModalType.Changelog))} />;
  }

  if (action === NavbarAction.LOGOUT) {
    return <NavbarLink {...navLinkProps} onClick={handleLogout} />;
  }

  // default case, just a link
  return <NavbarLink {...navLinkProps} to={link || '/'} />;
}
