import { TagTypes, emptySplitApi } from './emptyApi';

const devApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    email: builder.mutation<EmailResponse, any>({
      query(data) {
        return {
          url: `/api/dev/email`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getInMaintanceMode: builder.query<SettingResponse, void>({
      query: () => `/api/dev/setting/maintenance-status?timestamp`,
    }),
    getSurveyId: builder.query<SettingResponse, void>({
      query: () => ({
        url: `/api/dev/setting/survey-id`,
      }),
      providesTags: [{ type: TagTypes.survey }],
    }),
    testAuth: builder.query<string, void>({
      query: () => ({
        url: `/api/dev/auth`,
      }),
    }),
  }),
});

export type SettingResponse = {
  setting: string;
  value: string;
};

export type EmailResponse = {
  to: string;
  from: string;
  email: string;
};

export default devApi;
