import { useParams } from 'react-router-dom';
import { Title } from '@mantine/core';

import reportApi from 'services/reportV2';

import Loading from 'components/Loading';
import Overview from 'components/ReportV2/Overview/Overview';
import { PageHeader } from 'ui/PageHeader';
import { Disclaimer } from 'ui/Disclaimer';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';
import sampleApi from 'services/sample';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppSelector } from 'hooks';

export default function HealthGoals() {
  const { barcode } = useParams() as { barcode: string };
  const { firstName } = useAppSelector((state) => state.user);
  const { useAssignedSamples } = useFlags();

  const {
    data: sampleMetadataResponse,
    isLoading: isSampleMetadataLoading,
    error: sampleMetadataError,
  } = sampleApi.endpoints.getSampleMetadata.useQuery({ barcode });

  const {
    data: allFindings,
    error: findingsError,
    isLoading: isFindingsLoading,
  } = reportApi.useGetAllFindingsQuery(barcode);

  if (findingsError) return <Error error={findingsError} />;
  // if (sampleMetadataError) return <Error error={sampleMetadataError} />;
  if (isFindingsLoading || isSampleMetadataLoading) return <Loading />;
  if (!allFindings) return <Title m="xl">Report not found</Title>;

  const name = sampleMetadataResponse?.userFirstName || firstName;

  return (
    <>
      <PageHeader title="Health Goals" />
      <PageContainer size="md">
        <Overview barcode={barcode} goals={allFindings.goals} userName={name} useAssignedSamples={useAssignedSamples} />
        <Disclaimer />
      </PageContainer>
    </>
  );
}
