import DIET_CONFLICTS from 'assets/diet-conflicts.json';

export function getEvidenceLabel(evidence: number) {
  if (evidence <= 0) return '○○○';
  if (evidence <= 1) return '●○○';
  if (evidence <= 2) return '●●○';
  return '●●●';
}

export function getEvidenceDescription(dots: number) {
  if (dots === 0) return 'No Significant Association';
  if (dots === 1) return 'Weak Association';
  if (dots === 2) return 'Typical Association';
  if (dots === 3) return 'Strong Association';
  return '';
}

export function getResearchStatusDescription(status: string) {
  switch (status) {
    case 'Established Research':
      return 'Association supported by at least two papers from a high-impact journal and/or with a large study size';
    case 'Preliminary Research':
      return 'Association supported by at least two papers from a medium-impact journal and/or with a medium study size';
    case 'Limited Research':
      return 'Association supported by at least one study';
    case 'Insufficient Research':
      return 'Association wasn’t found by existing study';
    default:
      return null;
  }
}

export function getAssociationText(categoricalScore: number) {
  if (categoricalScore === 3) return 'High';
  if (categoricalScore === 2) return 'Medium';
  if (categoricalScore === 1) return 'Low';
  return 'No';
}

export function organismHasNegativeImpact(guidance: string, score: number) {
  if ((guidance === 'Below Median' && score < 0) || (guidance === 'Above Median' && score > 0)) {
    return true;
  }
  return false;
}

export function aggregateUnique(total: Array<any>, item: any) {
  const idx = total.findIndex((totalItem: any) => totalItem._id === item._id);
  if (idx === -1) {
    total.push(item);
  } else if (total[idx].categorical_score < item.categorical_score) {
    total[idx] = item;
  }
}

export function aggregateField(total: Array<any>, item: any, field: string) {
  item[field].some((item: any) => aggregateUnique(total, item));
  return total;
}

export function capitalize(str: string) {
  if (str) {
    let sentences = str.split(' ');
    sentences = sentences.map((sentence: string) => sentence.charAt(0).toUpperCase() + sentence.slice(1));
    str = sentences.join(' ');
    return str;
  }

  return '';
}

export function filterNoScore(item: any) {
  return item && item.categorical_score > 0;
}

export function filterNoImprovement(item: any) {
  return item && item.improvement > 0;
}

export function dedupe<T extends { [key: string]: unknown }>(arr: T[], key: string): T[] {
  return [...new Map(arr.map((item: T) => [item[key], item])).values()];
}

export function mergeDedupe(arr: any[], mergeKey: string, dedupeKey: string) {
  return dedupe(
    arr.reduce((merged, item) => merged.concat(item[mergeKey]), []),
    dedupeKey
  );
}

export function aggregateDietDiffs(acc: any, item: any) {
  item.diets.forEach((diet: any) => {
    const diff = (diet.score / item.score - 1) * 100;
    const existingDiet = acc.diets.find((item: any) => item.name === diet.name && item.action === diet.action);
    if (diff === 0) {
      return;
    }
    const obj = {
      id: item.items_id,
      name: item.name,
      diff,
      disease_categorical_score: diet.disease_categorical_score,
      categorical_score: item.categorical_score,
    };

    if (existingDiet) {
      existingDiet.items.push(obj);
      existingDiet.totalDifference += diff;
    } else {
      acc.diets.push({ ...diet, items: [obj], totalDifference: diff });
    }
  });

  return acc;
}

export function aggregateImpact(total: any, item: any, action: any) {
  const matchOne = item.treatments.find((conditionAction: any) => conditionAction._id === action.action_id);
  const matchTwo = item.diets.find((conditionAction: any) => conditionAction._id === action.action_id);

  function transform(obj: any) {
    return {
      id: item.conditions_id || item.sensitivities_id || item.symptoms_id,
      name: item.name,
      diff: obj.score - item.score,
      categorical_score: item.categorical_score,
      disease_categorical_score: obj.disease_categorical_score,
    };
  }
  if (matchOne) total.push(transform(matchOne));
  if (matchTwo) total.push(transform(matchTwo));
  return total;
}

export function round(num: number, precision: number) {
  const multiplier = Math.pow(10, precision);
  return Math.round(num * multiplier) / multiplier;
}

export function sortByRange(a: any, b: any) {
  return b.range - a.range;
}

export function sortByRangeAsc(a: any, b: any) {
  return a.range - b.range;
}

export function sortByImpact(a: any, b: any) {
  const absoluteValueA = Math.abs(a.impact_on_association);
  const absoluteValueB = Math.abs(b.impact_on_association);

  return absoluteValueB - absoluteValueA;
}

export function sortByScore(a: any, b: any) {
  return Math.abs(b.score) - Math.abs(a.score);
}

export function sortByScoreAsc(a: any, b: any) {
  return Math.abs(a.score) - Math.abs(b.score);
}

export function sortByCategoricalScore(a: any, b: any) {
  if (a.categorical_score === b.categorical_score) {
    return Math.abs(b.improvement) - Math.abs(a.improvement);
  }

  return b.categorical_score - a.categorical_score;
}

export function multiplyPixels(pixels: string, multiple: number) {
  const number = pixels.replace('px', '');
  const result = parseInt(number) * multiple;
  return result.toString() + 'px';
}

export function countStr(list: any[], singular: string, plural: string) {
  return `${list.length} ${list.length === 1 ? singular : plural}`;
}

export function resolveDietConflicts(
  resolved: any[],
  action: { action: 'Avoid' | 'Adopt'; name: keyof typeof DIET_CONFLICTS }
) {
  const isConflicted = resolved.some((a: { action: 'Avoid' | 'Adopt'; name: keyof typeof DIET_CONFLICTS }) => {
    if (DIET_CONFLICTS[a.name] && DIET_CONFLICTS[a.name][a.action])
      return DIET_CONFLICTS[a.name][a.action].some(
        (conflict) => conflict.action === action.action && conflict.name === action.name
      );
    return false;
  });

  if (!isConflicted) resolved.push(action);

  return resolved;
}

// helper funciton to un capitalise the first letter of a string
export const unCapitalize = (str: string) => str.charAt(0).toLowerCase() + str.slice(1);

// uncapitolize all letters in a string
export const unCapitalizeAll = (str: string) => str.toLowerCase();

// helper to capitalize all words a string
export const capitalizeAll = (str: string) => str.replace(/\b\w/g, (char) => char.toUpperCase());
