import React from 'react';
import { UnstyledButton, createStyles, type UnstyledButtonProps } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

interface BackButtonProps extends UnstyledButtonProps {}

export const BackButton: React.FC<BackButtonProps> = ({ children, ...rest }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <UnstyledButton className={classes.button} onClick={goBack} {...rest}>
      <FontAwesomeIcon style={{ marginRight: '6px' }} icon={faAngleLeft} />
      {children || 'Back'}
    </UnstyledButton>
  );
};

const useStyles = createStyles((theme) => ({
  button: {
    width: 'fit-content',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
BackButton.displayName = 'BackButton';
