import { createSlice } from '@reduxjs/toolkit';

import Condition from 'types/condition';

export interface ReportState {
  condition?: Condition;
  organism: any;
  publication?: any;
  action?: any;
  symptom?: any;
  highlight: {
    symptomId?: string;
    symptoms?: string[];
    papers?: string[];
    organisms?: string[];
    treatments?: string[];
    diets?: string[];
  };
  intake: {
    conditions: string[];
    symptoms: string[];
    sensitivities: string[];
  };
}

const initialState: ReportState = {
  condition: undefined,
  publication: undefined,
  organism: undefined,
  action: undefined,
  symptom: undefined,
  highlight: {
    symptomId: '',
    symptoms: [],
    papers: [],
    organisms: [],
    treatments: [],
    diets: [],
  },
  intake: {
    conditions: [],
    symptoms: [],
    sensitivities: [],
  },
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    select: (state, action) => {
      state.condition = action.payload;
    },
    openPublication: (state, action) => {
      state.publication = action.payload;
    },
    closePublication: (state) => {
      state.publication = undefined;
    },
    setHighlightedConditions: (state, action) => {
      state.highlight.symptomId = action.payload;
    },
    setHighlightedSensitivities: (state, action) => {
      state.highlight.symptomId = action.payload;
    },
    setHighlightedSymptoms: (state, action) => {
      state.highlight.symptoms = action.payload;
    },
    setHighlightedPapers: (state, action) => {
      state.highlight.papers = action.payload;
    },
    setHighlightedOrganisms: (state, action) => {
      state.highlight.organisms = action.payload;
    },
    setHighlightedTreatments: (state, action) => {
      state.highlight.treatments = action.payload;
    },
    setHighlightedDiets: (state, action) => {
      state.highlight.diets = action.payload;
    },
    setIntake: (state, action) => {
      state.intake = action.payload;
    },
    setOrganism: (state, action) => {
      state.organism = action.payload;
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setSymptom: (state, action) => {
      state.symptom = action.payload;
    },
  },
});

export default reportSlice;
