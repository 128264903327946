const ContentSecurityPolicyProduction: string[] = [
  "default-src 'self' *.intercomcdn.com;",
  "connect-src 'self' wss://nexus-websocket-a.intercom.io *.intercom.io cdn.weglot.com https://cdn-api-weglot.com *.auth0.com https://www.google-analytics.com *.jona.health *.mixpanel.com *.launchdarkly.com;",
  "script-src 'self' https://cdn.mxpnl.com https://www.googletagmanager.com *.intercom.io *.intercomcdn.com;",
  "style-src 'self' https://www.gstatic.com fonts.googleapis.com cdn.weglot.com 'unsafe-inline';",
  "font-src 'self' fonts.gstatic.com *.intercomcdn.com;",
  "img-src 'self' data: *.cloudfront.net https://www.googletagmanager.com https://canny.io *.intercomassets.com *.intercomcdn.com;",
  "frame-src 'self' *.auth0.com *.jona.health *.typeform.com https://drive.google.com/;",
];

const ContentSecurityPolicyDev: string[] = [
  "default-src 'self' *.intercomcdn.com;",
  "connect-src 'self' wss://nexus-websocket-a.intercom.io *.intercom.io cdn-api-weglot.com cdn.weglot.com localhost:* *.auth0.com *.mixpanel.com *.launchdarkly.com;",
  "script-src 'self' *.intercom.io *.intercomcdn.com;",
  "style-src 'self' fonts.googleapis.com cdn.weglot.com 'unsafe-inline';",
  "font-src 'self' fonts.gstatic.com *.intercomcdn.com;",
  "img-src 'self' data: *.cloudfront.net https://www.googletagmanager.com https://canny.io *.intercomassets.com *.intercomcdn.com;",
  "frame-src 'self' *.jona.health *.auth0.com *.typeform.com https://drive.google.com/;",
];

export function GetCSPValues() {
  if (process.env.NODE_ENV === 'production') {
    return ContentSecurityPolicyProduction.join(' ');
  } else {
    return ContentSecurityPolicyDev.join(' ');
  }
}

export default GetCSPValues;
