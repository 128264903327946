import { FC } from 'react';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Anchor, Badge, Box, BoxProps, Button, Grid, Group, Text, createStyles } from '@mantine/core';

interface LiteratureAccordionProps extends BoxProps {
  name: string;
  papers: any[];
}

const LiteratureAccordion: FC<LiteratureAccordionProps> = ({ name, papers, ...props }) => {
  const { classes } = useStyles();
  return (
    <Box id="related-literature" {...props}>
      {papers.map((paper, index) => (
        <Accordion
          classNames={{
            item: classes.acc_item,
            panel: classes.acc_panel,
          }}
          key={index}
          radius="xs"
          variant="separated"
        >
          <Accordion.Item value={paper.paper_title}>
            <Accordion.Control>
              <Grid align="center">
                <Grid.Col span={10} sm={8} md={9}>
                  <Text lineClamp={2} span fw="500" fz="sm">
                    {paper.paper_title}
                  </Text>
                </Grid.Col>
                <Grid.Col span={3}>
                  <Box>
                    <Group noWrap>
                      <Badge fz="xs" fw="500" radius="xs" maw="100%" ml="auto" title={paper.publisher}>
                        {paper.publisher}
                      </Badge>

                      <Button
                        compact
                        component="a"
                        href={`https://pubmed.ncbi.nlm.nih.gov/${paper._id}/`}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faExternalLink}></FontAwesomeIcon>
                      </Button>
                    </Group>
                  </Box>
                </Grid.Col>
              </Grid>
            </Accordion.Control>
            <Accordion.Panel>
              <Text fz="xs" dangerouslySetInnerHTML={{ __html: paper.paper_abstract }} />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      ))}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  acc_item: {
    borderRadius: theme.radius.sm,
    transition: 'border-color 0.3s',
    padding: theme.spacing.sm,
    marginBottom: theme.spacing.sm,
  },
  acc_panel: {
    paddingTop: theme.spacing.sm,
  },
}));

export default LiteratureAccordion;
