import { Button, ButtonProps } from '@mantine/core';
import { Link, LinkProps } from 'react-router-dom';

export default function LinkButton({ children, ...rest }: ButtonProps & LinkProps) {
  return (
    <Button component={Link}  ff="Sora" fz="xs" fw="500" {...rest}>
      {children}
    </Button>
  );
}
