import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';

import userApi from 'services/user';
import reportApi from 'services/reportV2';
import { selectCurrentKit, setCurrentKitUsingBarcode } from 'slices/kit';

import Loading from 'components/Loading';
import { Error } from 'ui/ErrorPage';

export default function ReportV2() {
  const { barcode } = useParams() as { barcode: string };
  const { currentKit } = useSelector(selectCurrentKit);
  const dispatch = useDispatch();

  const {
    data: userReports,
    isLoading: userReportLoading,
    error: userReportError,
  } = reportApi.endpoints.getAllReports.useQuery();

  useEffect(() => {
    if (barcode && barcode !== currentKit?.barcode && userReports) {
      dispatch(setCurrentKitUsingBarcode({ barcode, userReports: userReports.userReports }));
    }
  }, [barcode, userReports, dispatch]);

  if (userReportError) return <Error error={userReportError} />;
  if (userReportLoading) return <Loading />;
  return <Outlet />;
}
