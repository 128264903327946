import { Box, createStyles, Stack, Text } from '@mantine/core';
import { OrganismPhylaData } from 'types/V2Types';
import { PhylaVisualization } from './PhylaVisualization';
import { TaxonomyPopover } from './TaxonomyPopover';

interface PhylaCompositionCardProps {
  data?: OrganismPhylaData;
}

export function PhylaCompositionCard({ data }: PhylaCompositionCardProps) {
  const { classes } = useStyles();

  return (
    <Box className={classes.card}>
      <Stack spacing="sm" className={classes.content}>
        <Text component="p" weight={700} className={classes.text}>
          <strong>Phyla Composition</strong>
        </Text>
        <Text className={classes.italicText}>
          Phyla are major groups in the classification of living organisms. In the context of the gut microbiome, phyla
          like Bacteroida and Bacillota (formerly Firmicutes) represent large groups of bacterial species that share
          certain characteristics and evolutionary traits.
        </Text>
        <Box className={classes.outerBox}>
          <Box className={classes.innerBox}>
            <PhylaVisualization data={data} />
          </Box>
          <TaxonomyPopover />
        </Box>
      </Stack>
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colors.gray[1],
    padding: theme.spacing.md,
  },
  innerBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  content: {
    gap: theme.spacing.sm,
  },
  infoIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
  },
  italicText: {
    fontStyle: 'italic',
    fontSize: theme.fontSizes.sm,
    lineHeight: 'normal',
  },
  outerBox: {
    width: '100%',
    position: 'relative',
  },
  text: {
    margin: 0,
    padding: 0,
  },
}));
