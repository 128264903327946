import React from 'react';
import { Badge, Box, Tooltip } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

interface ImpactBadgeProps {
  impact: number;
  name: string;
  toolTipText?: string;
}

const ImpactBadge: React.FC<ImpactBadgeProps> = ({ impact, name, toolTipText }) => {
  const isPositiveImpact = impact > 0;
  const color = isPositiveImpact ? 'beige.9' : 'blue.4';
  const icon = isPositiveImpact ? faArrowUp : faArrowDown;

  return (
    <Tooltip width={220} label={toolTipText} multiline withArrow withinPortal disabled={!toolTipText}>
      <Box style={{ zIndex: 2 }}>
        <Badge
          radius="sm"
          style={{ border: '0.5px solid' }}
          color={color}
          leftSection={<FontAwesomeIcon icon={icon} />}
        >
          {name}
        </Badge>
      </Box>
    </Tooltip>
  );
};

export default ImpactBadge;
