import { Button, Group, Title, TitleOrder } from '@mantine/core';
import LinkButton from 'components/ReportV2/elements/LinkButton';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import { FC, ReactNode } from 'react';
import useLinkedSectionHeadStyles from './useLinkedSectionHeadStyles';

interface LinkedSectionHeadProps {
  title: ReactNode;
  order?: TitleOrder;
  id?: string;
  to?: string;
  tooltip?: ReactNode;
  tooltipDirection?: any;
  spoilerControlRef?: React.RefObject<HTMLButtonElement>;
  spoilerIsOpen?: boolean;
  openSpoilerClick?: (ref: any) => void;
}

const LinkedSectionHead: FC<LinkedSectionHeadProps> = ({
  title,
  order = 4,
  id,
  to,
  tooltip,
  tooltipDirection = 'top-right',
  spoilerControlRef,
  openSpoilerClick,
  spoilerIsOpen,
}) => {
  const { classes } = useLinkedSectionHeadStyles();

  return (
    <Group id={id} position="apart" mb="sm">
      <Group spacing="sm">
        <Title ff="Sora" order={order}>
          {title}
        </Title>
        {!!tooltip && <TitlePopover content={tooltip} direction={tooltipDirection} />}
      </Group>
      {!!to && (
        <LinkButton
          to={to}
          compact
          size="xs"
          variant="outline"
          className={classes.btn}
          onClick={(e) => {
            if (to.startsWith('#')) {
              e.preventDefault();
              document.querySelector(to)?.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          View all
        </LinkButton>
      )}
      {!!openSpoilerClick && (
        <Button
          compact
          size="xs"
          variant="outline"
          className={classes.btn}
          ref={spoilerControlRef}
          onClick={() => openSpoilerClick(spoilerControlRef)}
        >
          {spoilerIsOpen ? 'Hide all' : 'View all'}
        </Button>
      )}
    </Group>
  );
};

LinkedSectionHead.defaultProps = {
  order: 4,
  tooltipDirection: 'top-right',
};

export default LinkedSectionHead;
