import { type TableProps as MantineTableProps, createStyles } from '@mantine/core';
import React from 'react';

interface TableProps extends MantineTableProps {
  data: string[][] | React.ReactNode[][];
  divider?: boolean;
}

const CompactTable: React.FC<TableProps> = ({ data, divider = true, ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <table {...props} className={classes.table}>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} className={cx(divider && classes.tr_divider, classes.tr)}>
            {row.map((column, columnIndex) => (
              <td key={columnIndex} className={classes.td}>
                {column}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const useStyles = createStyles((theme) => {
  const rowBorder = `1px solid ${theme.colors.dark[1]}`;
  return {
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      tableLayout: 'auto',
    },

    tr: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    tr_divider: {
      '&:not(:last-child)': {
        borderBottom: rowBorder,
      },
    },

    td: {
      paddingTop: theme.spacing.lg,
      paddingBottom: theme.spacing.lg,
    },
  };
});

export default CompactTable;
