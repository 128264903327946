import { Badge, Flex, Text, Tooltip, createStyles, Skeleton } from '@mantine/core';
import AssociationSection from 'components/ReportV2/elements/AssociationSection';
import LinkButton from 'components/ReportV2/elements/LinkButton';
import { FC, useEffect } from 'react';
import reportApi from 'services/reportV2';
import { ReportOrganism } from 'types/V2Types';
import List from 'ui/List';
import InlineMD from '../elements/InlineMarkdown';

interface DietRecommendationProps {
  findingId: string;
  barcode: string;
  actionId?: string;
}

const DietRecommendation: FC<DietRecommendationProps> = ({ findingId, barcode, actionId = 'high_score' }) => {
  const {
    data: action,
    isLoading: actionIsLoading,
    error,
  } = reportApi.useGetActionQuery({ barcode, findingId, actionId });

  if (error) {
    console.error('Error in the Diet Recommendation: ', error);
  }

  const { classes, cx } = useStyles();

  useEffect(() => {
    if (actionIsLoading || !action) return;
  }, [action, actionIsLoading]);

  if (actionIsLoading || !action) return <Skeleton mt="md" mb="xl" ml="xl" height={300} width={600} animate={false} />;

  const getBadgeClass = () => {
    let className;
    if (action.guidance === 'Adopt') {
      className = classes.positiveLabel;
    } else {
      className = classes.negativeLabel;
    }
    return cx(classes.listItemBadge, classes.label, className);
  };

  const getOrganismBadgeClass = (organism: ReportOrganism) => {
    let className;
    if (organism.score >= 0) {
      className = classes.positiveLabel;
    } else {
      className = classes.negativeLabel;
    }
    return cx(classes.listItemBadge, classes.label, className);
  };

  const getOrganismShortDescription = (organism: ReportOrganism) => {
    let shortDescription = organism.one_line_description;
    shortDescription = shortDescription || organism.description?.split('\n\n')[0];
    return shortDescription;
  };

  const organismHasDescription = (organism: any) => {
    return getOrganismShortDescription(organism) === '';
  };

  const description = action.one_line_description !== '' ? action.one_line_description : action.description;
  const name = action.preferred_name ?? action.name;

  return (
    <Flex className={classes.cardListItem} py="sm" direction="column" mx="xl" mb="lg">
      <Flex align="center" mb="lg">
        <Badge size="md" mr="sm" className={getBadgeClass()}>
          {action.guidance}
        </Badge>
        <Text mr="sm" fw="600">
          <InlineMD>{name}</InlineMD>
        </Text>
        <AssociationSection associationScore={action.association_score} researchStatus={action.research_status} />
        <LinkButton
          ml="sm"
          h="20px"
          size="xs"
          to={`/reports/${barcode}/findings/${findingId}/actions/${action.action_id}`}
        >
          Details
        </LinkButton>
      </Flex>
      <Flex>
        <Text mb="sm">{description}</Text>
      </Flex>
      {action.recommended_foods && action.recommended_foods.length > 0 && (
        <>
          <Text mb="sm" fw="600">
            Foods to eat
          </Text>
          <List items={action.recommended_foods.slice(0, 3)} />
        </>
      )}
      {action.foods_to_avoid && action.foods_to_avoid.length > 0 && (
        <>
          <Text mt="md" mb="sm" fw="600">
            Foods to avoid
          </Text>
          <List items={action.foods_to_avoid.slice(0, 3)} />
        </>
      )}
      {!action.recommended_foods && !action.foods_to_avoid && <Text mb="sm">{action.description}</Text>}
      {action.organisms.length > 0 && (
        <Flex direction="column">
          <Text mt="md" mb="sm" fw="600">
            Impact on microbiome
          </Text>
          <Flex direction="row" justify="flex-start" gap="sm" align="center" mb="md" wrap="wrap">
            {action.organisms.slice(0, 3).map((organism: ReportOrganism, index: number) => (
              <Flex key={index}>
                <Tooltip
                  disabled={organismHasDescription(organism)}
                  multiline
                  w={400}
                  withArrow
                  label={getOrganismShortDescription(organism)}
                >
                  <Badge size="md" className={getOrganismBadgeClass(organism)}>
                    {organism.score < 0 ? 'Decrease' : 'Increase'} <i>{organism.name}</i>
                  </Badge>
                </Tooltip>
              </Flex>
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default DietRecommendation;

DietRecommendation.defaultProps = {
  actionId: 'high_score',
};

const useStyles = createStyles((theme) => ({
  label: {
    border: `1px solid ${theme.colors.gray[5]}`,
    borderRadius: '3px',
  },
  positiveLabel: {
    backgroundColor: `${theme.colors.green[1]} !important`,
  },
  negativeLabel: {
    backgroundColor: `${theme.colors.red[1]} !important`,
  },
  neutralLabel: {
    backgroundColor: `${theme.colors.gray[1]} !important`,
  },
  cardListItem: {
    // borderBottom: '0.5px solid #AAAAAA',
    fontSize: theme.fontSizes.sm,
  },
  listItemBadge: {
    minWidth: '72px',
    backgroundColor: '#eeeeee',
    color: 'black',
    fontWeight: 500,
    textTransform: 'none',
    borderRadius: '3px',
  },
  linkBtn: {
    flexGrow: 1,
  },
}));
