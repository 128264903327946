import {
  type ReportFinding,
  type ReportAction,
  type AllFindings,
  ActionPlan,
  ActionPlanQuery,
  ReportOrganismQuery,
  OrganismResult,
  FindingOverview,
  OrganismGeneraResult,
  FindingListItem,
  ReportDiff,
  IReportChangelog,
  GetAllReportsResponse,
  OrganismPhylaResult,
  OrganismPhylaData,
} from 'types/V2Types';
import { emptySplitApi, TagTypes } from './emptyApi';
import { Article } from 'ui/Jona-v1/Overview/TopArticles';

const BASE_PATH = '/api/report';

interface ReportFindingQuery {
  barcode: string;
  findingId: string;
}

interface ReportActionQuery {
  barcode: string;
  findingId: string;
  actionId: string;
}

const reportApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    // Fetch a single report by barcode
    getReport: builder.query({
      query: (barcode) => ({
        url: `${BASE_PATH}/${barcode}`,
      }),
      providesTags: (barcode) => [{ type: TagTypes.report, id: barcode }],
    }),

    // Fetch all reports
    getAllReports: builder.query<GetAllReportsResponse, void>({
      query: () => ({
        url: `${BASE_PATH}/all`,
      }),
      providesTags: (result) =>
        result
          ? [...result.userReports, ...result.providerReports, ...result.authorizedReports]
              .flat()
              .map((report) => ({ type: TagTypes.report, id: report.barcode }))
              .concat({ type: TagTypes.user, id: result.auth0Id })
          : [],
    }),

    // Fetch a finding
    getFinding: builder.query<ReportFinding, ReportFindingQuery>({
      query: ({ barcode, findingId }) => ({
        url: `${BASE_PATH}/${barcode}/finding/${findingId}`,
      }),
      providesTags: (result, error, arg) =>
        result?.finding_id
          ? [
              { type: TagTypes.finding, id: result.finding_id },
              { type: TagTypes.report, id: arg.barcode },
            ]
          : [],
    }),

    // Fetch all findings
    getAllFindings: builder.query<AllFindings, string | undefined>({
      query: (barcode) => ({
        url: `${BASE_PATH}/${barcode}/findings`,
      }),
      providesTags: (result) => (result ? [{ type: TagTypes.report, id: result.barcode }] : []),
    }),

    // Fetch an action
    getAction: builder.query<ReportAction, ReportActionQuery>({
      query: ({ barcode, findingId, actionId }) => ({
        url: `${BASE_PATH}/${barcode}/finding/${findingId}/action/${actionId}`,
      }),
      providesTags: (result, error, arg) =>
        result?.kb_id
          ? [
              { type: TagTypes.action, id: result.kb_id },
              { type: TagTypes.report, id: arg.barcode },
            ]
          : [],
    }),

    // Fetch the action plan
    getActionPlan: builder.query<ActionPlan, { barcode: string | undefined; query: ActionPlanQuery }>({
      query: ({ barcode, query }) => ({
        url: `${BASE_PATH}/${barcode}/plan`,
        params: query,
      }),
      providesTags: (result, error, arg) =>
        result?.goal_kb_id
          ? [
              { type: TagTypes.action, id: result.goal_kb_id },
              { type: TagTypes.report, id: arg.barcode },
            ]
          : [],
    }),

    // Fetch available action plan findings
    getActionPlanFindings: builder.query<FindingListItem[], string | undefined>({
      query: (barcode) => ({
        url: `${BASE_PATH}/${barcode}/plan/findings`,
      }),
    }),

    // Fetch the action plan
    getOverview: builder.query<FindingOverview, ReportFindingQuery>({
      query: ({ barcode, findingId }) => ({
        url: `${BASE_PATH}/${barcode}/finding/${findingId}/overview`,
      }),
      providesTags: (result, error, arg) =>
        result?.kb_id
          ? [
              { type: TagTypes.action, id: result.kb_id },
              { type: TagTypes.report, id: arg.barcode },
            ]
          : [],
    }),

    // Fetch report organisms with pagination and search
    getReportOrganisms: builder.query<OrganismResult, { barcode: string; query: ReportOrganismQuery }>({
      query: ({ barcode, query }) => ({
        url: `${BASE_PATH}/${barcode}/organisms`,
        method: 'GET',
        params: query,
      }),
      providesTags: (result, error, { barcode, query }) => (result ? [{ type: TagTypes.organism, id: barcode }] : []),
    }),

    // Fetch report organsim genera
    getReportOrganismGenera: builder.query<OrganismGeneraResult, { barcode: string }>({
      query: ({ barcode }) => ({
        url: `${BASE_PATH}/${barcode}/organisms/genera`,
        method: 'GET',
      }),
    }),

    // Fetch report organsim phyla
    getReportOrganismPhyla: builder.query<OrganismPhylaData, { barcode: string }>({
      query: ({ barcode }) => ({
        url: `${BASE_PATH}/${barcode}/organisms/phyla`,
        method: 'GET',
      }),
      transformResponse: (response: OrganismPhylaResult): OrganismPhylaData => {
        const organismData: OrganismPhylaData = {
          name: 'root',
          abundance: 0,
          children: response?.phyla.map((phylum) => ({
            name: phylum.name,
            abundance: phylum.abundance,
            children: [],
          })),
        };
        return organismData;
      },
    }),

    // Fetch the report Diff
    getReportDiff: builder.query<ReportDiff, { barcode: string }>({
      query: ({ barcode }) => ({
        url: `${BASE_PATH}/${barcode}/diff`,
        method: 'GET',
        params: { intake: true },
      }),
      providesTags: (result, error, { barcode }) => (result ? [{ type: TagTypes.diff, id: barcode }] : []),
    }),

    // Set the diff as viewed
    setDiffAsViewed: builder.mutation<void, { barcode: string; diffId: string }>({
      query: ({ barcode, diffId }) => ({
        url: `${BASE_PATH}/${barcode}/diff/${diffId}/viewed`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, { barcode, diffId }) => [{ type: TagTypes.diff, id: barcode }],
    }),

    // Fetch the report changelog
    getChangelog: builder.query<IReportChangelog, { barcode: string }>({
      query: ({ barcode }) => ({
        url: `${BASE_PATH}/${barcode}/changelog`,
        method: 'GET',
      }),
    }),

    // Fetch the top articles
    getTopArticles: builder.query<Article[], { barcode: string; amount: number }>({
      query: ({ barcode, amount }) => ({
        url: `${BASE_PATH}/${barcode}/top-papers`,
        method: 'GET',
        params: { amount },
      }),
    }),
  }),
});

export default reportApi;
