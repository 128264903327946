import React, { useRef, useState, useEffect } from 'react';
import { Tooltip, Text, type TextProps } from '@mantine/core';

interface TruncatedTextProps extends TextProps {
  // TextProps is not enough unfortunately, and to avoid handling typescript errors for now, we will use any
  component?: any;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ children, ...props }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const checkTruncation = () => {
      if (textRef.current) {
        const { clientHeight, scrollHeight } = textRef.current;
        const isCurrentlyTruncated = scrollHeight > clientHeight;
        setIsTruncated(isCurrentlyTruncated);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);

    return () => {
      window.removeEventListener('resize', checkTruncation);
    };
  }, [children]);

  return (
    <Tooltip multiline label={children} disabled={!isTruncated}>
      <Text ref={textRef} lineClamp={2} {...props}>
        {children}
      </Text>
    </Tooltip>
  );
};

export default TruncatedText;
