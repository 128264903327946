import { MantineStyleSystemProps, Text, Tooltip, TooltipProps, createStyles } from '@mantine/core';
import { getAssociationText, getEvidenceLabel } from 'utilsV2';

interface DotsProps extends MantineStyleSystemProps {
  n: number;
  disabled?: boolean;
  withBorder?: boolean;
}

const Dots: React.FC<DotsProps> = ({ n, disabled = false, withBorder = false, ...props }: DotsProps) => {
  const { classes } = useStyles({ withBorder });

  return (
    <Tooltip {...props} label={getAssociationText(n) + ' Association'} disabled={disabled} withArrow withinPortal >
      <Text className={classes.dotsText}>{getEvidenceLabel(n)}</Text>
    </Tooltip>
  );
}

const useStyles = createStyles((theme, { withBorder }: { withBorder: boolean; }) => ({
  dotsText: {
    display: 'inline-block',
    backgroundColor: 'white',
    textAlign: 'center',
    lineHeight: theme.spacing.md,
    borderRadius: '30px',
    width: '42px',
    height: '18px',
    fontSize: '14px',
    letterSpacing: '1px',
    color: 'black',
    ...(withBorder && {
      border: `1px solid ${theme.black}`,
    }
    )
  },
}));

export default Dots;