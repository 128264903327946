import { useParams } from 'react-router-dom';
import { Title } from '@mantine/core';

import reportApi from 'services/reportV2';

import { Disclaimer } from 'ui/Disclaimer';
import { PageHeader } from 'ui/PageHeader';
import { PageUnavailable } from 'ui/ErrorPage';
import Loading from 'components/Loading';
import Action, { ActionProps } from 'components/ReportV2/Action/Action';
import HeadAssociationBlock from 'components/ReportV2/elements/HeadAssociationBlock';
import InlineMD from 'components/ReportV2/elements/InlineMarkdown';
import { PageContainer } from 'ui/Containers';

export default function ActionPage() {
  const { barcode, findingId, actionId } = useParams() as { barcode: string; findingId: string; actionId: string };

  const {
    data: actionData,
    isLoading: actionIsLoading,
    error,
  } = reportApi.useGetActionQuery({ barcode, findingId, actionId });

  if (error) return <PageUnavailable />;
  if (actionIsLoading) return <Loading />;
  if (!actionData) return <Title m="xl">Report not found</Title>;

  const actionProps: ActionProps = {
    ...actionData,
    barcode,
  };

  return (
    <>
      <PageHeader
        title={
          <InlineMD>
            {actionData.guidance} {actionData.name}
          </InlineMD>
        }
        pageTitle={actionData.guidance}
        showBackButton
      />
      <PageContainer size="md">
        <Action {...actionProps} />
        <Disclaimer />
      </PageContainer>
    </>
  );
}
