import { Box, Button, Flex, Grid, TextInput, createStyles } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useEffect } from 'react';
import * as Yup from 'yup';

import Row from 'components/Row';
import userApi from 'services/user';
import { useAppDispatch, useNotifications } from 'hooks';
import { IUserProfile } from 'types/user';
import React from 'react';
import { setUser } from 'slices/user';

const schema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
});

interface ProfileFormProps {
  profileData: IUserProfile;
}

const ProfileForm: React.FC<ProfileFormProps> = React.memo(({ profileData }) => {
  const { firstName, lastName, email } = profileData;
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { showNotification, NotificationType } = useNotifications();

  const [updateProfile, res] = userApi.endpoints.updateProfile.useMutation();

  const form = useForm({
    initialValues: {
      firstName,
      lastName,
      email,
    },
    validate: yupResolver(schema),
  });

  useEffect(() => {
    if (res.isSuccess) {
      showNotification({
        type: NotificationType.SUCCESS,
        message: 'Profile updated',
      });
      dispatch(setUser({ firstName: form.values.firstName, lastName: form.values.lastName }));
    }
  }, [res.isSuccess]);

  function handleSubmit(values: any) {
    updateProfile({
      firstName: values.firstName,
      lastName: values.lastName,
    });
  }

  return (
    <Box className={classes.container}>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Grid>
          <Grid.Col span={6}>
            <TextInput
              className={classes.input}
              label="First Name"
              placeholder="Enter your first name..."
              {...form.getInputProps('firstName')}
            />
            </Grid.Col>
            <Grid.Col span={6}>
          <TextInput
            className={classes.input}
            label="Last Name"
            placeholder="Enter your last name..."
            {...form.getInputProps('lastName')}
          />
        </Grid.Col>
        </Grid>
        <TextInput
          className={classes.input}
          label="Email"
          placeholder="Enter your email..."
          disabled
          {...form.getInputProps('email')}
        />
        <Row>
          <Button className={classes.button} type="submit">
            Save
          </Button>
        </Row>
      </form>
    </Box>
  );
});

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: theme.spacing.lg,
  },
  button: {
    marginLeft: 'auto',
  },
}));

export default ProfileForm;
