import { Box, Collapse, Flex, Group, Paper, SimpleGrid, Text, createStyles } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import CollapsibleHeader from 'components/ReportV2/elements/CollapsibleHeader';
import { BLOCK } from 'components/ReportV2/elements/constants';
import { Link } from 'react-router-dom';
import { unCapitalizeAll } from 'utils';
import { ReportActionOverview, ReportTopMatch } from 'types/V2Types';
import ImpactBadge from './ImpactBadge';
import AssociationSection from './AssociationSection';
import InlineMD from './InlineMarkdown';
import Card from 'components/Card';

interface ActionCardProps {
  action: ReportActionOverview;
  barcode: string;
  findingId: string;
  index: number;
  showIcons?: boolean;
  finding_kb: string;
}

function ActionCard({ action, barcode, findingId, index, showIcons, finding_kb }: ActionCardProps) {
  const { classes, cx } = useStyles({ action: action.guidance });
  const actionName = action.name;
  const isSupplement = action.category?.includes('Supplement');

  const description = action.one_line_description ? action.one_line_description : action.description;

  const isNegative = negativeImpact.includes(action.guidance);
  const textColor = isNegative ? 'red.9' : 'green.9';

  return (
    <Card radius="sm">
      <Flex direction="column" gap="xs" h="100%">
        <Link
          className="stretched-link"
          to={`/reports/${barcode}/${finding_kb}/${findingId}/actions/${action.action_id}`}
        >
          <Group position="apart">
            <Text fw="500">
              {index + 1}.{' '}
              <Text fw={700} span c={textColor}>
                {action.guidance}
              </Text>{' '}
              <InlineMD>{actionName}</InlineMD>
            </Text>
            {showIcons && !isSupplement && (
              <Group position="apart">
                <Box className={classes.iconWithTip}>
                  <AssociationSection
                    isNewItem={action.isNewItem}
                    hasChanges={!!action.changes}
                    associationScore={action.association_score}
                    researchStatus={action.research_status}
                  />
                </Box>
                <Box className={cx(classes.papersBadge, classes.iconWithTip)}>
                  {action.papers_length && BLOCK.NUMBER_PAPERS_ICON(action.papers_length)}
                </Box>
              </Group>
            )}
          </Group>
        </Link>
        <Text fz="sm" lineClamp={2} className={classes.grow}>
          {description}
        </Text>
        <Flex justify="flex-start" gap="xs" align="flex-start" direction="row" wrap="wrap">
          {action.top_matches?.map((match: ReportTopMatch, i: number) => (
            <ImpactBadge
              key={i}
              name={match.name}
              impact={match.impact_on_association}
              toolTipText={match.one_line_description}
            />
          ))}
        </Flex>
      </Flex>
    </Card>
  );
}

const negativeImpact = ['Avoid', 'Worsen', 'Consider Limiting'];
const positiveImpact = ['Adopt', 'Improve'];

function getTagBg(action: string) {
  if (negativeImpact.includes(action)) return 'rgba(205, 27, 27, 0.5)';
  if (positiveImpact.includes(action)) return 'rgba(54, 157, 83, 0.7)';
}

const useStyles = createStyles((_, { action }: { action: string }) => ({
  itemCard: {
    position: 'relative',
    backgroundColor: 'rgba(4, 74, 126, 0.05)',
    borderColor: 'rgba(4, 74, 126, 0.2) !important',

    '.action-tag': {
      backgroundColor: getTagBg(action),
    },

    '.main-grid &:first-of-type': {
      backgroundColor: negativeImpact.includes(action) ? 'rgba(228, 108, 109, 0.2)' : 'rgba(54, 157, 83, 0.1)',
      borderColor: negativeImpact.includes(action)
        ? 'rgba(228, 108, 109, 0.5) !important'
        : 'rgba(54, 157, 83, 0.2) !important',
    },
  },
  papersBadge: {
    borderRadius: '8px',
    padding: '2px 6px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)',

    svg: {
      fontSize: 'small',
    },
  },
  grow: {
    flexGrow: 1,
  },
  iconWithTip: {
    zIndex: 2,
  },
}));

interface ActionsSectionProps {
  name: string;
  actions: ReportActionOverview[];
  barcode: string;
  findingId: string;
  isMetabolism?: boolean;
  extended_guidance?: string[];
  finding_kb: string;
}

export default function ActionsSection({
  name,
  actions,
  barcode,
  findingId,
  isMetabolism,
  extended_guidance,
  finding_kb,
}: ActionsSectionProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const showN = 4;

  const title = isMetabolism && extended_guidance ? 'Interpretation' : 'Actionable Insights';

  return (
    <>
      <CollapsibleHeader
        title={title}
        tooltip={BLOCK.ACTIONABLE_INSIGHTS}
        tooltipDirection={'bottom-right'}
        onClick={toggle}
        buttonContent={opened ? 'Collapse' : 'View all'}
        showButton={actions.length > showN}
      />

      {!isMetabolism && (
        <Text mb="md">
          Here are actionable steps you can consider that may decrease your microbiome association with{' '}
          {unCapitalizeAll(name)}. These recommendations are ranked by their effectiveness as determined by Jona AI.
        </Text>
      )}

      {/* NO ACTIONS */}
      {!isMetabolism && !actions.length && (
        <Paper withBorder radius={2} fz="sm" p="lg">
          {BLOCK.NO_ACTION}
        </Paper>
      )}

      {isMetabolism && extended_guidance && (
        <Flex gap="lg" direction="column">
          {extended_guidance.map((guidance, i) => (
            <Text key={i}>
              <InlineMD>{guidance}</InlineMD>
            </Text>
          ))}
          <InlineMD>
            _Any changes in diet or lifestyle should be made under the supervision of a doctor or dietician._
          </InlineMD>
        </Flex>
      )}

      {/* ACTIONS */}
      {actions.length > 0 && (
        <>
          <SimpleGrid mb="md" mt="lg" className="main-grid">
            {actions.slice(0, showN).map((action: any, i: number) => (
              <ActionCard
                key={i}
                action={action}
                barcode={barcode}
                findingId={findingId}
                index={i}
                showIcons={true}
                finding_kb={finding_kb}
              />
            ))}
          </SimpleGrid>
          <Collapse in={opened}>
            <SimpleGrid>
              {actions.slice(showN).map((action: any, i: number) => (
                <ActionCard
                  key={i}
                  action={action}
                  barcode={barcode}
                  findingId={findingId}
                  index={showN + i}
                  showIcons={true}
                  finding_kb={finding_kb}
                />
              ))}
            </SimpleGrid>
          </Collapse>
        </>
      )}
    </>
  );
}
