import {
  Breadcrumbs as MantineBreadcrumbs,
  type BreadcrumbsProps as MantineBreadcrumbsProps,
  UnstyledButton,
  createStyles,
} from '@mantine/core';
import { formatReportName } from 'appConfig/navbarConfig';
import InlineMD from 'components/ReportV2/elements/InlineMarkdown';
import { useAppSelector } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import reportApi from 'services/reportV2';
import { UserMode } from 'slices/app';
import { selectCurrentKit } from 'slices/kit';
import { UserReport } from 'types/V2Types';

interface BreadcrumbsProps extends Omit<MantineBreadcrumbsProps, 'children'> {}

interface Breadcrum {
  label: string;
  link: string;
}

const breadcrumbNameMap: { [key: string]: string | null } = {
  summary: 'Summary',
  symptoms: 'Symptoms',
  conditions: 'Conditions',
  metabolisms: 'Food Digestion',
  goals: 'Health Goals',
  plan: 'Action Plan',
  pathogens: 'Pathogens',
  organisms: 'Organisms',
  actions: null, // null to hide, will render the action name instead
  reports: null, // null to hide, will render the report date instead
  client: null,
  edit: 'Edit',

  // Intake routes
  intake: 'Health Survey',
  consent: 'Consent',
  demographics: 'Demographics',
  kit: 'Health Goals',
  lifestyle: 'Lifestyle & Nutrition',
  history: 'Health History',
};

const getSegmentLabel = (
  segment: string,
  previousSegment: string,
  currentKit?: UserReport,
  findingName?: string,
  actionName?: string,
  currentClientName?: string
) => {
  if (previousSegment === 'reports' && currentKit?.created_at && currentKit?.userAccess?.hasAccess) {
    return formatReportName(currentKit.created_at);
  } else if (previousSegment === 'symptoms' && currentKit?.barcode) {
    return findingName || ':symptomId';
  } else if (previousSegment === 'conditions') {
    return findingName || ':conditionId';
  } else if (previousSegment === 'metabolisms') {
    return findingName || ':metabolismId';
  } else if (previousSegment === 'actions') {
    return actionName || ':actionId';
  } else if (previousSegment === 'client') {
    return currentClientName || ':clientId';
  }
  return breadcrumbNameMap[segment];
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ ...props }) => {
  // Hook methods
  const { classes } = useStyles();
  const location = useLocation();
  // Store methods
  const { userMode } = useAppSelector((state) => state.app);
  const { currentKit } = useSelector(selectCurrentKit);
  const { currentClientKits, currentClientName } = useAppSelector((state) => state.clientKit);
  // Local methods
  const [crumbs, setCrumbs] = useState<Breadcrum[]>([]);
  const pathnames = location.pathname.split('/').filter((x) => x);

  // Determine the correct kit based on userMode
  const activeKit = useMemo(() => {
    if (userMode === UserMode.PROVIDER && currentClientKits) {
      return currentClientKits.find((kit) => pathnames.includes(kit.barcode)) || currentKit;
    }
    return currentKit;
  }, [userMode, currentClientKits, currentKit, pathnames]);

  const findingId = pathnames.find(
    (_, index) =>
      pathnames[index - 1] === 'symptoms' ||
      pathnames[index - 1] === 'conditions' ||
      pathnames[index - 1] === 'metabolisms'
  );
  const actionId = pathnames.find((segment, index) => pathnames[index - 1] === 'actions');

  const { data: findingData } = reportApi.useGetFindingQuery(
    {
      barcode: String(activeKit?.barcode),
      findingId: findingId as string,
    },
    { skip: !activeKit?.barcode || !findingId }
  );
  const { data: actionData } = reportApi.useGetActionQuery(
    {
      barcode: String(activeKit?.barcode),
      findingId: findingId as string,
      actionId: actionId as string,
    },
    { skip: !activeKit?.barcode || !findingId || !actionId }
  );

  useEffect(() => {
    const newCrumbs = [];
    for (let index = 0; index < pathnames.length; index++) {
      const segment = pathnames[index];
      const previousSegment = pathnames[index - 1];
      const label = getSegmentLabel(
        segment,
        previousSegment,
        activeKit,
        findingData?.name,
        actionData?.name,
        currentClientName
      );
      const link = `/${pathnames.slice(0, index + 1).join('/')}`;
      label && newCrumbs.push({ label, link });
    }
    setCrumbs(newCrumbs);
  }, [location.pathname, findingData, actionData]);

  return crumbs.length > 0 ? (
    <MantineBreadcrumbs classNames={{ separator: classes.separator, breadcrumb: classes.breadcrumb }} {...props}>
      {crumbs.map(({ label, link }, index) => (
        <UnstyledButton component={Link} to={link} key={index}>
          <InlineMD>{label}</InlineMD>
        </UnstyledButton>
      ))}
    </MantineBreadcrumbs>
  ) : null;
};

const useStyles = createStyles((theme) => ({
  separator: {
    paddingLeft: theme.spacing.xs,
    paddingRight: theme.spacing.xs,
    color: theme.colors.gray[6],
  },
  breadcrumb: {
    color: theme.colors.gray[6],
    ':last-of-type': {
      color: theme.colors.gray[9],
    },
    '&:hover': {
      color: theme.colors.gray[9],
      textDecoration: 'underline',
    },
  },
}));
export default Breadcrumbs;
