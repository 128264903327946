import { Badge, Button, Text, Title, createStyles } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';

import Label from 'components/Label';
import ActivationForm from 'components/Landing/ActivationForm';
import userApi, { IsIntakeComplete, IsIntakeV2Completed, ShouldShowIntakeV2 } from 'services/user';
import Loading from '../components/Loading';
import Card from 'components/Card';
import { PageHeader } from 'ui/PageHeader';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';

export default function RegisterKit() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { data: userData, isLoading, error } = userApi.endpoints.getUser.useQuery();

  function postActivation() {
    if (userData) {
      if (ShouldShowIntakeV2(userData)) {
        if (IsIntakeV2Completed(userData)) {
          navigate('/reports');
        } else {
          navigate('/intake');
        }
      } else {
        navigate('/intake/consent');
      }
    }
  }

  function handleIntakeClick() {
    if (userData) {
      navigate('/intake');
    }
  }

  function handleReportClick() {
    navigate('/reports');
  }

  if (error) return <Error error={error} />;
  if (isLoading) return <Loading />;
  if (!userData) return <Title m="xl">Report not found</Title>;

  const KIT_REGISTERED = userData.barcodes.length > 0;
  const INTAKE_COMPLETE = IsIntakeComplete(userData);

  return (
    <>
      <PageHeader title="Activate Kit" showBackButton={false} />
      <PageContainer size="md">
        <Title mb="sm" className={classes.title}>
          1. Activate your Kit
          {KIT_REGISTERED && (
            <Badge className={classes.badge} variant="filled">
              Completed
            </Badge>
          )}
        </Title>
        <Card>
          <Text mb="lg">
            Your Kit Code is the 8-digit number that can be found on the upper right corner of your kit box or on the
            test tube.
          </Text>
          <ActivationForm postActivation={postActivation} />
        </Card>

        <Title mb="sm" className={classes.title} color={!KIT_REGISTERED ? 'gray' : ''}>
          2. Submit your health survey
          {INTAKE_COMPLETE && (
            <Badge className={classes.badge} variant="filled">
              Completed
            </Badge>
          )}
        </Title>
        <Card>
          <Text mb="sm" color={!KIT_REGISTERED ? 'gray' : ''}>
            Complete your health survey to receive personalized recommendations based on your unique microbiome and
            health goals.
          </Text>
          <Button fullWidth mt="lg" variant="filled" onClick={handleIntakeClick} disabled={!KIT_REGISTERED}>
            Review Health Survey
          </Button>
        </Card>

        <Title mb="sm" color={!INTAKE_COMPLETE ? 'gray' : ''} className={classes.title}>
          3. Dive into your microbiome report
        </Title>
        <Card>
          <Text color={!INTAKE_COMPLETE ? 'gray' : ''}>
            Your AI-generated microbiome profiling report should be available 3-4 weeks after we receive your sample.
          </Text>
          <Button fullWidth mt="lg" variant="filled" disabled={!INTAKE_COMPLETE} onClick={handleReportClick}>
            View Report
          </Button>
        </Card>

        <Card mt="lg" mb="xl">
          <Label>Need help?</Label>
          <br />
          <Link className={classes.link} to="/support">
            Help & Support
          </Link>
        </Card>
      </PageContainer>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.lg,
  },
  contentCol: {
    maxWidth: '608px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  link: {
    color: theme.colors.blue[5],
  },
  intakeStatus: {
    fontSize: '32px',
  },
  title: {
    display: 'flex',
    marginTop: theme.spacing.xl,
    fontSize: theme.fontSizes.lg,
    textTransform: 'uppercase',

    ':first-of-type': {
      marginTop: 0,
    },
  },
  badge: {
    marginLeft: 'auto',
  },
}));
