import { Box, Title, Text } from '@mantine/core';
import Logo from 'components/Logo';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const UseComputer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isMobile = /Mobi|Android/i.test(userAgent);

    if (!isMobile) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <Box style={{ padding: '50px', textAlign: 'center' }}>
      <Title>Mobile Version Coming Soon!</Title>
      <Text component="p">We are currently working on a mobile version of our application.</Text>
      <Text component="p">In the meantime, please use a computer to access our services.</Text>
      <br />
      <Logo />
    </Box>
  );
};

export default UseComputer;
