import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { SurveySource, openSurveyDialog } from 'slices/surveyDialog';
import { AppDispatch } from 'store';

interface CustomButtonProps extends ButtonProps {
  source?: SurveySource;
  withIcon?: boolean;
}

const OpenSurveyButton: React.FC<CustomButtonProps> = ({ source, withIcon, children, ...props }) => {
  const dispatch: AppDispatch = useDispatch();

  const handleOpenDialog = () => {
    dispatch(openSurveyDialog(source || SurveySource.BUTTON));
  };

  return (
    <Button ff="Sora" fz="xs" fw="500" onClick={handleOpenDialog} {...props}>
      {children}
      {withIcon && <FontAwesomeIcon icon={faEdit} style={{ marginLeft: '8px' }} />}
    </Button>
  );
};

export default OpenSurveyButton;
