import React from 'react';
import PageUnavailable from './PageUnavailable';
import UnknownError from './UnknownError';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import ModalUnavailable from './ModalUnavailable';

interface ErrorProps {
  error?: FetchBaseQueryError | SerializedError;
  isModal?: boolean;
}

const Error: React.FC<ErrorProps> = ({ error, isModal = false }) => {
  // 4xx errors - you don't have access to this link or this link is invalid.
  if (error && 'status' in error && Number(error.status) >= 400 && Number(error.status) < 500) {
    if (isModal) return <ModalUnavailable />;
    return <PageUnavailable />;
  } else {
    // default unknown error - try again later
    return <UnknownError />;
  }
};

export default Error;
