import { Badge, Button, Text, Title, createStyles } from '@mantine/core';
import { Link, useNavigate } from 'react-router-dom';

import Label from 'components/Label';
import ActivateBarcodeForm from 'components/Activation/ActivateBarcodeForm';
import userApi, { IsIntakeComplete, IsIntakeV2Completed, ShouldShowIntakeV2 } from 'services/user';
import Loading from '../components/Loading';
import Card from 'components/Card';
import { PageHeader } from 'ui/PageHeader';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';

export default function ActivationPage() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { data: userData, isLoading, error } = userApi.endpoints.getUser.useQuery();

  if (error) return <Error error={error} />;
  if (isLoading) return <Loading />;
  if (!userData) return <Title m="xl">User not found</Title>;

  function postActivation(barcode: string) {
    navigate(`/reports/${barcode}/intake`);
  }

  return (
    <>
      <PageHeader title="Activate Kit" showBackButton={false} />
      <PageContainer size="md">
        <Title mb="sm" className={classes.title}>
          Activate Kit
        </Title>
        <Card>
          <ActivateBarcodeForm postActivation={postActivation} />
        </Card>

        <Card mt="lg" mb="xl">
          <Label>Need help?</Label>
          <br />
          <Link className={classes.link} to="/support">
            Help & Support
          </Link>
        </Card>
      </PageContainer>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    marginBottom: theme.spacing.lg,
  },
  contentCol: {
    maxWidth: '608px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  link: {
    color: theme.colors.blue[5],
  },
  intakeStatus: {
    fontSize: '32px',
  },
  title: {
    display: 'flex',
    marginTop: theme.spacing.xl,
    fontSize: theme.fontSizes.lg,
    textTransform: 'uppercase',

    ':first-of-type': {
      marginTop: 0,
    },
  },
  badge: {
    marginLeft: 'auto',
  },
}));
