import { Box } from '@mantine/core';
import { usePageMetadata } from 'hooks';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { ConsentFormMarkdown } from 'types/markdown';
import { JonaEmblemBlackCircleIcon } from 'ui/Logo';

export default function Consent() {
  usePageMetadata({ title: 'Consent Form' });

  return (
    <Box pl="xl" pr="xl">
      <div style={{ width: '54px', height: '54px' }}>
        <Link to="/">
          <JonaEmblemBlackCircleIcon size={34} />
        </Link>
      </div>
      <br />
      <Markdown>{ConsentFormMarkdown}</Markdown>
    </Box>
  );
}
