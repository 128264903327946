const heights = [
  '4\'5"',
  '4\'6"',
  '4\'7"',
  '4\'8"',
  '4\'9"',
  '4\'10"',
  '4\'11"',
  '5\'0"',
  '5\'1"',
  '5\'2"',
  '5\'3"',
  '5\'4"',
  '5\'5"',
  '5\'6"',
  '5\'7"',
  '5\'8"',
  '5\'9"',
  '5\'10"',
  '5\'11"',
  '6\'0"',
  '6\'1"',
  '6\'2"',
  '6\'3"',
  '6\'4"',
  '6\'5"',
  '6\'6"',
  '6\'7"',
  '6\'8"',
  '6\'9"',
  '6\'10"',
  '6\'11"',
  'Other',
];
export default heights;

export function getHeightInches(height: string): number {
  if (height === 'Other' || !height) return 0;
  const [feet, inches] = height.split("'");
  return parseInt(feet) * 12 + parseInt(inches.replace('"', ''));
}
