import { faCheckCircle, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { notifications } from '@mantine/notifications';
import { Text, UnstyledButton, createStyles } from '@mantine/core';

interface NotificationProps {
  type: NotificationType;
  message: React.ReactNode;
  callBack?: () => void;
  callBackText?: React.ReactNode;
}

enum NotificationType {
  SUCCESS = 'success',
  DENY = 'deny',
  INFO = 'info',
  ERROR = 'error',
}

function useNotifications() {
  const { classes } = useNotificationsStyles();

  const showNotification = ({ type, message, callBack, callBackText }: NotificationProps) => {
    const icons = {
      [NotificationType.SUCCESS]: <FontAwesomeIcon className={classes.success_icon} icon={faCheckCircle} />,
      [NotificationType.DENY]: <FontAwesomeIcon className={classes.deny_icon} icon={faCircleXmark} />,
      [NotificationType.INFO]: null,
      [NotificationType.ERROR]: <FontAwesomeIcon className={classes.error_icon} icon={faCircleXmark} />,
    };

    notifications.show({
      message: (
        <Text span inline>
          <Text mr="sm" span inline>
            {icons[type]}
          </Text>
          {message}
          {callBack && callBackText && (
            <UnstyledButton onClick={callBack}>
              <Text td="underline" size="xs" ml="sm" color="white">
                {callBackText}
              </Text>
            </UnstyledButton>
          )}
        </Text>
      ),
      autoClose: 5000,
      withCloseButton: true,
      styles: (theme) => ({
        root: {
          width: '100%',
          height: '48px',
          marginTop: '0!important',
          backgroundColor: theme.colors.dark[4],
          borderRadius: 0,
          textAlign: 'center',
        },

        title: { color: theme.white },
        description: { color: theme.white },
        closeButton: {
          color: theme.white,
          '&:hover': { backgroundColor: theme.colors.blue[7] },
        },
      }),
    });
  };

  return { showNotification, NotificationType };
}
const useNotificationsStyles = createStyles((theme) => ({
  success_icon: {
    color: theme.colors.green[5],
  },
  deny_icon: {
    color: '#ffffff',
  },
  error_icon: {
    color: theme.colors.red[5],
  },
}));

export default useNotifications;
