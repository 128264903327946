import { Box, type BoxProps, Text, Flex, Stack, ThemeIcon, createStyles } from '@mantine/core';
import NavButton from './NavButton';
import BadgeScroller from './BadgeScroller';

export interface Goal {
  title: string;
  actions?: string[];
  noAssociation?: boolean;
}

interface GoalsProps extends BoxProps {
  goals?: Goal[];
  goalsPageUrl: string;
}

const GoalsOverview: React.FC<GoalsProps> = ({ goals = [], goalsPageUrl, ...props }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.goalsOverview} {...props}>
      {/* Header */}
      <Flex justify="space-between" align="center">
        <Text fw={700}>Your Goals</Text>
        <NavButton to={goalsPageUrl} color="blue" compact>
          View your goals
        </NavButton>
      </Flex>

      {/* Body */}
      {goals?.length > 0 ? (
        goals.map(({ title, actions = [], noAssociation }, index) => (
          <Flex key={index} p="md" mt="xs" className={classes.goalItem}>
            <ThemeIcon
              mt="sm"
              mr="sm"
              radius="xl"
              color="dark"
              variant="light"
              size="lg"
              className={classes.customThemeIconColor}
            >
              <Text fw={700}>{index + 1}.</Text>
            </ThemeIcon>
            <Stack w="100%" spacing="md">
              <Text fw={700} inline>
                Reduce and manage {title}
              </Text>
              {noAssociation ? (
                <Text>
                  Jona’s database does not currently cover associations between {title} and the gut microbiome as the
                  current research is too limited to provide substantial recommendations. We recommend discussing this
                  symptom with your doctor to investigate other potential root causes and create a treatment plan.
                </Text>
              ) : actions?.length > 0 ? (
                <Stack spacing="sm">
                  <Text inline>Consider the following:</Text>
                  <BadgeScroller badges={actions} />
                </Stack>
              ) : (
                <Text>
                  We did not find any direct microbiome-associated actions based on current scientific literature. As
                  research evolves, we will share updated insights with you.
                </Text>
              )}
            </Stack>
          </Flex>
        ))
      ) : (
        <Stack mt="lg" spacing="sm">
          <Text fw={700}>No set goals</Text>
          <Text>
            It looks like you have not reported any symptoms! We have found some microbiome associations that you might
            like to address. See the next section, Jona’s key findings!
          </Text>
        </Stack>
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  goalsOverview: {
    backgroundColor: theme.colors.gray[0],
    padding: theme.spacing.lg,
    border: '1px solid #ffffff',
  },
  goalItem: {
    maxWidth: 600,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  customThemeIconColor: {
    backgroundColor: theme.colors.gray[2],
  },
}));

export default GoalsOverview;
