import { Button, Container, Divider, Flex, Group, Select, Text, Title, createStyles } from '@mantine/core';
import { ActionPlanCondition, ActionPlanPathogen, DietRec } from '../components/ReportV2/ActionPlan';
import LinkButton from 'components/ReportV2/elements/LinkButton';
import Loading from 'components/Loading';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import reportApi from 'services/reportV2';
import userApi from 'services/user';
import { FindingListItem, RelatedPathogen, ReportFinding, SampleMetadata } from 'types/V2Types';
import { capitalize } from 'utils';
import { PageHeader } from 'ui/PageHeader';
import { Disclaimer } from 'ui/Disclaimer';
import { Error } from 'ui/ErrorPage';
import { useQuery } from 'hooks';
import { ResizableCarousel } from 'ui/Carousel';
import { PageContainer } from 'ui/Containers';
import Card from 'components/Card';
import sampleApi from 'services/sample';

export default function ActionPlan() {
  const { classes } = useStyles();
  const { barcode } = useParams() as { barcode: string };
  const [query, setQuery] = useQuery();

  const [foodExpanded, setfoodExpanded] = useState(false);
  const [conditionExpanded, setConditionExpanded] = useState(false);
  const [pathogensExpanded, setPathogensExpanded] = useState(false);
  const [dietsExpanded, setDietsExpanded] = useState(false);
  const [sampleMetadata, setSampleMetadata] = useState<SampleMetadata | null>(null);

  const { data, isLoading, error } = reportApi.useGetReportQuery(barcode);
  const { data: userData, isLoading: isUserLoading, error: userError } = userApi.endpoints.getUser.useQuery();
  const {
    data: sampleMetadataResponse,
    isLoading: isSampleMetadataLoading,
    error: sampleMetadataError,
  } = sampleApi.endpoints.getSampleMetadata.useQuery({ barcode });

  const {
    data: actionPlanFindings,
    isLoading: actionPlanFindingsIsLoading,
    error: actionPlanError,
  } = reportApi.endpoints.getActionPlanFindings.useQuery(barcode);
  const {
    data: plan,
    isLoading: actionIsLoading,
    error: actionError,
  } = reportApi.endpoints.getActionPlan.useQuery({
    barcode: barcode,
    query: { findingId: query.get('findingId') || undefined },
  });

  useEffect(() => {
    if (sampleMetadataResponse) {
      setSampleMetadata(sampleMetadataResponse);
    }
  }, [sampleMetadataResponse, setSampleMetadata]);

  let stepNumber = 1;

  function toggleExpandedTriggers() {
    setfoodExpanded(!foodExpanded);
  }

  function toggleExpandedConditions() {
    setConditionExpanded(!conditionExpanded);
  }

  function toggleExpandedDietsLifestyles() {
    setDietsExpanded(!dietsExpanded);
  }

  function toggleExpandedPathogens() {
    setPathogensExpanded(!pathogensExpanded);
  }

  function getAndIncrementStepNumber() {
    const currentNumber = stepNumber;
    stepNumber += 1;
    return currentNumber;
  }

  const mapFindingsToDropdown = (findingListItems: FindingListItem[]) => {
    const genera = findingListItems
      .map((finding: FindingListItem) => {
        return {
          value: finding.finding_id,
          label: finding.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    return genera;
  };

  const handleFindingChange = (findingId: string) => {
    setQuery('findingId', findingId);
  };

  const showLoading =
    actionIsLoading ||
    !plan ||
    actionPlanFindingsIsLoading ||
    isLoading ||
    isUserLoading ||
    !userData ||
    !data ||
    isSampleMetadataLoading;

  if (error || userError || actionPlanError || actionError)
    return <Error error={error || userError || actionPlanError || actionError} />;

  const sampleUser: string = sampleMetadata?.userFirstName || userData?.firstName || '';

  return (
    <>
      <PageHeader title="Action Plan" />
      <PageContainer size="md">
        {showLoading ? (
          <Loading my="auto" />
        ) : (
          <>
            <Card>
              <Group grow mb="lg">
                <Title ff="Sora" order={3}>
                  {capitalize(sampleUser)}, here is your action plan for
                </Title>
                {actionPlanFindings && actionPlanFindings.length > 0 && (
                  <Select
                    data={mapFindingsToDropdown(actionPlanFindings)}
                    onChange={(value) => handleFindingChange(value as string)}
                    defaultValue={plan?.goal_finding_id}
                    variant="filled"
                    size="sm"
                    styles={(theme) => ({
                      wrapper: {},
                      input: {
                        background: theme.colors.gray[0],
                        border: '1px solid #fff',
                        color: 'black',
                        fontSize: '20px',
                        fontWeight: 700,
                        lineHeight: '37.8px',
                      },
                    })}
                  ></Select>
                )}
              </Group>

              {plan.trigger_foods.length > 0 && (
                <>
                  <Flex className={classes.title}>
                    <Title mt="md" ff="Sora" order={5}>
                      {getAndIncrementStepNumber()}. Consider limiting trigger compounds
                    </Title>
                    {plan.trigger_foods.length > 1 && (
                      <Flex justify="flex-end" ml="sm" h="20px" className={classes.linkBtn}>
                        <Button ff="Sora" fz="xs" fw="500" onClick={toggleExpandedTriggers} ml="md" h="20px" size="xs">
                          {foodExpanded ? 'Collapse' : 'Show All'}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                  <Container px="0" fluid>
                    {!foodExpanded && plan.trigger_foods.length > 1 ? (
                      <ResizableCarousel
                        slides={plan.trigger_foods.map((trigger: any, index: number) => {
                          return {
                            content: <DietRec barcode={barcode} {...trigger} />,
                            id: index,
                          };
                        })}
                      />
                    ) : (
                      plan.trigger_foods.map((trigger: any, index: number) => {
                        return (
                          <div key={index}>
                            {index !== 0 && <Divider mb="lg" mx="xl" />}
                            <DietRec barcode={barcode} {...trigger} />
                          </div>
                        );
                      })
                    )}
                  </Container>
                </>
              )}

              {plan.diet_recommendations.length > 0 && (
                <>
                  <Flex className={classes.title}>
                    <Title ff="Sora" order={5}>
                      {getAndIncrementStepNumber()}. Consider the following diet and lifestyle changes
                    </Title>
                    {plan.diet_recommendations.length > 1 && (
                      <Flex justify="flex-end" ml="sm" h="20px" className={classes.linkBtn}>
                        <Button
                          ff="Sora"
                          fz="xs"
                          fw="500"
                          onClick={toggleExpandedDietsLifestyles}
                          ml="md"
                          h="20px"
                          size="xs"
                        >
                          {dietsExpanded ? 'Collapse' : 'Show All'}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                  <Container px="0" fluid>
                    {!dietsExpanded && plan.diet_recommendations.length > 1 ? (
                      <ResizableCarousel
                        slides={plan.diet_recommendations.map((diet: any, index: number) => {
                          return {
                            content: <DietRec barcode={barcode} {...diet} />,
                            id: index,
                          };
                        })}
                      />
                    ) : (
                      plan.diet_recommendations.map((diet: any, index: number) => {
                        return (
                          <div key={index}>
                            {index !== 0 && <Divider mb="lg" mx="xl" />}
                            <DietRec barcode={barcode} {...diet} />
                          </div>
                        );
                      })
                    )}
                  </Container>
                </>
              )}

              {plan.associated_conditions.length > 0 && (
                <>
                  <Flex className={classes.title}>
                    <Title ff="Sora" order={5}>
                      {getAndIncrementStepNumber()}. Speak to your doctor if you are concerned about any of the
                      following conditions.
                    </Title>
                    {plan.associated_conditions.length > 1 && (
                      <Flex justify="flex-end" ml="sm" h="20px" className={classes.linkBtn}>
                        <Button
                          ff="Sora"
                          fz="xs"
                          fw="500"
                          onClick={toggleExpandedConditions}
                          ml="md"
                          h="20px"
                          size="xs"
                        >
                          {conditionExpanded ? 'Collapse' : 'Show All'}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                  <Text mx="xl" size="sm" mb="lg">
                    These are conditions that can cause {plan?.goal_name.toLowerCase()} and were found to be associated
                    with your microbiome. This does not mean that you have these conditions, but rather that your
                    microbiome shares a similar signature observed in research related to these health issues.
                  </Text>

                  <Divider mb="lg" mx="xl" />
                  <Container px="0" fluid>
                    {!conditionExpanded && plan.associated_conditions.length > 1 ? (
                      <ResizableCarousel
                        slides={plan.associated_conditions.map((finding: ReportFinding, index: number) => {
                          return { content: <ActionPlanCondition finding={finding} barcode={barcode} />, id: index };
                        })}
                      />
                    ) : (
                      plan.associated_conditions.map((finding: ReportFinding, index: number) => {
                        return (
                          <div key={index}>
                            {index !== 0 && <Divider mb="lg" mx="xl" />}
                            <ActionPlanCondition finding={finding} barcode={barcode} />
                          </div>
                        );
                      })
                    )}
                  </Container>
                </>
              )}

              {plan.pathogens.length > 0 && (
                <>
                  <Flex className={classes.title}>
                    <Title ff="Sora" order={5}>
                      {getAndIncrementStepNumber()}. Consult with your doctor about your detected pathogens
                    </Title>
                    {plan.pathogens.length > 1 && (
                      <Flex justify="flex-end" ml="sm" h="20px" className={classes.linkBtn}>
                        <Button ff="Sora" fz="xs" fw="500" onClick={toggleExpandedPathogens} ml="md" h="20px" size="xs">
                          {conditionExpanded ? 'Collapse' : 'Show All'}
                        </Button>
                      </Flex>
                    )}
                  </Flex>
                  <Container px="0" fluid>
                    {!pathogensExpanded && plan.pathogens.length > 1 ? (
                      <ResizableCarousel
                        slides={plan.pathogens.map((pathogen: RelatedPathogen, index: number) => {
                          return {
                            content: <ActionPlanPathogen pathogen={pathogen} />,
                            id: index,
                          };
                        })}
                      />
                    ) : (
                      plan.pathogens.map((pathogen: RelatedPathogen, index: number) => {
                        return (
                          <div key={index}>
                            {index !== 0 && <Divider mb="lg" mx="xl" />}
                            <ActionPlanPathogen pathogen={pathogen} />
                          </div>
                        );
                      })
                    )}
                  </Container>
                </>
              )}

              <Title ff="Sora" order={5} className={classes.title}>
                {getAndIncrementStepNumber()}. Track progress and retest
              </Title>
              <Container px="md" mx="lg" fluid>
                <Text m="sm" size="sm">
                  After making the above changes to your microbiome, you can retest in three to six months to see if
                  your microbiome shifted in a positive direction and aligns with your health objectives. We hope to see
                  your symptoms improve, your microbiome diversity increase, and your microbiome signature shift toward
                  reduced associations with conditions and symptoms. These shifts in your microbiome and changing
                  associations should be discussed with a professional who may be able to offer tailored advice and
                  guidance.
                </Text>
                <Text m="sm" size="sm">
                  Across tests, we can start to understand how your gut is changing over time and in response to
                  different strategies. If your symptoms worsen or change, please consult your primary care provider for
                  more guidance.
                </Text>
                <Flex justify="center">
                  <LinkButton
                    to="https://jona.health/products/microbiome-test"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Plan your next test
                  </LinkButton>
                </Flex>
              </Container>
            </Card>
            <Disclaimer />
          </>
        )}
      </PageContainer>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  title: {
    borderBottom: '0.5px solid #6C6F75',
    margin: theme.spacing.md,
    paddingBottom: theme.spacing.xs,
  },
  paper: {
    backgroundColor: 'rgba(4, 74, 126, 0.06)',
    borderColor: `${theme.colors.gray[4]} !important`,
    borderRadius: '7px',
  },
  linkBtn: {
    flexGrow: 1,
  },
  input: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '37.8px',
  },
  slideItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '24px',
  },
}));
