import { Fragment } from 'react';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, createStyles } from '@mantine/core';

const taxonomyLevels = [
  { name: 'Phyla', width: 177, color: '#224DFC' },
  { name: 'Classes', width: 161, color: 'rgba(39, 160, 65, 0.95)' },
  { name: 'Orders', width: 145, color: 'rgba(158, 107, 219, 0.95)' },
  { name: 'Families', width: 129, color: '#AB5F7D' },
  { name: 'Genera', width: 113, color: '#047F81' },
  { name: 'Species', width: 97, color: '#C9501D' },
];

export function TaxonomyHierarchy() {
  const { classes } = useStyles();

  return (
    <Box className={classes.hierarchyCard}>
      {taxonomyLevels.map((level, index) => (
        <Fragment key={level.name}>
          <Button className={classes.taxonomyButton} sx={{ width: level.width, backgroundColor: level.color }}>
            {level.name}
          </Button>
          {index < taxonomyLevels.length - 1 && <FontAwesomeIcon icon={faArrowDown} />}
        </Fragment>
      ))}
    </Box>
  );
}

const useStyles = createStyles((theme) => ({
  hierarchyCard: {
    backgroundColor: '#CBCBCB',
    padding: theme.spacing.md,
    border: '1px solid white',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    alignItems: 'center',
  },
  taxonomyButton: {
    borderRadius: 3,
  },
}));
