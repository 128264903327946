import { Text, Flex } from '@mantine/core';

export default function DigestionDisclaimer() {
  return (
    <Flex mb="lg" direction='column'>
      <Text mb="sm">
        The following is an understanding of your food digestion capability based on your microbiome and health survey.{' '}
        <span style={{ fontWeight: 700 }}>
          It&apos;s important to understand that these associations are not clinical diagnoses.
        </span>{' '}
        They should be used as supplemental materials when consulting with your physician or a qualified nutritionist.
      </Text>
      <Text>
        This is an actively researched area at Jona, and future releases will include understanding related to other major dietary components and the process of digestion.
      </Text>
    </Flex>
  );
}
