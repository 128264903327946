import { Badge, createStyles, type BadgeProps } from '@mantine/core';

export enum BadgeStatus {
  active = 'Active',
  pending = 'Pending',
  denied = 'Denied',
}

interface StatusBadgeProps extends Omit<BadgeProps, 'children'> {
  status: BadgeStatus;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ status, ...props }) => {
  const { classes, cx } = useStyles();
  return (
    <Badge
      color="gray.3"
      variant="filled"
      radius="xs"
      size="lg"
      className={cx(classes[status], classes.statusBadge)}
      {...props}
    >
      {status}
    </Badge>
  );
};
const useStyles = createStyles((theme) => ({
  statusBadge: {
    textTransform: 'capitalize',
    padding: theme.spacing.sm,
    fontSize: theme.fontSizes.xs,
  },

  [BadgeStatus.active]: {
    color: theme.colors.dark[5],
  },

  [BadgeStatus.pending]: {
    color: theme.colors.dark[2],
  },

  [BadgeStatus.denied]: {
    color: theme.colors.dark[8],
    backgroundColor: theme.colors.dark[2],
  },
}));

export default StatusBadge;
