export class IntakeV2 {
  public id: string = '';

  public tosAndPrivacy: boolean = false;
  public consent: boolean = false;

  public kit_registration: IntakeKitRegistration = new IntakeKitRegistration();
  public demographics: IntakeDemographics = new IntakeDemographics();
  public health_history: IntakeHealthHistory = new IntakeHealthHistory();
  public lifestyle: IntakeLifestyle = new IntakeLifestyle();
  public nutrition: IntakeNutrition = new IntakeNutrition();

  public creation_date: Date = new Date();
}

export class IntakeNutrition {
  public completed: boolean = false;
  public fruits: boolean = false;
  public greensOrLeafyVegetables: boolean = false;
  public starchyVegetables: boolean = false;
  public grains: boolean = false;
  public redMeat: boolean = false;
  public fishOrSeafood: boolean = false;
  public fermentedFoods: boolean = false;
  public oliveOil: boolean = false;
  public dessertsOrSweets: boolean = false;
  public sweetenedBeverages: boolean = false;
  public dairyProducts: boolean = false;
  public nutritionNotes: string = '';
}

export class IntakeLifestyle {
  public completed: boolean = false;
  public smoker: string = '';
  public alcoholicBeveragesPerWeek: number = 0;
  public recreationalDrugs: string = '';
  public exerciseFrequencyPerWeek: string = '';
  public exerciseType: string = '';
  public changeInPhysicalActivity: string = '';
  public changeInPhysicalActivity_other: string = '';
  public travelOutsideUSFrequency: string = '';
  public livingAreaClassification: string = '';
  public stressLevel: number = 0;
  public hoursSleepPerNight: string = '';
  public averageSleepQuality: string = '';
  public lifestyleNotes: string = '';
}

export class IntakeHealthHistory {
  public completed: boolean = false;

  public historyConditions: string[] = [];
  public historyConditions_other: string = '';

  public historySymptoms: string[] = [];
  public historySymptoms_other: string = '';

  public bloodType: string = '';

  public currentMedications: string[] = [];
  public currentMedications_other: string = '';
  public currentMedications_explanation: string = '';
  public currentMedications_glp1Brands: string[] = [];

  public effectiveMedTreatmentBehaviorChange: string[] = [];

  public currentSupplements: string[] = [];
  public currentSupplements_other: string = '';

  public bariatricSurgery: string = '';

  public surgeries: string = '';

  public bowelMovementFrequency: string = '';
  public bowelMovementFrequency_other: string = '';

  public lastAntibioticsUse: string = '';
  public lastAntibioticsUse_other: string = '';

  public foodIntoleranceOrSensitivity: string[] = [];
  public foodIntoleranceOrSensitivity_other: string = '';

  public healthNotes: string = '';
}

export class IntakeKitRegistration {
  public completed: boolean = false;

  public barcode: string = '';
  public sampleDate: Date = new Date();

  public menstrationOrPregnant: string[] = [];
  public menstrationOrPregnant_other: string = '';

  public diagnosedClinicalConditionsLast6Months: string[] = [];
  public diagnosedClinicalConditionsLast6Months_other: string = '';
  public diagnosedClinicalConditionsMostConcerned: string = '';
  public conditionSeverity: string = '';

  public symptomsLast6Months: string[] = [];
  public symptomsLast6Months_other: string = '';
  public symptomsMostConcerned: string = '';
  public symptomsBeganAfter: string = '';
  public symptomsSeverity: string = '';

  public wouldLikeToLearn: string[] = [];
  public wouldLikeToLearn_other: string = '';

  public referredBy: string[] = [];
  public referredBy_other: string = '';

  public provider: string = '';

  public foodSensitivitiesOrAllergies: string[] = [];
  public foodSensitivitiesOrAllergies_other: string = '';

  public giSymptomImpact: string[] = [];
  public giSymptomImpact_other: string = '';

  public biggestHealthGoal: string = '';
  public biggestHeathGoal_other: string = '';
}

export class IntakeDemographics {
  public completed: boolean = false;

  public firstName: string = '';
  public lastName: string = '';

  public zip: string = '';
  public state: string = '';

  public gender: string = '';
  public gender_other: string = '';
  public sex: string = '';

  public ethnicity: string = '';

  public height: string = '';
  public heightInches: number = 0;
  public weight: number = 0;
  public bmi: number = 0;

  public birthdate: Date = new Date();
  public income: string = '';
}

export enum IntakeStep {
  ToSConsent = 'ToSConsent',
  KitRegistration = 'KitRegistration',
  Demographics = 'Demographics',
  HealthHistory = 'HealthHistory',
  LifestyleAndNutrition = 'LifestyleAndNutrition',
}

export const IntakeTitles = {
  [IntakeStep.ToSConsent]: 'Terms of Service & Privacy Policy',
  [IntakeStep.KitRegistration]: 'Health Goals',
  [IntakeStep.Demographics]: 'Demographics',
  [IntakeStep.HealthHistory]: 'Health History & Status',
  [IntakeStep.LifestyleAndNutrition]: 'Lifestyle & Nutrition',
};

export default IntakeV2;
