import {
  Client,
  ClientInviteData,
  ClientListData,
  ClientsCount,
  EditClientData,
  Invite,
  InviteList,
  ProviderInvite,
  ReleaseToClient,
  ReleaseToClientResponse,
} from 'types/provider';
import { TagTypes, emptySplitApi } from './emptyApi';
import { ClientData } from 'ui/Jona-v1/Forms/AddClientForm';

export interface GetClientsQuery {
  page?: number;
  perPage?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  filters?: string[];
}

const providerApi = emptySplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getProvider: builder.query<any, { providerId: number }>({
      query: ({ providerId }) => ({
        url: `/api/provider/${providerId}`,
      }),
    }),
    getInviteData: builder.query<ClientInviteData, { providerId: number }>({
      query: ({ providerId }) => ({
        url: `/api/provider/${providerId}/client/invite/default`,
      }),
    }),
    getClients: builder.query<ClientListData, { providerId: number; query: GetClientsQuery }>({
      query: ({ providerId, query }) => ({
        url: `/api/provider/${providerId}/clients/search`,
        params: query,
      }),
      providesTags: (result, error) => (result && !error ? [{ type: TagTypes.clients }] : []),
    }),
    searchInvites: builder.query<InviteList, { providerId: number; query: GetClientsQuery }>({
      query: ({ providerId, query }) => ({
        url: `/api/provider/${providerId}/invites/search`,
        params: query,
      }),
      providesTags: (result, error) => (result && !error ? [{ type: TagTypes.invites }] : []),
    }),
    getClient: builder.query<Client, { providerId: number; clientId: number | string }>({
      query: ({ providerId, clientId }) => ({
        url: `/api/provider/${providerId}/client/${clientId}`,
      }),
      providesTags: (result, error, { providerId, clientId }) =>
        result && !error ? [{ type: TagTypes.client, providerId, clientId }] : [],
    }),
    saveClient: builder.mutation<
      EditClientData,
      { providerId: number; clientId: number | string; clientData: ClientData }
    >({
      query: ({ providerId, clientId, clientData }) => ({
        url: `/api/provider/${providerId}/client/${clientId}`,
        method: 'POST',
        body: clientData,
      }),
      invalidatesTags: (result, error, { providerId, clientId }) =>
        result && !error ? [{ type: TagTypes.client, providerId, clientId }] : [],
    }),
    getInvitedClient: builder.query<Invite, { providerId: number; clientId: number | string }>({
      query: ({ providerId, clientId }) => ({
        url: `/api/provider/${providerId}/client/${clientId}/invite`,
      }),
      providesTags: (result, error) => (result && !error ? [{ type: TagTypes.invitedClient }] : []),
    }),
    inviteClient: builder.mutation<any, ProviderInvite>({
      query: (inviteData) => ({
        url: `/api/provider/${inviteData.providerId}/client/invite`,
        method: 'POST',
        body: inviteData,
      }),
      invalidatesTags: [{ type: TagTypes.invites }, { type: TagTypes.invitedClient }, { type: TagTypes.clientCount }],
    }),
    hasProviderRole: builder.query<boolean, void>({
      query: () => ({
        url: `/api/provider/is-provider`,
      }),
    }),
    getClientsCount: builder.query<ClientsCount, { providerId: number }>({
      query: ({ providerId }) => ({
        url: `/api/provider/${providerId}/clients/count`,
      }),
      providesTags: (result, error) => (result && !error ? [{ type: TagTypes.clientCount }] : []),
    }),
    setReleasedToClient: builder.mutation<ReleaseToClientResponse, ReleaseToClient>({
      query: ({ providerId, clientId, sampleId, releasedToClient }) => ({
        url: `/api/provider/${providerId}/client/${clientId}/release-report`,
        method: 'POST',
        body: { sampleId, releasedToClient },
      }),
      invalidatesTags: (result, error) =>
        result && !error ? [{ type: TagTypes.clients }, { type: TagTypes.client }] : [],
    }),
  }),
});

export default providerApi;
