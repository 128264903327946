import { AppShell, Container } from '@mantine/core';
import Loading from 'components/Loading';
import JonaV1Navbar from 'ui/Jona-v1/Navbar';

export default function AppLoadingState() {
  return (
    <AppShell navbar={<JonaV1Navbar loading={true} />}>
      <Container>
        <Loading />
      </Container>
    </AppShell>
  );
}
