import { List, Text } from '@mantine/core';
import ReactMarkdown from 'react-markdown';
import { NewBadge, UpdatesBadge } from 'ui/Badges';

interface ChangelogMarkdownProps {
  children?: string;
}

const ChangelogMarkdown: React.FC<ChangelogMarkdownProps> = ({ children }) => {
  if (!children) return null;
  return (
    <ReactMarkdown
      components={{
        p: ({ node, ...props }) => <Text mt="md">{props.children}</Text>,
        img: ({ node, ...props }) => <img style={{ maxWidth: '100%' }} {...props} />,
        ul: ({ node, ...props }) => <List>{props.children}</List>,
        li: ({ node, ...props }) => <List.Item>{props.children}</List.Item>,
        // Override the rendering of links to conditionally render a badge
        a: ({ href, children: _children, ...props }) => {
          if (href === '#new-badge') {
            return <NewBadge m="xs">{_children}</NewBadge>;
          }
          if (href === '#updated-badge') {
            return <UpdatesBadge m="xs">{_children}</UpdatesBadge>;
          }
          // Fallback to default link rendering for other links
          return (
            <a href={href} {...props}>
              {_children}
            </a>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

export default ChangelogMarkdown;
