import ProfileForm from 'components/Profile/ProfileForm';
import Card from 'components/Card';
import { PageHeader } from 'ui/PageHeader';
import { PageContainer } from 'ui/Containers';
import userApi from 'services/user';
import Loading from 'components/Loading';
import { Error } from 'ui/ErrorPage';

export default function Profile() {
  const { data, isLoading, error } = userApi.endpoints.getProfile.useQuery();

  if (isLoading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <>
      <PageHeader title="Profile" showBackButton={false} />
      <PageContainer size="sm">
        <Card>{data && <ProfileForm profileData={data} />}</Card>
      </PageContainer>
    </>
  );
}
