import { createStyles } from '@mantine/core';

import logoSrc from 'assets/Jona_Logotype_2023_Black.webp';

export default function Logo() {
  const { classes } = useStyles();

  return <img className={classes.logo} src={logoSrc} alt="logo" />;
}

const useStyles = createStyles(() => ({
  logo: {
    height: 24,
  },
}));
