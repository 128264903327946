import { Badge, Title, Flex, Group, Paper, Text, createStyles, type PaperProps, BadgeProps } from '@mantine/core';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import {
  FoodTriggersPopover,
  LifestylePopover,
  MetabolismsPopover,
  NutritionPopover,
  OrganismsPopover,
  PathogensPopover,
  SupplementsPopover,
} from './HealthGoalsCardPopovers';

export enum HealthGoalsVariants {
  Pathogens = 'Pathogens',
  Organisms = 'Organisms',
  Lifestyle = 'Lifestyle',
  Supplements = 'Supplements',
  Metabolisms = 'Metabolisms',
  Nutrition = 'Nutrition',
  FoodTriggers = 'Food Triggers',
}

export enum BadgeGuidance {
  Adopt = 'Adopt',
  BelowMedian = 'Below Median',
  Detected = 'Detected',
}

interface HealthGoalsCardProps extends PaperProps {
  variant: HealthGoalsVariants;
  guidanceRow: { guidance: string; name: string }[];
  itemName: string;
  totalItems: number;
}

const HealthGoalsCard: React.FC<HealthGoalsCardProps> = ({ variant, guidanceRow, itemName, totalItems }) => {
  const { classes, cx } = useStyles();

  const variantClassMap = {
    [HealthGoalsVariants.Pathogens]: classes.healthGoalsCard_blue,
    [HealthGoalsVariants.Organisms]: classes.healthGoalsCard_blue,
    [HealthGoalsVariants.Metabolisms]: classes.healthGoalsCard_blue,
    [HealthGoalsVariants.Lifestyle]: classes.healthGoalsCard_yellow,
    [HealthGoalsVariants.Supplements]: classes.healthGoalsCard_yellow,
    [HealthGoalsVariants.Nutrition]: classes.healthGoalsCard_yellow,
    [HealthGoalsVariants.FoodTriggers]: classes.healthGoalsCard_yellow,
  };

  const variantLabelsMap = {
    [HealthGoalsVariants.Pathogens]: `${totalItems} ${totalItems === 1 ? 'key pathogen' : 'key pathogens'}`,
    [HealthGoalsVariants.Organisms]: `${totalItems} ${totalItems === 1 ? 'organism' : 'organisms'}`,
    [HealthGoalsVariants.Lifestyle]: `${totalItems} ${totalItems === 1 ? 'lifestyle change' : 'lifestyle changes'}`,
    [HealthGoalsVariants.Supplements]: `${totalItems} ${totalItems === 1 ? 'supplement' : 'supplements'}`,
    [HealthGoalsVariants.Metabolisms]: `${totalItems} ${totalItems === 1 ? 'metabolism' : 'metabolisms'}`,
    [HealthGoalsVariants.Nutrition]: `${totalItems} ${
      totalItems === 1 ? 'dietary recommendation' : 'dietary recommendations'
    }`,
    [HealthGoalsVariants.FoodTriggers]: `${totalItems} ${
      totalItems === 1 ? 'low digestion item' : 'low digestion items'
    }`,
  };

  const HealthGoalsCardPopovers = {
    [HealthGoalsVariants.Pathogens]: <PathogensPopover />,
    [HealthGoalsVariants.Organisms]: <OrganismsPopover />,
    [HealthGoalsVariants.Lifestyle]: <LifestylePopover />,
    [HealthGoalsVariants.Supplements]: <SupplementsPopover />,
    [HealthGoalsVariants.Metabolisms]: <MetabolismsPopover />,
    [HealthGoalsVariants.Nutrition]: <NutritionPopover />,
    [HealthGoalsVariants.FoodTriggers]: <FoodTriggersPopover />,
  };

  return (
    <Paper withBorder className={cx(classes.healthGoalsCard, variantClassMap[variant])}>
      <Group position="apart" mb="md">
        <Title order={5} fw={500}>
          {variant}
        </Title>
        <TitlePopover content={HealthGoalsCardPopovers[variant]} direction="top-right" iconVariant="gray" />
      </Group>

      <Text fz="sm" mb="md" mih="60px">
        We identified {variantLabelsMap[variant]} directly linked to {itemName}.
        {totalItems >= 3 ? ' Here are the top 3.' : ''}
      </Text>
      {guidanceRow.map(({ guidance, name }, index) => (
        <Flex className={classes.guidanceRow} key={index} py="sm">
          <Badge
            className={cx(classes.healthGoalsBadge, guidance === BadgeGuidance.Adopt && classes[BadgeGuidance.Adopt])}
            size="md"
            radius="sm"
          >
            {guidance}
          </Badge>
          <Text fw="600" ml="sm" inline>
            {name}
          </Text>
        </Flex>
      ))}
    </Paper>
  );
};
const useStyles = createStyles((theme) => ({
  healthGoalsCard: {
    padding: theme.spacing.lg,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderColor: 'white !important',
    borderRadius: '15px',
    height: '277px',
    width: '277px',
  },
  guidanceRow: {
    borderBottom: `0.5px solid ${theme.colors.gray[6]}`,
    fontSize: theme.fontSizes.xs,
    flexWrap: 'nowrap',
    alignItems: 'center',
    ':last-of-type': {
      borderBottom: 'none',
    },
  },
  healthGoalsCard_blue: {
    backgroundColor: '#c4d6ed5f',
    borderColor: `${theme.colors.blue[3]} !important`,
  },
  healthGoalsCard_yellow: {
    backgroundColor: theme.colors.beige[4],
    borderColor: `${theme.colors.beige[7]} !important`,
  },
  healthGoalsBadge: {
    backgroundColor: '#fff',
    textTransform: 'none',
    fontWeight: 500,
  },
  [BadgeGuidance.Adopt]: {
    backgroundColor: 'rgba(235, 251, 238, 1)',
    border: ` 1px solid ${theme.colors.green[5]}`,
  },
}));
export default HealthGoalsCard;
