import { createStyles } from '@mantine/core';

const useCardStyles = createStyles((theme) => ({
  itemCard: {
    maxWidth: 400,
    position: 'relative',

    '&.improve': {
      backgroundColor: 'rgba(4, 74, 126, 0.05)',
      borderColor: 'rgba(4, 74, 126, 0.2) !important',
    },
    '&.worsen': {
      backgroundColor: 'rgba(228, 108, 109, 0.2)',
      borderColor: 'rgba(228, 108, 109, 0.5) !important',
    },

    '&.improve .action-tag': {
      backgroundColor: 'rgba(54, 157, 83, 0.7) !important',
    },
    '&.worsen .action-tag': {
      backgroundColor: 'rgba(205, 27, 27, 0.5) !important',
    },
  },
  papersBadge: {
    borderRadius: theme.radius.sm,
    padding: '2px 6px',
    backgroundColor: 'rgba(255, 255, 255, 0.70)',

    svg: {
      fontSize: 'small',
    },
  },
  grow: {
    flexGrow: 1,
    maxHeight: 200,
    overflow: 'auto',
  },
}));

export default useCardStyles;
