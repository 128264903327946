import Loading from 'components/Loading';
import sampleApi from 'services/sample';
import IntakeTasklist from './SampleIntakeTasklist';
import { useParams } from 'react-router-dom';
import { Title } from '@mantine/core';
import { Error } from 'ui/ErrorPage';

export default function IntakeLanding() {
  const { barcode } = useParams<{ barcode?: string }>();

  if (!barcode) {
    return <Title m="xl">No barcode found</Title>;
  }

  const { data, isLoading, error } = sampleApi.endpoints.getSampleIntake.useQuery({ barcode });

  if (error) return <Error error={error} />;

  if (isLoading) return <Loading />;

  if (!data) return null;

  return <IntakeTasklist intake={data} />;
}
