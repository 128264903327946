import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Pack } from '@visx/hierarchy';
import { defaultStyles, useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { hierarchy } from 'd3-hierarchy';
import { useMemo } from 'react';
import { OrganismPhylaData } from 'types/V2Types';
import { extent } from 'd3';

const tooltipStyles = {
  ...defaultStyles,
  backgroundColor: 'rgba(0, 0, 0, 0.80)',
  color: '#fff',
};

const width = 340;
const height = 340;
const margin = 10; // Margin for the outer circle

function getBubbleSize(abundance: number) {
  if (abundance >= 50) return 120;
  if (abundance >= 25) return 100;
  if (abundance >= 15) return 80;
  if (abundance >= 5) return 70;
  return 60;
}

function getColor(index: number) {
  if (index === 0) return '#5E7DB6'; // dark blue for largest
  if (index === 1) return '#D3F9D8'; // green for second largest
  return '#E3ECF6'; // light blue for everything else
}

function getAbundanceText(abundance: number) {
  return abundance <= 1 ? '<1%' : `${abundance}%`;
}

function formatAbundance(abundance: number) {
  return `${abundance.toFixed(2)}%`;
}

type TooltipData = Omit<OrganismPhylaData, 'children'>;

interface PhylaVisualizationProps {
  data?: OrganismPhylaData;
}

export function PhylaVisualization({ data }: PhylaVisualizationProps) {
  if (!data) return null;

  const root = useMemo(() => hierarchy(data).sum((d) => d.abundance), []);
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<TooltipData>();

  const { TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const [min, max] = extent(data.children.map((d) => d.abundance)) as [number, number];

  const sizeScale = scaleLinear({
    domain: [min, max],
    range: [20, 75],
  });

  const fontScale = scaleLinear({
    domain: [min, max],
    range: [10, 20],
  });

  return (
    <>
      <svg width={width} height={height}>
        {/* Outer circle */}
        <circle
          cx={width / 2}
          cy={height / 2}
          r={(Math.min(width, height) - margin * 2) / 2}
          fill="#F5F5F6"
          stroke="black"
          strokeWidth={2}
        />
        <Pack root={root} size={[width, height]} radius={(node) => sizeScale(node.data.abundance)} padding={5}>
          {(packData) => {
            return (
              <Group top={0} left={0}>
                {packData
                  .descendants()
                  .slice(1)
                  .sort((a, b) => (a.data.abundance > b.data.abundance ? -1 : 1))
                  .map((node, i) => {
                    const abundance = Math.round(node.data.abundance);
                    const diameter = getBubbleSize(abundance);
                    const color = getColor(i);
                    const isLargestCircle = i === 0;

                    return (
                      <Group key={`node-${i}`} top={node.y + margin} left={node.x + margin}>
                        <circle
                          r={node.r}
                          fill={color}
                          stroke="white"
                          strokeWidth={1}
                          onMouseEnter={(event) => {
                            const { clientX, clientY } = event;
                            showTooltip({
                              tooltipData: {
                                name: node.data.name,
                                abundance: node.data.abundance,
                              },
                              tooltipTop: clientY,
                              tooltipLeft: clientX,
                            });
                          }}
                          onMouseLeave={() => hideTooltip()}
                        />
                        <text
                          dy=".3em"
                          textAnchor="middle"
                          fontSize={fontScale(node.data.abundance)}
                          fill={isLargestCircle ? 'white' : 'black'}
                          style={{ pointerEvents: 'none' }}
                        >
                          {node.data.name.length > 10 ? `${node.data.name.slice(0, 10)}...` : node.data.name}
                        </text>
                        <text
                          dy={diameter / 4}
                          textAnchor="middle"
                          fontSize={fontScale(node.data.abundance)}
                          fill={isLargestCircle ? 'white' : 'black'}
                          style={{ pointerEvents: 'none' }}
                        >
                          {getAbundanceText(abundance)}
                        </text>
                      </Group>
                    );
                  })}
              </Group>
            );
          }}
        </Pack>
      </svg>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal left={tooltipLeft} style={tooltipStyles} top={tooltipTop}>
          {tooltipData.name} - {formatAbundance(tooltipData.abundance)}
        </TooltipInPortal>
      )}
    </>
  );
}
