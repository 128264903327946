import {
  Button,
  Input,
  SegmentedControl,
  Title,
  Flex,
  Text,
  Stack,
  List,
  Loader,
  Select,
  createStyles,
} from '@mantine/core';
import OrganismsList from 'components/ReportV2/elements/OrganismsList';
import Loading from 'components/Loading';
import { useParams } from 'react-router-dom';
import reportApi from 'services/reportV2';
import {
  type ReportOrganismQuery,
  SortingOrder,
  OrganismFilter,
  OrganismGeneraResult,
  OrganismGenus,
} from 'types/V2Types';
import { useEffect, useState } from 'react';
import Label from 'components/Label';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import { PageHeader } from 'ui/PageHeader';
import { Disclaimer } from 'ui/Disclaimer';
import { Error } from 'ui/ErrorPage';
import { PageContainer } from 'ui/Containers';

export default function Organisms() {
  const { barcode } = useParams() as { barcode: string };

  const { classes } = useStyles();
  const [genusFiltered, setGenusFiltered] = useState(false);
  const [queryParams, setQueryParams] = useState<ReportOrganismQuery>({
    sortByRelativeAbundance: SortingOrder.DESC,
    page: 1,
    perPage: 10,
    filterBy: OrganismFilter.PHYLUN,
  });

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: 1, search: event.target.value }));
  };

  const handleFilterChange = (filter: OrganismFilter) => {
    if (filter === OrganismFilter.GENUS) {
      setGenusFiltered(true);
    } else {
      setGenusFiltered(false);
    }

    setQueryParams((prevParams) => ({
      ...prevParams,
      page: 1,
      filterBy: filter,
    }));
  };

  const handleSortChange = () => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      page: 1,
      sortByRelativeAbundance:
        prevParams.sortByRelativeAbundance === SortingOrder.DESC ? SortingOrder.ASC : SortingOrder.DESC,
    }));
  };

  const handlePageChange = (newPage: number) => {
    setQueryParams((prevParams) => ({ ...prevParams, page: newPage }));

    window.scrollTo(0, 0);
  };

  const handleGenusSelection = (genusId: string) => {
    if (genusId !== '') {
      setQueryParams((prevParams) => ({
        ...prevParams,
        page: 1,
        filterBy: OrganismFilter.GENUS,
        genusId: genusId,
      }));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading, isFetching, error } = reportApi.useGetReportOrganismsQuery({ barcode, query: queryParams });
  const {
    data: generaData,
    isLoading: generaLoading,
    isFetching: generaFetching,
    error: generaError,
  } = reportApi.useGetReportOrganismGeneraQuery({ barcode });

  if (generaError || error) return <Error error={generaError || error} />;
  if (isLoading || generaLoading) return <Loading />;
  if (!data || !generaData) return <Title m="xl">No organisms found</Title>;

  const { organisms, total, totalDetected } = data;

  const totalPages = queryParams.perPage ? Math.ceil(total / queryParams.perPage) : 0;

  const mapFilterToText = () => {
    switch (queryParams.filterBy) {
      case OrganismFilter.PHYLUN:
        return 'Phyla';
      case OrganismFilter.PATHOGEN:
        return 'Pathogens';
      case OrganismFilter.PROBIOTIC:
        return 'Probiotics';
      case OrganismFilter.NONE:
        return 'Organisms';
    }
  };

  const mapGeneraToDropdown = (generaData: OrganismGeneraResult) => {
    const genera = generaData.genera.map((genus: OrganismGenus) => {
      return {
        value: genus.id,
        label: genus.name,
      };
    });
    return genera;
  };

  return (
    <>
      <PageHeader title="Organisms" />
      <PageContainer>
        {/* Search Bar */}
        <Input
          spellCheck={false}
          rightSection={isFetching || generaFetching ? <Loader size="xs" color="gray" variant="bars" /> : null}
          placeholder="Search organisms..."
          value={queryParams.search}
          onChange={handleSearchChange}
          style={{ marginBottom: '16px' }}
        />

        <Flex justify="space-between" mb="lg">
          {/* Filter Options */}
          <div>
            <Label>Filters</Label>
            <SegmentedControl
              data={[
                { value: OrganismFilter.PHYLUN, label: 'Phyla' },
                { value: OrganismFilter.PATHOGEN, label: 'Pathogens' },
                { value: OrganismFilter.PROBIOTIC, label: 'Probiotics' },
                { value: OrganismFilter.NONE, label: 'All Organisms' },
                // { value: OrganismFilter.GENUS, label: 'Genus'},
              ]}
              value={queryParams.filterBy}
              onChange={(value) => handleFilterChange(value as OrganismFilter)}
            />
          </div>
          <div hidden={!genusFiltered}>
            <Label>Filter by Genus</Label>
            <Select
              spellCheck={false}
              size="sm"
              className={classes.genus}
              transitionProps={{ transition: 'scale-y', duration: 150, timingFunction: 'ease' }}
              searchable
              data={mapGeneraToDropdown(generaData)}
              onChange={(value) => handleGenusSelection(value as string)}
            />
          </div>

          <Title order={6} style={{ marginTop: '16px' }}>
            Your Total {mapFilterToText()}: {totalDetected}{' '}
            <TitlePopover
              direction="bottom-left"
              title="Your Total Organisms"
              content={
                <Stack>
                  <Text>
                    The total number of possible organisms that can exist in the human gut microbiome is enormous,
                    likely numbering over 1000 different species that could potentially colonize the gastrointestinal
                    tract. However, the average person harbors far fewer than this total potential amount:
                  </Text>
                  <List size="14px" spacing="0px">
                    <Text color="white">
                      <List.Item>
                        An individual typically has around 160 to 350 different species that make up their personal gut
                        microbiome.
                      </List.Item>
                      <List.Item>
                        The vast majority (around 90-99%) of the gut microbiome is comprised of just 30-40 different
                        species.
                      </List.Item>
                      <List.Item>
                        The most abundant gut bacteria phyla are Bacteroidetes and Firmicutes, followed by
                        Actinobacteria, Proteobacteria, and Verrucomicrobia.
                      </List.Item>
                      <List.Item>
                        The most common gut genera found in healthy adults include: Bacteroides, Prevotella,
                        Faecalibacterium, Roseburia, Ruminococcus, Bifidobacterium, and Blautia among others.
                      </List.Item>
                    </Text>
                  </List>

                  <Title order={6} italic>
                    Pathogens
                  </Title>
                  <Text>
                    Our list of pathogens is curated using recommendations and reports published by the Centers for
                    Disease Control and Prevention (CDC) on infectious diarrhea, gastroenteritis, food safety,
                    intestinal parasites as well as the CDC Yellow Book.
                  </Text>

                  <Title order={6} italic>
                    Probiotics
                  </Title>
                  <Text>
                    This is a common organism found in probiotic supplements and is one of several that are commonly
                    manufactured and available over the counter as dietary supplements
                  </Text>
                </Stack>
              }
            />
          </Title>
        </Flex>

        {/* Organisms List */}
        <OrganismsList
          useRelativeAbundanceForColor
          organisms={organisms}
          showResearchStatus={false}
          sortByRelativeAbundance={() => handleSortChange()}
          reversed={queryParams.sortByRelativeAbundance === SortingOrder.ASC}
          showRank={genusFiltered}
          sorted
        />

        {/* Pagination Buttons */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '16px',
            paddingBottom: '24px',
          }}
        >
          <div>
            <Button
              disabled={queryParams.page === 1}
              onClick={() => queryParams.page && handlePageChange(queryParams.page - 1)}
              style={{ marginRight: '8px' }}
            >
              Previous
            </Button>
            <Button
              disabled={queryParams.page === totalPages}
              onClick={() => queryParams.page && handlePageChange(queryParams.page + 1)}
            >
              Next
            </Button>
          </div>
          <div>
            Page {queryParams.page} of {totalPages}
          </div>
        </div>

        <Disclaimer />
      </PageContainer>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  genus: {
    input: {
      height: '2.25rem',
    },
  },
}));
