export interface IUser {
  auth0_id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface UserHasAccessResponse {
  hasAccess: boolean;
  message?: string;
}

export interface UserReport {
  barcode: string;
  user?: IUser;
  kb_version?: string;
  intakeComplete: boolean;
  reportReady: boolean;
  created_at: Date | string;
  userAccess?: UserHasAccessResponse;
}

export interface GetAllReportsResponse {
  auth0Id: string;
  userReports: UserReport[];
  providerReports: UserReport[];
  authorizedReports: UserReport[];
}

export interface FindingsPrioritized {
  intakePriority: ReportFindingOverview[];
  highPriority: ReportFindingOverview[];
  lowPriority: ReportFindingOverview[];
}

export interface AllFindings {
  barcode: string;
  conditions: FindingsPrioritized;
  symptoms: FindingsPrioritized;
  metabolisms: ReportFindingOverview[];

  goals: FindingGoals;

  pathogens: RelatedPathogen[];
  numberOfDetectedPathogens: number;
  alphaDiversity: AlphaDiversityMetric;
}

export interface FindingGoals {
  primaryGoal: FindingGoal;
  secondaryGoal?: FindingGoal;
}

export interface FindingGoal {
  name: string;
  overview?: ReportFindingOverview;
}

export interface FindingOverview {
  name: string;
  finding_id: string;
  kb: string;
  kb_id: string;

  organisms: ReportOrganism[];
  metabolisms: ReportFinding[];
  pathogens: RelatedPathogen[];

  diet_actions: OverviewAction[];
  food_triggers: OverviewAction[];
  lifestyles: OverviewAction[];
  treatments: OverviewAction[];
}

export interface OverviewAction {
  name: string;
  guidance: string;
}

export interface DisplaySymptom {
  _id: string;
  preferred_name: string;
}

export interface RelatedPathogen {
  organism_id: string;
  description: string;
  one_line_description: string;
  guidance: string;
  symptoms: ReportFindingOverview[];
  name: string;
}

export interface RelatedFinding {
  finding_id: string;
  kb: string;
  name: string;
  guidance: string;
}

export enum ActionType {
  DIET = 'diet',
  LIFESTYLE = 'lifestyle',
  FOODTRIGGER = 'food_trigger',
  TREATMENT = 'treatment',
}

export enum ACTION_KB {
  DIET = 'diets',
  LIFESTYLE = 'lifestyles',
  TREATMENTS = 'treatments',
}

export enum FINDING_KB {
  CONDITION = 'conditions',
  SYMPTOMS = 'symptoms',
  METABOLISMS = 'metabolisms',
}

export interface ReportTopMatch {
  kb_id: string;
  kb: string;
  score: number;
  impact_on_association: number;
  research_status: string;
  papers: string[];
  // kb
  name: string;
  preferred_name: string;
  description: string;
  one_line_description?: string;
  category: string;
}

// Enriched from KB
export interface KBData {
  name: string;
  preferred_name: string;
  category: string | undefined;
  description: string;
  one_line_description?: string;
  bullet_point_description?: DescriptionList[];
  relations?: any; //TODO:JD add type
}

// ACTION
export interface ReportActionOverview extends KBData, Diff {
  action_id: string;

  action_type: ActionType;
  research_status: string;
  association_score: number;
  guidance: string;

  papers_length: number;
  top_matches?: ReportTopMatch[];
}
export interface ReportAction extends KBData, Diff {
  action_id: string;

  action_type: ActionType;
  kb: string;
  kb_id: string;
  guidance: string;
  guidance_direction: number;
  type: string;
  score: number;
  association_score: number;
  research_status: string;
  improvement: number;

  findingName: string;

  papers: ReportPaper[];
  organisms: ReportOrganism[];
  recommended_foods?: ReportFoodItem[];
  foods_to_avoid?: ReportFoodItem[];
  other_recommended_foods?: ReportFoodItem[];
}

// FINDING
export interface ReportFindingOverview extends KBData, Diff {
  finding_id: string;

  kb_id: string;
  research_status: string;
  association_score: number;
  guidance: string;

  intakeConcern?: boolean;

  organisms: ReportOrganism[];
  symptoms?: ReportFindingOverview[];
  conditions?: ReportFindingOverview[];
}
export interface ReportFinding extends KBData, Diff {
  finding_id: string;

  kb: string;
  kb_id: string;
  research_status: string;
  association_score: number;
  guidance: string;
  guidance_direction: number;
  extended_guidance?: string[];

  intakeConcern?: boolean;

  actions: ReportActionOverview[];
  organisms: ReportOrganism[];
  papers: ReportPaper[];
  topPapers?: ReportPaper[];
}

export interface FindingFoods {
  finding_id: string;
  recommended_foods?: ReportFoodItem[];
  foods_to_avoid?: ReportFoodItem[];
}

export interface ReportFoodItem {
  name: string;
  description: string;
  imgPath: string;
}

export interface AssociatedFinding {
  finding_id: string;
  name: string;
  kb: string;
  category: string;
}

export interface ReportPaper {
  _id: string;
  paper_title: string;
  paper_abstract: string;
  publisher: string;

  url?: string;

  matches: ReportOrganism[];
  organismNames?: string[];
}

export interface ReportOrganism {
  _id: string;
  name: string;
  description: string;
  impact_on_association: number;

  papers_count?: number;

  one_line_description: string;
  research_status: string;
  score: number;
  guidance: string;
  range: number;
  relative_abundance: number;
  rank: string;
  is_pathogen: boolean;
  is_probiotic: boolean;
}

export interface ActionPlanQuery {
  findingId?: string;
}

export interface ActionPlan {
  goal_name: string;
  goal_kb_id: string;
  goal_finding_id: string;

  barcode: string;
  trigger_foods: RelatedAction[];
  diet_recommendations: RelatedAction[];
  associated_conditions: ReportFinding[];

  pathogens: RelatedPathogen[];
}

export interface FindingListItem {
  finding_id: string;
  name: string;
}

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum OrganismFilter {
  PATHOGEN = 'pathogen',
  PROBIOTIC = 'probiotic',
  PHYLUN = 'phylum',
  GENUS = 'genus',
  NONE = 'none',
}

export interface ReportOrganismQuery {
  sortByRelativeAbundance?: SortingOrder;
  page?: number;
  perPage?: number;
  search?: string;
  filterBy?: OrganismFilter;
  genusId?: string;
}

export interface OrganismResult {
  organisms: ReportOrganism[];
  page: number;
  perPage: number;
  total: number;
  totalDetected: number;
}

export interface RelatedAction {
  action_id: string;
  finding_id: string;
}

export interface OrganismGenus {
  id: string;
  name: string;
}

export interface OrganismGeneraResult {
  genera: OrganismGenus[];
}

interface OrganismPhylum {
  name: string;
  abundance: number;
}

// response from getReportOrganismPhyla
export interface OrganismPhylaResult {
  phyla: OrganismPhylum[];
}

// transformed data for Phyla visualization
export interface OrganismPhylaData extends OrganismPhylum {
  children: OrganismPhylaData[];
}

export interface DescriptionList {
  header: string;
  descriptions: string[];
}

export interface SampleMetadata {
  userFirstName?: string;
  userLastName?: string;

  labReceivedAt?: string;
  reportReadyAt?: string;
}

// Report Diff Types
// ------------------
export type Changes = { [key: string]: { old: any; new: any } };

export interface Diff {
  _id: string;
  name: string;
  kb_id: string;
  kb: string;
  guidance?: string;
  changes?: Changes;
  isNewItem?: boolean;
  isRemoved?: boolean;
}

interface ActionDiff extends Diff {}

export interface FindingDiff extends Diff {
  actions?: ActionDiff[];
}

export interface ReportDiff {
  _id: string;
  report_version_id: string;
  findings?: FindingDiff[];
  viewedByUser: boolean;
}

export interface IReportChangelog {
  title: string;
  markdownDetails: string;
  publishedAt?: string;
}

export interface AlphaDiversityMetric {
  value: number;
  upper_bound: number;
  lower_bound: number;
}