import { PageContainer } from 'ui/Containers';
import providerApi from 'services/provider';
import { AddClientForm } from 'ui/Jona-v1/Forms';
import { Error, ErrorPopup } from 'ui/ErrorPage';

import { PageHeader } from 'ui/PageHeader';
import Loading from 'components/Loading';
import { capitalizeAll } from 'utils';
import { ClientData } from 'ui/Jona-v1/Forms/AddClientForm';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useNotifications } from 'hooks';
import { ModalType, openModal, setModalMessage } from 'slices/app';
import userApi from 'services/user';
import { useEffect } from 'react';
import { resetClientKit } from 'slices/clientKit';

//TODO:EMAIL_INVITE This should be in a database and configurable in the portal or provider profile page
const INVITATION_READ_ONLY = 'To accept the invitation, please create an account by clicking the link below:';
const INVITATION_AND_ORDER_READ_ONLY = (name: any, url: any, code: any) =>
  `To accept the invitation and order the Jona kit, please click the link provided by ${name} here: ${url} and use the code: [${code}]`;

export default function AddClient() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showNotification, NotificationType } = useNotifications();

  const [inviteClient, { error: inviteClientError }] = providerApi.endpoints.inviteClient.useMutation();

  // reset the selected client when the page is loaded
  useEffect(() => {
    dispatch(resetClientKit());
  }, [dispatch]);

  const {
    data: userStaffProviderData,
    isLoading: userStaffProviderIsLoading,
    error: userStaffProviderError,
  } = userApi.endpoints.getStaffProvider.useQuery();

  if (userStaffProviderError) return <Error error={userStaffProviderError} />;
  if (userStaffProviderIsLoading) return <Loading />;

  const handleSendInvite = async (data: ClientData) => {
    if (!userStaffProviderData?.id) {
      console.error('Provider ID not found');
      return;
    }
    try {
      const { invitationMessageCustom, ...rest } = data;
      await inviteClient({
        ...rest,
        providerId: userStaffProviderData.id,
        invitationMessage: invitationMessageCustom,
      }).unwrap();

      // if there's no error after sending the invite, show the success message
      const firstAndLastName = capitalizeAll(`${rest.firstName} ${rest.lastName}`);
      const inviteSuccessMessage = `You have successfully invited ${firstAndLastName} as a client.`;

      // if the provider is purchasing a kit, show the modal
      if (!rest.clientPurchaseKit) {
        dispatch(setModalMessage(inviteSuccessMessage));
        dispatch(openModal(ModalType.ClientInviteNotification));
      } else {
        // if the client is purchasing a kit, show the notification
        showNotification({
          type: NotificationType.SUCCESS,
          message: inviteSuccessMessage,
        });
      }
      navigate('/clients');
    } catch (error) {
      console.error('Error sending invite:', error);
    }
  };

  const handleCancel = () => {
    navigate('/clients');
  };

  return (
    <>
      <PageHeader title="Add New Client" />
      <PageContainer size="md">
        {inviteClientError && <ErrorPopup error={inviteClientError} />}
        <AddClientForm
          defaultInviteText={userStaffProviderData?.inviteMessage}
          inviteReadOnlyText={INVITATION_READ_ONLY}
          orderAndInviteReadOnlyText={INVITATION_AND_ORDER_READ_ONLY(
            userStaffProviderData?.name,
            userStaffProviderData?.orderUrl,
            userStaffProviderData?.shopifyOrderCode
          )}
          onSendInvite={handleSendInvite}
          onCancel={handleCancel}
        />
      </PageContainer>
    </>
  );
}
