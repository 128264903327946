import CustomAccordion from './CustomAccordion/CustomAccordion';
import CustomAccordionContent from './CustomAccordion/CustomAccordionContent';
import CustomAccordionTitle from './CustomAccordion/CustomAccordionTitle';
import { type FindingsPrioritized } from 'types/V2Types';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Paper, Text } from '@mantine/core';
import { getHealthGoalsLink } from 'utilsV2';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface ConditionsAccordionProps {
  conditions: FindingsPrioritized;
}

const ConditionsAccordion: React.FC<ConditionsAccordionProps> = ({ conditions }) => {
  const { barcode } = useParams() as { barcode: string };
  const { useAssignedSamples } = useFlags();
  const locationState: { openAccordions?: boolean } = useLocation().state || { openAccordions: false };

  const { intakePriority, highPriority, lowPriority } = conditions;

  const intakeLink = getHealthGoalsLink(barcode, useAssignedSamples);

  return (
    <>
      <CustomAccordion
        title="Self-reported Conditions"
        initiallyOpen={locationState.openAccordions}
        description={
          !!intakePriority.length ? (
            <Text italic>
              Below are the findings related to your self-reported conditions in your{' '}
              <Link to={intakeLink}>health survey.</Link>
            </Text>
          ) : (
            <Paper withBorder radius={2} fz="sm" p="md">
              <Text>
                Based on your sample, you do not have any self-reported conditions from your{' '}
                <Link to={intakeLink}>health survey.</Link>
              </Text>
            </Paper>
          )
        }
        items={intakePriority.map((condition) => {
          return {
            id: condition.finding_id,
            category: condition.category,
            title: (
              <CustomAccordionTitle
                title={condition.preferred_name ?? condition.name}
                guidance={condition.guidance}
                associationScore={condition.association_score}
                researchStatus={condition.research_status}
                category={condition.category}
                isNewItem={condition.isNewItem}
                hasChanges={!!condition.changes}
              />
            ),
            content: (
              <CustomAccordionContent
                id={condition.finding_id}
                linkUrl={`/reports/${barcode}/conditions/${condition.finding_id}`}
                name={condition.preferred_name ?? condition.name}
                description={condition.one_line_description ?? condition.description}
                barcode={barcode}
                symptoms={condition.symptoms}
                totalSymptomsLength={condition.relations.causes.symptoms.length}
                organisms={condition.organisms}
              />
            ),
          };
        })}
      />

      <CustomAccordion
        mt="md"
        title="Associated Conditions"
        initiallyOpen={locationState.openAccordions}
        description={
          !!highPriority.length ? (
            <Text italic>Below are the closest associated conditions to your microbiome signature.</Text>
          ) : (
            <Paper withBorder radius={2} fz="sm" p="md">
              <Text>Based on your sample, you do not have any high microbiome associations.</Text>
            </Paper>
          )
        }
        items={highPriority.map((condition) => {
          return {
            id: condition.finding_id,
            category: condition.category,
            title: (
              <CustomAccordionTitle
                title={condition.preferred_name ?? condition.name}
                guidance={condition.guidance}
                associationScore={condition.association_score}
                researchStatus={condition.research_status}
                category={condition.category}
                isNewItem={condition.isNewItem}
                hasChanges={!!condition.changes}
              />
            ),
            content: (
              <CustomAccordionContent
                id={condition.finding_id}
                linkUrl={`/reports/${barcode}/conditions/${condition.finding_id}`}
                name={condition.preferred_name ?? condition.name}
                description={condition.one_line_description ?? condition.description}
                barcode={barcode}
                symptoms={condition.symptoms}
                totalSymptomsLength={condition.relations.causes.symptoms.length}
                organisms={condition.organisms}
              />
            ),
          };
        })}
      />

      {!!lowPriority.length && (
        <CustomAccordion
          mt="md"
          title="All Conditions"
          initiallyOpen={locationState.openAccordions}
          description={
            <Text italic>Below are the rest of the findings associating any conditions to your microbiome.</Text>
          }
          colapsable={true}
          showN={0}
          items={lowPriority.map((condition) => {
            return {
              id: condition.finding_id,
              category: condition.category,
              title: (
                <CustomAccordionTitle
                  title={condition.preferred_name ?? condition.name}
                  guidance={condition.guidance}
                  associationScore={condition.association_score}
                  researchStatus={condition.research_status}
                  category={condition.category}
                  isNewItem={condition.isNewItem}
                  hasChanges={!!condition.changes}
                />
              ),
              content: (
                <CustomAccordionContent
                  id={condition.finding_id}
                  linkUrl={`/reports/${barcode}/conditions/${condition.finding_id}`}
                  name={condition.preferred_name ?? condition.name}
                  description={condition.one_line_description ?? condition.description}
                  barcode={barcode}
                  symptoms={condition.symptoms}
                  totalSymptomsLength={condition.relations.causes.symptoms.length}
                  organisms={condition.organisms}
                />
              ),
            };
          })}
        />
      )}
    </>
  );
};

export default ConditionsAccordion;
