import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

export interface PageMetadata {
  title: string;
  description?: string;
}

const DEFAULT_METADATA: PageMetadata = {
  title: 'Jona',
  description:
    'Imagine an AI system that can read the entire scientific and medical literature on the microbiome and personally analyze your gut health',
};

const usePageMetadata = ({ title, description } = DEFAULT_METADATA) => {
  useEffect(() => {
    if (document.title !== title) {
      if (document.title !== DEFAULT_METADATA.title) {
        mixpanel.track('Page View', { title: document.title });
        mixpanel.time_event('Page View');
      }
      document.title = title;
    }

    if (description) {
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) metaDescription.setAttribute('content', description);
    }
    // ... other metadata ...
  }, [title, description]);
};

export default usePageMetadata;
