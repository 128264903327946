import { Outlet } from 'react-router-dom';
import { IntakeStep, IntakeTitles } from 'types/intakeV2';
import { PageContainer } from 'ui/Containers';
import { PageHeader } from 'ui/PageHeader';

export default function Intake() {
  const getTitle = () => {
    if (location.pathname.includes('consent')) return IntakeTitles[IntakeStep.ToSConsent];
    if (location.pathname.includes('kit')) return IntakeTitles[IntakeStep.KitRegistration];
    if (location.pathname.includes('demographics')) return IntakeTitles[IntakeStep.Demographics];
    if (location.pathname.includes('lifestyle')) return IntakeTitles[IntakeStep.LifestyleAndNutrition];
    if (location.pathname.includes('history')) return IntakeTitles[IntakeStep.HealthHistory];
    return 'Health Survey';
  };

  return (
    <>
      <PageHeader title={getTitle()} />
      <PageContainer size="sm">
        <Outlet />
      </PageContainer>
    </>
  );
}
