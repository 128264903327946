import React from 'react';
import { Title, createStyles, Flex, TitleOrder, Stack } from '@mantine/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCurrentKit } from 'slices/kit';
import { BackButton } from 'ui/Buttons';
import { usePageMetadata, useQuery } from 'hooks';
import { TitlePopover } from 'components/ReportV2/elements/Popovers';
import { BarcodeBadge } from 'ui/Badges';
import Breadcrumbs from 'ui/Jona-v1/Breadcrumbs';

interface Props {
  title: string | React.ReactNode;
  pageTitle?: string;
  toolTip?: React.ReactNode;
  showBackButton?: boolean;
  extraInfo?: React.ReactNode;
  className?: string;
  titleOrder?: TitleOrder;
  showBreadcrumbs?: boolean;
}

export const PageHeader: React.FC<Props> = ({
  title,
  pageTitle,
  toolTip,
  showBackButton = true,
  extraInfo,
  className,
  titleOrder,
  showBreadcrumbs = true,
}) => {
  const { classes } = useStyles();

  if (pageTitle) {
    usePageMetadata({ title: pageTitle });
  } else if (typeof title === 'string') {
    usePageMetadata({ title });
  }

  const [query] = useQuery();
  const shouldShowBackButton = query.get('back') === 'true' || showBackButton;

  const { currentKit } = useSelector(selectCurrentKit);

  return (
    <div className={className}>
      <Flex justify="space-between" mb="lg">
        <Stack>
          {shouldShowBackButton && <BackButton>Back</BackButton>}
          {showBreadcrumbs && <Breadcrumbs />}
        </Stack>

        <Stack>
          {currentKit?.barcode && (
            <Link to="/reports" style={{ textDecoration: 'none' }}>
              <BarcodeBadge barcode={currentKit?.barcode} />
            </Link>
          )}
        </Stack>
      </Flex>

      <Flex justify="space-between" fw="500" align="flex-end" className={classes.header}>
        <Flex justify="center" align="center">
          <Title order={titleOrder} fw="500" mr="sm" className={classes.title}>
            {title}
          </Title>
          {toolTip && <TitlePopover direction="bottom-right" content={toolTip} />}
        </Flex>

        {extraInfo}
      </Flex>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  header: {
    borderBottom: `1px solid ${theme.colors.dark[5]}`,
    paddingTop: theme.spacing.md,
  },
  title: {
    textTransform: 'capitalize',
    alignSelf: 'flex-end',
  },
  date: {
    fontSize: theme.fontSizes.sm,
    lineHeight: 1,
  },
  icon: {
    marginRight: theme.spacing.xs,
    marginLeft: theme.spacing.xs,
  },
  barcode: {
    lineHeight: 1,
    marginLeft: 'auto',
    fontSize: theme.fontSizes.md,
    textDecoration: 'none',
  },
}));
