import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setToken as setAccessToken } from 'services/token';

export function useAuthenticatedToken() {
  const [token, setToken] = useState('');
  const { user, isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect, error } = useAuth0();

  useEffect(() => {
    const initialize = async () => {
      if (!isAuthenticated) return;
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
      setToken(accessToken);
    };

    initialize();
  }, [isAuthenticated, getAccessTokenSilently]);

  // Handle redirection and loading states outside of the hook to allow for more flexible UI handling
  return { user, token, isLoading, isAuthenticated, loginWithRedirect, error };
}

export default useAuthenticatedToken;
